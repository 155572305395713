/*
    Table of Contents

    Name                 : MOMENTUM
    Author               : L
    Author Portfolio     : https://intergral-gs.com
    


    Table of Sass Contents

    1. Abstracts
        _bs-custom
        _inheritance
        _maps
        _mixin
        _variable

    2. bootstrap 
        -Here goes all official bootstrap scss files

    3. Global/Base
        _color
        _custom-grid
        _fonts
        _helper
        _reset

    4. layout
        +footer
        +header
        +rtl
        +sidebar
        +tables
        +theme
        +version-dark

    5. Conponents
        +app
        +charts
        +forms
        +map
        +uc
        +ui
        +widget

    6. Page
        _index-1
        _page-error
        _page-register
        _page-signin
        _page-user-lock
        _page-user-lock

*/
/*$primary  : #7356f1 !default;*/
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");
@import url("./../icons/simple-line-icons/css/simple-line-icons.css");
@import url("./../icons/font-awesome-old/css/font-awesome.min.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");
@import url("./../icons/line-awesome/css/line-awesome.min.css");
@import url("./../icons/avasta/css/style.css");
@import url("./../icons/flaticon/flaticon.css");
@import url("./../icons/icomoon/icomoon.css");
@import url(./../vendor/animate/animate.min.css);
@import url(./../vendor/aos/css/aos.min.css);
@import url(./../vendor/perfect-scrollbar/css/perfect-scrollbar.css);
@import url(./../vendor/metismenu/css/metisMenu.min.css);
.gradient_one {
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%); }

.gradient-1 {
  background: #f0a907;
  background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0a907', endColorstr='#f53c79',GradientType=0 ); }

.gradient-2 {
  background: #4dedf5;
  background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4dedf5', endColorstr='#480ceb',GradientType=0 ); }

.gradient-3 {
  background: #51f5ae;
  background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#51f5ae', endColorstr='#3fbcda',GradientType=0 ); }

.gradient-4 {
  background: #f25521;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f25521', endColorstr='#f9c70a',GradientType=1 ); }

.gradient-5 {
  background: #f53c79;
  background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f53c79', endColorstr='#f0a907',GradientType=1 ); }

.gradient-6 {
  background: #36b9d8;
  background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36b9d8', endColorstr='#4bffa2',GradientType=1 ); }

.gradient-7 {
  background: #4400eb;
  background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4400eb', endColorstr='#44e7f5',GradientType=1 ); }

.gradient-8 {
  background: #F7B00F;
  background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F7B00F', endColorstr='#F25521',GradientType=1 ); }

.gradient-9, .datepicker.datepicker-dropdown td.day:hover, .datepicker.datepicker-dropdown th.next:hover, .datepicker.datepicker-dropdown th.prev:hover, .datepicker table tr td.selected, .datepicker table tr td.active, .datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
  background: #f31e7a !important;
  background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f31e7a', endColorstr='#fd712c',GradientType=1 ); }

.gradient-10 {
  background: #f25521 !important;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f25521', endColorstr='#f9c70a',GradientType=1 ); }

.gradient-11 {
  background: #3398fb;
  background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3398fb', endColorstr='#8553ee',GradientType=1 ); }

.gradient-12 {
  background: #36e1b4;
  background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36e1b4', endColorstr='#11cae7',GradientType=1 ); }

.gradient-13 {
  background: #ffbf31;
  background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffbf31', endColorstr='#ff890e',GradientType=1 ); }

.gradient-14 {
  background: #23bdb8;
  background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#23bdb8', endColorstr='#43e794',GradientType=1 ); }

.gradient-15 {
  background: #9a56ff;
  background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9a56ff', endColorstr='#e36cd9',GradientType=1 ); }

.gradient-16 {
  background: #f48665;
  background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f48665', endColorstr='#fda23f',GradientType=1 ); }

.gradient-17 {
  background: #e36cd9;
  background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e36cd9', endColorstr='#fe60ae',GradientType=1 ); }

.gradient-18 {
  background: #a15cff;
  background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a15cff', endColorstr='#ce82fd',GradientType=1 ); }

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/*!
 * Bootstrap v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #5e72e4;
  --indigo: #6610f2;
  --purple: #7d49eb;
  --pink: #e83e8c;
  --red: #EE3232;
  --orange: #ff9900;
  --yellow: #FFFA6F;
  --green: #297F00;
  --teal: #20c997;
  --cyan: #3065D0;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary:#3F9AE0 ;
  /* #DD2F6E */ 
  --secondary: #392540;
  --success: #2bc155;
  --info: #2F4CDD;
  --warning: #ffb800;
  --danger: #f35757;
  --light: #f4f4f4;
  --dark: #3e4954;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; 
--iconBlue:#2f80ed;
}

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem !important;
  /* font-size: 0.875rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #7e7e7e;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #3F9AE0;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #a51b4d;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #89879f;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #3d4465; }

h1, .h1 {
  font-size: 2.25rem; }

h2, .h2 {
  font-size: 1.875rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.938rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.75rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }
@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%; }

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%; }

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%; }

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%; }

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.3333333333%; }

.offset-2 {
  margin-left: 16.6666666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.3333333333%; }

.offset-5 {
  margin-left: 41.6666666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.3333333333%; }

.offset-8 {
  margin-left: 66.6666666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.3333333333%; }

.offset-11 {
  margin-left: 91.6666666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-sm-first {
    order: -1; }

  .order-sm-last {
    order: 13; }

  .order-sm-0 {
    order: 0; }

  .order-sm-1 {
    order: 1; }

  .order-sm-2 {
    order: 2; }

  .order-sm-3 {
    order: 3; }

  .order-sm-4 {
    order: 4; }

  .order-sm-5 {
    order: 5; }

  .order-sm-6 {
    order: 6; }

  .order-sm-7 {
    order: 7; }

  .order-sm-8 {
    order: 8; }

  .order-sm-9 {
    order: 9; }

  .order-sm-10 {
    order: 10; }

  .order-sm-11 {
    order: 11; }

  .order-sm-12 {
    order: 12; }

  .offset-sm-0 {
    margin-left: 0; }

  .offset-sm-1 {
    margin-left: 8.3333333333%; }

  .offset-sm-2 {
    margin-left: 16.6666666667%; }

  .offset-sm-3 {
    margin-left: 25%; }

  .offset-sm-4 {
    margin-left: 33.3333333333%; }

  .offset-sm-5 {
    margin-left: 41.6666666667%; }

  .offset-sm-6 {
    margin-left: 50%; }

  .offset-sm-7 {
    margin-left: 58.3333333333%; }

  .offset-sm-8 {
    margin-left: 66.6666666667%; }

  .offset-sm-9 {
    margin-left: 75%; }

  .offset-sm-10 {
    margin-left: 83.3333333333%; }

  .offset-sm-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-md-first {
    order: -1; }

  .order-md-last {
    order: 13; }

  .order-md-0 {
    order: 0; }

  .order-md-1 {
    order: 1; }

  .order-md-2 {
    order: 2; }

  .order-md-3 {
    order: 3; }

  .order-md-4 {
    order: 4; }

  .order-md-5 {
    order: 5; }

  .order-md-6 {
    order: 6; }

  .order-md-7 {
    order: 7; }

  .order-md-8 {
    order: 8; }

  .order-md-9 {
    order: 9; }

  .order-md-10 {
    order: 10; }

  .order-md-11 {
    order: 11; }

  .order-md-12 {
    order: 12; }

  .offset-md-0 {
    margin-left: 0; }

  .offset-md-1 {
    margin-left: 8.3333333333%; }

  .offset-md-2 {
    margin-left: 16.6666666667%; }

  .offset-md-3 {
    margin-left: 25%; }

  .offset-md-4 {
    margin-left: 33.3333333333%; }

  .offset-md-5 {
    margin-left: 41.6666666667%; }

  .offset-md-6 {
    margin-left: 50%; }

  .offset-md-7 {
    margin-left: 58.3333333333%; }

  .offset-md-8 {
    margin-left: 66.6666666667%; }

  .offset-md-9 {
    margin-left: 75%; }

  .offset-md-10 {
    margin-left: 83.3333333333%; }

  .offset-md-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-lg-first {
    order: -1; }

  .order-lg-last {
    order: 13; }

  .order-lg-0 {
    order: 0; }

  .order-lg-1 {
    order: 1; }

  .order-lg-2 {
    order: 2; }

  .order-lg-3 {
    order: 3; }

  .order-lg-4 {
    order: 4; }

  .order-lg-5 {
    order: 5; }

  .order-lg-6 {
    order: 6; }

  .order-lg-7 {
    order: 7; }

  .order-lg-8 {
    order: 8; }

  .order-lg-9 {
    order: 9; }

  .order-lg-10 {
    order: 10; }

  .order-lg-11 {
    order: 11; }

  .order-lg-12 {
    order: 12; }

  .offset-lg-0 {
    margin-left: 0; }

  .offset-lg-1 {
    margin-left: 8.3333333333%; }

  .offset-lg-2 {
    margin-left: 16.6666666667%; }

  .offset-lg-3 {
    margin-left: 25%; }

  .offset-lg-4 {
    margin-left: 33.3333333333%; }

  .offset-lg-5 {
    margin-left: 41.6666666667%; }

  .offset-lg-6 {
    margin-left: 50%; }

  .offset-lg-7 {
    margin-left: 58.3333333333%; }

  .offset-lg-8 {
    margin-left: 66.6666666667%; }

  .offset-lg-9 {
    margin-left: 75%; }

  .offset-lg-10 {
    margin-left: 83.3333333333%; }

  .offset-lg-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-xl-first {
    order: -1; }

  .order-xl-last {
    order: 13; }

  .order-xl-0 {
    order: 0; }

  .order-xl-1 {
    order: 1; }

  .order-xl-2 {
    order: 2; }

  .order-xl-3 {
    order: 3; }

  .order-xl-4 {
    order: 4; }

  .order-xl-5 {
    order: 5; }

  .order-xl-6 {
    order: 6; }

  .order-xl-7 {
    order: 7; }

  .order-xl-8 {
    order: 8; }

  .order-xl-9 {
    order: 9; }

  .order-xl-10 {
    order: 10; }

  .order-xl-11 {
    order: 11; }

  .order-xl-12 {
    order: 12; }

  .offset-xl-0 {
    margin-left: 0; }

  .offset-xl-1 {
    margin-left: 8.3333333333%; }

  .offset-xl-2 {
    margin-left: 16.6666666667%; }

  .offset-xl-3 {
    margin-left: 25%; }

  .offset-xl-4 {
    margin-left: 33.3333333333%; }

  .offset-xl-5 {
    margin-left: 41.6666666667%; }

  .offset-xl-6 {
    margin-left: 50%; }

  .offset-xl-7 {
    margin-left: 58.3333333333%; }

  .offset-xl-8 {
    margin-left: 66.6666666667%; }

  .offset-xl-9 {
    margin-left: 75%; }

  .offset-xl-10 {
    margin-left: 83.3333333333%; }

  .offset-xl-11 {
    margin-left: 91.6666666667%; } }
@media (min-width: 1440) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }

  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }

  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }

  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }

  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }

  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-xxl-first {
    order: -1; }

  .order-xxl-last {
    order: 13; }

  .order-xxl-0 {
    order: 0; }

  .order-xxl-1 {
    order: 1; }

  .order-xxl-2 {
    order: 2; }

  .order-xxl-3 {
    order: 3; }

  .order-xxl-4 {
    order: 4; }

  .order-xxl-5 {
    order: 5; }

  .order-xxl-6 {
    order: 6; }

  .order-xxl-7 {
    order: 7; }

  .order-xxl-8 {
    order: 8; }

  .order-xxl-9 {
    order: 9; }

  .order-xxl-10 {
    order: 10; }

  .order-xxl-11 {
    order: 11; }

  .order-xxl-12 {
    order: 12; }

  .offset-xxl-0 {
    margin-left: 0; }

  .offset-xxl-1 {
    margin-left: 8.3333333333%; }

  .offset-xxl-2 {
    margin-left: 16.6666666667%; }

  .offset-xxl-3 {
    margin-left: 25%; }

  .offset-xxl-4 {
    margin-left: 33.3333333333%; }

  .offset-xxl-5 {
    margin-left: 41.6666666667%; }

  .offset-xxl-6 {
    margin-left: 50%; }

  .offset-xxl-7 {
    margin-left: 58.3333333333%; }

  .offset-xxl-8 {
    margin-left: 66.6666666667%; }

  .offset-xxl-9 {
    margin-left: 75%; }

  .offset-xxl-10 {
    margin-left: 83.3333333333%; }

  .offset-xxl-11 {
    margin-left: 91.6666666667%; } }
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #7e7e7e; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #EEEEEE; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #EEEEEE; }
  .table tbody + tbody {
    border-top: 2px solid #EEEEEE; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #EEEEEE; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #EEEEEE; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #7e7e7e;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f5c5d6; }
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ed93b4; }

.table-hover .table-primary:hover {
  background-color: #f1afc7; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #f1afc7; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c8c2ca; }
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #988e9c; }

.table-hover .table-secondary:hover {
  background-color: #bcb4be; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #bcb4be; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c4eecf; }
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #91dfa7; }

.table-hover .table-success:hover {
  background-color: #b0e8bf; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b0e8bf; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c5cdf5; }
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #93a2ed; }

.table-hover .table-info:hover {
  background-color: #afbaf1; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #afbaf1; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffebb8; }
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffda7a; }

.table-hover .table-warning:hover {
  background-color: #ffe49f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe49f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fcd0d0; }
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f9a8a8; }

.table-hover .table-danger:hover {
  background-color: #fab8b8; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fab8b8; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc; }
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f9f9f9; }

.table-hover .table-light:hover {
  background-color: #efefef; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #efefef; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c9cccf; }
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9ba0a6; }

.table-hover .table-dark:hover {
  background-color: #bcbfc3; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bcbfc3; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #EEEEEE; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }
@media (max-width: 1439.98) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d7dae3;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: var(--iconBlue);
    /* border-color: #ef9dba;  */
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 47, 110, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #7e7e7e;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    color: #89879f; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #2bc155; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(43, 193, 85, 0.9);
  border-radius: 0.75rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #2bc155;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232bc155' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #2bc155;
    box-shadow: 0 0 0 0.2rem rgba(43, 193, 85, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #2bc155;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232bc155' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #2bc155;
    box-shadow: 0 0 0 0.2rem rgba(43, 193, 85, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #2bc155; }
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #2bc155; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #2bc155; }
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #49d670;
  background-color: #49d670; }
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(43, 193, 85, 0.25); }
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #2bc155; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #2bc155; }
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #2bc155;
  box-shadow: 0 0 0 0.2rem rgba(43, 193, 85, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f35757; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(243, 87, 87, 0.9);
  border-radius: 0.75rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f35757;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f35757' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f35757' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f35757;
    box-shadow: 0 0 0 0.2rem rgba(243, 87, 87, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f35757;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f35757' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f35757' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #f35757;
    box-shadow: 0 0 0 0.2rem rgba(243, 87, 87, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f35757; }
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f35757; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #f35757; }
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f68787;
  background-color: #f68787; }
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(243, 87, 87, 0.25); }
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f35757; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f35757; }
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f35757;
  box-shadow: 0 0 0 0.2rem rgba(243, 87, 87, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #7e7e7e;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #7e7e7e;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 47, 110, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #3F9AE0;
  border-color: #3F9AE0; }
  .btn-primary:hover {
    color: #fff;
    background-color: #c5205c;
    border-color: #bb1e57; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #c5205c;
    border-color: #bb1e57;
    box-shadow: 0 0 0 0.2rem rgba(226, 78, 132, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #3F9AE0;
    border-color: #3F9AE0; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(226, 78, 132, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #392540;
  border-color: #392540; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #231728;
    border-color: #1c1220; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #231728;
    border-color: #1c1220;
    box-shadow: 0 0 0 0.2rem rgba(87, 70, 93, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #392540;
    border-color: #392540; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #1c1220;
    border-color: #150e18; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(87, 70, 93, 0.5); }

.btn-success {
  color: #fff;
  background-color: #2bc155;
  border-color: #2bc155; }
  .btn-success:hover {
    color: #fff;
    background-color: #24a247;
    border-color: #229743; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #24a247;
    border-color: #229743;
    box-shadow: 0 0 0 0.2rem rgba(75, 202, 111, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #2bc155;
    border-color: #2bc155; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #229743;
    border-color: #1f8d3e; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(75, 202, 111, 0.5); }

.btn-info {
  color: #fff;
  background-color: #2F4CDD;
  border-color: #2F4CDD; }
  .btn-info:hover {
    color: #fff;
    background-color: #203cc5;
    border-color: #1e38bb; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #203cc5;
    border-color: #1e38bb;
    box-shadow: 0 0 0 0.2rem rgba(78, 103, 226, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #2F4CDD;
    border-color: #2F4CDD; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1e38bb;
    border-color: #1d35b0; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(78, 103, 226, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffb800;
  border-color: #ffb800; }
  .btn-warning:hover {
    color: #212529;
    background-color: #d99c00;
    border-color: #cc9300; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #d99c00;
    border-color: #cc9300;
    box-shadow: 0 0 0 0.2rem rgba(222, 162, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffb800;
    border-color: #ffb800; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc9300;
    border-color: #bf8a00; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 162, 6, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #f35757;
  border-color: #f35757; }
  .btn-danger:hover {
    color: #fff;
    background-color: #f03333;
    border-color: #f02727; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #f03333;
    border-color: #f02727;
    box-shadow: 0 0 0 0.2rem rgba(245, 112, 112, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f35757;
    border-color: #f35757; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f02727;
    border-color: #ef1c1c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 112, 112, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f4f4f4;
  border-color: #f4f4f4; }
  .btn-light:hover {
    color: #212529;
    background-color: #e1e1e1;
    border-color: #dbdbdb; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e1e1e1;
    border-color: #dbdbdb;
    box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f4f4f4;
    border-color: #f4f4f4; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dbdbdb;
    border-color: #d4d4d4; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 213, 214, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #3e4954;
  border-color: #3e4954; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2e363e;
    border-color: #283037; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #2e363e;
    border-color: #283037;
    box-shadow: 0 0 0 0.2rem rgba(91, 100, 110, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #3e4954;
    border-color: #3e4954; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #283037;
    border-color: #23292f; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(91, 100, 110, 0.5); }

.btn-outline-primary {
  color: #3F9AE0;
  border-color: #3F9AE0; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #3F9AE0;
    border-color: #3F9AE0; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 47, 110, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #3F9AE0;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3F9AE0;
    border-color: #3F9AE0; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 47, 110, 0.5); }

.btn-outline-secondary {
  color: #392540;
  border-color: #392540; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #392540;
    border-color: #392540; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(57, 37, 64, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #392540;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #392540;
    border-color: #392540; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(57, 37, 64, 0.5); }

.btn-outline-success {
  color: #2bc155;
  border-color: #2bc155; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #2bc155;
    border-color: #2bc155; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(43, 193, 85, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #2bc155;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #2bc155;
    border-color: #2bc155; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(43, 193, 85, 0.5); }

.btn-outline-info {
  color: #2F4CDD;
  border-color: #2F4CDD; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #2F4CDD;
    border-color: #2F4CDD; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 76, 221, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #2F4CDD;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #2F4CDD;
    border-color: #2F4CDD; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(47, 76, 221, 0.5); }

.btn-outline-warning {
  color: #ffb800;
  border-color: #ffb800; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffb800;
    border-color: #ffb800; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 184, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffb800;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffb800;
    border-color: #ffb800; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 184, 0, 0.5); }

.btn-outline-danger {
  color: #f35757;
  border-color: #f35757; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f35757;
    border-color: #f35757; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 87, 87, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f35757;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f35757;
    border-color: #f35757; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 87, 87, 0.5); }

.btn-outline-light {
  color: #f4f4f4;
  border-color: #f4f4f4; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f4f4f4;
    border-color: #f4f4f4; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f4f4f4;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f4f4f4;
    border-color: #f4f4f4; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5); }

.btn-outline-dark {
  color: #3e4954;
  border-color: #3e4954; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3e4954;
    border-color: #3e4954; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 73, 84, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3e4954;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3e4954;
    border-color: #3e4954; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 73, 84, 0.5); }

.btn-link {
  font-weight: 400;
  color: #3F9AE0;
  text-decoration: none; }
  .btn-link:hover {
    color: #a51b4d;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #7e7e7e;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }

  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }
@media (min-width: 1440) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }
.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }
.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }
.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }
.dropleft .dropdown-toggle::after {
  display: none; }
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }
.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #3F9AE0; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #d7dae3;
  border-radius: 0.75rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #3F9AE0;
    background-color: #3F9AE0; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(221, 47, 110, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #ef9dba; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #f6c8d9;
    border-color: #f6c8d9; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.75rem; }
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3F9AE0;
  background-color: #3F9AE0; }
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(221, 47, 110, 0.5); }
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(221, 47, 110, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(221, 47, 110, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(221, 47, 110, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #d7dae3;
  border-radius: 0.75rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #ef9dba;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 47, 110, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #ef9dba;
    box-shadow: 0 0 0 0.2rem rgba(221, 47, 110, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #d7dae3;
  border-radius: 0.75rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.75rem 0.75rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(221, 47, 110, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(221, 47, 110, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(221, 47, 110, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #3F9AE0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #f6c8d9; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #3F9AE0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #f6c8d9; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #3F9AE0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #f6c8d9; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.75rem; }
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3F9AE0; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }
.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid,
  .navbar .container-sm,
  .navbar .container-md,
  .navbar .container-lg,
  .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3359375rem;
  padding-bottom: 0.3359375rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.75rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }
@media (max-width: 1439.98) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }
@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid,
    .navbar-expand-xxl > .container-sm,
    .navbar-expand-xxl > .container-md,
    .navbar-expand-xxl > .container-lg,
    .navbar-expand-xxl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-md,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid,
  .navbar-expand > .container-sm,
  .navbar-expand > .container-md,
  .navbar-expand > .container-lg,
  .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.75rem - 1px);
      border-top-right-radius: calc(0.75rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.75rem - 1px);
      border-bottom-left-radius: calc(0.75rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }
.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.75rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.75rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.75rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3F9AE0;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #a51b4d;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 47, 110, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem; }
.page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3F9AE0;
  border-color: #3F9AE0; }
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5; }
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #3F9AE0; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #bb1e57; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 47, 110, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #392540; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #1c1220; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(57, 37, 64, 0.5); }

.badge-success {
  color: #fff;
  background-color: #2bc155; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #229743; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 193, 85, 0.5); }

.badge-info {
  color: #fff;
  background-color: #2F4CDD; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #1e38bb; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(47, 76, 221, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffb800; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #cc9300; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 184, 0, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #f35757; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #f02727; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 87, 87, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f4f4f4; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dbdbdb; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 244, 244, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #3e4954; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #283037; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(62, 73, 84, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.75rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #731839;
  background-color: #f8d5e2;
  border-color: #f5c5d6; }
  .alert-primary hr {
    border-top-color: #f1afc7; }
  .alert-primary .alert-link {
    color: #490f24; }

.alert-secondary {
  color: #1e1321;
  background-color: #d7d3d9;
  border-color: #c8c2ca; }
  .alert-secondary hr {
    border-top-color: #bcb4be; }
  .alert-secondary .alert-link {
    color: #010001; }

.alert-success {
  color: #16642c;
  background-color: #d5f3dd;
  border-color: #c4eecf; }
  .alert-success hr {
    border-top-color: #b0e8bf; }
  .alert-success .alert-link {
    color: #0d3a1a; }

.alert-info {
  color: #182873;
  background-color: #d5dbf8;
  border-color: #c5cdf5; }
  .alert-info hr {
    border-top-color: #afbaf1; }
  .alert-info .alert-link {
    color: #0f1949; }

.alert-warning {
  color: #856000;
  background-color: #fff1cc;
  border-color: #ffebb8; }
  .alert-warning hr {
    border-top-color: #ffe49f; }
  .alert-warning .alert-link {
    color: #523b00; }

.alert-danger {
  color: #7e2d2d;
  background-color: #fddddd;
  border-color: #fcd0d0; }
  .alert-danger hr {
    border-top-color: #fab8b8; }
  .alert-danger .alert-link {
    color: #582020; }

.alert-light {
  color: #7f7f7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc; }
  .alert-light hr {
    border-top-color: #efefef; }
  .alert-light .alert-link {
    color: #666666; }

.alert-dark {
  color: #20262c;
  background-color: #d8dbdd;
  border-color: #c9cccf; }
  .alert-dark hr {
    border-top-color: #bcbfc3; }
  .alert-dark .alert-link {
    color: #0b0d0e; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.75rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3F9AE0;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.75rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #7e7e7e;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #3F9AE0;
    border-color: #3F9AE0; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.75rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.75rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.75rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.75rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.75rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.75rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.75rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.75rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }
@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.75rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.75rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }
.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #731839;
  background-color: #f5c5d6; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #731839;
    background-color: #f1afc7; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #731839;
    border-color: #731839; }

.list-group-item-secondary {
  color: #1e1321;
  background-color: #c8c2ca; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #1e1321;
    background-color: #bcb4be; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #1e1321;
    border-color: #1e1321; }

.list-group-item-success {
  color: #16642c;
  background-color: #c4eecf; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #16642c;
    background-color: #b0e8bf; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #16642c;
    border-color: #16642c; }

.list-group-item-info {
  color: #182873;
  background-color: #c5cdf5; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #182873;
    background-color: #afbaf1; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #182873;
    border-color: #182873; }

.list-group-item-warning {
  color: #856000;
  background-color: #ffebb8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856000;
    background-color: #ffe49f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856000;
    border-color: #856000; }

.list-group-item-danger {
  color: #7e2d2d;
  background-color: #fcd0d0; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7e2d2d;
    background-color: #fab8b8; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7e2d2d;
    border-color: #7e2d2d; }

.list-group-item-light {
  color: #7f7f7f;
  background-color: #fcfcfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7f7f7f;
    background-color: #efefef; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7f7f7f;
    border-color: #7f7f7f; }

.list-group-item-dark {
  color: #20262c;
  background-color: #c9cccf; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #20262c;
    background-color: #bcbfc3; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #20262c;
    border-color: #20262c; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #EEEEEE;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #EEEEEE;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }

  .modal-sm {
    max-width: 300px; } }
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.75rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #3d4465;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #7e7e7e; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #3F9AE0 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #bb1e57 !important; }

.bg-secondary {
  background-color: #392540 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #1c1220 !important; }

.bg-success {
  background-color: #2bc155 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #229743 !important; }

.bg-info {
  background-color: #2F4CDD !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1e38bb !important; }

.bg-warning {
  background-color: #ffb800 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc9300 !important; }

.bg-danger {
  background-color: #f35757 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f02727 !important; }

.bg-light {
  background-color: #f4f4f4 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dbdbdb !important; }

.bg-dark {
  background-color: #3e4954 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #283037 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #EEEEEE !important; }

.border-top {
  border-top: 1px solid #EEEEEE !important; }

.border-right {
  border-right: 1px solid #EEEEEE !important; }

.border-bottom {
  border-bottom: 1px solid #EEEEEE !important; }

.border-left {
  border-left: 1px solid #EEEEEE !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #3F9AE0 !important; }

.border-secondary {
  border-color: #392540 !important; }

.border-success {
  border-color: #2bc155 !important; }

.border-info {
  border-color: #2F4CDD !important; }

.border-warning {
  border-color: #ffb800 !important; }

.border-danger {
  border-color: #f35757 !important; }

.border-light {
  border-color: #f4f4f4 !important; }

.border-dark {
  border-color: #3e4954 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.75rem !important; }

.rounded-top {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important; }

.rounded-right {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important; }

.rounded-left {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }

  .d-sm-inline {
    display: inline !important; }

  .d-sm-inline-block {
    display: inline-block !important; }

  .d-sm-block {
    display: block !important; }

  .d-sm-table {
    display: table !important; }

  .d-sm-table-row {
    display: table-row !important; }

  .d-sm-table-cell {
    display: table-cell !important; }

  .d-sm-flex {
    display: flex !important; }

  .d-sm-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }

  .d-md-inline {
    display: inline !important; }

  .d-md-inline-block {
    display: inline-block !important; }

  .d-md-block {
    display: block !important; }

  .d-md-table {
    display: table !important; }

  .d-md-table-row {
    display: table-row !important; }

  .d-md-table-cell {
    display: table-cell !important; }

  .d-md-flex {
    display: flex !important; }

  .d-md-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }

  .d-lg-inline {
    display: inline !important; }

  .d-lg-inline-block {
    display: inline-block !important; }

  .d-lg-block {
    display: block !important; }

  .d-lg-table {
    display: table !important; }

  .d-lg-table-row {
    display: table-row !important; }

  .d-lg-table-cell {
    display: table-cell !important; }

  .d-lg-flex {
    display: flex !important; }

  .d-lg-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }

  .d-xl-inline {
    display: inline !important; }

  .d-xl-inline-block {
    display: inline-block !important; }

  .d-xl-block {
    display: block !important; }

  .d-xl-table {
    display: table !important; }

  .d-xl-table-row {
    display: table-row !important; }

  .d-xl-table-cell {
    display: table-cell !important; }

  .d-xl-flex {
    display: flex !important; }

  .d-xl-inline-flex {
    display: inline-flex !important; } }
@media (min-width: 1440) {
  .d-xxl-none {
    display: none !important; }

  .d-xxl-inline {
    display: inline !important; }

  .d-xxl-inline-block {
    display: inline-block !important; }

  .d-xxl-block {
    display: block !important; }

  .d-xxl-table {
    display: table !important; }

  .d-xxl-table-row {
    display: table-row !important; }

  .d-xxl-table-cell {
    display: table-cell !important; }

  .d-xxl-flex {
    display: flex !important; }

  .d-xxl-inline-flex {
    display: inline-flex !important; } }
@media print {
  .d-print-none {
    display: none !important; }

  .d-print-inline {
    display: inline !important; }

  .d-print-inline-block {
    display: inline-block !important; }

  .d-print-block {
    display: block !important; }

  .d-print-table {
    display: table !important; }

  .d-print-table-row {
    display: table-row !important; }

  .d-print-table-cell {
    display: table-cell !important; }

  .d-print-flex {
    display: flex !important; }

  .d-print-inline-flex {
    display: inline-flex !important; } }
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }

  .flex-sm-column {
    flex-direction: column !important; }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-sm-wrap {
    flex-wrap: wrap !important; }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-sm-fill {
    flex: 1 1 auto !important; }

  .flex-sm-grow-0 {
    flex-grow: 0 !important; }

  .flex-sm-grow-1 {
    flex-grow: 1 !important; }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-sm-start {
    justify-content: flex-start !important; }

  .justify-content-sm-end {
    justify-content: flex-end !important; }

  .justify-content-sm-center {
    justify-content: center !important; }

  .justify-content-sm-between {
    justify-content: space-between !important; }

  .justify-content-sm-around {
    justify-content: space-around !important; }

  .align-items-sm-start {
    align-items: flex-start !important; }

  .align-items-sm-end {
    align-items: flex-end !important; }

  .align-items-sm-center {
    align-items: center !important; }

  .align-items-sm-baseline {
    align-items: baseline !important; }

  .align-items-sm-stretch {
    align-items: stretch !important; }

  .align-content-sm-start {
    align-content: flex-start !important; }

  .align-content-sm-end {
    align-content: flex-end !important; }

  .align-content-sm-center {
    align-content: center !important; }

  .align-content-sm-between {
    align-content: space-between !important; }

  .align-content-sm-around {
    align-content: space-around !important; }

  .align-content-sm-stretch {
    align-content: stretch !important; }

  .align-self-sm-auto {
    align-self: auto !important; }

  .align-self-sm-start {
    align-self: flex-start !important; }

  .align-self-sm-end {
    align-self: flex-end !important; }

  .align-self-sm-center {
    align-self: center !important; }

  .align-self-sm-baseline {
    align-self: baseline !important; }

  .align-self-sm-stretch {
    align-self: stretch !important; } }
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }

  .flex-md-column {
    flex-direction: column !important; }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-md-wrap {
    flex-wrap: wrap !important; }

  .flex-md-nowrap {
    flex-wrap: nowrap !important; }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-md-fill {
    flex: 1 1 auto !important; }

  .flex-md-grow-0 {
    flex-grow: 0 !important; }

  .flex-md-grow-1 {
    flex-grow: 1 !important; }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-md-start {
    justify-content: flex-start !important; }

  .justify-content-md-end {
    justify-content: flex-end !important; }

  .justify-content-md-center {
    justify-content: center !important; }

  .justify-content-md-between {
    justify-content: space-between !important; }

  .justify-content-md-around {
    justify-content: space-around !important; }

  .align-items-md-start {
    align-items: flex-start !important; }

  .align-items-md-end {
    align-items: flex-end !important; }

  .align-items-md-center {
    align-items: center !important; }

  .align-items-md-baseline {
    align-items: baseline !important; }

  .align-items-md-stretch {
    align-items: stretch !important; }

  .align-content-md-start {
    align-content: flex-start !important; }

  .align-content-md-end {
    align-content: flex-end !important; }

  .align-content-md-center {
    align-content: center !important; }

  .align-content-md-between {
    align-content: space-between !important; }

  .align-content-md-around {
    align-content: space-around !important; }

  .align-content-md-stretch {
    align-content: stretch !important; }

  .align-self-md-auto {
    align-self: auto !important; }

  .align-self-md-start {
    align-self: flex-start !important; }

  .align-self-md-end {
    align-self: flex-end !important; }

  .align-self-md-center {
    align-self: center !important; }

  .align-self-md-baseline {
    align-self: baseline !important; }

  .align-self-md-stretch {
    align-self: stretch !important; } }
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }

  .flex-lg-column {
    flex-direction: column !important; }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-lg-wrap {
    flex-wrap: wrap !important; }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-lg-fill {
    flex: 1 1 auto !important; }

  .flex-lg-grow-0 {
    flex-grow: 0 !important; }

  .flex-lg-grow-1 {
    flex-grow: 1 !important; }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-lg-start {
    justify-content: flex-start !important; }

  .justify-content-lg-end {
    justify-content: flex-end !important; }

  .justify-content-lg-center {
    justify-content: center !important; }

  .justify-content-lg-between {
    justify-content: space-between !important; }

  .justify-content-lg-around {
    justify-content: space-around !important; }

  .align-items-lg-start {
    align-items: flex-start !important; }

  .align-items-lg-end {
    align-items: flex-end !important; }

  .align-items-lg-center {
    align-items: center !important; }

  .align-items-lg-baseline {
    align-items: baseline !important; }

  .align-items-lg-stretch {
    align-items: stretch !important; }

  .align-content-lg-start {
    align-content: flex-start !important; }

  .align-content-lg-end {
    align-content: flex-end !important; }

  .align-content-lg-center {
    align-content: center !important; }

  .align-content-lg-between {
    align-content: space-between !important; }

  .align-content-lg-around {
    align-content: space-around !important; }

  .align-content-lg-stretch {
    align-content: stretch !important; }

  .align-self-lg-auto {
    align-self: auto !important; }

  .align-self-lg-start {
    align-self: flex-start !important; }

  .align-self-lg-end {
    align-self: flex-end !important; }

  .align-self-lg-center {
    align-self: center !important; }

  .align-self-lg-baseline {
    align-self: baseline !important; }

  .align-self-lg-stretch {
    align-self: stretch !important; } }
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }

  .flex-xl-column {
    flex-direction: column !important; }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-xl-wrap {
    flex-wrap: wrap !important; }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-xl-fill {
    flex: 1 1 auto !important; }

  .flex-xl-grow-0 {
    flex-grow: 0 !important; }

  .flex-xl-grow-1 {
    flex-grow: 1 !important; }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-xl-start {
    justify-content: flex-start !important; }

  .justify-content-xl-end {
    justify-content: flex-end !important; }

  .justify-content-xl-center {
    justify-content: center !important; }

  .justify-content-xl-between {
    justify-content: space-between !important; }

  .justify-content-xl-around {
    justify-content: space-around !important; }

  .align-items-xl-start {
    align-items: flex-start !important; }

  .align-items-xl-end {
    align-items: flex-end !important; }

  .align-items-xl-center {
    align-items: center !important; }

  .align-items-xl-baseline {
    align-items: baseline !important; }

  .align-items-xl-stretch {
    align-items: stretch !important; }

  .align-content-xl-start {
    align-content: flex-start !important; }

  .align-content-xl-end {
    align-content: flex-end !important; }

  .align-content-xl-center {
    align-content: center !important; }

  .align-content-xl-between {
    align-content: space-between !important; }

  .align-content-xl-around {
    align-content: space-around !important; }

  .align-content-xl-stretch {
    align-content: stretch !important; }

  .align-self-xl-auto {
    align-self: auto !important; }

  .align-self-xl-start {
    align-self: flex-start !important; }

  .align-self-xl-end {
    align-self: flex-end !important; }

  .align-self-xl-center {
    align-self: center !important; }

  .align-self-xl-baseline {
    align-self: baseline !important; }

  .align-self-xl-stretch {
    align-self: stretch !important; } }
@media (min-width: 1440) {
  .flex-xxl-row {
    flex-direction: row !important; }

  .flex-xxl-column {
    flex-direction: column !important; }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-xxl-wrap {
    flex-wrap: wrap !important; }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-xxl-fill {
    flex: 1 1 auto !important; }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-xxl-start {
    justify-content: flex-start !important; }

  .justify-content-xxl-end {
    justify-content: flex-end !important; }

  .justify-content-xxl-center {
    justify-content: center !important; }

  .justify-content-xxl-between {
    justify-content: space-between !important; }

  .justify-content-xxl-around {
    justify-content: space-around !important; }

  .align-items-xxl-start {
    align-items: flex-start !important; }

  .align-items-xxl-end {
    align-items: flex-end !important; }

  .align-items-xxl-center {
    align-items: center !important; }

  .align-items-xxl-baseline {
    align-items: baseline !important; }

  .align-items-xxl-stretch {
    align-items: stretch !important; }

  .align-content-xxl-start {
    align-content: flex-start !important; }

  .align-content-xxl-end {
    align-content: flex-end !important; }

  .align-content-xxl-center {
    align-content: center !important; }

  .align-content-xxl-between {
    align-content: space-between !important; }

  .align-content-xxl-around {
    align-content: space-around !important; }

  .align-content-xxl-stretch {
    align-content: stretch !important; }

  .align-self-xxl-auto {
    align-self: auto !important; }

  .align-self-xxl-start {
    align-self: flex-start !important; }

  .align-self-xxl-end {
    align-self: flex-end !important; }

  .align-self-xxl-center {
    align-self: center !important; }

  .align-self-xxl-baseline {
    align-self: baseline !important; }

  .align-self-xxl-stretch {
    align-self: stretch !important; } }
.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }

  .float-sm-right {
    float: right !important; }

  .float-sm-none {
    float: none !important; } }
@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }

  .float-md-right {
    float: right !important; }

  .float-md-none {
    float: none !important; } }
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }

  .float-lg-right {
    float: right !important; }

  .float-lg-none {
    float: none !important; } }
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }

  .float-xl-right {
    float: right !important; }

  .float-xl-none {
    float: none !important; } }
@media (min-width: 1440) {
  .float-xxl-left {
    float: left !important; }

  .float-xxl-right {
    float: right !important; }

  .float-xxl-none {
    float: none !important; } }
.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }

  .m-sm-1 {
    margin: 0.25rem !important; }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }

  .m-sm-2 {
    margin: 0.5rem !important; }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }

  .m-sm-3 {
    margin: 1rem !important; }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }

  .m-sm-4 {
    margin: 1.5rem !important; }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }

  .m-sm-5 {
    margin: 3rem !important; }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }

  .p-sm-0 {
    padding: 0 !important; }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }

  .p-sm-1 {
    padding: 0.25rem !important; }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }

  .p-sm-2 {
    padding: 0.5rem !important; }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }

  .p-sm-3 {
    padding: 1rem !important; }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }

  .p-sm-4 {
    padding: 1.5rem !important; }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }

  .p-sm-5 {
    padding: 3rem !important; }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }

  .m-sm-n1 {
    margin: -0.25rem !important; }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }

  .m-sm-n2 {
    margin: -0.5rem !important; }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }

  .m-sm-n3 {
    margin: -1rem !important; }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }

  .m-sm-n4 {
    margin: -1.5rem !important; }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }

  .m-sm-n5 {
    margin: -3rem !important; }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }

  .m-sm-auto {
    margin: auto !important; }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }

  .m-md-1 {
    margin: 0.25rem !important; }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }

  .m-md-2 {
    margin: 0.5rem !important; }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }

  .m-md-3 {
    margin: 1rem !important; }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }

  .m-md-4 {
    margin: 1.5rem !important; }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }

  .m-md-5 {
    margin: 3rem !important; }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }

  .p-md-0 {
    padding: 0 !important; }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }

  .p-md-1 {
    padding: 0.25rem !important; }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }

  .p-md-2 {
    padding: 0.5rem !important; }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }

  .p-md-3 {
    padding: 1rem !important; }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }

  .p-md-4 {
    padding: 1.5rem !important; }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }

  .p-md-5 {
    padding: 3rem !important; }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }

  .m-md-n1 {
    margin: -0.25rem !important; }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }

  .m-md-n2 {
    margin: -0.5rem !important; }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }

  .m-md-n3 {
    margin: -1rem !important; }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }

  .m-md-n4 {
    margin: -1.5rem !important; }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }

  .m-md-n5 {
    margin: -3rem !important; }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }

  .m-md-auto {
    margin: auto !important; }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }

  .m-lg-1 {
    margin: 0.25rem !important; }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }

  .m-lg-2 {
    margin: 0.5rem !important; }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }

  .m-lg-3 {
    margin: 1rem !important; }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }

  .m-lg-4 {
    margin: 1.5rem !important; }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }

  .m-lg-5 {
    margin: 3rem !important; }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }

  .p-lg-0 {
    padding: 0 !important; }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }

  .p-lg-1 {
    padding: 0.25rem !important; }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }

  .p-lg-2 {
    padding: 0.5rem !important; }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }

  .p-lg-3 {
    padding: 1rem !important; }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }

  .p-lg-4 {
    padding: 1.5rem !important; }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }

  .p-lg-5 {
    padding: 3rem !important; }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }

  .m-lg-n1 {
    margin: -0.25rem !important; }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }

  .m-lg-n2 {
    margin: -0.5rem !important; }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }

  .m-lg-n3 {
    margin: -1rem !important; }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }

  .m-lg-n4 {
    margin: -1.5rem !important; }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }

  .m-lg-n5 {
    margin: -3rem !important; }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }

  .m-lg-auto {
    margin: auto !important; }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }

  .m-xl-1 {
    margin: 0.25rem !important; }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }

  .m-xl-2 {
    margin: 0.5rem !important; }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }

  .m-xl-3 {
    margin: 1rem !important; }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }

  .m-xl-4 {
    margin: 1.5rem !important; }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }

  .m-xl-5 {
    margin: 3rem !important; }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }

  .p-xl-0 {
    padding: 0 !important; }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }

  .p-xl-1 {
    padding: 0.25rem !important; }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }

  .p-xl-2 {
    padding: 0.5rem !important; }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }

  .p-xl-3 {
    padding: 1rem !important; }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }

  .p-xl-4 {
    padding: 1.5rem !important; }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }

  .p-xl-5 {
    padding: 3rem !important; }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }

  .m-xl-n1 {
    margin: -0.25rem !important; }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }

  .m-xl-n2 {
    margin: -0.5rem !important; }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }

  .m-xl-n3 {
    margin: -1rem !important; }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }

  .m-xl-n4 {
    margin: -1.5rem !important; }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }

  .m-xl-n5 {
    margin: -3rem !important; }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }

  .m-xl-auto {
    margin: auto !important; }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }
@media (min-width: 1440) {
  .m-xxl-0 {
    margin: 0 !important; }

  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }

  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }

  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }

  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }

  .m-xxl-1 {
    margin: 0.25rem !important; }

  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }

  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }

  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }

  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }

  .m-xxl-2 {
    margin: 0.5rem !important; }

  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }

  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }

  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }

  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }

  .m-xxl-3 {
    margin: 1rem !important; }

  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }

  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }

  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }

  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }

  .m-xxl-4 {
    margin: 1.5rem !important; }

  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }

  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }

  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }

  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }

  .m-xxl-5 {
    margin: 3rem !important; }

  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }

  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }

  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }

  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }

  .p-xxl-0 {
    padding: 0 !important; }

  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }

  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }

  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }

  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }

  .p-xxl-1 {
    padding: 0.25rem !important; }

  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }

  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }

  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }

  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }

  .p-xxl-2 {
    padding: 0.5rem !important; }

  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }

  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }

  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }

  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }

  .p-xxl-3 {
    padding: 1rem !important; }

  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }

  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }

  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }

  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }

  .p-xxl-4 {
    padding: 1.5rem !important; }

  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }

  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }

  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }

  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }

  .p-xxl-5 {
    padding: 3rem !important; }

  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }

  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }

  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }

  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }

  .m-xxl-n1 {
    margin: -0.25rem !important; }

  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }

  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }

  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }

  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }

  .m-xxl-n2 {
    margin: -0.5rem !important; }

  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }

  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }

  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }

  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }

  .m-xxl-n3 {
    margin: -1rem !important; }

  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }

  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }

  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }

  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }

  .m-xxl-n4 {
    margin: -1.5rem !important; }

  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }

  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }

  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }

  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }

  .m-xxl-n5 {
    margin: -3rem !important; }

  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }

  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }

  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }

  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }

  .m-xxl-auto {
    margin: auto !important; }

  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }

  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }

  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }

  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }

  .text-sm-right {
    text-align: right !important; }

  .text-sm-center {
    text-align: center !important; } }
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }

  .text-md-right {
    text-align: right !important; }

  .text-md-center {
    text-align: center !important; } }
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }

  .text-lg-right {
    text-align: right !important; }

  .text-lg-center {
    text-align: center !important; } }
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }

  .text-xl-right {
    text-align: right !important; }

  .text-xl-center {
    text-align: center !important; } }
@media (min-width: 1440) {
  .text-xxl-left {
    text-align: left !important; }

  .text-xxl-right {
    text-align: right !important; }

  .text-xxl-center {
    text-align: center !important; } }
.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #3F9AE0 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #a51b4d !important; }

.text-secondary {
  color: #392540 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #0e0910 !important; }

.text-success {
  color: #2bc155 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1d8239 !important; }

.text-info {
  color: #2F4CDD !important; }

a.text-info:hover, a.text-info:focus {
  color: #1b32a5 !important; }

.text-warning {
  color: #ffb800 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b38100 !important; }

.text-danger {
  color: #f35757 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ed1111 !important; }

.text-light {
  color: #f4f4f4 !important; }

a.text-light:hover, a.text-light:focus {
  color: #cecece !important; }

.text-dark {
  color: #3e4954 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1e2328 !important; }

.text-body {
  color: #7e7e7e !important; }

.text-muted {
  color: #89879f !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }

  a:not(.btn) {
    text-decoration: underline; }

  abbr[title]::after {
    content: " (" attr(title) ")"; }

  pre {
    white-space: pre-wrap !important; }

  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }

  thead {
    display: table-header-group; }

  tr,
  img {
    page-break-inside: avoid; }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }

  h2,
  h3 {
    page-break-after: avoid; }

  @page {
    size: a3; }
  body {
    min-width: 992px !important; }

  .container {
    min-width: 992px !important; }

  .navbar {
    display: none; }

  .badge {
    border: 1px solid #000; }

  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }

  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #EEEEEE; }

  .table .thead-dark th {
    color: inherit;
    border-color: #EEEEEE; } }
.c-pointer {
  cursor: pointer; }

* {
  outline: none;
  padding: 0; }
  *::after {
    margin: 0;
    padding: 0; }
  *::before {
    margin: 0;
    padding: 0; }

body {
  overflow-x: hidden;
  height: 100%;
  position: relative;
  max-width: 100%;
  font-size: 1rem; }
  @media only screen and (max-width: 1400px) {
    body {
      font-size: 0.875rem; } }

p {
  line-height: 1.8; }

.box-shadow-none {
  box-shadow: none !important; }

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative; }
  #main-wrapper.show {
    opacity: 1; }

.rounded-lg {
  border-radius: 0.75rem; }

ul {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

a {
  color: #7e7e7e; }
  a:hover, a:focus, a.active {
    text-decoration: none; }

.btn-link:hover, .btn-link:focus, .btn-link.active {
  text-decoration: none; }

.w-space-no {
  white-space: nowrap; }

.content-body .container {
  margin-top: 40px; }
.content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl {
  padding-top: 40px;
  padding-right: 40px;
  padding-left: 40px; }
  @media only screen and (max-width: 1400px) {
    .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl {
      padding-top: 30px;
      padding-right: 30px;
      padding-left: 30px; } }
  @media only screen and (max-width: 767px) {
    .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl {
      padding-top: 20px;
      padding-right: 20px;
      padding-left: 20px; } }
  @media only screen and (max-width: 575px) {
    .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl {
      padding-top: 15px;
      padding-right: 15px;
      padding-left: 15px; } }

/* sp */
.row.sp80,
.sp80 {
  margin-left: -40px;
  margin-right: -40px; }
  .row.sp80 [class*="col-"],
  .sp80 [class*="col-"] {
    padding-left: 40px;
    padding-right: 40px; }

.row.sp60,
.sp60 {
  margin-left: -30px;
  margin-right: -30px; }
  .row.sp60 [class*="col-"],
  .sp60 [class*="col-"] {
    padding-left: 30px;
    padding-right: 30px; }

.row.sp40,
.sp40 {
  margin-left: -20px;
  margin-right: -20px; }
  .row.sp40 [class*="col-"],
  .sp40 [class*="col-"] {
    padding-left: 20px;
    padding-right: 20px; }

.row.sp20,
.sp20 {
  margin-left: -9px;
  margin-right: -9px; }
  .row.sp20 [class*="col-"],
  .sp20 [class*="col-"] {
    padding-left: 9px;
    padding-right: 9px; }

.row.sp15,
.sp15 {
  margin-left: -7.5px;
  margin-right: -7.5px; }
  .row.sp15 [class*="col-"],
  .sp15 [class*="col-"] {
    padding-left: 7.5px;
    padding-right: 7.5px; }

.row.sp10,
.sp10 {
  margin-left: -5px;
  margin-right: -5px; }
  .row.sp10 [class*="col-"],
  .sp10 [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px; }

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0; }
  .row.spno [class*="col-"],
  .spno [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

.content-heading {
  font-size: 16px;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
  border-bottom: 1px solid #d7dae3;
  padding-bottom: 10px; }
  [direction="rtl"] .content-heading {
    text-align: right; }

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none; }

.support-ticket {
  position: fixed;
  bottom: 30px;
  right: 15px;
  z-index: 999999; }

.support-ticket-btn {
  width: 100px;
  background: #7CB442;
  animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
  border-radius: 50px;
  color: #fff;
  font-size: 8px;
  font-size: 16px;
  padding: 5px 10px 7px;
  text-align: center;
  display: inline-block;
  box-shadow: rgba(124, 180, 66, 0.7) 0px 8px 35px 0px; }
  .support-ticket-btn:hover, .support-ticket-btn:focus {
    color: #fff; }

.text-blue {
  color: #5e72e4; }

.text-indigo {
  color: #6610f2; }

.text-purple {
  color: #7d49eb; }

.text-pink {
  color: #e83e8c; }

.text-red {
  color: #EE3232; }

.text-orange {
  color: #ff9900; }

.text-yellow {
  color: #FFFA6F; }

.text-green {
  color: #297F00; }

.text-teal {
  color: #20c997; }

.text-cyan {
  color: #3065D0; }

.bg-gray-dark {
  background: #143b64 !important; }

.bg-purpal {
  background: #4527a0 !important; }

.bg-red {
  background: #c62828 !important; }

.bg-blue-dark {
  background: #283593 !important; }

.bg-blue {
  background: #7356f1 !important; }

.bg-blue-light {
  background: #3695eb !important; }

.bg-green-light {
  background: #00838f !important; }

.bg-green {
  background: #ff8f16 !important; }

.bg-black {
  background: #000; }

.text-black {
  color: #000 !important; }

.dz-scroll {
  position: relative; }

.fs-12 {
  font-size: 12px !important;
  line-height: 1.5; }

.fs-13 {
  font-size: 13px !important;
  line-height: 1.5; }

.fs-14 {
  font-size: 14px !important;
  line-height: 1.5; }

.fs-15 {
  font-size: 14px !important;
  line-height: 1.5; }

.fs-16 {
  font-size: 16px !important;
  line-height: 1.5; }

.fs-18 {
  font-size: 18px !important;
  line-height: 1.5; }

.fs-20 {
  font-size: 20px !important;
  line-height: 1.5; }

.fs-22 {
  font-size: 22px !important;
  line-height: 1.5; }

.fs-24 {
  font-size: 24px !important;
  line-height: 1.4; }

.fs-26 {
  font-size: 26px !important;
  line-height: 1.4; }

.fs-28 {
  font-size: 28px !important;
  line-height: 1.4; }

.fs-32 {
  font-size: 32px !important;
  line-height: 1.25; }

.fs-35 {
  font-size: 35px !important;
  line-height: 1.25; }

.font-w100 {
  font-weight: 100; }

.font-w200 {
  font-weight: 200; }

.font-w300 {
  font-weight: 300; }

.font-w400 {
  font-weight: 400; }

.font-w500 {
  font-weight: 500; }

.font-w600 {
  font-weight: 600; }

.font-w700 {
  font-weight: 700; }

.font-w800 {
  font-weight: 800; }

.font-w900 {
  font-weight: 900; }

.scale1 {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  display: inline-block; }

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  display: inline-block; }

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block; }

.scale4 {
  transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  display: inline-block; }

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  display: inline-block; }

@-webkit-keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8); }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1); } }
@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8); }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1); } }
@keyframes crescendo {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8); }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1); } }
@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(5px) scale(0.8);
    -ms-transform: translateY(5px) scale(0.8);
    transform: translateY(5px) scale(0.8); }
  to {
    -webkit-transform: translateY(0px) scale(1);
    -ms-transform: translateY(0px) scale(1);
    transform: translateY(0px) scale(1); } }
.height10 {
  height: 10px; }

.height20 {
  height: 20px; }

.height30 {
  height: 30px; }

.height40 {
  height: 40px; }

.height50 {
  height: 50px; }

.height60 {
  height: 60px; }

.height70 {
  height: 70px; }

.height80 {
  height: 80px; }

.height90 {
  height: 90px; }

.height100 {
  height: 100px; }

.height110 {
  height: 110px; }

.height120 {
  height: 120px; }

.height130 {
  height: 130px; }

.height140 {
  height: 140px; }

.height150 {
  height: 150px; }

.height160 {
  height: 160px; }

.height170 {
  height: 170px; }

.height180 {
  height: 180px; }

.height190 {
  height: 190px; }

.height200 {
  height: 200px; }

.height210 {
  height: 210px; }

.height220 {
  height: 220px; }

.height230 {
  height: 230px; }

.height240 {
  height: 240px; }

.height250 {
  height: 250px; }

.height260 {
  height: 260px; }

.height270 {
  height: 270px; }

.height280 {
  height: 280px; }

.height290 {
  height: 290px; }

.height300 {
  height: 300px; }

.height310 {
  height: 310px; }

.height320 {
  height: 320px; }

.height330 {
  height: 330px; }

.height340 {
  height: 340px; }

.height350 {
  height: 350px; }

.height360 {
  height: 360px; }

.height370 {
  height: 370px; }

.height380 {
  height: 380px; }

.height390 {
  height: 390px; }

.height400 {
  height: 400px; }

.height415 {
  height: 415px; }

.height720 {
  height: 720px; }

.height750 {
  height: 750px; }

.height800 {
  height: 800px; }

.width10 {
  width: 10px; }

.width20 {
  width: 20px; }

.width30 {
  width: 30px; }

.width40 {
  width: 40px; }

.width50 {
  width: 50px; }

.width60 {
  width: 60px; }

.width70 {
  width: 70px; }

.width80 {
  width: 80px; }

.width90 {
  width: 90px; }

.width100 {
  width: 100px; }

.width110 {
  width: 110px; }

.width120 {
  width: 120px; }

.width130 {
  width: 130px; }

.width140 {
  width: 140px; }

.width150 {
  width: 150px; }

.width160 {
  width: 160px; }

.width170 {
  width: 170px; }

.width180 {
  width: 180px; }

.width190 {
  width: 190px; }

.width200 {
  width: 200px; }

.width210 {
  width: 210px; }

.width220 {
  width: 220px; }

.width230 {
  width: 230px; }

.width240 {
  width: 240px; }

.width250 {
  width: 250px; }

.width260 {
  width: 260px; }

.width270 {
  width: 270px; }

.width280 {
  width: 280px; }

.width290 {
  width: 290px; }

.width300 {
  width: 300px; }

.width310 {
  width: 310px; }

.width320 {
  width: 320px; }

.width330 {
  width: 330px; }

.width340 {
  width: 340px; }

.width350 {
  width: 350px; }

.width360 {
  width: 360px; }

.width370 {
  width: 370px; }

.width380 {
  width: 380px; }

.width390 {
  width: 390px; }

.width400 {
  width: 400px; }

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%; }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%; }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%; }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%; }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%; }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%; }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%; }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; } }
/* Editable */
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff; }

.sk-three-bounce {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: white; }

.sk-three-bounce .sk-child {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-color: #3F9AE0;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both; }

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-three-bounce {
  0%,
    80%,
    100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
@keyframes sk-three-bounce {
  0%,
    80%,
    100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
.footer {
  padding-left: 18rem;
  /* padding-left: 17.1875rem; */
  background-color: #ffffff;
 }
  .footer .copyright {
    padding: 0.9375rem; 
    background: #08344d;/* momentum */
    /* background: #3c77a5; pclk*/
    /* background: #1d2664; */
    color: white;
  }
    .footer .copyright p {
      text-align: center;
      margin: 0; }
    .footer .copyright a {
      color: #3F9AE0; }

[data-sidebar-style="mini"] .nav-control,
[data-layout="horizontal"] .nav-control {
  display: none; }

@media only screen and (max-width: 767px) {
  [data-sidebar-style="overlay"] .nav-header .logo-abbr {
    display: block; } }

[data-header-position="fixed"] .nav-header {
  position: fixed;
  left: auto; }

.nav-header {
  height: 7.5rem;
  width: 21.563rem;
  display: inline-block;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  transition: all .2s ease;
  z-index: 5; }
  .nav-header .logo-abbr {
    max-width: 45px; 
  }
    @media only screen and (max-width: 575px) {
      .nav-header .logo-abbr {
        max-width: 35px; } }
  .nav-header .logo-compact {
    display: none; }
  @media only screen and (max-width: 1400px) {
    .nav-header {
      width: 18rem; } }
  .nav-header .brand-logo {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.125rem;
    color: #fff;
    text-decoration: none;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: 700; }
    [data-sidebar-style="compact"] .nav-header .brand-logo, [data-sidebar-style="mini"] .nav-header .brand-logo {
      padding-left: 0;
      padding-right: 0;
      justify-content: center; }
    @media only screen and (max-width: 767px) {
      .nav-header .brand-logo {
        padding-left: 0;
        padding-right: 0;
        justify-content: center; } }
  .nav-header .brand-title {
    margin-left: 20px;
    max-width: 160px;
    margin-top: 5px; 
    height: auto;
  }
    [data-theme-version="dark"] .nav-header .brand-title {
      background-position: 0 120%; }

@media only screen and (max-width: 1199px) {
  .nav-header {
    width: 5rem;
    height: 5rem; }
    .nav-header .brand-title {
      display: none; } }
.nav-control {
  cursor: pointer;
  position: absolute;
  right: -4.0625rem;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  font-size: 1.4rem;
  padding: 2px 0.5rem 0;
  border-radius: 2px; }
  @media only screen and (max-width: 767px) {
    .nav-control {
      right: -3.188rem; } }

.hamburger {
  display: inline-block;
  left: 0px;
  position: relative;
  top: 3px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 26px;
  z-index: 999; }

.hamburger .line {
  background: #3F9AE0;
  display: block;
  height: 3px;
  border-radius: 3px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.hamburger .line:nth-child(1) {
  width: 20px; }

.hamburger .line:nth-child(2) {
  width: 26px; }

.hamburger .line:nth-child(3) {
  width: 22px; }

.hamburger:hover {
  cursor: pointer; }

.hamburger:hover .line {
  width: 26px; }

/*
    .hamburger.is-active {
      left: 60px;
    }
    */
.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 10px;
  height: 2px; }

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  width: 22px;
  height: 2px; }

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(4px) rotate(45deg);
  transform: translateY(4px) rotate(45deg); }

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-4px) rotate(-45deg);
  transform: translateY(-4px) rotate(-45deg); }

@media (min-width: 767px) {
  [data-sidebar-style="compact"] .nav-control {
    display: none; }
  [data-sidebar-style="compact"] .nav-header {
    width: 9.375rem; } }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .brand-title {
  display: none; }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .logo-abbr {
  display: block; }

[data-sidebar-style="full"][data-layout="horizontal"] .logo-compact {
  display: none; }

[data-sidebar-style="mini"] .nav-header .logo-abbr {
  display: block; }

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .brand-title {
  display: none; }
[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .logo-compact {
  max-width: 75px; }

[data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-left: 30px;
  padding-right: 30px;
  justify-content: start; }

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header {
  width: 9.375rem; }
  [data-sidebar-style="modern"][data-layout="vertical"] .nav-header .brand-title {
    display: none; }
  [data-sidebar-style="modern"][data-layout="vertical"] .nav-header .logo-compact {
    display: none; }

.header {
  height: 7.5rem;
  z-index: 1;
  position: relative;
  padding: 0rem;
  background-color: #ffffff;
  z-index: 4;
  padding-left: 21.563rem;
  transition: all .2s ease; }
  @media only screen and (max-width: 1400px) {
    .header {
      padding-left: 18rem; } }
  .header .header-content {
    height: 100%;
    padding-left: 5.3125rem;
    padding-right: 1.875rem;
    align-items: center;
    display: flex; }
    @media only screen and (max-width: 767px) {
      .header .header-content {
        padding-left: 3.75rem;
        padding-right: 0.938rem; } }
  .header .navbar {
    padding: 0;
    height: 100%;
    width: 100%; }
    .header .navbar .navbar-collapse {
      height: 100%;
      width: 100%; }
  @media only screen and (max-width: 1199px) {
    .header {
      height: 5rem; } }

/* pulse in SVG */
svg.pulse-svg {
  overflow: visible; }
  svg.pulse-svg .first-circle, svg.pulse-svg .second-circle, svg.pulse-svg .third-circle {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
    fill: #3F9AE0; }
  svg.pulse-svg .second-circle {
    -webkit-animation-delay: 1s;
    animation-delay: 1s; }
  svg.pulse-svg .third-circle {
    -webkit-animation-delay: 2s;
    animation-delay: 2s; }

/* pulse in CSS */
.pulse-css {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border-radius: 3.5rem;
  height: .4rem;
  position: absolute;
  background: #3F9AE0;
  right: 5px;
  top: .6rem;
  width: .4rem; }
  .pulse-css:after, .pulse-css:before {
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -.2rem;
    background-color: #3F9AE0;
    margin: auto;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite; }
    [direction="rtl"] .pulse-css:after, [direction="rtl"] .pulse-css:before {
      left: auto;
      right: -.2rem; }

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0; }
  50% {
    opacity: 0.1; }
  70% {
    opacity: 0.09; }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0; } }
@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0; }
  50% {
    opacity: 0.1; }
  70% {
    opacity: 0.09; }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0; } }
[data-sidebar-style="full"] .header,
[data-sidebar-style="overlay"] .header {
  width: 100%; }
  @media only screen and (min-width: 1200px) and (max-width: 1350px) {
    [data-sidebar-style="full"] .header,
    [data-sidebar-style="overlay"] .header {
      width: 100%;
      padding-left: 17rem; } }
  @media only screen and (max-width: 1199px) {
    [data-sidebar-style="full"] .header,
    [data-sidebar-style="overlay"] .header {
      width: 100%;
      padding-left: 5rem; } }

      [data-sidebar-style="full"] .logo-abbr,[data-sidebar-style="overlay"] .logo-abbr  {
        display: none; }

[data-sidebar-style="mini"] .header {
  width: 100%;
  padding-left: 5rem; }

[data-sidebar-style="compact"] .header {
  width: 100%;
  padding-left: 9.375rem; }
  [data-sidebar-style="compact"] .header .header-content {
    padding-left: 1.875rem; }

[data-header-position="fixed"] .header {
  position: fixed;
  top: 0;
  width: 100%; }
[data-header-position="fixed"] .content-body {
  padding-top: 7.5rem; }
  @media only screen and (max-width: 1199px) {
    [data-header-position="fixed"] .content-body {
      padding-top: 5rem; } }
[data-header-position="fixed"] .deznav {
  margin-top: 0; }

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
  width: 1199px; }

[data-sidebar-style="modern"] .header {
  width: 100%;
  padding-left: 9.375rem; }
[data-sidebar-style="modern"][data-layout="horizontal"] .nav-header .brand-logo {
  justify-content: start; }
[data-sidebar-style="modern"][data-layout="horizontal"] .header .header-content {
  padding-left: 30px; }

.header-left {
  height: 100%;
  display: flex;
  align-items: center; }
  .header-left .dashboard_bar {
    font-size: 28px;
    font-weight: 600;
    color: #fff; }
    @media only screen and (max-width: 1199px) {
      .header-left .dashboard_bar {
        font-size: 24px; } }
    @media only screen and (max-width: 767px) {
      .header-left .dashboard_bar {
        font-size: 20px; } }
    @media only screen and (max-width: 575px) {
      .header-left .dashboard_bar {
        display: none; } }
  .header-left input {
    background: #ffffff !important;
    min-width: 170px;
    min-height: 40px;
    border-color: transparent;
    color: #3e4954 !important;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    box-shadow: none; }
    [direction="rtl"] .header-left input {
      border-top-left-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .header-left input:focus, .header-left input:active, .header-left input.active {
      border-color: transparent;
      box-shadow: none; }
    .header-left input::-webkit-input-placeholder {
      /* Edge */
      color: #3e4954; }
    .header-left input:-ms-input-placeholder {
      /* Internet Explorer */
      color: #3e4954; }
    .header-left input::placeholder {
      color: #3e4954; }
  .header-left .search_bar {
    display: flex;
    align-items: center;
    height: 100%; }
    .header-left .search_bar .dropdown-menu {
      box-shadow: none; }
    .header-left .search_bar .search_icon {
      background: #ffffff !important;
      height: 40px;
      padding: 8px 0 8px 15px !important;
      border-top-left-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem; }
      .header-left .search_bar .search_icon i {
        font-size: 24px;
        color: #3e4954;
        line-height: 1; }
      @media only screen and (max-width: 767px) {
        .header-left .search_bar .search_icon {
          border-radius: 4px;
          padding: 8px 10px !important; } }
    @media only screen and (max-width: 767px) {
      .header-left .search_bar {
        position: static; }
        .header-left .search_bar .dropdown-menu {
          width: 300px;
          left: -15vw;
          box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.1);
          border-radius: 4px !important; } }
    @media only screen and (max-width: 767px) and (max-width: 575px) {
      .header-left .search_bar .dropdown-menu {
        width: 250px;
        left: -25vw; } }
    @media only screen and (max-width: 767px) {
          .header-left .search_bar .dropdown-menu .form-control {
            border-radius: 4px !important;
            width: 100%; }
          [direction="rtl"] .header-left .search_bar .dropdown-menu {
            right: -98px; } }
    .header-left .search_bar .dropdown-menu,
    .header-left .search_bar .dropdown-menu.show {
      border: 0px;
      background-color: transparent;
      border-top-right-radius: 5rem;
      border-bottom-right-radius: 5rem; }
      @media only screen and (min-width: 768px) {
        .header-left .search_bar .dropdown-menu,
        .header-left .search_bar .dropdown-menu.show {
          left: 34px;
          top: 0;
          transform: translateY(50%);
          display: block; } }

[data-sidebar-style="compact"] .header-left {
  margin-left: 0; }

.header-right {
  height: 100%; }
  .header-right .nav-item {
    height: 100%;
    display: flex;
    align-items: center; }
    .header-right .nav-item .nav-link {
      color: #464a53;
      font-size: 18px; }
  .header-right .right-sidebar {
    margin-right: -30px; }
    .header-right .right-sidebar a {
      height: 80px;
      width: 80px;
      text-align: center;
      justify-content: center;
      display: flex;
      align-items: center;
      border-left: 1px solid #f4f4f4; }
  .header-right > li:not(:first-child) {
    padding-left: 1.5rem; }
    @media only screen and (max-width: 767px) {
      .header-right > li:not(:first-child) {
        padding-left: 0.5rem; } }
  @media only screen and (max-width: 767px) {
    .header-right .notification_dropdown {
      position: static; } }
  .header-right .notification_dropdown .nav-link {
    position: relative;
    color: #3e4954;
    background: #f4f4f4;
    border-radius: 2rem;
    height: 55px;
    width: 55px;
    text-align: center;
    line-height: 2; }
    @media only screen and (max-width: 1199px) {
      .header-right .notification_dropdown .nav-link {
        padding: 10px;
        height: 40px;
        width: 40px;
        line-height: 1; } }
    .header-right .notification_dropdown .nav-link i {
      font-size: 24px; }
      @media only screen and (max-width: 1199px) {
        .header-right .notification_dropdown .nav-link i {
          font-size: 18px; } }
    @media only screen and (max-width: 1199px) {
      .header-right .notification_dropdown .nav-link svg {
        width: 18px;
        height: 18px; } }
    .header-right .notification_dropdown .nav-link .badge {
      position: absolute;
      font-size: 14px;
      border-radius: 50%;
      right: -10px;
      top: -10px;
      padding: 0px;
      font-weight: normal;
      text-align: center;
      line-height: 25px;
      height: 25px;
      width: 25px; }
      @media only screen and (max-width: 767px) {
        .header-right .notification_dropdown .nav-link .badge {
          line-height: 18px;
          height: 20px;
          width: 20px;
          font-size: 12px; } }
  .header-right .notification_dropdown .dropdown-item:focus a, .header-right .notification_dropdown .dropdown-item:active a {
    color: #fff; }
  .header-right .notification_dropdown .dropdown-item a {
    color: #3e4954; }
    .header-right .notification_dropdown .dropdown-item a:hover {
      text-decoration: none; }
  .header-right .dropdown-menu {
    border-width: 0;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.05); }
    [data-theme-version="dark"] .header-right .dropdown-menu {
      box-shadow: none; }
  .header-right .header-profile > a.nav-link {
    padding: 0;
    display: flex;
    align-items: center; }
    .header-right .header-profile > a.nav-link i {
      font-weight: 700; }
    .header-right .header-profile > a.nav-link .header-info {
      padding-right: 20px;
      text-align: right;
      border-left: 1px solid #eee;
      padding-left: 20px; }
      @media only screen and (max-width: 1199px) {
        .header-right .header-profile > a.nav-link .header-info {
          padding-right: 10px;
          padding-left: 10px; } }
      @media only screen and (max-width: 767px) {
        .header-right .header-profile > a.nav-link .header-info {
          display: none; } }
      .header-right .header-profile > a.nav-link .header-info span {
        font-size: 20px;
        color: #000;
        display: block;
        font-weight: 500; }
        @media only screen and (max-width: 1199px) {
          .header-right .header-profile > a.nav-link .header-info span {
            font-size: 14px; } }
      .header-right .header-profile > a.nav-link .header-info strong {
        color: #3e4954; }
      .header-right .header-profile > a.nav-link .header-info small {
        display: block;
        font-size: 13px;
        color: #89879f;
        font-weight: 400;
        line-height: 2.2; }
        @media only screen and (max-width: 1199px) {
          .header-right .header-profile > a.nav-link .header-info small {
            font-size: 12px;
            line-height: 1.5; } }
  .header-right .header-profile .dropdown-menu {
    padding: 15px 0;
    min-width: 12.5rem; }
  .header-right .header-profile img {
    width: 70px;
    height: 70px;
    border-radius: 3rem; }
    @media only screen and (max-width: 1199px) {
      .header-right .header-profile img {
        width: 40px;
        height: 40px; } }
  .header-right .header-profile .dropdown-toggle i {
    font-size: 1.25rem; }
  @media only screen and (max-width: 575px) {
    .header-right .header-profile .dropdown-toggle span {
      display: none; } }
  .header-right .header-profile .profile_title {
    background: #3F9AE0;
    color: #fff;
    padding: 10px 20px; }
    .header-right .header-profile .profile_title h5 {
      color: #fff;
      margin-bottom: 3px; }
  .header-right .header-profile .dropdown-item {
    padding: 8px 24px; }

.dz-fullscreen #icon-minimize {
  display: none; }
.dz-fullscreen.active #icon-full {
  display: none; }
.dz-fullscreen.active #icon-minimize {
  display: inline-block; }

.notification_dropdown .dropdown-menu-right {
  min-width: 310px;
  padding: 0rem 0 1rem;
  top: 100%; }
  .notification_dropdown .dropdown-menu-right .notification_title {
    background: #3F9AE0;
    color: #fff;
    padding: 10px 20px; }
    .notification_dropdown .dropdown-menu-right .notification_title h5 {
      color: #fff;
      margin-bottom: 3px; }
  .notification_dropdown .dropdown-menu-right .media {
    width: 45px !important;
    height: 45px !important;
    font-size: 18px !important; }
    [data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .media {
      border-color: #333a54; }
    .notification_dropdown .dropdown-menu-right .media > span {
      width: 35px;
      height: 35px;
      border-radius: 50px;
      display: inline-block;
      padding: 7px 9px;
      margin-right: 10px; }
      [direction="rtl"].notification_dropdown .dropdown-menu-right .media > span {
        margin-right: 0;
        margin-left: 10px; }
      .notification_dropdown .dropdown-menu-right .media > span.success {
        background: #e7faec;
        color: #2bc155; }
      .notification_dropdown .dropdown-menu-right .media > span.primary {
        background: #fdf4f8;
        color: #3F9AE0; }
      .notification_dropdown .dropdown-menu-right .media > span.danger {
        background: #fde6e6;
        color: #f35757; }
    .notification_dropdown .dropdown-menu-right .media .notify-time {
      width: 100% !important;
      margin-right: 0 !important;
      color: #828690; }
    .notification_dropdown .dropdown-menu-right .media p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 200px;
      margin-bottom: 0;
      margin-top: 5px; }
      @media only screen and (max-width: 575px) {
        .notification_dropdown .dropdown-menu-right .media p {
          max-width: 100px; } }
  .notification_dropdown .dropdown-menu-right .all-notification {
    display: block;
    padding: 15px 30px 0;
    text-align: center;
    border-top: 1px solid #f4f4f4; }
    .notification_dropdown .dropdown-menu-right .all-notification i {
      margin-left: 10px; }

.rtl {
  text-align: right;
  direction: rtl; }
  .rtl .nav {
    padding-right: 0; }
  .rtl .navbar-nav .nav-item {
    float: right; }
  .rtl .navbar-nav .nav-item + .nav-item {
    margin-right: 1rem;
    margin-left: inherit; }
  .rtl th {
    text-align: right; }
  .rtl .alert-dismissible {
    padding-right: 1.25rem;
    padding-left: 4rem; }
  .rtl .dropdown-menu {
    right: 0;
    text-align: right; }
  .rtl .checkbox label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .btn-group > .btn:last-child:not(:first-child),
  .rtl .btn-group > .dropdown-toggle:not(:first-child) {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .custom-control-label::after,
  .rtl .custom-control-label::before {
    right: 0;
    left: inherit; }
  .rtl .custom-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    background: #fff url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") no-repeat left 0.75rem center;
    background-size: 8px 10px; }
  .rtl .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .rtl .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .rtl .input-group > .input-group-append:not(:last-child) > .btn,
  .rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .rtl .input-group > .input-group-prepend > .btn,
  .rtl .input-group > .input-group-prepend > .input-group-text {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .input-group > .input-group-append > .btn,
  .rtl .input-group > .input-group-append > .input-group-text,
  .rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .rtl .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
  .rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .input-group > .custom-select:not(:first-child),
  .rtl .input-group > .form-control:not(:first-child) {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .input-group > .custom-select:not(:last-child),
  .rtl .input-group > .form-control:not(:last-child) {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .input-group > .custom-select:not(:last-child):not(:first-child),
  .rtl .input-group > .form-control:not(:last-child):not(:first-child) {
    border-radius: 0; }
  .rtl .custom-control {
    padding-right: 1.5rem;
    padding-left: inherit;
    margin-right: inherit;
    margin-left: 1rem; }
  .rtl .custom-control-indicator {
    right: 0;
    left: inherit; }
  .rtl .custom-file-label::after {
    right: initial;
    left: -1px;
    border-radius: .25rem 0 0 .25rem; }
  .rtl .radio input,
  .rtl .radio-inline,
  .rtl .checkbox input,
  .rtl .checkbox-inline input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .list-group {
    padding-right: 0;
    padding-left: 40px; }
  .rtl .close {
    float: left; }
  .rtl .modal-header .close {
    margin: -15px auto -15px -15px; }
  .rtl .modal-footer > :not(:first-child) {
    margin-right: .25rem; }
  .rtl .alert-dismissible .close {
    right: inherit;
    left: 0; }
  .rtl .dropdown-toggle::after {
    margin-right: .255em;
    margin-left: 0; }
  .rtl .form-check-input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .form-check-label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .offset-1 {
    margin-right: 8.3333333333%;
    margin-left: 0; }
  .rtl .offset-2 {
    margin-right: 16.6666666667%;
    margin-left: 0; }
  .rtl .offset-3 {
    margin-right: 25%;
    margin-left: 0; }
  .rtl .offset-4 {
    margin-right: 33.3333333333%;
    margin-left: 0; }
  .rtl .offset-5 {
    margin-right: 41.6666666667%;
    margin-left: 0; }
  .rtl .offset-6 {
    margin-right: 50%;
    margin-left: 0; }
  .rtl .offset-7 {
    margin-right: 58.3333333333%;
    margin-left: 0; }
  .rtl .offset-8 {
    margin-right: 66.6666666667%;
    margin-left: 0; }
  .rtl .offset-9 {
    margin-right: 75%;
    margin-left: 0; }
  .rtl .offset-10 {
    margin-right: 83.3333333333%;
    margin-left: 0; }
  .rtl .offset-11 {
    margin-right: 91.6666666667%;
    margin-left: 0; }
  @media (min-width: 576px) {
    .rtl .offset-sm-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-sm-1 {
      margin-right: 8.3333333333%;
      margin-left: 0; }
    .rtl .offset-sm-2 {
      margin-right: 16.6666666667%;
      margin-left: 0; }
    .rtl .offset-sm-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-sm-4 {
      margin-right: 33.3333333333%;
      margin-left: 0; }
    .rtl .offset-sm-5 {
      margin-right: 41.6666666667%;
      margin-left: 0; }
    .rtl .offset-sm-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-sm-7 {
      margin-right: 58.3333333333%;
      margin-left: 0; }
    .rtl .offset-sm-8 {
      margin-right: 66.6666666667%;
      margin-left: 0; }
    .rtl .offset-sm-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-sm-10 {
      margin-right: 83.3333333333%;
      margin-left: 0; }
    .rtl .offset-sm-11 {
      margin-right: 91.6666666667%;
      margin-left: 0; } }
  @media (min-width: 768px) {
    .rtl .offset-md-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-md-1 {
      margin-right: 8.3333333333%;
      margin-left: 0; }
    .rtl .offset-md-2 {
      margin-right: 16.6666666667%;
      margin-left: 0; }
    .rtl .offset-md-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-md-4 {
      margin-right: 33.3333333333%;
      margin-left: 0; }
    .rtl .offset-md-5 {
      margin-right: 41.6666666667%;
      margin-left: 0; }
    .rtl .offset-md-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-md-7 {
      margin-right: 58.3333333333%;
      margin-left: 0; }
    .rtl .offset-md-8 {
      margin-right: 66.6666666667%;
      margin-left: 0; }
    .rtl .offset-md-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-md-10 {
      margin-right: 83.3333333333%;
      margin-left: 0; }
    .rtl .offset-md-11 {
      margin-right: 91.6666666667%;
      margin-left: 0; } }
  @media (min-width: 992px) {
    .rtl .offset-lg-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-lg-1 {
      margin-right: 8.3333333333%;
      margin-left: 0; }
    .rtl .offset-lg-2 {
      margin-right: 16.6666666667%;
      margin-left: 0; }
    .rtl .offset-lg-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-lg-4 {
      margin-right: 33.3333333333%;
      margin-left: 0; }
    .rtl .offset-lg-5 {
      margin-right: 41.6666666667%;
      margin-left: 0; }
    .rtl .offset-lg-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-lg-7 {
      margin-right: 58.3333333333%;
      margin-left: 0; }
    .rtl .offset-lg-8 {
      margin-right: 66.6666666667%;
      margin-left: 0; }
    .rtl .offset-lg-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-lg-10 {
      margin-right: 83.3333333333%;
      margin-left: 0; }
    .rtl .offset-lg-11 {
      margin-right: 91.6666666667%;
      margin-left: 0; } }
  @media (min-width: 1200px) {
    .rtl .offset-xl-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-xl-1 {
      margin-right: 8.3333333333%;
      margin-left: 0; }
    .rtl .offset-xl-2 {
      margin-right: 16.6666666667%;
      margin-left: 0; }
    .rtl .offset-xl-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-xl-4 {
      margin-right: 33.3333333333%;
      margin-left: 0; }
    .rtl .offset-xl-5 {
      margin-right: 41.6666666667%;
      margin-left: 0; }
    .rtl .offset-xl-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-xl-7 {
      margin-right: 58.3333333333%;
      margin-left: 0; }
    .rtl .offset-xl-8 {
      margin-right: 66.6666666667%;
      margin-left: 0; }
    .rtl .offset-xl-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-xl-10 {
      margin-right: 83.3333333333%;
      margin-left: 0; }
    .rtl .offset-xl-11 {
      margin-right: 91.6666666667%;
      margin-left: 0; } }
  @media (min-width: 1440) {
    .rtl .offset-xxl-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-xxl-1 {
      margin-right: 8.3333333333%;
      margin-left: 0; }
    .rtl .offset-xxl-2 {
      margin-right: 16.6666666667%;
      margin-left: 0; }
    .rtl .offset-xxl-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-xxl-4 {
      margin-right: 33.3333333333%;
      margin-left: 0; }
    .rtl .offset-xxl-5 {
      margin-right: 41.6666666667%;
      margin-left: 0; }
    .rtl .offset-xxl-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-xxl-7 {
      margin-right: 58.3333333333%;
      margin-left: 0; }
    .rtl .offset-xxl-8 {
      margin-right: 66.6666666667%;
      margin-left: 0; }
    .rtl .offset-xxl-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-xxl-10 {
      margin-right: 83.3333333333%;
      margin-left: 0; }
    .rtl .offset-xxl-11 {
      margin-right: 91.6666666667%;
      margin-left: 0; } }
  .rtl .mr-0,
  .rtl .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .ml-0,
  .rtl .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .mr-1,
  .rtl .mx-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important; }
  .rtl .ml-1,
  .rtl .mx-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important; }
  .rtl .mr-2,
  .rtl .mx-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important; }
  .rtl .ml-2,
  .rtl .mx-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important; }
  .rtl .mr-3,
  .rtl .mx-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important; }
  .rtl .ml-3,
  .rtl .mx-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important; }
  .rtl .mr-4,
  .rtl .mx-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important; }
  .rtl .ml-4,
  .rtl .mx-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important; }
  .rtl .mr-5,
  .rtl .mx-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important; }
  .rtl .ml-5,
  .rtl .mx-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important; }
  .rtl .pr-0,
  .rtl .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pl-0,
  .rtl .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pr-1,
  .rtl .px-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important; }
  .rtl .pl-1,
  .rtl .px-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important; }
  .rtl .pr-2,
  .rtl .px-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important; }
  .rtl .pl-2,
  .rtl .px-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important; }
  .rtl .pr-3,
  .rtl .px-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important; }
  .rtl .pl-3,
  .rtl .px-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important; }
  .rtl .pr-4,
  .rtl .px-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important; }
  .rtl .pl-4,
  .rtl .px-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important; }
  .rtl .pr-5,
  .rtl .px-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important; }
  .rtl .pl-5,
  .rtl .px-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important; }
  .rtl .mr-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .ml-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  @media (min-width: 576px) {
    .rtl .mr-sm-0,
    .rtl .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-sm-0,
    .rtl .mx-sm-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-sm-1,
    .rtl .mx-sm-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-sm-1,
    .rtl .mx-sm-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-sm-2,
    .rtl .mx-sm-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-sm-2,
    .rtl .mx-sm-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-sm-3,
    .rtl .mx-sm-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-sm-3,
    .rtl .mx-sm-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-sm-4,
    .rtl .mx-sm-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-sm-4,
    .rtl .mx-sm-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-sm-5,
    .rtl .mx-sm-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-sm-5,
    .rtl .mx-sm-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-sm-0,
    .rtl .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-sm-0,
    .rtl .px-sm-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-sm-1,
    .rtl .px-sm-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-sm-1,
    .rtl .px-sm-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-sm-2,
    .rtl .px-sm-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-sm-2,
    .rtl .px-sm-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-sm-3,
    .rtl .px-sm-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-sm-3,
    .rtl .px-sm-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-sm-4,
    .rtl .px-sm-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-sm-4,
    .rtl .px-sm-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-sm-5,
    .rtl .px-sm-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-sm-5,
    .rtl .px-sm-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-sm-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-sm-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 768px) {
    .rtl .mr-md-0,
    .rtl .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-md-0,
    .rtl .mx-md-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-md-1,
    .rtl .mx-md-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-md-1,
    .rtl .mx-md-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-md-2,
    .rtl .mx-md-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-md-2,
    .rtl .mx-md-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-md-3,
    .rtl .mx-md-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-md-3,
    .rtl .mx-md-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-md-4,
    .rtl .mx-md-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-md-4,
    .rtl .mx-md-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-md-5,
    .rtl .mx-md-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-md-5,
    .rtl .mx-md-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-md-0,
    .rtl .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-md-0,
    .rtl .px-md-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-md-1,
    .rtl .px-md-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-md-1,
    .rtl .px-md-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-md-2,
    .rtl .px-md-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-md-2,
    .rtl .px-md-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-md-3,
    .rtl .px-md-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-md-3,
    .rtl .px-md-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-md-4,
    .rtl .px-md-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-md-4,
    .rtl .px-md-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-md-5,
    .rtl .px-md-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-md-5,
    .rtl .px-md-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-md-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-md-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 992px) {
    .rtl .mr-lg-0,
    .rtl .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-lg-0,
    .rtl .mx-lg-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-lg-1,
    .rtl .mx-lg-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-lg-1,
    .rtl .mx-lg-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-lg-2,
    .rtl .mx-lg-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-lg-2,
    .rtl .mx-lg-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-lg-3,
    .rtl .mx-lg-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-lg-3,
    .rtl .mx-lg-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-lg-4,
    .rtl .mx-lg-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-lg-4,
    .rtl .mx-lg-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-lg-5,
    .rtl .mx-lg-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-lg-5,
    .rtl .mx-lg-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-lg-0,
    .rtl .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-lg-0,
    .rtl .px-lg-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-lg-1,
    .rtl .px-lg-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-lg-1,
    .rtl .px-lg-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-lg-2,
    .rtl .px-lg-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-lg-2,
    .rtl .px-lg-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-lg-3,
    .rtl .px-lg-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-lg-3,
    .rtl .px-lg-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-lg-4,
    .rtl .px-lg-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-lg-4,
    .rtl .px-lg-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-lg-5,
    .rtl .px-lg-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-lg-5,
    .rtl .px-lg-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-lg-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-lg-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 1200px) {
    .rtl .mr-xl-0,
    .rtl .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-xl-0,
    .rtl .mx-xl-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-xl-1,
    .rtl .mx-xl-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-xl-1,
    .rtl .mx-xl-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-xl-2,
    .rtl .mx-xl-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-xl-2,
    .rtl .mx-xl-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-xl-3,
    .rtl .mx-xl-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-xl-3,
    .rtl .mx-xl-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-xl-4,
    .rtl .mx-xl-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-xl-4,
    .rtl .mx-xl-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-xl-5,
    .rtl .mx-xl-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-xl-5,
    .rtl .mx-xl-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-xl-0,
    .rtl .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-xl-0,
    .rtl .px-xl-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-xl-1,
    .rtl .px-xl-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-xl-1,
    .rtl .px-xl-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-xl-2,
    .rtl .px-xl-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-xl-2,
    .rtl .px-xl-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-xl-3,
    .rtl .px-xl-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-xl-3,
    .rtl .px-xl-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-xl-4,
    .rtl .px-xl-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-xl-4,
    .rtl .px-xl-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-xl-5,
    .rtl .px-xl-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-xl-5,
    .rtl .px-xl-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-xl-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-xl-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 1440) {
    .rtl .mr-xxl-0,
    .rtl .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-xxl-0,
    .rtl .mx-xxl-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-xxl-1,
    .rtl .mx-xxl-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-xxl-1,
    .rtl .mx-xxl-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-xxl-2,
    .rtl .mx-xxl-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-xxl-2,
    .rtl .mx-xxl-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-xxl-3,
    .rtl .mx-xxl-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-xxl-3,
    .rtl .mx-xxl-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-xxl-4,
    .rtl .mx-xxl-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-xxl-4,
    .rtl .mx-xxl-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-xxl-5,
    .rtl .mx-xxl-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-xxl-5,
    .rtl .mx-xxl-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-xxl-0,
    .rtl .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-xxl-0,
    .rtl .px-xxl-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-xxl-1,
    .rtl .px-xxl-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-xxl-1,
    .rtl .px-xxl-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-xxl-2,
    .rtl .px-xxl-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-xxl-2,
    .rtl .px-xxl-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-xxl-3,
    .rtl .px-xxl-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-xxl-3,
    .rtl .px-xxl-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-xxl-4,
    .rtl .px-xxl-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-xxl-4,
    .rtl .px-xxl-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-xxl-5,
    .rtl .px-xxl-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-xxl-5,
    .rtl .px-xxl-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-xxl-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-xxl-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  .rtl .text-right {
    text-align: left !important; }
  .rtl .text-left {
    text-align: right !important; }
  @media (min-width: 576px) {
    .rtl .text-sm-right {
      text-align: left !important; }
    .rtl .text-sm-left {
      text-align: right !important; } }
  @media (min-width: 768px) {
    .rtl .text-md-right {
      text-align: left !important; }
    .rtl .text-md-left {
      text-align: right !important; } }
  @media (min-width: 992px) {
    .rtl .text-lg-right {
      text-align: left !important; }
    .rtl .text-lg-left {
      text-align: right !important; } }
  @media (min-width: 1200px) {
    .rtl .text-xl-right {
      text-align: left !important; }
    .rtl .text-xl-left {
      text-align: right !important; } }
  @media (min-width: 1440) {
    .rtl .text-xxl-right {
      text-align: left !important; }
    .rtl .text-xxl-left {
      text-align: right !important; } }

.rtl .mx-0 {
  margin-right: auto;
  margin-left: 0 !important; }
.rtl .mx-0 {
  margin-left: auto;
  margin-right: 0 !important; }
.rtl .mx-1 {
  margin-right: auto;
  margin-left: 0.25rem !important; }
.rtl .mx-1 {
  margin-left: auto;
  margin-right: 0.25rem !important; }
.rtl .mx-2 {
  margin-right: auto;
  margin-left: 0.5rem !important; }
.rtl .mx-2 {
  margin-left: auto;
  margin-right: 0.5rem !important; }
.rtl .mx-3 {
  margin-right: auto;
  margin-left: 1rem !important; }
.rtl .mx-3 {
  margin-left: auto;
  margin-right: 1rem !important; }
.rtl .mx-4 {
  margin-right: auto;
  margin-left: 1.5rem !important; }
.rtl .mx-4 {
  margin-left: auto;
  margin-right: 1.5rem !important; }
.rtl .mx-5 {
  margin-right: auto;
  margin-left: 3rem !important; }
.rtl .mx-5 {
  margin-left: auto;
  margin-right: 3rem !important; }
.rtl .px-0 {
  padding-right: auto;
  padding-left: 0 !important; }
.rtl .px-0 {
  padding-left: auto;
  padding-right: 0 !important; }
.rtl .px-1 {
  padding-right: auto;
  padding-left: 0.25rem !important; }
.rtl .px-1 {
  padding-left: auto;
  padding-right: 0.25rem !important; }
.rtl .px-2 {
  padding-right: auto;
  padding-left: 0.5rem !important; }
.rtl .px-2 {
  padding-left: auto;
  padding-right: 0.5rem !important; }
.rtl .px-3 {
  padding-right: auto;
  padding-left: 1rem !important; }
.rtl .px-3 {
  padding-left: auto;
  padding-right: 1rem !important; }
.rtl .px-4 {
  padding-right: auto;
  padding-left: 1.5rem !important; }
.rtl .px-4 {
  padding-left: auto;
  padding-right: 1.5rem !important; }
.rtl .px-5 {
  padding-right: auto;
  padding-left: 3rem !important; }
.rtl .px-5 {
  padding-left: auto;
  padding-right: 3rem !important; }
.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important; }
.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }
.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important; }
.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }
@media (min-width: 576px) {
  .rtl .mx-sm-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-sm-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-sm-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-sm-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-sm-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-sm-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-sm-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-sm-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-sm-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-sm-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-sm-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-sm-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-sm-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-sm-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-sm-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-sm-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-sm-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-sm-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-sm-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-sm-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-sm-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-sm-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-sm-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-sm-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }
@media (min-width: 768px) {
  .rtl .mx-md-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-md-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-md-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-md-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-md-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-md-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-md-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-md-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-md-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-md-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-md-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-md-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-md-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-md-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-md-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-md-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-md-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-md-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-md-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-md-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-md-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-md-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-md-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-md-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }
@media (min-width: 992px) {
  .rtl .mx-lg-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-lg-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-lg-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-lg-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-lg-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-lg-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-lg-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-lg-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-lg-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-lg-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-lg-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-lg-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-lg-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-lg-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-lg-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-lg-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-lg-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-lg-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-lg-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-lg-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-lg-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-lg-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-lg-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-lg-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }
@media (min-width: 1200px) {
  .rtl .mx-xl-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-xl-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-xl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-xl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-xl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-xl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-xl-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-xl-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-xl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-xl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-xl-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-xl-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-xl-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-xl-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-xl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-xl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-xl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-xl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-xl-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-xl-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-xl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-xl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-xl-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-xl-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }
@media (min-width: 1440) {
  .rtl .mx-xxl-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-xxl-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-xxl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-xxl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-xxl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-xxl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-xxl-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-xxl-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-xxl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-xxl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-xxl-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-xxl-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-xxl-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-xxl-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-xxl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-xxl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-xxl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-xxl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-xxl-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-xxl-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-xxl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-xxl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-xxl-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-xxl-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }
.rtl .float-left {
  float: right !important; }
.rtl .float-right {
  float: left !important; }
.rtl .float-none {
  float: none !important; }
@media (min-width: 576px) {
  .rtl .float-sm-left {
    float: right !important; }
  .rtl .float-sm-right {
    float: left !important; }
  .rtl .float-sm-none {
    float: none !important; } }
@media (min-width: 768px) {
  .rtl .float-md-left {
    float: right !important; }
  .rtl .float-md-right {
    float: left !important; }
  .rtl .float-md-none {
    float: none !important; } }
@media (min-width: 992px) {
  .rtl .float-lg-left {
    float: right !important; }
  .rtl .float-lg-right {
    float: left !important; }
  .rtl .float-lg-none {
    float: none !important; } }
@media (min-width: 1200px) {
  .rtl .float-xl-left {
    float: right !important; }
  .rtl .float-xl-right {
    float: left !important; }
  .rtl .float-xl-none {
    float: none !important; } }
@media (min-width: 1440) {
  .rtl .float-xxl-left {
    float: right !important; }
  .rtl .float-xxl-right {
    float: left !important; }
  .rtl .float-xxl-none {
    float: none !important; } }

[direction="rtl"][data-theme-version="dark"] .border, [direction="rtl"][data-theme-version="dark"] .border-left, [direction="rtl"][data-theme-version="dark"] .border-right {
  border-color: #333a54 !important; }
[direction="rtl"] .text-right {
  text-align: left !important; }
[direction="rtl"] .text-left {
  text-align: right !important; }
[direction="rtl"] .border-right {
  border-left: 1px solid #d7dae3 !important;
  border-right: 0 !important; }
[direction="rtl"] .border-left {
  border-right: 1px solid #d7dae3 !important;
  border-left: 0 !important; }
[direction="rtl"] .dropdown-menu {
  left: auto; }
[direction="rtl"] .dropdown-menu-right {
  left: 0;
  right: auto; }
  @media only screen and (max-width: 575px) {
    [direction="rtl"] .dropdown-menu-right {
      left: 15px; } }
[direction="rtl"] .notification_dropdown .dropdown-menu-right .media > span {
  margin-left: 10px;
  margin-right: 0; }

[direction="rtl"]:not([data-container="boxed"]) .nav-header {
  left: auto;
  right: 0; }
[direction="rtl"][data-container="wide-boxed"] .nav-header {
  left: auto;
  right: 0; }
[direction="rtl"] .nav-header {
  text-align: right;
  right: auto; }
  [direction="rtl"] .nav-header .brand-title {
    margin-left: 0;
    margin-right: 15px; }
  [direction="rtl"] .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 1.75rem; }
    [data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
    [data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo[data-layout="horizontal"] {
      padding-right: 30px; }
    [data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
    [data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
      [data-layout="horizontal"][data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
        padding-right: 30px; }
    @media (max-width: 767.98px) {
      [direction="rtl"] .nav-header .brand-logo {
        padding-right: 0; } }
[direction="rtl"] .nav-control {
  right: auto;
  left: -4.0625rem; }
  @media (max-width: 767.98px) {
    [direction="rtl"] .nav-control {
      left: -4.0625rem; } }
  @media (max-width: 575.98px) {
    [direction="rtl"] .nav-control {
      left: -4.0625rem; } }
[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  right: 0; }

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-right: 30px; }

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
  width: 16rem; }

[direction="rtl"] .header {
  padding: 0 0.9375rem;
  padding-right: 17.1875rem; }
  @media (max-width: 767.98px) {
    [direction="rtl"] .header {
      padding-right: 3.75rem;
      padding-left: 0; } }
  [direction="rtl"] .header .header-content {
    padding-left: 0;
    padding-right: 5.3125rem; }
    @media only screen and (max-width: 575px) {
      [direction="rtl"] .header .header-content {
        padding-right: 4.5rem; } }
    [data-sidebar-style="compact"][direction="rtl"] .header .header-content {
      padding-right: 1.875rem; }
    [data-sidebar-style="modern"][direction="rtl"] .header .header-content {
      padding-right: 5.3125rem; }
    [data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
      padding-right: 5.3125rem; }
  [direction="rtl"] .header .nav-control {
    right: 0.4375rem;
    left: auto; }
[direction="rtl"] .header-left .search_bar .dropdown-menu,
[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
  right: 40px !important; }
  @media only screen and (max-width: 575px) {
    [direction="rtl"] .header-left .search_bar .dropdown-menu,
    [direction="rtl"] .header-left .search_bar .dropdown-menu.show {
      right: -100px !important; } }
[direction="rtl"] .header-left .search_bar .search_icon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem; }
  @media only screen and (max-width: 575px) {
    [direction="rtl"] .header-left .search_bar .search_icon {
      border-radius: 5rem; } }
[direction="rtl"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem; }
  [direction="rtl"][data-layout="horizontal"] .header .header-content {
    padding-right: 1.875rem;
    padding-left: 1.875rem; }
[direction="rtl"][data-layout="horizontal"][data-sidebar-style="mini"] .header {
  padding-right: 6rem; }
[direction="rtl"][data-sidebar-style="mini"] .header {
  padding: 0 0.9375rem;
  padding-right: 3.75rem; }
[direction="rtl"][data-sidebar-style="compact"] .header {
  padding: 0 0.9375rem;
  padding-right: 9.375rem; }
[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem; }
[direction="rtl"][data-sidebar-style="modern"] .header {
  padding: 0 0.9375rem;
  padding-right: 9.375rem; }
[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem; }

[direction="rtl"] {
  text-align: right; }
  [direction="rtl"] .deznav {
    text-align: right; }
    [direction="rtl"] .deznav .metismenu ul {
      padding: 0 25px 0 0; }
      [direction="rtl"] .deznav .metismenu ul:after {
        left: auto;
        right: 25px; }
      [direction="rtl"] .deznav .metismenu ul a:before {
        left: auto;
        right: -5px; }
    [direction="rtl"] .deznav .metismenu li > a svg {
      margin-left: 5px;
      margin-right: 0; }
      [data-sidebar-style="compact"][direction="rtl"] .deznav .metismenu li > a svg {
        left: auto;
        margin-left: auto;
        margin-right: auto; }
      [data-sidebar-style="icon-hover"][direction="rtl"] .deznav .metismenu li > a svg {
        margin-left: 0; }
    [direction="rtl"] .deznav .metismenu li.active > .has-arrow:after {
      transform: rotate(45deg) translateY(-50%); }
    [direction="rtl"] .deznav .metismenu .has-arrow:after {
      left: 1.5625rem;
      right: auto; }
      [data-layout="horizontal"][direction="rtl"] .deznav .metismenu .has-arrow:after {
        left: 1.125rem; }
      [data-sidebar-style="modern"][direction="rtl"] .deznav .metismenu .has-arrow:after {
        -webkit-transform: rotate(-45deg) translateY(-50%);
        transform: rotate(-45deg) translateY(-50%); }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul li:hover ul {
    right: 11.8125rem;
    left: 0; }

[direction="rtl"] .list-group {
  padding-left: 0; }
[direction="rtl"] .form-check-input {
  margin-left: -1.25rem;
  margin-right: inherit; }
[direction="rtl"] .form-check-inline .form-check-input {
  margin-right: 0;
  margin-left: 10px; }
[direction="rtl"] .radio input,
[direction="rtl"] .radio-inline,
[direction="rtl"] .checkbox input,
[direction="rtl"] .checkbox-inline input {
  margin-left: 0;
  margin-right: 0; }
[direction="rtl"] .content-body {
  margin-right: 17.1875rem;
  margin-left: auto; }
  [data-sidebar-style="modern"][direction="rtl"] .content-body {
    margin-right: 9.375rem; }
  [direction="rtl"] .content-body .page-titles {
    text-align: right; }
[direction="rtl"][data-layout="horizontal"] .content-body {
  margin-right: 0; }
[direction="rtl"][data-layout="horizontal"] .deznav .metismenu li li .has-arrow:after {
  -webkit-transform: rotate(-4deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%); }
[direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 3.75rem; }
[direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 9.375rem; }
[direction="rtl"][data-sidebar-style="overlay"] .content-body {
  margin-right: 0; }
[direction="rtl"] #external-events .external-event:before {
  margin-right: 0;
  margin-left: .9rem; }
[direction="rtl"] .post-input a i {
  margin-left: 15px;
  margin-right: 0; }

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  [direction="rtl"] .content-body {
    margin-right: 14.375rem; } }
[direction="rtl"] .sidebar-right {
  left: -15.625rem;
  right: auto;
  box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1); }
  [direction="rtl"] .sidebar-right.show {
    left: 0;
    right: auto; }
  [direction="rtl"] .sidebar-right .sidebar-right-trigger {
    left: 100%;
    right: auto;
    border-radius: 0 5px 5px 0;
    box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15); }
[direction="rtl"] .bootstrap-select .dropdown-toggle .filter-option {
  text-align: right; }

html[dir="rtl"] [direction="rtl"] .footer {
  padding-right: 17.1875rem;
  padding-left: 0; }
  @media (max-width: 767.98px) {
    html[dir="rtl"] [direction="rtl"] .footer {
      padding-right: 0; } }
html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
  padding-right: 0; }
html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
  padding-right: 3.75rem; }
html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
  padding-right: 0; }
html[dir="rtl"] [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .footer {
  padding-right: 3.75rem; }
html[dir="rtl"] [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .footer {
  padding-right: 9.375rem; }

.nav-label {
  margin: 10px 30px 0;
  padding: 1.5625rem 0 10px;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  border-top: 1px solid #eaeaea;
  color: #999999; }
  [data-theme-version="dark"] .nav-label {
    border-color: #333a54; }
  .nav-label.first {
    border: 0px;
    margin-top: 0px; }

.nav-badge {
  position: absolute;
  right: 2.8125rem;
  top: 0.625rem; }

.content-body {
  margin-left: 21.563rem;
  z-index: 0;
  transition: all .2s ease; }
  @media only screen and (max-width: 1400px) {
    .content-body {
      margin-left: 18rem; } }

.bell img {
  -webkit-animation: ring 8s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 8s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 8s .7s ease-in-out infinite; }

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0); }
  1% {
    -webkit-transform: rotateZ(30deg); }
  3% {
    -webkit-transform: rotateZ(-28deg); }
  5% {
    -webkit-transform: rotateZ(34deg); }
  7% {
    -webkit-transform: rotateZ(-32deg); }
  9% {
    -webkit-transform: rotateZ(30deg); }
  11% {
    -webkit-transform: rotateZ(-28deg); }
  13% {
    -webkit-transform: rotateZ(26deg); }
  15% {
    -webkit-transform: rotateZ(-24deg); }
  17% {
    -webkit-transform: rotateZ(22deg); }
  19% {
    -webkit-transform: rotateZ(-20deg); }
  21% {
    -webkit-transform: rotateZ(18deg); }
  23% {
    -webkit-transform: rotateZ(-16deg); }
  25% {
    -webkit-transform: rotateZ(14deg); }
  27% {
    -webkit-transform: rotateZ(-12deg); }
  29% {
    -webkit-transform: rotateZ(10deg); }
  31% {
    -webkit-transform: rotateZ(-8deg); }
  33% {
    -webkit-transform: rotateZ(6deg); }
  35% {
    -webkit-transform: rotateZ(-4deg); }
  37% {
    -webkit-transform: rotateZ(2deg); }
  39% {
    -webkit-transform: rotateZ(-1deg); }
  41% {
    -webkit-transform: rotateZ(1deg); }
  43% {
    -webkit-transform: rotateZ(0); }
  100% {
    -webkit-transform: rotateZ(0); } }
@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0); }
  1% {
    -moz-transform: rotate(30deg); }
  3% {
    -moz-transform: rotate(-28deg); }
  5% {
    -moz-transform: rotate(34deg); }
  7% {
    -moz-transform: rotate(-32deg); }
  9% {
    -moz-transform: rotate(30deg); }
  11% {
    -moz-transform: rotate(-28deg); }
  13% {
    -moz-transform: rotate(26deg); }
  15% {
    -moz-transform: rotate(-24deg); }
  17% {
    -moz-transform: rotate(22deg); }
  19% {
    -moz-transform: rotate(-20deg); }
  21% {
    -moz-transform: rotate(18deg); }
  23% {
    -moz-transform: rotate(-16deg); }
  25% {
    -moz-transform: rotate(14deg); }
  27% {
    -moz-transform: rotate(-12deg); }
  29% {
    -moz-transform: rotate(10deg); }
  31% {
    -moz-transform: rotate(-8deg); }
  33% {
    -moz-transform: rotate(6deg); }
  35% {
    -moz-transform: rotate(-4deg); }
  37% {
    -moz-transform: rotate(2deg); }
  39% {
    -moz-transform: rotate(-1deg); }
  41% {
    -moz-transform: rotate(1deg); }
  43% {
    -moz-transform: rotate(0); }
  100% {
    -moz-transform: rotate(0); } }
@keyframes ring {
  0% {
    transform: rotate(0); }
  1% {
    transform: rotate(30deg); }
  3% {
    transform: rotate(-28deg); }
  5% {
    transform: rotate(34deg); }
  7% {
    transform: rotate(-32deg); }
  9% {
    transform: rotate(30deg); }
  11% {
    transform: rotate(-28deg); }
  13% {
    transform: rotate(26deg); }
  15% {
    transform: rotate(-24deg); }
  17% {
    transform: rotate(22deg); }
  19% {
    transform: rotate(-20deg); }
  21% {
    transform: rotate(18deg); }
  23% {
    transform: rotate(-16deg); }
  25% {
    transform: rotate(14deg); }
  27% {
    transform: rotate(-12deg); }
  29% {
    transform: rotate(10deg); }
  31% {
    transform: rotate(-8deg); }
  33% {
    transform: rotate(6deg); }
  35% {
    transform: rotate(-4deg); }
  37% {
    transform: rotate(2deg); }
  39% {
    transform: rotate(-1deg); }
  41% {
    transform: rotate(1deg); }
  43% {
    transform: rotate(0); }
  100% {
    transform: rotate(0); } }
.deznav {
  width: 21.563rem;
  padding-bottom: 0;
  height: calc(100% - 120px);
  position: absolute;
  top: 7.5rem;
  padding-top: 0;
  z-index: 5;
  background-color: #fff;
  transition: all .2s ease; }
  @media only screen and (max-width: 1199px) {
    .deznav {
      background-color: #fff;
      top: 5rem;
      height: calc(100% - 80px); } }
  @media only screen and (max-width: 1400px) {
    .deznav {
      width: 18rem; } }
  .deznav .deznav-scroll {
    position: relative;
    height: 100%; }
  .deznav ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  .deznav .metismenu {
    display: flex;
    flex-direction: column;
    padding-top: 15px; }
    .deznav .metismenu.fixed {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0; }
    .deznav .metismenu > li {
      display: flex;
      flex-direction: column; }
      .deznav .metismenu > li a > i {
        font-size: 1.5rem;
        display: inline-block;
        vertical-align: middle;
        padding: 0 0.875rem 0 0;
        position: relative;
        top: 0;
        line-height: 1; }

        /* @mg */
        .deznav .metismenu > li > ul > li {
          display: flex;
          flex-direction: column; 
        
        }
        .deznav .metismenu > li > ul > li > a {
          color:#302e34 !important;
          /* color:#787878 !important; */
          font-size: 13px;
        }

        .deznav .metismenu > li > ul > li > a.mm-active {
          color:#4857c5 !important;
          font-size: 13px;
        }
        .deznav .metismenu > li > ul > li.mm-active > a {
          color:#4857c5 !important;
          font-size: 17px;
        }
        .deznav .metismenu > li > ul > li.mm-active > a >span {
          color:#4857c5 !important;
          font-size: 17px;
        }

     
          .deznav .metismenu > li > ul > li> a > i {
            font-size: 1.5rem;
            display: inline-block;
            vertical-align: middle;
            padding: 0 0.875rem 0 0;
            position: relative;
            top: 0;
            line-height: 1; }

            /* @mg */

        [data-sidebar-style="compact"] .deznav .metismenu > li a > i {
          display: block;
          padding: 0; }
        @media only screen and (max-width: 767px) {
          .deznav .metismenu > li a > i {
            font-size: 1.25rem;
            padding: 0 .75rem 0 0; } }
      .deznav .metismenu > li > a {
        font-weight: 400;
        display: inline-block;
        font-size: 15px; }
        .deznav .metismenu > li > a svg {
          max-width: 24px;
          max-height: 24px;
          height: 100%;
          margin-right: 5px;
          margin-top: -3px;
          color: #3F9AE0; }
        .deznav .metismenu > li > a g [fill] {
          fill: #8088a1; }
      .deznav .metismenu > li:hover > a, .deznav .metismenu > li:focus > a {
        color: #3F9AE0; }
        .deznav .metismenu > li:hover > a g [fill], .deznav .metismenu > li:focus > a g [fill] {
          fill: #3F9AE0; }
      .deznav .metismenu > li.mm-active > a {
        color: #3F9AE0; }
        .deznav .metismenu > li.mm-active > a g [fill] {
          fill: #3F9AE0; }
    .deznav .metismenu li {
      position: relative; }
    .deznav .metismenu ul {
      transition: all .2s ease-in-out;
      position: relative;
      z-index: 1;
      padding: 0.5rem 0; }
      .deznav .metismenu ul a {
        padding-top: .5rem;
        padding-bottom: .5rem;
        position: relative;
        font-size: 15px;
        padding-left: 3rem; }
        @media only screen and (max-width: 767px) {
          .deznav .metismenu ul a {
            padding-left: 3rem;
            font-size: 14px; } }
        .deznav .metismenu ul a:hover, .deznav .metismenu ul a:focus, .deznav .metismenu ul a.mm-active {
          text-decoration: none;
          color: #3F9AE0; }
    .deznav .metismenu a {
      position: relative;
      display: block;
      padding: 0.625rem 1.875rem;
      outline-width: 0;
      color: #7e7e7e;
      text-decoration: none; }
      @media only screen and (max-width: 767px) {
        .deznav .metismenu a {
          padding: 0.625rem 1.25rem; } }
    .deznav .metismenu .has-arrow:after {
      width: .5rem;
      height: .5rem;
      right: 1.875rem;
      top: 48%;
      border-color: inherit;
      -webkit-transform: rotate(-225deg) translateY(-50%);
      transform: rotate(-225deg) translateY(-50%); }
    .deznav .metismenu .has-arrow[aria-expanded=true]:after,
    .deznav .metismenu .mm-active > .has-arrow:after {
      -webkit-transform: rotate(-135deg) translateY(-50%);
      transform: rotate(-135deg) translateY(-50%); }

@media only screen and (max-width: 1199px) {
  .nav-header {
    width: 5rem; } }

@media (max-width: 767px) {
  .brand-title {
    display: none; }

  .footer {
    padding-left: 0; }

  .deznav {
    left: 0;
    top: 5rem; } }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu > ul.collapse:not(.in),
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu > ul.collapse:not(.in) {
  height: 252px !important; }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu:hover > a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu:hover > a {
  width: calc(70vw + 3.75rem); }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu:hover > ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu:hover > ul ul a,
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu:hover > ul ul a {
    width: 101%; }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-xl:hover > a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-xl:hover > a {
  width: calc(70vw + 3rem); }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-xl:hover > ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 200px;
  width: 70vw; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-xl:hover > ul ul a,
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-xl:hover > ul ul a {
    width: 101%; }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-lg:hover > a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-lg:hover > a {
  width: calc(55vw + 3rem); }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-lg:hover > ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 200px;
  width: 55vw; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-lg:hover > ul ul a,
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-lg:hover > ul ul a {
    width: 101%; }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-md:hover > a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-md:hover > a {
  width: calc(45vw + 3); }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-md:hover > ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 18.75rem;
  width: 45vw; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-md:hover > ul ul a,
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-md:hover > ul ul a {
    width: 101%; }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-sm:hover > a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-sm:hover > a {
  width: calc(30vw + 3); }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-sm:hover > ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 18.125rem;
  width: 30vw; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mega-menu-sm:hover > ul ul a,
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mega-menu-sm:hover > ul ul a {
    width: 101%; }

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu:hover > a {
  width: calc(60vw + 3.75rem); }
[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 25rem;
  width: 60vw; }
  [data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu:hover > ul ul a {
    width: 101%; }
[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-xl:hover > a {
  width: calc(60vw + 3.75rem); }
[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 25.625rem;
  width: 60vw; }
[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-lg:hover > a {
  width: calc(50vw + 3.75rem); }
[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 16.25rem;
  width: 50vw; }
[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-md:hover > a {
  width: calc(40vw + 3.75rem); }
[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 18.75rem;
  width: 40vw; }
[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-sm:hover > a {
  width: calc(22vw + 3.75rem); }
[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 18.125rem;
  width: 22vw; }

[data-layout="horizontal"] .deznav .metismenu > li.mega-menu:not(:last-child) {
  position: static; }
[data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul {
  left: 0;
  right: 0; }
  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #333a54; }
  [data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #03090f; }
  [data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #8f1743; }
  [data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #22134e; }
  [data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #711717; }
  [data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #121843; }
  [data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #3511d0; }
  [data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #1161aa; }
  [data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #002629; }
  [data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li {
    border-color: #af5b00; }
  [data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li a {
    transition: all .4s ease-in-out; }
    [data-layout="horizontal"] .deznav .metismenu > li.mega-menu ul li a:hover {
      border-radius: 0.25rem; }
[data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
  z-index: 99; }
  [data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    background-color: #fff;
    right: 2.8125rem;
    top: 0; }
    [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #1d2544; }
    [data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #0c223a; }
    [data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #bb1e57; }
    [data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #331d77; }
    [data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #9c1f1f; }
    [data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #1d276b; }
    [data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #4c27ed; }
    [data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #167cd8; }
    [data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #00545c; }
    [data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul::after {
      background-color: #e27500; }
  [data-layout="horizontal"] .deznav .metismenu > li.mega-menu:hover > ul ul a {
    width: 101%; }
[data-layout="horizontal"] .deznav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 210px;
  width: 70vw; }
[data-layout="horizontal"] .deznav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 210px;
  width: 700px;
  height: 210px !important; }
  @media only screen and (min-width: 1200px) and (max-width: 1500px) {
    [data-layout="horizontal"] .deznav .metismenu > li.mega-menu-lg:hover > ul {
      width: 700px; } }
[data-layout="horizontal"] .deznav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 20rem;
  width: 54vw; }
  @media only screen and (min-width: 1200px) and (max-width: 1500px) {
    [data-layout="horizontal"] .deznav .metismenu > li.mega-menu-md:hover > ul {
      width: 60vw; } }
[data-layout="horizontal"] .deznav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 20rem;
  width: 25vw; }
  @media only screen and (min-width: 1200px) and (max-width: 1500px) {
    [data-layout="horizontal"] .deznav .metismenu > li.mega-menu-sm:hover > ul {
      width: 35vw; } }

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li.mega-menu:hover > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }
[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li.mega-menu-xl:hover > ul {
  max-height: 21.875rem;
  width: 100%; }
[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li.mega-menu-lg:hover > ul {
  max-height: 21.875rem;
  width: 55vw; }
[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li.mega-menu-md:hover > ul {
  max-height: 18.75rem;
  width: 45vw; }
[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li.mega-menu-sm:hover > ul {
  max-height: 18.125rem;
  width: 50vw; }

/*$primary  : #7356f1 !default;*/
/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li {
  padding: 0 0 0 20px; }
  [data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li > a {
    font-size: 18px;
    padding: 20px 30px 20px 10px;
    border-radius: 3rem 0 0 3rem;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    @media only screen and (max-width: 1400px) {
      [data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li > a {
        font-size: 16px; } }
  [data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a {
    background: #f9f9f9;
    /* background: #fff; */
    font-weight: 600;
    color: #3F9AE0;
    box-shadow: 0px 1px 7px 3px rgb(135 107 117 / 13%);
    /* box-shadow: 0 12px 15px 0 rgba(221, 47, 110, 0.13);  */
  }
    [data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {
      color: #3F9AE0;
      font-weight: normal; }
  [data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li .has-arrow:after {
    right: 3.125rem; }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
  width: 5rem;
  z-index: 999; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
    background-color: #fff !important
    /* background-color: #525556 !important */
   }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .copyright,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .plus-box {
  display: none; }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding-left: 5rem;
  width: 100%; }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
    padding: 0 0.9375rem;
    padding-right: 5rem; }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav {
  width: 5rem;
  overflow: visible;
  position: absolute; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .nav-text {
    display: none; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .slimScrollDiv,
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .deznav-scroll {
    overflow: visible !important; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li {
    position: relative; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a {
      padding: 0.8125rem 0.9375rem; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a svg {
        max-width: 24px;
        max-height: 24px;
        margin-right: 0; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a:before {
        content: none; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul {
      position: absolute;
      left: 5rem;
      top: 0;
      width: 15rem;
      z-index: 1001;
      display: none;
      padding-left: 1px;
      height: auto !important;
      box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
      border-radius: 6px;
      margin-left: 0;
      border: 0;
      background: #fff; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul:after {
        content: "";
        position: absolute;
        background: inherit;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        left: -5px;
        top: 20px; }
      [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul {
        left: auto;
        right: 3rem; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul li:hover ul {
        left: 11.8125rem;
        top: 0; }
        [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul li:hover ul:after {
          content: none; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li:hover > ul {
      display: block;
      height: auto;
      overflow: visible; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li {
    transition: all 0.4s ease-in-out;
    padding: 0 13px; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li > a.has-arrow:after {
      display: none; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a {
      background: #3F9AE0;
      border-radius: 0.75rem; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a i {
        color: var(--iconBlue); }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover:nth-last-child(-n + 1) > ul {
      bottom: 0;
      top: auto; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
      border-radius: 0.75rem;
      background: #3F9AE0;
      color: #fff; }
      [data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
        background: #1e2746; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a i {
        color: #fff; }
    [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > ul {
      height: auto !important;
      padding: 10px 0; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > ul a {
        padding: 6px 20px 6px 20px;
        margin-left: -.1rem; }
      [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > ul ul {
        padding: 10px 0; }
        [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > ul ul a {
          padding: 6px 20px 6px 20px;
          margin-left: -.1rem; }
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .nav-label,
  [data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .nav-badge {
    display: none; }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-left: 5rem; }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
    margin-right: 5rem;
    margin-left: auto;
    border: 0; }
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle + .footer {
  padding-left: 5rem; }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle + .footer {
    padding-left: 0;
    padding-right: 5rem; }

[data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu {
  padding: 0 30px; }
[data-sidebar-style="full"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem; }

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/*$primary  : #7356f1 !default;*/
@media only screen and (min-width: 768px) {
  [data-sidebar-style="mini"] .nav-header {
    width: 5rem; }
    [data-sidebar-style="mini"] .nav-header .nav-control {
      z-index: -1; }
      [data-sidebar-style="mini"] .nav-header .nav-control .hamburger {
        left: 5rem !important; }
        [data-sidebar-style="mini"] .nav-header .nav-control .hamburger .line {
          background-color: #3e4954 !important; }
    [data-sidebar-style="mini"] .nav-header .brand-title {
      display: none; }
    [data-sidebar-style="mini"] .nav-header .hamburger {
      display: none; }
  [data-sidebar-style="mini"] .header .header-content {
    padding-left: 1.875rem; }
    [direction="rtl"][data-sidebar-style="mini"] .header .header-content {
      padding-right: 1.875rem; }
  [data-sidebar-style="mini"] .deznav {
    width: 5rem;
    overflow: visible;
    position: absolute; }
    [data-sidebar-style="mini"] .deznav .copyright,
    [data-sidebar-style="mini"] .deznav .plus-box {
      display: none; }
    [data-sidebar-style="mini"] .deznav .nav-text {
      display: none; }
    [data-sidebar-style="mini"] .deznav .slimScrollDiv,
    [data-sidebar-style="mini"] .deznav .deznav-scroll {
      overflow: visible !important; }
    [data-sidebar-style="mini"] .deznav .nav-user {
      padding: 11px; }
      [data-sidebar-style="mini"] .deznav .nav-user .media-body {
        display: none; }
    [data-sidebar-style="mini"] .deznav .metismenu li a {
      padding: 0.813rem 0.875rem; }
      [data-sidebar-style="mini"] .deznav .metismenu li a svg {
        margin-right: 0; }
    [data-sidebar-style="mini"] .deznav .metismenu li > ul {
      position: absolute;
      left: 5rem;
      top: 2.9375rem;
      width: 11.875rem;
      z-index: 1001;
      display: none;
      padding-left: 1px;
      box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
      height: auto !important;
      border-radius: 6px;
      background: #fff; }
      [direction="rtl"]:not([data-layout="horizontal"])[data-sidebar-style="mini"] .deznav .metismenu li > ul {
        left: auto;
        right: 5rem;
        box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.15); }
    [data-sidebar-style="mini"] .deznav .metismenu > li {
      padding: 2px 15px; }
      [data-sidebar-style="mini"] .deznav .metismenu > li > a.has-arrow:after {
        display: none; }
    [data-sidebar-style="mini"] .deznav .metismenu .nav-label,
    [data-sidebar-style="mini"] .deznav .metismenu .nav-badge {
      display: none; }
  [data-sidebar-style="mini"] .content-body {
    margin-left: 5rem; }
  [data-sidebar-style="mini"] .footer {
    padding-left: 5rem; }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li:hover > ul {
    display: block; }
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:nth-last-child(-n + 1) > ul {
    bottom: 0;
    top: auto !important; }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:nth-last-child(-n + 1) > ul:after {
      top: auto;
      bottom: 20px; } }
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:nth-last-child(-n + 1) > ul {
    bottom: 0;
    top: auto !important; } }
@media only screen and (min-width: 768px) {
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul {
    overflow: visible; }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul:after {
      content: none; }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul li:hover ul {
      padding: 10px 0;
      width: 13rem;
      left: 13rem;
      top: -10px;
      border: 0;
      margin: 0; }
      [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul li:hover ul:after {
        content: none; }
      [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li > ul li:hover ul {
        left: auto;
        right: 13rem; }
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a {
    background: #3F9AE0;
    color: #fff;
    border-radius: 12px; }
    [data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a {
      background: #1e2746; }
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
    background: #3F9AE0;
    color: #fff;
    border-radius: 12px;
    position: unset; }
    [data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
      background: #1e2746; }
    [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a .nav-text {
      padding-left: auto;
      padding-right: 1.6875rem; }
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul {
    height: auto !important;
    overflow: visible;
    border: 0;
    margin-left: 0;
    left: 5rem;
    width: 13rem;
    border-radius: 12px;
    border: 0;
    padding: 10px 0;
    top: 0; }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul:after {
      content: "";
      position: absolute;
      background: inherit;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      left: -5px;
      top: 20px; }
    [data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul {
      box-shadow: 0px 9px 10px 0px rgba(0, 0, 0, 0.1); }
    [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul {
      left: auto;
      right: 5rem; }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul a {
      padding: 6px 20px 6px 20px; }
      [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul a:before {
        content: none; }
      [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul a.has-arrow:after {
        right: 1.25rem; }
    [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul ul a {
      padding: 6px 20px 6px 20px;
      margin-left: -1.6px; }
      [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > ul ul a:before {
        content: none; }

  [data-sidebar-style="mini"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
    width: 1199px; }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu {
    padding: 0 30px; }
  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu > li > a {
    padding: 15px 20px; }
    [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu > li > a {
      padding: 15px 20px; }
      [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu > li > a svg {
        margin-left: 0; }
    [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu > li > a svg {
      margin-right: 0;
      margin-top: 0; } }
@media only screen and (max-width: 1199px) {
  [data-sidebar-style="mini"] .deznav li.mm-active ul {
    height: auto !important; }
  [data-sidebar-style="mini"] .deznav li a.has-arrow::after {
    transform: rotate(-45deg) translateY(-50%); } }
/*$primary  : #7356f1 !default;*/
@media (min-width: 1199px) {
  [data-layout="horizontal"] .nav-header {
    width: 16rem; }
    [data-layout="horizontal"] .nav-header .nav-control {
      display: none; }
    [data-layout="horizontal"] .nav-header .brand-logo {
      /*  padding-left: 0;
       justify-content: center; */ }
  [data-layout="horizontal"] .header {
    width: 100%;
    padding-left: 16rem; }
  [data-layout="horizontal"] .deznav {
    width: 100%;
    position: relative;
    height: auto;
    padding-bottom: 0;
    top: 0;
    z-index: 2; }
    [data-layout="horizontal"] .deznav .slimScrollDiv {
      overflow: visible !important; }
      [data-layout="horizontal"] .deznav .slimScrollDiv .deznav-scroll {
        overflow: visible !important; }
    [data-layout="horizontal"] .deznav .deznav-scroll {
      overflow: visible !important; }
    [data-layout="horizontal"] .deznav .slimScrollBar {
      display: none !important; }
    [data-layout="horizontal"] .deznav .nav-user,
    [data-layout="horizontal"] .deznav .nav-label {
      display: none; }
    [data-layout="horizontal"] .deznav .metismenu {
      flex-direction: row; }
      [data-layout="horizontal"] .deznav .metismenu .collapse.in {
        display: none; }
      [data-layout="horizontal"] .deznav .metismenu ul {
        border-left: 0; }
        [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu ul {
          box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1); }
      [data-layout="horizontal"] .deznav .metismenu li {
        flex-direction: column;
        position: relative; }
        [data-layout="horizontal"] .deznav .metismenu li:hover > ul {
          display: block; }
        [data-layout="horizontal"] .deznav .metismenu li > ul {
          position: absolute;
          height: auto !important;
          top: 3.25rem;
          width: 100%;
          min-width: 13.75rem;
          z-index: 999;
          left: auto;
          right: auto;
          padding: 0.5rem 0;
          display: none;
          box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
          margin: 0;
          background: #fff; }
          [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li > ul {
            box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1); }
          [data-layout="horizontal"] .deznav .metismenu li > ul li {
            padding: 0; }
            [data-layout="horizontal"] .deznav .metismenu li > ul li a {
              transition: all .4s ease-in-out;
              padding: 8px 20px 8px 45px;
              margin-left: -.1rem; }
              [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li > ul li a {
                padding: 8px 45px 8px 20px;
                text-align: right; }
              [data-layout="horizontal"] .deznav .metismenu li > ul li a:hover {
                border-radius: .4rem; }
              [data-layout="horizontal"] .deznav .metismenu li > ul li a:before {
                left: 22px; }
                [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li > ul li a:before {
                  left: auto;
                  right: 21px; }
          [data-layout="horizontal"] .deznav .metismenu li > ul ul {
            left: 100%;
            top: 0;
            box-shadow: 0px 10px 13px 0px rgba(82, 63, 105, 0.05); }
            [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li > ul ul {
              left: auto;
              right: 100%; }
      [data-layout="horizontal"] .deznav .metismenu > li {
        flex: 0 0 auto;
        position: relative; }
        [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li {
          border-color: rgba(255, 255, 255, 0.07); }
          [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li.mm-active {
            border-color: transparent; }
        [data-layout="horizontal"] .deznav .metismenu > li.mm-active {
          padding: 0; }
          [data-layout="horizontal"] .deznav .metismenu > li.mm-active > a {
            background: #fff;
            box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05); }
        [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li:first-child {
          border-right: 0; }
        [data-theme-version="dark"][direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li {
          border-color: #333a54; }
        [data-layout="horizontal"] .deznav .metismenu > li > a {
          padding: 15px 40px 15px 15px; }
          [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li > a {
            padding: 15px 15px 15px 40px; }
          [data-layout="horizontal"] .deznav .metismenu > li > a i {
            padding: 0 0.4375rem 0 0; }
            [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li > a i {
              padding: 0 0 0 0.4375rem; }
          [data-layout="horizontal"] .deznav .metismenu > li > a .nav-badge {
            display: none; }
          [data-layout="horizontal"] .deznav .metismenu > li > a:after {
            right: 20px;
            transform: rotate(-135deg) translateY(-50%); }
        [data-layout="horizontal"] .deznav .metismenu > li:hover {
          border-color: transparent; }
          [data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
            display: flex !important;
            flex-direction: column;
            flex-wrap: wrap;
            height: auto !important;
            box-shadow: 0px 10px 13px 0px rgba(82, 63, 105, 0.05); }
            [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li:hover > ul {
              box-shadow: 0px 0 13px 0px rgba(0, 0, 0, 0.1); }
        [data-layout="horizontal"] .deznav .metismenu > li > ul > li:hover ul.collapse {
          display: block !important;
          position: absolute;
          left: auto !important;
          right: -100% !important;
          top: 0 !important; }
        [data-layout="horizontal"] .deznav .metismenu > li:nth-last-child(-n + 5) > ul {
          left: auto;
          right: 0; }
          [data-layout="horizontal"] .deznav .metismenu > li:nth-last-child(-n + 5) > ul > li:hover ul.collapse {
            right: auto !important;
            left: -100% !important; }
        [data-layout="horizontal"] .deznav .metismenu > li:last-child > ul ul {
          left: -100%; }
        [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li:nth-last-child(-n + 3) > ul {
          left: 0;
          right: auto; }
          [direction="rtl"][data-layout="horizontal"] .deznav .metismenu > li:nth-last-child(-n + 3) > ul > li:hover ul.collapse {
            right: -100% !important;
            left: auto !important; }
  [data-layout="horizontal"] .content-body {
    margin-left: 0; }
    [data-layout="horizontal"] .content-body .page-titles {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1.875rem; }
  [data-layout="horizontal"] .footer {
    margin-left: 0;
    margin: 0 auto;
    padding-left: 0; }
  [data-layout="horizontal"] .sidebar-right {
    z-index: 1; }

  [data-header-position="fixed"][data-layout="horizontal"] .deznav {
    top: 5rem; }

  [data-header-position="fixed"][data-sidebar-position="fixed"] .deznav {
    position: fixed; }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"] .content-body {
    padding-top: 8.3rem; }

  [data-layout="horizontal"][data-container="boxed"] .footer {
    margin-left: 0;
    max-width: 1199px;
    margin: 0 auto; }

  [data-layout="horizontal"][data-container="wide"] .page-titles {
    margin-left: -30px;
    margin-right: -30px; }

  [data-layout="horizontal"][data-sidebar-style="compact"] .page-titles {
    margin-top: 0; }
  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu > li > ul {
    top: 4.315rem; }
  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu > li > a {
    padding: 0.8125rem 2.1rem; }
    [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu > li > a::after {
      display: none; }
  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu > li li {
    text-align: left; }

  [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header {
    width: 6rem;
    padding-left: 30px;
    padding-right: 30px; }
    [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header .brand-logo {
      justify-content: start; }
  [data-sidebar-style="mini"][data-layout="horizontal"] .header {
    width: 100%;
    padding-left: 6rem; }
  [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu > li:hover a {
    width: auto; }
    [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu > li:hover a .nav-text {
      display: none; }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] .content-body {
    padding-top: 8.75rem; }

  [data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
    position: fixed;
    left: 0;
    top: 0; } }
@media (min-width: 767px) {
  [data-sidebar-style="compact"] .deznav .nav-user {
    display: none; }
  [data-sidebar-style="compact"] .deznav .metismenu li {
    text-align: center; }
    [data-sidebar-style="compact"] .deznav .metismenu li a {
      padding: 0.625rem 0.9375rem 0.625rem 0.9375rem; }
      [data-sidebar-style="compact"] .deznav .metismenu li a svg {
        max-width: 21px;
        max-height: 21px;
        display: block;
        margin-left: auto;
        margin-right: auto; }
    [data-sidebar-style="compact"] .deznav .metismenu li ul:after {
      content: none; }
  [data-sidebar-style="compact"] .nav-text {
    display: inline-block;
    margin-top: 0.3125rem; }
  [data-sidebar-style="compact"] .nav-label.first {
    display: none; }
  [data-sidebar-style="compact"] .nav-badge {
    display: none; }
  [data-sidebar-style="compact"] .footer {
    padding-left: 9.375rem; }
  [data-sidebar-style="compact"] .content-body {
    margin-left: 9.375rem; } }
[data-layout="horizontal"][data-sidebar-style="compact"] .footer {
  padding-left: 0; }
[data-layout="horizontal"][data-sidebar-style="compact"] .content-body {
  margin-left: 0; }
[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu {
  padding: 0 30px; }

[data-layout="vertical"][data-sidebar-style="compact"] .deznav {
  width: 9.375rem; }
  [data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul {
    margin-left: 0;
    border: 0;
    background: rgba(0, 0, 0, 0.02);
    padding: 10px 0; }
    [data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul {
      background: 255, 255, 255, 0.05; }
    [data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul a:before {
      content: none; }
    [data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul ul a {
      padding: 0.625rem 0.9375rem; }
  [data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li > a.has-arrow::after {
    top: 1px;
    display: inline-block;
    right: auto;
    margin-left: 5px;
    position: relative;
    width: 7px;
    height: 7px;
    border-width: 2px 0px 0px 2px; }
    [direction="rtl"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu > li > a.has-arrow::after {
      left: auto;
      margin-left: 0;
      margin-right: 5px; }

@media (min-width: 767px) {
  [data-sidebar-style="icon-hover"][data-layout="horizontal"] .metismenu {
    padding: 0 30px; }
  [data-sidebar-style="icon-hover"][data-layout="horizontal"] .header .header-content {
    padding-left: 1.875rem; }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header {
    width: 5.063rem; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
      padding-left: 20px; }
      [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
        padding-left: 1.25rem;
        padding-right: 1.25rem; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .logo-abbr {
        display: block; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .brand-title {
        display: none; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .nav-control {
      display: none; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
    padding-left: 5.063rem; }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
      padding-right: 4.38rem;
      padding-left: 0.9375rem; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
      padding-left: 1.375rem; }
      [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
        padding-right: 1.375rem;
        padding-left: 0; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
    overflow: visible;
    position: absolute;
    left: -12.125rem; } }
  @media only screen and (min-width: 767px) and (min-width: 1200px) and (max-width: 1350px) {
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
      left: -10rem; } }
@media (min-width: 767px) {
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
      left: auto;
      right: -12.125rem; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .nav-label {
      display: none; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li > a {
      display: flex;
      justify-content: space-between; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li > a > svg, [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu > li > a > i {
        order: 1;
        margin-right: 0;
        margin-top: 0; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
      border-left: 0;
      padding-left: 0;
      padding-right: 28px; }
      [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
        padding-right: 0;
        padding-left: 28px; }
      [data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
        border-color: #333a54; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
        left: auto;
        right: 28px; }
        [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
          left: 28px;
          right: auto; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a {
        position: relative; }
        [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
          left: auto;
          right: -5px; }
          [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
            right: auto;
            left: -5px; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
      right: 5rem; }
      [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
        right: auto;
        left: 5rem; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header {
    width: 17.1875rem; }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header .brand-logo {
      padding-left: 1.6rem; }
      [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .nav-header .brand-logo .brand-title {
        display: block; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .header {
    padding-left: 4.38rem; }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .header {
      padding-right: 4.38rem;
      padding-left: 0.9375rem; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .deznav {
    left: 0; }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .icon-hover-toggle .deznav {
      left: auto;
      right: 0; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
    margin-left: 5rem; }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
      margin-left: 0;
      margin-right: 5rem; }
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
    padding-left: 4.375rem; }
    [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
      margin-left: 0;
      margin-right: 4.375rem; } }
@media (min-width: 767px) {
  [data-sidebar-style="modern"] .nav-header {
    width: 9.375rem; }
    [data-sidebar-style="modern"] .nav-header .brand-logo {
      justify-content: center; }
  [data-sidebar-style="modern"] .deznav .metismenu > li {
    text-align: center; }
    [data-sidebar-style="modern"] .deznav .metismenu > li > a {
      padding: 20px 15px 20px 15px !important; }
      [data-sidebar-style="modern"] .deznav .metismenu > li > a::after {
        display: none; }
      [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
        background-color: white; }
    [data-sidebar-style="modern"] .deznav .metismenu > li.mm-active {
      padding: 0; }
    [data-sidebar-style="modern"] .deznav .metismenu > li li {
      text-align: left; }
      [direction="rtl"][data-sidebar-style="modern"] .deznav .metismenu > li li {
        text-align: right; }
  [data-sidebar-style="modern"] .deznav .metismenu li a {
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem; }
  [data-sidebar-style="modern"] .deznav .metismenu li ul:after {
    content: none; }
  [data-sidebar-style="modern"] .deznav .metismenu li > ul {
    height: auto !important; }
  [data-sidebar-style="modern"] .deznav .metismenu .nav-label {
    display: none; }
  [data-sidebar-style="modern"] .deznav .nav-label {
    display: none; }
  [data-sidebar-style="modern"] .deznav .nav-text {
    display: block;
    margin-top: 0.3125rem; }
  [data-sidebar-style="modern"] .footer {
    padding-left: 9.375rem; }
  [data-sidebar-style="modern"] .content-body {
    margin-left: 9.375rem; }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav {
    width: 9.375rem;
    left: 0; }
    [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav {
      left: auto;
      right: 0; }
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .slimScrollDiv,
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .deznav-scroll {
      overflow: visible !important; }
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > a i {
      font-size: 20px; }
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu > li > ul {
      display: none;
      padding: 1.875rem 0.9375rem; }
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li {
      position: relative; }
      [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a {
        padding: 0.625rem 1.5rem; }
        [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a:before {
          content: none; }
      [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
        position: absolute;
        left: 105%;
        top: 0;
        bottom: auto;
        background-color: #fff;
        border: 1px solid #d7dae3;
        width: 200px; }
        [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
          background: #1e2746;
          box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1); }
        [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
          left: auto;
          right: 105%; }
      [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover > ul {
        display: block;
        left: 100%;
        padding: 1rem 0;
        margin-left: -10px;
        border: 0;
        box-shadow: 5px 0px 13px 0px rgba(82, 63, 105, 0.05); }
        [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover > ul {
          box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1); }
        [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover > ul {
          left: auto;
          right: 100%;
          box-shadow: -5px 0px 13px 0px rgba(82, 63, 105, 0.05); }
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-label {
      display: none; }
    [data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-text {
      display: block;
      margin-top: 0; }
  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .deznav {
    left: -9.375rem; }
    [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .deznav {
      left: auto;
      right: -9.375rem; }
  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .content-body {
    margin-left: 0; }
    [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .content-body {
      margin-right: 0; }

  [data-sidebar-style="modern"][data-layout="horizontal"] .footer,
  [data-sidebar-style="modern"][data-layout="horizontal"] .content-body {
    margin-left: 0; }
  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu {
    padding: 0 30px; }
    [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu > li > a {
      padding: 0.8125rem 2.25rem; }
    [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu > li > ul {
      top: 4.5625rem; }
  [data-sidebar-style="modern"][data-layout="horizontal"][data-container="boxed"] .deznav .metismenu > li > a {
    padding: 0.8125rem 1.25rem; } }
[data-sidebar-style="overlay"] .deznav {
  left: -100%; }
  [direction="rtl"][data-sidebar-style="overlay"] .deznav {
    left: auto;
    right: -100%; }
[data-sidebar-style="overlay"] .content-body {
  margin-left: 0; }
[data-sidebar-style="overlay"] .nav-header {
  position: absolute; }
  [data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
    left: 0; }
    [data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
      background-color: #ffffff;
      /* background-color: #3F9AE0; */
     }
[data-sidebar-style="overlay"] .menu-toggle .nav-header {
  position: absolute;
  left: auto; }
[data-sidebar-style="overlay"] .menu-toggle .deznav {
  left: 0; }
  [direction="rtl"][data-sidebar-style="overlay"] .menu-toggle .deznav {
    left: auto;
    right: 0; }
[data-sidebar-style="overlay"] .footer {
  padding-left: 0; }

[data-sidebar-style="overlay"][data-header-position="fixed"] .nav-header {
  position: fixed; }

[data-sidebar-position="fixed"][data-header-position="fixed"] .nav-header {
  position: fixed; }

[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; }
[data-sidebar-position="fixed"][data-layout="vertical"] .deznav {
  position: fixed; }
  [data-sidebar-position="fixed"][data-layout="vertical"] .deznav .deznav-scroll {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; }
[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .deznav {
  position: fixed; }

[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .deznav {
  position: absolute; }

.sidebar-right {
  right: -15.625rem;
  position: fixed;
  top: 0;
  width: 15.625rem;
  background-color: #fff;
  height: calc(100% - 7.0625rem);
  margin-top: 5rem;
  transition: all .5s ease-in-out;
  padding-bottom: 1.875rem;
  box-shadow: -2px 3px 10px 0px rgba(119, 119, 119, 0.1); }
  [direction="rtl"] .sidebar-right .slimScrollDiv {
    overflow: visible !important; }
  .sidebar-right .sidebar-right-trigger {
    position: absolute;
    z-index: 9;
    top: 4.75rem;
    right: 100%;
    background-color: #fff;
    color: #3F9AE0;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    text-align: center;
    font-size: 1.75rem;
    line-height: 3rem;
    border-radius: 5px 0 0 5px;
    box-shadow: -5px 3px 5px 0px rgba(119, 119, 119, 0.15); }
    [data-theme-version="dark"] .sidebar-right .sidebar-right-trigger {
      background-color: #31343b;
      color: #fff; }
    .sidebar-right .sidebar-right-trigger:hover {
      color: #3F9AE0; }
  .sidebar-right.show {
    right: 0;
    z-index: 999; }
  .sidebar-right .nav-tabs {
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2; }
    [data-theme-version="dark"] .sidebar-right .nav-tabs {
      background-color: #181f39; }
    .sidebar-right .nav-tabs .nav-item {
      margin-bottom: 0;
      flex: 1; }
      .sidebar-right .nav-tabs .nav-item .nav-link {
        border: 0;
        font-size: 1.125rem;
        position: relative;
        text-align: center;
        background-color: #fff; }
        .sidebar-right .nav-tabs .nav-item .nav-link::after {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          content: "";
          background: transparent; }
        [data-theme-version="dark"] .sidebar-right .nav-tabs .nav-item .nav-link {
          background-color: #181f39; }
        [data-theme-version="dark"] .sidebar-right .nav-tabs .nav-item .nav-link.active {
          border-right: none;
          border-left: none;
          border-top: none; }
  .sidebar-right .tab-content {
    padding: 1.25rem; }
    .sidebar-right .tab-content .tab-pane .admin-settings > div {
      margin-bottom: 10px; }
    .sidebar-right .tab-content .tab-pane .admin-settings p {
      margin-bottom: 0.125rem; }
    .sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"] {
      display: none; }
      .sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"] + label {
        display: inline-block;
        width: 25px;
        height: 25px;
        cursor: pointer;
        transition: all .1s ease;
        border-radius: 4px;
        margin-right: 5px;
        margin-bottom: 3px; }
      .sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked + label {
        position: relative; }
        .sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked + label::after {
          height: 33px;
          width: 33px;
          left: -4px;
          top: -4px;
          content: "";
          position: absolute;
          background-color: inherit;
          border-radius: 6px;
          opacity: 0.4; }
  .sidebar-right #nav_header_color_1 + label,
  .sidebar-right #header_color_1 + label,
  .sidebar-right #sidebar_color_1 + label,
  .sidebar-right #primary_color_1 + label {
    background-color: #fff; }
  .sidebar-right #nav_header_color_2 + label,
  .sidebar-right #header_color_2 + label,
  .sidebar-right #sidebar_color_2 + label,
  .sidebar-right #primary_color_2 + label {
    background-color: #143b64; }
  .sidebar-right #nav_header_color_3 + label,
  .sidebar-right #header_color_3 + label,
  .sidebar-right #sidebar_color_3 + label,
  .sidebar-right #primary_color_3 + label {
    background-color: #3F9AE0; }
  .sidebar-right #nav_header_color_4 + label,
  .sidebar-right #header_color_4 + label,
  .sidebar-right #sidebar_color_4 + label,
  .sidebar-right #primary_color_4 + label {
    background-color: #4527a0; }
  .sidebar-right #nav_header_color_5 + label,
  .sidebar-right #header_color_5 + label,
  .sidebar-right #sidebar_color_5 + label,
  .sidebar-right #primary_color_5 + label {
    background-color: #c62828; }
  .sidebar-right #nav_header_color_6 + label,
  .sidebar-right #header_color_6 + label,
  .sidebar-right #sidebar_color_6 + label,
  .sidebar-right #primary_color_6 + label {
    background-color: #283593; }
  .sidebar-right #nav_header_color_7 + label,
  .sidebar-right #header_color_7 + label,
  .sidebar-right #sidebar_color_7 + label,
  .sidebar-right #primary_color_7 + label {
    background-color: #7356f1; }
  .sidebar-right #nav_header_color_8 + label,
  .sidebar-right #header_color_8 + label,
  .sidebar-right #sidebar_color_8 + label,
  .sidebar-right #primary_color_8 + label {
    background-color: #3695eb; }
  .sidebar-right #nav_header_color_9 + label,
  .sidebar-right #header_color_9 + label,
  .sidebar-right #sidebar_color_9 + label,
  .sidebar-right #primary_color_9 + label {
    background-color: #00838f; }
  .sidebar-right #nav_header_color_10 + label,
  .sidebar-right #header_color_10 + label,
  .sidebar-right #sidebar_color_10 + label,
  .sidebar-right #primary_color_10 + label {
    background-color: #ff8f16; }
  .sidebar-right #nav_header_color_11 + label,
  .sidebar-right #header_color_11 + label,
  .sidebar-right #sidebar_color_11 + label,
  .sidebar-right #primary_color_11 + label {
    background-color: #6673fd; }
  .sidebar-right #nav_header_color_12 + label,
  .sidebar-right #header_color_12 + label,
  .sidebar-right #sidebar_color_12 + label,
  .sidebar-right #primary_color_12 + label {
    background-color: #558b2f; }
  .sidebar-right #nav_header_color_13 + label,
  .sidebar-right #header_color_13 + label,
  .sidebar-right #sidebar_color_13 + label,
  .sidebar-right #primary_color_13 + label {
    background-color: #2a2a2a; }
  .sidebar-right #nav_header_color_14 + label,
  .sidebar-right #header_color_14 + label,
  .sidebar-right #sidebar_color_14 + label,
  .sidebar-right #primary_color_14 + label {
    background-color: #1367c8; }
  .sidebar-right #nav_header_color_15 + label,
  .sidebar-right #header_color_15 + label,
  .sidebar-right #sidebar_color_15 + label,
  .sidebar-right #primary_color_15 + label {
    background-color: #ed0b4c; }
  .sidebar-right #nav_header_color_1 + label,
  .sidebar-right #header_color_1 + label,
  .sidebar-right #sidebar_color_1 + label,
  .sidebar-right #primary_color_1 + label {
    border: 1px solid #89879f; }

@keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%); }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%); }
  100% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%); } }
@-webkit-keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%); }
  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%); }
  100% {
    transform: translateY(-8%);
    -webkit-transform: translateY(-8%); } }
.nav-user {
  background: #3F9AE0;
  margin-bottom: 10px;
  padding: 20px 25px 15px; }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .nav-user {
      padding: 20px 15px 15px; } }
  .nav-user img {
    width: 35px;
    height: 35px; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .nav-user img {
        width: 35px;
        height: 35px;
        margin-bottom: 10px; } }
  .nav-user h5 {
    margin-left: 10px;
    margin-bottom: 3px;
    color: #fff; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .nav-user h5 {
        display: none; } }
    [data-sibebarbg="color_2"] .nav-user h5 {
      color: #fff; }
  .nav-user p {
    margin-left: 10px;
    margin-bottom: 8px;
    color: #afcff7; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .nav-user p {
        display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .nav-user i {
      margin-top: 15px;
      display: block; } }

.menu-toggle .nav-user {
  padding: 20px 15px 15px; }
  .menu-toggle .nav-user img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px; }
  .menu-toggle .nav-user h5,
  .menu-toggle .nav-user p {
    display: none; }
  .menu-toggle .nav-user i {
    margin-top: 15px;
    display: block; }
  .menu-toggle .nav-user .dropdown-menu {
    left: 45px !important;
    top: 22px !important; }

.chatbox {
  width: 340px;
  height: 100vh;
  position: fixed;
  right: -500px;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.15);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s; }
  [data-theme-version="dark"] .chatbox {
    background: #1e2746; }
  .chatbox .chatbox-close {
    position: absolute;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    width: 0;
    height: 100%;
    right: 340px;
    background: #000;
    z-index: 1;
    opacity: 0.1;
    cursor: pointer; }
  .chatbox .card-fotter {
    padding: 0.75rem 1rem; }
  .chatbox .card-body {
    padding: 1rem; }
  .chatbox.active {
    right: 0; }
    .chatbox.active .chatbox-close {
      width: 100vw; }
  .chatbox .type_msg {
    padding-top: 10px; }
  .chatbox .nav {
    padding: 1rem 1rem 0 1rem;
    background: #392540;
    border: 0;
    justify-content: space-between; }
    .chatbox .nav .nav-link {
      color: #fff;
      opacity: 0.7;
      text-transform: uppercase; }
      .chatbox .nav .nav-link:hover, .chatbox .nav .nav-link.active {
        background: transparent;
        color: #fff;
        opacity: 1;
        border-color: #f35757; }
  .chatbox .img_cont {
    width: 40px;
    border-radius: 40px;
    margin-right: 10px;
    position: relative;
    height: 40px;
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    min-width: 40px;
    min-height: 40px; }
    .chatbox .img_cont .icon {
      color: #fff; }
    .chatbox .img_cont.primary {
      background: #fdf4f8;
      color: #3F9AE0; }
      [data-theme-version="dark"] .chatbox .img_cont.primary {
        background: rgba(221, 47, 110, 0.2); }
    .chatbox .img_cont.warning {
      background: #fff6e0;
      color: #ffb800; }
      [data-theme-version="dark"] .chatbox .img_cont.warning {
        background: rgba(255, 184, 0, 0.1); }
    .chatbox .img_cont.success {
      background: #e7faec;
      color: #2bc155; }
      [data-theme-version="dark"] .chatbox .img_cont.success {
        background: rgba(43, 193, 85, 0.1); }
    .chatbox .img_cont.info {
      background: #dee3fa;
      color: #2F4CDD; }
      [data-theme-version="dark"] .chatbox .img_cont.info {
        background: rgba(47, 76, 221, 0.1); }
    .chatbox .img_cont img {
      width: 100%; }
    .chatbox .img_cont .online_icon {
      background: #2bc155;
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 15px;
      right: -1px;
      bottom: 0px;
      border: 2px solid #fff; }
      .chatbox .img_cont .online_icon.offline {
        background: #f35757; }
  .chatbox .card {
    box-shadow: none; }
  .chatbox .search {
    height: 40px; }
  .chatbox .user_info span {
    font-size: 15px;
    color: #000;
    display: block;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    max-width: 170px; }
    [data-theme-version="dark"] .chatbox .user_info span {
      color: #fff; }
  .chatbox .user_info p {
    font-size: 13px;
    margin-bottom: 0;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    max-width: 170px; }
  .chatbox .contacts li {
    padding: 7px 1rem;
    border-bottom: 1px solid #eee;
    cursor: pointer; }
    [data-theme-version="dark"] .chatbox .contacts li {
      border-color: #333a54; }
    .chatbox .contacts li > div {
      display: flex;
      align-items: center; }
    .chatbox .contacts li:hover {
      background: #f4f7ff; }
      [data-theme-version="dark"] .chatbox .contacts li:hover {
        background-color: #181f39; }
  .chatbox .contacts .name-first-letter {
    background: #ffffff;
    padding: 4px 1rem;
    font-weight: 700;
    color: #000;
    position: sticky;
    top: 0;
    z-index: 1; }
    [data-theme-version="dark"] .chatbox .contacts .name-first-letter {
      color: #fff;
      background: #181f39; }
  .chatbox .msg_card_body {
    height: calc(100vh - 195px); }
  .chatbox .contacts_body {
    height: calc(100vh - 120px); }
  .chatbox .card-header {
    background: #f4f7ff;
    padding: 15px 20px;
    justify-content: center; }
    .chatbox .card-header h6 {
      font-size: 15px; }
    .chatbox .card-header p {
      line-height: 1.2;
      font-size: 12px;
      color: #7e7e7e; }
  .chatbox .chat-list-header {
    justify-content: space-between;
    background: #fff; }
    [data-theme-version="dark"] .chatbox .chat-list-header {
      background: #1e2746; }
    .chatbox .chat-list-header a {
      text-align: center;
      width: 30px;
      height: 30px;
      background: #ffffff;
      border-radius: 6px;
      line-height: 30px;
      display: block; }
      [data-theme-version="dark"] .chatbox .chat-list-header a {
        background: rgba(221, 47, 110, 0.2); }
        [data-theme-version="dark"] .chatbox .chat-list-header a svg g [fill] {
          fill: #fff; }
  .chatbox .img_cont_msg {
    width: 30px;
    height: 30px;
    display: block;
    max-width: 30px;
    min-width: 30px; }
    .chatbox .img_cont_msg img {
      width: 100%; }
  .chatbox .msg_cotainer {
    background: #3F9AE0;
    margin-left: 10px;
    border-radius: 0 0.75rem 0.75rem 0.75rem;
    padding: 10px 15px;
    color: #fff;
    position: relative; }
    .chatbox .msg_cotainer .msg_time {
      display: block;
      font-size: 11px;
      color: #fff;
      margin-top: 5px;
      opacity: 0.5; }
    .chatbox .msg_cotainer:after {
      content: "";
      position: absolute;
      left: -10px;
      border-right: 10px solid #3F9AE0;
      border-bottom: 10px solid transparent;
      border-top: 0px solid;
      top: 0; }
  .chatbox .msg_cotainer_send {
    background: #ffffff;
    padding: 10px 15px;
    border-radius: 6px 0px 6px 6px;
    margin-right: 10px;
    color: #222;
    position: relative;
    text-align: right; }
    [data-theme-version="dark"] .chatbox .msg_cotainer_send {
      background: #181f39;
      color: #fff; }
    .chatbox .msg_cotainer_send .msg_time_send {
      display: block;
      font-size: 11px;
      text-align: right;
      margin-top: 5px;
      opacity: 0.6; }
    .chatbox .msg_cotainer_send:after {
      content: "";
      position: absolute;
      right: -10px;
      border-left: 10px solid #ffffff;
      border-bottom: 10px solid transparent;
      border-top: 0px solid;
      top: 0; }
      [data-theme-version="dark"] .chatbox .msg_cotainer_send:after {
        border-left: 10px solid #181f39; }
  .chatbox .type_msg .form-control {
    padding: 10px 0;
    height: 50px;
    border: 0; }
  .chatbox .type_msg .btn {
    font-size: 18px;
    border-radius: 38px !important;
    width: 38px;
    height: 38px;
    padding: 0;
    margin-top: 6px; }
  .chatbox .video_cam {
    margin-left: 15px; }
    .chatbox .video_cam span {
      width: 35px;
      height: 35px;
      background: #10ca93;
      text-align: center;
      line-height: 35px;
      border-radius: 35px;
      color: #fff;
      margin-right: 5px;
      align-self: center;
      font-size: 16px;
      padding: 0 3px;
      display: inline-block; }
  .chatbox .note_card .contacts li {
    padding: 12px 1rem; }

@media only screen and (max-width: 576px) {
  .chatbox {
    width: 280px; }
    .chatbox .chatbox-close {
      right: 280px; } }
[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_2"] .nav-header {
  background-color: #143b64; }
  [data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_2"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_2"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_2"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_3"] .nav-header {
  /* background-color: #64afe9b3;  pclk*/
  background-color: #fffdf4;/* momentum */
}
  [data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_3"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_3"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_3"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_4"] .nav-header {
  background-color: #4527a0; }
  [data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_4"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_4"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_4"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_5"] .nav-header {
  background-color: #c62828; }
  [data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_5"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_5"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_5"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_6"] .nav-header {
  background-color: #283593; }
  [data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_6"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_6"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_6"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_7"] .nav-header {
  background-color: #7356f1; }
  [data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_7"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_7"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_7"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_8"] .nav-header {
  background-color: #3695eb; }
  [data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_8"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_8"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_8"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_9"] .nav-header {
  background-color: #00838f; }
  [data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_9"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_9"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_9"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_10"] .nav-header {
  background-color: #ff8f16; }
  [data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_10"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_10"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_10"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_11"] .nav-header {
  background-color: #6673fd; }
  [data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_11"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_11"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_11"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_12"] .nav-header {
  background-color: #558b2f; }
  [data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_12"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_12"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_12"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_13"] .nav-header {
  background-color: #2a2a2a; }
  [data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_13"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_13"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_13"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_14"] .nav-header {
  background-color: #1367c8; }
  [data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_14"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_14"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_14"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_15"] .nav-header {
  background-color: #ed0b4c; }
  [data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .brand-logo,
  [data-nav-headerbg="color_15"] .nav-header .brand-logo {
    color: #fff; }
    [data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .brand-logo:hover,
    [data-nav-headerbg="color_15"] .nav-header .brand-logo:hover {
      color: #fff; }
  [data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .hamburger .line,
  [data-nav-headerbg="color_15"] .nav-header .hamburger .line {
    background: #fff; }
[data-nav-headerbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background: #fff; }

[data-headerbg="color_2"][data-theme-version="dark"] .header,
[data-headerbg="color_2"] .header {
  background-color: #143b64;
  color: #fff; }
[data-headerbg="color_2"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_2"] .header-left input {
  border-color: #1d548f;
  background-color: #1d548f !important;
  color: #fff; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_2"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_2"] .header-left input:focus {
    border-color: #0c223a; }
[data-headerbg="color_2"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_2"] .header-left .search_icon {
  background-color: #1d548f !important; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_2"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_2"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_2"] .header-left .btn {
  background-color: #0c223a;
  border-color: #0c223a; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_2"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_2"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_2"] .header-left .btn:hover,
  [data-headerbg="color_2"] .header-left .btn:focus,
  [data-headerbg="color_2"] .header-left .btn:active {
    background-color: #03090f;
    border-color: #03090f; }
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_2"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_2"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_2"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_2"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_2"] .header-right .pulse-css:after,
  [data-headerbg="color_2"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_2"] .header-right .notification_dropdown .nav-link {
  background-color: #1d548f !important; }
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_2"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_2"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_2"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_2"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_2"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_2"] .nav-control {
  background-color: #1d548f;
  color: #fff; }

[data-headerbg="color_3"][data-theme-version="dark"] .header,
[data-headerbg="color_3"] .header {
  background-color: #d3ac5fa3;
  /* background-color: #3c77a5cc; pclk*/
  /* background-color: #3F9AE0; */
  color: #fff; }
[data-headerbg="color_3"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_3"] .header-left input {
  border-color: #e45b8d;
  background-color: #e45b8d !important;
  color: #fff; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_3"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_3"] .header-left input:focus {
    border-color: #bb1e57; }
[data-headerbg="color_3"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_3"] .header-left .search_icon {
  background-color: #e45b8d !important; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_3"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_3"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_3"] .header-left .btn {
  background-color: #bb1e57;
  border-color: #bb1e57; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_3"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_3"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_3"] .header-left .btn:hover,
  [data-headerbg="color_3"] .header-left .btn:focus,
  [data-headerbg="color_3"] .header-left .btn:active {
    background-color: #8f1743;
    border-color: #8f1743; }
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_3"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_3"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_3"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_3"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_3"] .header-right .pulse-css:after,
  [data-headerbg="color_3"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_3"] .header-right .notification_dropdown .nav-link {
  background-color: #e45b8d !important; }
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_3"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_3"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_3"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_3"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 1); }
[data-headerbg="color_3"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_3"] .nav-control {
  /* background-color: #e45b8d; */
  color: #fff; }

[data-headerbg="color_4"][data-theme-version="dark"] .header,
[data-headerbg="color_4"] .header {
  background-color: #4527a0;
  color: #fff; }
[data-headerbg="color_4"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_4"] .header-left input {
  border-color: #5731c9;
  background-color: #5731c9 !important;
  color: #fff; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_4"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_4"] .header-left input:focus {
    border-color: #331d77; }
[data-headerbg="color_4"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_4"] .header-left .search_icon {
  background-color: #5731c9 !important; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_4"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_4"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_4"] .header-left .btn {
  background-color: #331d77;
  border-color: #331d77; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_4"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_4"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_4"] .header-left .btn:hover,
  [data-headerbg="color_4"] .header-left .btn:focus,
  [data-headerbg="color_4"] .header-left .btn:active {
    background-color: #22134e;
    border-color: #22134e; }
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_4"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_4"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_4"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_4"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_4"] .header-right .pulse-css:after,
  [data-headerbg="color_4"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_4"] .header-right .notification_dropdown .nav-link {
  background-color: #5731c9 !important; }
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_4"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_4"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_4"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_4"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_4"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_4"] .nav-control {
  background-color: #5731c9;
  color: #fff; }

[data-headerbg="color_5"][data-theme-version="dark"] .header,
[data-headerbg="color_5"] .header {
  background-color: #c62828;
  color: #fff; }
[data-headerbg="color_5"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_5"] .header-left input {
  border-color: #da4747;
  background-color: #da4747 !important;
  color: #fff; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_5"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_5"] .header-left input:focus {
    border-color: #9c1f1f; }
[data-headerbg="color_5"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_5"] .header-left .search_icon {
  background-color: #da4747 !important; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_5"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_5"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_5"] .header-left .btn {
  background-color: #9c1f1f;
  border-color: #9c1f1f; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_5"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_5"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_5"] .header-left .btn:hover,
  [data-headerbg="color_5"] .header-left .btn:focus,
  [data-headerbg="color_5"] .header-left .btn:active {
    background-color: #711717;
    border-color: #711717; }
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_5"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_5"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_5"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_5"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_5"] .header-right .pulse-css:after,
  [data-headerbg="color_5"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_5"] .header-right .notification_dropdown .nav-link {
  background-color: #da4747 !important; }
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_5"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_5"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_5"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_5"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_5"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_5"] .nav-control {
  background-color: #da4747;
  color: #fff; }

[data-headerbg="color_6"][data-theme-version="dark"] .header,
[data-headerbg="color_6"] .header {
  background-color: #283593;
  color: #fff; }
[data-headerbg="color_6"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_6"] .header-left input {
  border-color: #3343bb;
  background-color: #3343bb !important;
  color: #fff; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_6"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_6"] .header-left input:focus {
    border-color: #1d276b; }
[data-headerbg="color_6"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_6"] .header-left .search_icon {
  background-color: #3343bb !important; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_6"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_6"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_6"] .header-left .btn {
  background-color: #1d276b;
  border-color: #1d276b; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_6"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_6"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_6"] .header-left .btn:hover,
  [data-headerbg="color_6"] .header-left .btn:focus,
  [data-headerbg="color_6"] .header-left .btn:active {
    background-color: #121843;
    border-color: #121843; }
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_6"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_6"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_6"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_6"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_6"] .header-right .pulse-css:after,
  [data-headerbg="color_6"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_6"] .header-right .notification_dropdown .nav-link {
  background-color: #3343bb !important; }
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_6"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_6"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_6"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_6"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_6"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_6"] .nav-control {
  background-color: #3343bb;
  color: #fff; }

[data-headerbg="color_7"][data-theme-version="dark"] .header,
[data-headerbg="color_7"] .header {
  background-color: #7356f1;
  color: #fff; }
[data-headerbg="color_7"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_7"] .header-left input {
  border-color: #9a85f5;
  background-color: #9a85f5 !important;
  color: #fff; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_7"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_7"] .header-left input:focus {
    border-color: #4c27ed; }
[data-headerbg="color_7"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_7"] .header-left .search_icon {
  background-color: #9a85f5 !important; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_7"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_7"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_7"] .header-left .btn {
  background-color: #4c27ed;
  border-color: #4c27ed; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_7"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_7"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_7"] .header-left .btn:hover,
  [data-headerbg="color_7"] .header-left .btn:focus,
  [data-headerbg="color_7"] .header-left .btn:active {
    background-color: #3511d0;
    border-color: #3511d0; }
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_7"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_7"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_7"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_7"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_7"] .header-right .pulse-css:after,
  [data-headerbg="color_7"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_7"] .header-right .notification_dropdown .nav-link {
  background-color: #9a85f5 !important; }
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_7"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_7"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_7"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_7"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_7"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_7"] .nav-control {
  background-color: #9a85f5;
  color: #fff; }

[data-headerbg="color_8"][data-theme-version="dark"] .header,
[data-headerbg="color_8"] .header {
  background-color: #3695eb;
  color: #fff; }
[data-headerbg="color_8"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_8"] .header-left input {
  border-color: #64adf0;
  background-color: #64adf0 !important;
  color: #fff; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_8"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_8"] .header-left input:focus {
    border-color: #167cd8; }
[data-headerbg="color_8"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_8"] .header-left .search_icon {
  background-color: #64adf0 !important; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_8"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_8"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_8"] .header-left .btn {
  background-color: #167cd8;
  border-color: #167cd8; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_8"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_8"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_8"] .header-left .btn:hover,
  [data-headerbg="color_8"] .header-left .btn:focus,
  [data-headerbg="color_8"] .header-left .btn:active {
    background-color: #1161aa;
    border-color: #1161aa; }
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_8"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_8"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_8"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_8"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_8"] .header-right .pulse-css:after,
  [data-headerbg="color_8"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_8"] .header-right .notification_dropdown .nav-link {
  background-color: #64adf0 !important; }
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_8"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_8"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_8"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_8"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_8"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_8"] .nav-control {
  background-color: #64adf0;
  color: #fff; }

[data-headerbg="color_9"][data-theme-version="dark"] .header,
[data-headerbg="color_9"] .header {
  background-color: #00838f;
  color: #fff; }
[data-headerbg="color_9"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_9"] .header-left input {
  border-color: #00b2c2;
  background-color: #00b2c2 !important;
  color: #fff; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_9"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_9"] .header-left input:focus {
    border-color: #00545c; }
[data-headerbg="color_9"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_9"] .header-left .search_icon {
  background-color: #00b2c2 !important; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_9"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_9"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_9"] .header-left .btn {
  background-color: #00545c;
  border-color: #00545c; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_9"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_9"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_9"] .header-left .btn:hover,
  [data-headerbg="color_9"] .header-left .btn:focus,
  [data-headerbg="color_9"] .header-left .btn:active {
    background-color: #002629;
    border-color: #002629; }
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_9"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_9"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_9"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_9"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_9"] .header-right .pulse-css:after,
  [data-headerbg="color_9"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_9"] .header-right .notification_dropdown .nav-link {
  background-color: #00b2c2 !important; }
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_9"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_9"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_9"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_9"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_9"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_9"] .nav-control {
  background-color: #00b2c2;
  color: #fff; }

[data-headerbg="color_10"][data-theme-version="dark"] .header,
[data-headerbg="color_10"] .header {
  background-color: #ff8f16;
  color: #fff; }
[data-headerbg="color_10"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_10"] .header-left input {
  border-color: #ffa849;
  background-color: #ffa849 !important;
  color: #fff; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_10"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_10"] .header-left input:focus {
    border-color: #e27500; }
[data-headerbg="color_10"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_10"] .header-left .search_icon {
  background-color: #ffa849 !important; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_10"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_10"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_10"] .header-left .btn {
  background-color: #e27500;
  border-color: #e27500; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_10"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_10"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_10"] .header-left .btn:hover,
  [data-headerbg="color_10"] .header-left .btn:focus,
  [data-headerbg="color_10"] .header-left .btn:active {
    background-color: #af5b00;
    border-color: #af5b00; }
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_10"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_10"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_10"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_10"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_10"] .header-right .pulse-css:after,
  [data-headerbg="color_10"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_10"] .header-right .notification_dropdown .nav-link {
  background-color: #ffa849 !important; }
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_10"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_10"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_10"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_10"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_10"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_10"] .nav-control {
  background-color: #ffa849;
  color: #fff; }

[data-headerbg="color_11"][data-theme-version="dark"] .header,
[data-headerbg="color_11"] .header {
  background-color: #6673fd;
  color: #fff; }
[data-headerbg="color_11"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_11"] .header-left input {
  border-color: #98a1fe;
  background-color: #98a1fe !important;
  color: #fff; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_11"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_11"] .header-left input:focus {
    border-color: #3445fc; }
[data-headerbg="color_11"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_11"] .header-left .search_icon {
  background-color: #98a1fe !important; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_11"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_11"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_11"] .header-left .btn {
  background-color: #3445fc;
  border-color: #3445fc; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_11"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_11"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_11"] .header-left .btn:hover,
  [data-headerbg="color_11"] .header-left .btn:focus,
  [data-headerbg="color_11"] .header-left .btn:active {
    background-color: #0318fa;
    border-color: #0318fa; }
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_11"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_11"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_11"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_11"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_11"] .header-right .pulse-css:after,
  [data-headerbg="color_11"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_11"] .header-right .notification_dropdown .nav-link {
  background-color: #98a1fe !important; }
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_11"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_11"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_11"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_11"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_11"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_11"] .nav-control {
  background-color: #98a1fe;
  color: #fff; }

[data-headerbg="color_12"][data-theme-version="dark"] .header,
[data-headerbg="color_12"] .header {
  background-color: #558b2f;
  color: #fff; }
[data-headerbg="color_12"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_12"] .header-left input {
  border-color: #6cb13c;
  background-color: #6cb13c !important;
  color: #fff; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_12"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_12"] .header-left input:focus {
    border-color: #3e6522; }
[data-headerbg="color_12"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_12"] .header-left .search_icon {
  background-color: #6cb13c !important; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_12"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_12"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_12"] .header-left .btn {
  background-color: #3e6522;
  border-color: #3e6522; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_12"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_12"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_12"] .header-left .btn:hover,
  [data-headerbg="color_12"] .header-left .btn:focus,
  [data-headerbg="color_12"] .header-left .btn:active {
    background-color: #263f15;
    border-color: #263f15; }
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_12"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_12"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_12"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_12"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_12"] .header-right .pulse-css:after,
  [data-headerbg="color_12"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_12"] .header-right .notification_dropdown .nav-link {
  background-color: #6cb13c !important; }
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_12"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_12"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_12"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_12"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_12"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_12"] .nav-control {
  background-color: #6cb13c;
  color: #fff; }

[data-headerbg="color_13"][data-theme-version="dark"] .header,
[data-headerbg="color_13"] .header {
  background-color: #2a2a2a;
  color: #fff; }
[data-headerbg="color_13"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_13"] .header-left input {
  border-color: #444444;
  background-color: #444444 !important;
  color: #fff; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_13"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_13"] .header-left input:focus {
    border-color: #111111; }
[data-headerbg="color_13"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_13"] .header-left .search_icon {
  background-color: #444444 !important; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_13"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_13"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_13"] .header-left .btn {
  background-color: #111111;
  border-color: #111111; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_13"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_13"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_13"] .header-left .btn:hover,
  [data-headerbg="color_13"] .header-left .btn:focus,
  [data-headerbg="color_13"] .header-left .btn:active {
    background-color: black;
    border-color: black; }
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_13"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_13"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_13"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_13"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_13"] .header-right .pulse-css:after,
  [data-headerbg="color_13"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_13"] .header-right .notification_dropdown .nav-link {
  background-color: #444444 !important; }
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_13"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_13"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_13"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_13"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_13"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_13"] .nav-control {
  background-color: #444444;
  color: #fff; }

[data-headerbg="color_14"][data-theme-version="dark"] .header,
[data-headerbg="color_14"] .header {
  background-color: #1367c8;
  color: #fff; }
[data-headerbg="color_14"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_14"] .header-left input {
  border-color: #2480ea;
  background-color: #2480ea !important;
  color: #fff; }
  [data-headerbg="color_14"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_14"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_14"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_14"] .header-left input:focus {
    border-color: #0f4f99; }
[data-headerbg="color_14"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_14"] .header-left .search_icon {
  background-color: #2480ea !important; }
  [data-headerbg="color_14"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_14"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_14"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_14"] .header-left .btn {
  background-color: #0f4f99;
  border-color: #0f4f99; }
  [data-headerbg="color_14"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_14"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_14"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_14"] .header-left .btn:hover,
  [data-headerbg="color_14"] .header-left .btn:focus,
  [data-headerbg="color_14"] .header-left .btn:active {
    background-color: #0a376b;
    border-color: #0a376b; }
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_14"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_14"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_14"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_14"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_14"] .header-right .pulse-css:after,
  [data-headerbg="color_14"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_14"] .header-right .notification_dropdown .nav-link {
  background-color: #2480ea !important; }
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_14"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_14"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_14"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_14"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_14"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_14"] .nav-control {
  background-color: #2480ea;
  color: #fff; }

[data-headerbg="color_15"][data-theme-version="dark"] .header,
[data-headerbg="color_15"] .header {
  background-color: #ed0b4c;
  color: #fff; }
[data-headerbg="color_15"][data-theme-version="dark"] .header-left input,
[data-headerbg="color_15"] .header-left input {
  border-color: #f6356d;
  background-color: #f6356d !important;
  color: #fff; }
  [data-headerbg="color_15"][data-theme-version="dark"] .header-left input::placeholder,
  [data-headerbg="color_15"] .header-left input::placeholder {
    color: #fff; }
  [data-headerbg="color_15"][data-theme-version="dark"] .header-left input:focus,
  [data-headerbg="color_15"] .header-left input:focus {
    border-color: #bc093c; }
[data-headerbg="color_15"][data-theme-version="dark"] .header-left .search_icon,
[data-headerbg="color_15"] .header-left .search_icon {
  background-color: #f6356d !important; }
  [data-headerbg="color_15"][data-theme-version="dark"] .header-left .search_icon i,
  [data-headerbg="color_15"] .header-left .search_icon i {
    color: #fff; }
[data-headerbg="color_15"][data-theme-version="dark"] .header-left .btn,
[data-headerbg="color_15"] .header-left .btn {
  background-color: #bc093c;
  border-color: #bc093c; }
  [data-headerbg="color_15"][data-theme-version="dark"] .header-left .btn:hover, [data-headerbg="color_15"][data-theme-version="dark"] .header-left .btn:focus, [data-headerbg="color_15"][data-theme-version="dark"] .header-left .btn:active,
  [data-headerbg="color_15"] .header-left .btn:hover,
  [data-headerbg="color_15"] .header-left .btn:focus,
  [data-headerbg="color_15"] .header-left .btn:active {
    background-color: #8c062d;
    border-color: #8c062d; }
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .dropdown > a,
[data-headerbg="color_15"] .header-right .dropdown > a {
  color: #fff; }
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .pulse-css,
[data-headerbg="color_15"] .header-right .pulse-css {
  background-color: #fff; }
  [data-headerbg="color_15"][data-theme-version="dark"] .header-right .pulse-css:after, [data-headerbg="color_15"][data-theme-version="dark"] .header-right .pulse-css:before,
  [data-headerbg="color_15"] .header-right .pulse-css:after,
  [data-headerbg="color_15"] .header-right .pulse-css:before {
    background-color: inherit; }
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-headerbg="color_15"] .header-right .notification_dropdown .nav-link {
  background-color: #f6356d !important; }
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .right-sidebar a,
[data-headerbg="color_15"] .header-right .right-sidebar a {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff; }
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong,
[data-headerbg="color_15"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info small,
[data-headerbg="color_15"] .header-right .header-profile > a.nav-link .header-info span,
[data-headerbg="color_15"] .header-right .header-profile > a.nav-link .header-info small {
  color: rgba(255, 255, 255, 0.7); }
[data-headerbg="color_15"][data-theme-version="dark"] .nav-control,
[data-headerbg="color_15"] .nav-control {
  background-color: #f6356d;
  color: #fff; }

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_2"] .deznav {
  background-color: #143b64; }
  [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_2"] .deznav .menu-tabs li a.active {
    color: #143b64; }
  [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_2"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_2"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_2"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_2"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_2"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_2"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_2"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_2"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_2"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_2"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_2"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_2"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_2"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_2"] .deznav .metismenu > li {
    border-color: #1d548f; }
  [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_2"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_2"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_2"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_2"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_2"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_2"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_2"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #1d548f; }
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_2"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_2"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #1d548f; }
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #1d548f; }
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_2"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_3"] .deznav {
  /* background-color: #ffffff;  */
  /* background-color: #7bc6ff;  pclk*/
  background-color: #fff19a; 
}
  [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_3"] .deznav .menu-tabs li a.active {
    color: #3F9AE0; }
  [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_3"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_3"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_3"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_3"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_3"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_3"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_3"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_3"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_3"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_3"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_3"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_3"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_3"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_3"] .deznav .metismenu > li {
    border-color: #e45b8d; }
  [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_3"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_3"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_3"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_3"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_3"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_3"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_3"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #e45b8d; }
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_3"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_3"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #e45b8d; }
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #e45b8d; }
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_3"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_4"] .deznav {
  background-color: #4527a0; }
  [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_4"] .deznav .menu-tabs li a.active {
    color: #4527a0; }
  [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_4"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_4"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_4"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_4"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_4"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_4"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_4"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_4"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_4"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_4"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_4"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_4"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_4"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_4"] .deznav .metismenu > li {
    border-color: #5731c9; }
  [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_4"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_4"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_4"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_4"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_4"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_4"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_4"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #5731c9; }
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_4"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_4"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #5731c9; }
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #5731c9; }
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_4"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_5"] .deznav {
  background-color: #c62828; }
  [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_5"] .deznav .menu-tabs li a.active {
    color: #c62828; }
  [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_5"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_5"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_5"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_5"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_5"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_5"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_5"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_5"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_5"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_5"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_5"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_5"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_5"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_5"] .deznav .metismenu > li {
    border-color: #da4747; }
  [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_5"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_5"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_5"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_5"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_5"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_5"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_5"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #da4747; }
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_5"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_5"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #da4747; }
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #da4747; }
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_5"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_6"] .deznav {
  background-color: #283593; }
  [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_6"] .deznav .menu-tabs li a.active {
    color: #283593; }
  [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_6"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_6"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_6"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_6"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_6"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_6"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_6"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_6"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_6"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_6"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_6"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_6"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_6"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_6"] .deznav .metismenu > li {
    border-color: #3343bb; }
  [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_6"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_6"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_6"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_6"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_6"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_6"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_6"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #3343bb; }
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_6"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_6"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #3343bb; }
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #3343bb; }
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_6"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_7"] .deznav {
  background-color: #7356f1; }
  [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_7"] .deznav .menu-tabs li a.active {
    color: #7356f1; }
  [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_7"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_7"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_7"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_7"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_7"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_7"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_7"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_7"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_7"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_7"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_7"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_7"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_7"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_7"] .deznav .metismenu > li {
    border-color: #9a85f5; }
  [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_7"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_7"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_7"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_7"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_7"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_7"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_7"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #9a85f5; }
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_7"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_7"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #9a85f5; }
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #9a85f5; }
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_7"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_8"] .deznav {
  background-color: #3695eb; }
  [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_8"] .deznav .menu-tabs li a.active {
    color: #3695eb; }
  [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_8"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_8"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_8"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_8"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_8"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_8"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_8"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_8"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_8"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_8"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_8"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_8"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_8"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_8"] .deznav .metismenu > li {
    border-color: #64adf0; }
  [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_8"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_8"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_8"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_8"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_8"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_8"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #64adf0; }
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_8"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_8"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #64adf0; }
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #64adf0; }
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_8"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_9"] .deznav {
  background-color: #00838f; }
  [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_9"] .deznav .menu-tabs li a.active {
    color: #00838f; }
  [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_9"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_9"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_9"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_9"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_9"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_9"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_9"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_9"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_9"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_9"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_9"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_9"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_9"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_9"] .deznav .metismenu > li {
    border-color: #00b2c2; }
  [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_9"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_9"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_9"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_9"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_9"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_9"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_9"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #00b2c2; }
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_9"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_9"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #00b2c2; }
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #00b2c2; }
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_9"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_10"] .deznav {
  background-color: #ff8f16; }
  [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_10"] .deznav .menu-tabs li a.active {
    color: #ff8f16; }
  [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_10"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_10"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_10"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_10"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_10"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_10"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_10"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_10"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_10"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_10"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_10"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_10"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_10"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_10"] .deznav .metismenu > li {
    border-color: #ffa849; }
  [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_10"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_10"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_10"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_10"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_10"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_10"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_10"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #ffa849; }
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_10"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_10"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #ffa849; }
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #ffa849; }
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_10"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_11"] .deznav {
  background-color: #6673fd; }
  [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_11"] .deznav .menu-tabs li a.active {
    color: #6673fd; }
  [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_11"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_11"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_11"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_11"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_11"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_11"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_11"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_11"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_11"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_11"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_11"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_11"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_11"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_11"] .deznav .metismenu > li {
    border-color: #98a1fe; }
  [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_11"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_11"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_11"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_11"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_11"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_11"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_11"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #98a1fe; }
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_11"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_11"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #98a1fe; }
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #98a1fe; }
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_11"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_11"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_12"] .deznav {
  background-color: #558b2f; }
  [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_12"] .deznav .menu-tabs li a.active {
    color: #558b2f; }
  [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_12"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_12"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_12"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_12"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_12"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_12"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_12"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_12"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_12"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_12"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_12"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_12"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_12"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_12"] .deznav .metismenu > li {
    border-color: #6cb13c; }
  [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_12"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_12"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_12"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_12"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_12"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_12"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_12"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #6cb13c; }
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_12"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_12"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #6cb13c; }
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #6cb13c; }
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_12"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_12"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_13"] .deznav {
  background-color: #2a2a2a; }
  [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_13"] .deznav .menu-tabs li a.active {
    color: #2a2a2a; }
  [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_13"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_13"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_13"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_13"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_13"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_13"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_13"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_13"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_13"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_13"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_13"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_13"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_13"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_13"] .deznav .metismenu > li {
    border-color: #444444; }
  [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_13"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_13"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_13"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_13"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_13"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_13"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_13"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #444444; }
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_13"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_13"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #444444; }
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #444444; }
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_13"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_13"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_14"] .deznav {
  background-color: #1367c8; }
  [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_14"] .deznav .menu-tabs li a.active {
    color: #1367c8; }
  [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_14"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_14"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_14"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_14"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_14"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_14"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_14"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_14"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_14"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_14"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_14"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_14"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_14"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_14"] .deznav .metismenu > li {
    border-color: #2480ea; }
  [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_14"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_14"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_14"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_14"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_14"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_14"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_14"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #2480ea; }
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_14"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_14"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #2480ea; }
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #2480ea; }
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_14"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_14"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav,
[data-sibebarbg="color_15"] .deznav {
  background-color: #ed0b4c; }
  [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .menu-tabs li a.active,
  [data-sibebarbg="color_15"] .deznav .menu-tabs li a.active {
    color: #ed0b4c; }
  [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu .nav-label,
  [data-sibebarbg="color_15"] .deznav .metismenu .nav-label {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.2); }
  [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li > a,
  [data-sibebarbg="color_15"] .deznav .metismenu > li > a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li > a svg,
    [data-sibebarbg="color_15"] .deznav .metismenu > li > a svg {
      color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li > a g [fill],
    [data-sibebarbg="color_15"] .deznav .metismenu > li > a g [fill] {
      fill: rgba(255, 255, 255, 0.7); }
  [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a,
  [data-sibebarbg="color_15"] .deznav .metismenu > li:hover > a,
  [data-sibebarbg="color_15"] .deznav .metismenu > li:focus > a,
  [data-sibebarbg="color_15"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li:hover > a g [fill], [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li:focus > a g [fill], [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a g [fill],
    [data-sibebarbg="color_15"] .deznav .metismenu > li:hover > a g [fill],
    [data-sibebarbg="color_15"] .deznav .metismenu > li:focus > a g [fill],
    [data-sibebarbg="color_15"] .deznav .metismenu > li.mm-active > a g [fill] {
      fill: #fff; }
    [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li:hover > a svg, [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li:focus > a svg, [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li.mm-active > a svg,
    [data-sibebarbg="color_15"] .deznav .metismenu > li:hover > a svg,
    [data-sibebarbg="color_15"] .deznav .metismenu > li:focus > a svg,
    [data-sibebarbg="color_15"] .deznav .metismenu > li.mm-active > a svg {
      color: #fff; }
  [data-layout="horizontal"][data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu > li, [data-sibebarbg="color_15"] .deznav .metismenu > li {
    border-color: #f6356d; }
  [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu a,
  [data-sibebarbg="color_15"] .deznav .metismenu a {
    color: rgba(255, 255, 255, 0.7); }
    [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu a:hover, [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu a:focus, [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu a.mm-active,
    [data-sibebarbg="color_15"] .deznav .metismenu a:hover,
    [data-sibebarbg="color_15"] .deznav .metismenu a:focus,
    [data-sibebarbg="color_15"] .deznav .metismenu a.mm-active {
      color: #fff; }
  [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu ul,
  [data-sibebarbg="color_15"] .deznav .metismenu ul {
    border-color: rgba(255, 255, 255, 0.2); }
    [data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu ul:after,
    [data-sibebarbg="color_15"] .deznav .metismenu ul:after {
      background-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul,
[data-sibebarbg="color_15"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li > ul {
  background-color: #f6356d; }
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_15"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_15"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu > li:hover > a {
  background-color: #f6356d; }
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #f6356d; }
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
  border-color: rgba(255, 255, 255, 0.2); }
[data-sibebarbg="color_15"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu > li,
[data-sibebarbg="color_15"][data-layout="horizontal"] .deznav .metismenu > li {
  border-color: rgba(255, 255, 255, 0.2); }

[data-headerbg="transparent"] .header {
  box-shadow: none;
  background: transparent; }

[data-headerbg="color_1"] .nav-header .hamburger.is-active .line,
[data-headerbg="color_1"] .nav-header .hamburger .line {
  background: #525556 !important; }

@media (min-width: 992px) {
  [data-container="boxed"] #main-wrapper {
    max-width: 1199px;
    margin: 0 auto; }
    [direction="rtl"][data-container="boxed"] #main-wrapper {
      text-align: right; } }
@media only screen and (min-width: 1350px) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 1199px; } }
@media only screen and (min-width: 1200px) and (max-width: 1349px) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 1199px; } }
[data-header-position="fixed"][data-layout="horizontal"][data-container="boxed"] .deznav {
  max-width: 1199px; }

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"] .header {
  width: 1199px; }

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="mini"] .header {
  width: 1199px; }

[data-container="boxed"] .metismenu.fixed {
  left: auto;
  max-width: 1199px; }
[data-container="boxed"] .page-titles {
  margin-bottom: 3rem;
  padding: 15px 15px; }
[data-container="boxed"] .content-body .container-fluid, [data-container="boxed"] .content-body .container-sm, [data-container="boxed"] .content-body .container-md, [data-container="boxed"] .content-body .container-lg, [data-container="boxed"] .content-body .container-xl {
  padding: 0.9375rem 0.9375rem 0 0.9375rem; }

[data-container="boxed"][data-layout="vertical"] .page-titles {
  margin-left: 0;
  margin-right: 0; }

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
  position: absolute; }
[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .deznav {
  position: absolute; }

[data-container="boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
  left: auto;
  max-width: 1199px; }

@media (min-width: 992px) {
  [data-container="wide-boxed"] #main-wrapper {
    max-width: 1480px;
    margin: 0 auto; } }

@media (min-width: 992px) {
  [data-container="wide-boxed"][data-sidebar-style="full"] .header {
    width: 100%; }
  [data-container="wide-boxed"][data-sidebar-style="full"] .menu-toggle .header {
    width: 100%; }
  [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="full"] .header {
    max-width: 1480px; }
  [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="full"] .menu-toggle .header {
    max-width: 1480px; } }

@media (min-width: 992px) {
  [data-container="wide-boxed"][data-sidebar-style="mini"] .header {
    width: 100%; }
  [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="mini"] .header {
    max-width: 1480px; }
  [data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-style="mini"] .header {
    max-width: 1480px; } }

@media (min-width: 992px) {
  [data-container="wide-boxed"][data-sidebar-style="compact"] .header {
    width: 100%; }
  [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="compact"] .header {
    max-width: 1480px; } }

@media (min-width: 992px) {
  [data-container="wide-boxed"][data-sidebar-style="overlay"] .header {
    width: 100%; }
  [data-header-position="fixed"][data-container="wide-boxed"][data-sidebar-style="overlay"] .header {
    max-width: 1480px; } }

[data-layout="horizontal"][data-container="wide-boxed"] .header {
  width: 100%; }
[data-sidebar-style="full"][data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"] .header {
  max-width: 1480px; }
[data-sidebar-style="mini"][data-layout="horizontal"][data-container="wide-boxed"] .header {
  width: 100%; }

[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"] .deznav.fixed {
  max-width: 1480px;
  transition: none; }
[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"][direction="rtl"] .deznav.fixed {
  right: 5%; }

[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="fixed"] .deznav {
  max-width: 1480px;
  left: 5%; }
[data-layout="horizontal"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="fixed"][direction="rtl"] .deznav {
  right: 5%; }

[data-primary="color_2"] a:hover, [data-primary="color_2"] a:focus, [data-primary="color_2"] a.active {
  color: #143b64; }
[data-primary="color_2"] [data-class="bg-primary"]:before {
  background: #143b64; }
[data-primary="color_2"] .email-left-box .intro-title {
  background: rgba(20, 59, 100, 0.1); }
  [data-primary="color_2"] .email-left-box .intro-title i {
    color: #143b64; }
[data-primary="color_2"] .widget-stat .media .media-body h4 {
  color: #143b64 !important; }
[data-primary="color_2"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(20, 59, 100, 0.1); }
[data-primary="color_2"] .mail-list .list-group-item.active i {
  color: #143b64; }
[data-primary="color_2"] .single-mail.active {
  background: #143b64; }
[data-primary="color_2"] .profile-info h4.text-primary {
  color: #143b64 !important; }
[data-primary="color_2"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_2"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #143b64;
  color: #143b64; }
[data-primary="color_2"] .amChartsInputField {
  border: 0;
  background: #143b64; }
[data-primary="color_2"] .amcharts-period-input,
[data-primary="color_2"] .amcharts-period-input-selected {
  background: #143b64; }
[data-primary="color_2"] .morris-hover {
  background: #143b64; }
[data-primary="color_2"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #143b64; }
[data-primary="color_2"] .custom-select:focus {
  border-color: #143b64;
  color: #143b64; }
[data-primary="color_2"] .daterangepicker td.active {
  background-color: #143b64; }
  [data-primary="color_2"] .daterangepicker td.active:hover {
    background-color: #143b64; }
[data-primary="color_2"] .daterangepicker button.applyBtn {
  background-color: #143b64;
  border-color: #143b64; }
[data-primary="color_2"] .wizard > .steps li.current a {
  background-color: #143b64; }
[data-primary="color_2"] .wizard .skip-email a {
  color: #143b64; }
[data-primary="color_2"] .wizard > .actions li:not(.disabled) a {
  background-color: #143b64; }
[data-primary="color_2"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #143b64; }
[data-primary="color_2"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #143b64;
  border-color: #143b64; }
[data-primary="color_2"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #143b64; }
[data-primary="color_2"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #143b64; }
[data-primary="color_2"] .is-invalid .input-group-prepend .input-group-text i {
  color: #2161a4; }
[data-primary="color_2"] .datamaps-hoverover {
  color: #143b64;
  border: 1px solid rgba(20, 59, 100, 0.3); }
[data-primary="color_2"] .jqvmap-zoomin,
[data-primary="color_2"] .jqvmap-zoomout {
  background-color: #143b64; }
[data-primary="color_2"] .table .thead-primary th {
  background-color: #143b64; }
[data-primary="color_2"] .table.primary-table-bg-hover thead th {
  background-color: #102e4f; }
[data-primary="color_2"] .table.primary-table-bg-hover tbody tr {
  background-color: #143b64; }
  [data-primary="color_2"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #184879; }
  [data-primary="color_2"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_2"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #102e4f; }
[data-primary="color_2"] table.dataTable tr.selected {
  color: #143b64; }
[data-primary="color_2"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #143b64 !important;
  background: rgba(20, 59, 100, 0.1); }
[data-primary="color_2"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #143b64 !important;
  background: rgba(20, 59, 100, 0.1); }
[data-primary="color_2"] .clipboard-btn:hover {
  background-color: #143b64; }
[data-primary="color_2"] .cd-h-timeline__dates::before {
  background: #143b64; }
[data-primary="color_2"] .cd-h-timeline__dates::after {
  background: #143b64; }
[data-primary="color_2"] .cd-h-timeline__line {
  background-color: #143b64; }
[data-primary="color_2"] .cd-h-timeline__date:after {
  border-color: #153d68;
  background-color: #143b64; }
[data-primary="color_2"] .cd-h-timeline__navigation {
  border-color: #153d68; }
[data-primary="color_2"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #153d68; }
[data-primary="color_2"] .dd-handle {
  background: #143b64; }
[data-primary="color_2"] .dd-handle:hover {
  background: #143b64; }
[data-primary="color_2"] .dd3-content:hover {
  background: #143b64; }
[data-primary="color_2"] .noUi-connect {
  background-color: #143b64; }
  [data-primary="color_2"] .noUi-connect.c-3-color {
    background-color: #143b64; }
[data-primary="color_2"] .noUi-horizontal .noUi-handle, [data-primary="color_2"] .noUi-vertical .noUi-handle {
  background-color: #143b64; }
[data-primary="color_2"] #slider-toggle.off .noUi-handle {
  border-color: #143b64; }
[data-primary="color_2"] .pignose-calendar {
  border-color: #143b64; }
  [data-primary="color_2"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #143b64; }
[data-primary="color_2"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #143b64; }
[data-primary="color_2"] .bootstrap-tagsinput .tag {
  background-color: #143b64; }
[data-primary="color_2"] .toast-success {
  background-color: #143b64; }
[data-primary="color_2"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #143b64; }
[data-primary="color_2"] .accordion-header-bg .accordion__header--primary {
  background-color: #143b64; }
[data-primary="color_2"] .alert-primary {
  background: #297ace;
  border-color: #297ace;
  color: #143b64; }
[data-primary="color_2"] .alert-alt.alert-primary {
  border-left: 4px solid #143b64; }
[data-primary="color_2"] .alert-alt.alert-primary.solid {
  border-left: 4px solid black !important; }
[data-primary="color_2"] .alert.alert-primary.solid {
  background: #143b64;
  border-color: #143b64; }
[data-primary="color_2"] .alert.alert-outline-primary {
  color: #143b64;
  border-color: #143b64; }
[data-primary="color_2"] .badge-outline-primary {
  border: 1px solid #143b64;
  color: #143b64; }
[data-primary="color_2"] .badge-primary {
  background-color: #143b64; }
[data-primary="color_2"] .page-titles h4 {
  color: #143b64; }
[data-primary="color_2"] .card-action > a {
  background: black; }
[data-primary="color_2"] .card-action .dropdown {
  background: black;
  color: #143b64; }
  [data-primary="color_2"] .card-action .dropdown:hover, [data-primary="color_2"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_2"] .card-loader i {
  background: #0e2946; }
[data-primary="color_2"] .dropdown-outline {
  border: 0.1rem solid #143b64; }
[data-primary="color_2"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #143b64; }
[data-primary="color_2"] .card-action .custom-dropdown {
  background: #3a87d8; }
  [data-primary="color_2"] .card-action .custom-dropdown.show, [data-primary="color_2"] .card-action .custom-dropdown:focus, [data-primary="color_2"] .card-action .custom-dropdown:hover {
    background: #143b64; }
[data-primary="color_2"] .label-primary {
  background: #143b64; }
[data-primary="color_2"] .pagination .page-item .page-link:hover {
  background: #143b64;
  border-color: #143b64; }
[data-primary="color_2"] .pagination .page-item.active .page-link {
  background-color: #143b64;
  border-color: #143b64; }
[data-primary="color_2"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_2"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #143b64; }
[data-primary="color_2"] .progress-bar {
  background-color: #143b64; }
[data-primary="color_2"] .progress-bar-primary {
  background-color: #143b64; }
[data-primary="color_2"] .ribbon__four {
  background-color: #143b64; }
  [data-primary="color_2"] .ribbon__four:after, [data-primary="color_2"] .ribbon__four:before {
    background-color: #256db9; }
[data-primary="color_2"] .ribbon__five {
  background-color: #143b64; }
  [data-primary="color_2"] .ribbon__five::before {
    border-color: transparent transparent #143b64 transparent; }
[data-primary="color_2"] .ribbon__six {
  background-color: #143b64; }
[data-primary="color_2"] .multi-steps > li {
  color: #143b64; }
  [data-primary="color_2"] .multi-steps > li:after {
    background-color: #143b64; }
  [data-primary="color_2"] .multi-steps > li.is-active:before {
    border-color: #143b64; }
[data-primary="color_2"] .timeline-badge.primary {
  background-color: #143b64 !important; }
[data-primary="color_2"] .tooltip-wrapper button:hover {
  background: #143b64; }
[data-primary="color_2"] .chart_widget_tab_one .nav-link.active {
  background-color: #143b64;
  border: 1px solid #143b64; }
  [data-primary="color_2"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #143b64; }
[data-primary="color_2"] .social-icon2 a {
  border: 0.1rem solid #143b64; }
[data-primary="color_2"] .social-icon2 i {
  color: #143b64; }
[data-primary="color_2"] .social-icon3 ul li a:hover i {
  color: #143b64; }
[data-primary="color_2"] .bgl-primary {
  background: #3a87d8;
  border-color: #3a87d8;
  color: #143b64; }
[data-primary="color_2"] .tdl-holder input[type=checkbox]:checked + i {
  background: #143b64; }
[data-primary="color_2"] .footer .copyright a {
  color: #143b64; }
[data-primary="color_2"] .hamburger .line {
  background: #143b64; }
[data-primary="color_2"] svg.pulse-svg .first-circle, [data-primary="color_2"] svg.pulse-svg .second-circle, [data-primary="color_2"] svg.pulse-svg .third-circle {
  fill: #143b64; }
[data-primary="color_2"] .pulse-css {
  background: #143b64; }
  [data-primary="color_2"] .pulse-css:after, [data-primary="color_2"] .pulse-css:before {
    background-color: #143b64; }
[data-primary="color_2"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #143b64; }
[data-primary="color_2"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_2"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_2"] .header-right .header-profile .dropdown-menu a.active {
  color: #143b64; }
[data-primary="color_2"] .header-right .header-profile .profile_title {
  background: #143b64; }
[data-primary="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #143b64 !important; }
[data-primary="color_2"] .deznav .metismenu > li > a svg {
  color: #143b64; }
[data-primary="color_2"] .deznav .metismenu > li:hover > a, [data-primary="color_2"] .deznav .metismenu > li:focus > a {
  color: #143b64; }
[data-primary="color_2"] .deznav .metismenu > li.mm-active > a {
  color: #143b64; }
[data-primary="color_2"] .deznav .metismenu ul a:hover, [data-primary="color_2"] .deznav .metismenu ul a:focus, [data-primary="color_2"] .deznav .metismenu ul a.mm-active {
  color: #143b64; }
@media (min-width: 767px) {
  [data-primary="color_2"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_2"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_2"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_2"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: #8fbae9; } }
[data-primary="color_2"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #143b64; }
[data-primary="color_2"] .nav-user {
  background: #143b64; }
[data-primary="color_2"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #143b64; }
  [data-primary="color_2"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #143b64; }
[data-primary="color_2"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #143b64;
  border-color: #143b64; }
[data-primary="color_2"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #143b64;
  border-color: #143b64; }
[data-primary="color_2"][data-theme-version="dark"] .header-left input:focus {
  border-color: #143b64; }
[data-primary="color_2"][data-theme-version="dark"] .loader__bar {
  background: #143b64; }
[data-primary="color_2"][data-theme-version="dark"] .loader__ball {
  background: #143b64; }
[data-primary="color_2"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #143b64; }
[data-primary="color_2"] .new-arrival-content .price {
  color: #143b64; }
[data-primary="color_2"] .chart-link a i.text-primary {
  color: #143b64; }
[data-primary="color_2"] #user-activity .nav-tabs .nav-link.active {
  background: #143b64;
  border-color: #143b64; }
[data-primary="color_2"] span#counter {
  color: #143b64; }
[data-primary="color_2"] .welcome-content:after {
  background: #143b64; }
[data-primary="color_2"] .timeline-badge {
  background-color: #143b64; }
[data-primary="color_2"] .page-timeline .timeline-workplan[data-primary="color_2"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(20, 59, 100, 0.4); }
[data-primary="color_2"] .sk-three-bounce .sk-child {
  background-color: #143b64; }
[data-primary="color_2"] .dropdown-item.active,
[data-primary="color_2"] .dropdown-item:active {
  color: #fff;
  background-color: #143b64; }
[data-primary="color_2"] .overlay-box:after {
  background: #143b64; }
[data-primary="color_2"] .btn-primary {
  background-color: #143b64;
  border-color: #143b64; }
[data-primary="color_2"] .bg-primary {
  background-color: #143b64 !important; }
[data-primary="color_2"] .text-primary {
  color: #143b64 !important; }
[data-primary="color_2"] .btn-primary:hover {
  background-color: #071524;
  border-color: #071524; }
[data-primary="color_2"] .btn-outline-primary {
  color: #143b64;
  border-color: #143b64; }
[data-primary="color_2"] .btn-outline-primary:hover {
  background-color: #143b64;
  border-color: #143b64; }
[data-primary="color_2"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_2"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_2"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_2"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_2"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_2"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #071524;
  border-color: #071524; }

[data-primary="color_3"] a:hover, [data-primary="color_3"] a:focus, [data-primary="color_3"] a.active {
  color: #3F9AE0; }
[data-primary="color_3"] [data-class="bg-primary"]:before {
  background: #3F9AE0; }
[data-primary="color_3"] .email-left-box .intro-title {
  background: rgba(221, 47, 110, 0.1); }
  [data-primary="color_3"] .email-left-box .intro-title i {
    color: #3F9AE0; }
[data-primary="color_3"] .widget-stat .media .media-body h4 {
  color: #3F9AE0 !important; }
[data-primary="color_3"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(221, 47, 110, 0.1); }
[data-primary="color_3"] .mail-list .list-group-item.active i {
  color: #3F9AE0; }
[data-primary="color_3"] .single-mail.active {
  background: #3F9AE0; }
[data-primary="color_3"] .profile-info h4.text-primary {
  color: #3F9AE0 !important; }
[data-primary="color_3"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_3"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #3F9AE0;
  color: #3F9AE0; }
[data-primary="color_3"] .amChartsInputField {
  border: 0;
  background: #3F9AE0; }
[data-primary="color_3"] .amcharts-period-input,
[data-primary="color_3"] .amcharts-period-input-selected {
  background: #3F9AE0; }
[data-primary="color_3"] .morris-hover {
  background: #3F9AE0; }
[data-primary="color_3"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #3F9AE0; }
[data-primary="color_3"] .custom-select:focus {
  border-color: #3F9AE0;
  color: #3F9AE0; }
[data-primary="color_3"] .daterangepicker td.active {
  background-color: #3F9AE0; }
  [data-primary="color_3"] .daterangepicker td.active:hover {
    background-color: #3F9AE0; }
[data-primary="color_3"] .daterangepicker button.applyBtn {
  background-color: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"] .wizard > .steps li.current a {
  background-color: #3F9AE0; }
[data-primary="color_3"] .wizard .skip-email a {
  color: #3F9AE0; }
[data-primary="color_3"] .wizard > .actions li:not(.disabled) a {
  background-color: #3F9AE0; }
[data-primary="color_3"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #3F9AE0; }
[data-primary="color_3"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #3F9AE0; }
[data-primary="color_3"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #3F9AE0; }
[data-primary="color_3"] .is-invalid .input-group-prepend .input-group-text i {
  color: #e8719c; }
[data-primary="color_3"] .datamaps-hoverover {
  color: #3F9AE0;
  border: 1px solid rgba(221, 47, 110, 0.3); }
[data-primary="color_3"] .jqvmap-zoomin,
[data-primary="color_3"] .jqvmap-zoomout {
  background-color: #3F9AE0; }
[data-primary="color_3"] .table .thead-primary th {
  background-color: #3F9AE0; }
[data-primary="color_3"] .table.primary-table-bg-hover thead th {
  background-color: #d02261; }
[data-primary="color_3"] .table.primary-table-bg-hover tbody tr {
  background-color: #3F9AE0; }
  [data-primary="color_3"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #e1457d; }
  [data-primary="color_3"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_3"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #d02261; }
[data-primary="color_3"] table.dataTable tr.selected {
  color: #3F9AE0; }
[data-primary="color_3"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #3F9AE0 !important;
  background: rgba(221, 47, 110, 0.1); }
[data-primary="color_3"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #3F9AE0 !important;
  background: rgba(221, 47, 110, 0.1); }
[data-primary="color_3"] .clipboard-btn:hover {
  background-color: #3F9AE0; }
[data-primary="color_3"] .cd-h-timeline__dates::before {
  background: #3F9AE0; }
[data-primary="color_3"] .cd-h-timeline__dates::after {
  background: #3F9AE0; }
[data-primary="color_3"] .cd-h-timeline__line {
  background-color: #3F9AE0; }
[data-primary="color_3"] .cd-h-timeline__date:after {
  border-color: #de3371;
  background-color: #3F9AE0; }
[data-primary="color_3"] .cd-h-timeline__navigation {
  border-color: #de3371; }
[data-primary="color_3"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #de3371; }
[data-primary="color_3"] .dd-handle {
  background: #3F9AE0; }
[data-primary="color_3"] .dd-handle:hover {
  background: #3F9AE0; }
[data-primary="color_3"] .dd3-content:hover {
  background: #3F9AE0; }
[data-primary="color_3"] .noUi-connect {
  background-color: #3F9AE0; }
  [data-primary="color_3"] .noUi-connect.c-3-color {
    background-color: #3F9AE0; }
[data-primary="color_3"] .noUi-horizontal .noUi-handle, [data-primary="color_3"] .noUi-vertical .noUi-handle {
  background-color: #3F9AE0; }
[data-primary="color_3"] #slider-toggle.off .noUi-handle {
  border-color: #3F9AE0; }
[data-primary="color_3"] .pignose-calendar {
  border-color: #3F9AE0; }
  [data-primary="color_3"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #3F9AE0; }
[data-primary="color_3"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #3F9AE0; }
[data-primary="color_3"] .bootstrap-tagsinput .tag {
  background-color: #3F9AE0; }
[data-primary="color_3"] .toast-success {
  background-color: #3F9AE0; }
[data-primary="color_3"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #3F9AE0; }
[data-primary="color_3"] .accordion-header-bg .accordion__header--primary {
  background-color: #3F9AE0; }
[data-primary="color_3"] .alert-primary {
  background: #ef9dba;
  border-color: #ef9dba;
  color: #3F9AE0; }
[data-primary="color_3"] .alert-alt.alert-primary {
  border-left: 4px solid #3F9AE0; }
[data-primary="color_3"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #791438 !important; }
[data-primary="color_3"] .alert.alert-primary.solid {
  background: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"] .alert.alert-outline-primary {
  color: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"] .badge-outline-primary {
  border: 1px solid #3F9AE0;
  color: #3F9AE0; }
[data-primary="color_3"] .badge-primary {
  background-color: #3F9AE0; }
[data-primary="color_3"] .page-titles h4 {
  color: #3F9AE0; }
[data-primary="color_3"] .card-action > a {
  background: black; }
[data-primary="color_3"] .card-action .dropdown {
  background: black;
  color: #3F9AE0; }
  [data-primary="color_3"] .card-action .dropdown:hover, [data-primary="color_3"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_3"] .card-loader i {
  background: #c8215d; }
[data-primary="color_3"] .dropdown-outline {
  border: 0.1rem solid #3F9AE0; }
[data-primary="color_3"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #3F9AE0; }
[data-primary="color_3"] .card-action .custom-dropdown {
  background: #f2b3ca; }
  [data-primary="color_3"] .card-action .custom-dropdown.show, [data-primary="color_3"] .card-action .custom-dropdown:focus, [data-primary="color_3"] .card-action .custom-dropdown:hover {
    background: #3F9AE0; }
[data-primary="color_3"] .label-primary {
  background: #3F9AE0; }
[data-primary="color_3"] .pagination .page-item .page-link:hover {
  background: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"] .pagination .page-item.active .page-link {
  background-color: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_3"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #3F9AE0; }
[data-primary="color_3"] .progress-bar {
  background-color: #3F9AE0; }
[data-primary="color_3"] .progress-bar-primary {
  background-color: #3F9AE0; }
[data-primary="color_3"] .ribbon__four {
  background-color: #3F9AE0; }
  [data-primary="color_3"] .ribbon__four:after, [data-primary="color_3"] .ribbon__four:before {
    background-color: #eb87ab; }
[data-primary="color_3"] .ribbon__five {
  background-color: #3F9AE0; }
  [data-primary="color_3"] .ribbon__five::before {
    border-color: transparent transparent #3F9AE0 transparent; }
[data-primary="color_3"] .ribbon__six {
  background-color: #3F9AE0; }
[data-primary="color_3"] .multi-steps > li {
  color: #3F9AE0; }
  [data-primary="color_3"] .multi-steps > li:after {
    background-color: #3F9AE0; }
  [data-primary="color_3"] .multi-steps > li.is-active:before {
    border-color: #3F9AE0; }
[data-primary="color_3"] .timeline-badge.primary {
  background-color: #3F9AE0 !important; }
[data-primary="color_3"] .tooltip-wrapper button:hover {
  background: #3F9AE0; }
[data-primary="color_3"] .chart_widget_tab_one .nav-link.active {
  background-color: #3F9AE0;
  border: 1px solid #3F9AE0; }
  [data-primary="color_3"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #3F9AE0; }
[data-primary="color_3"] .social-icon2 a {
  border: 0.1rem solid #3F9AE0; }
[data-primary="color_3"] .social-icon2 i {
  color: #3F9AE0; }
[data-primary="color_3"] .social-icon3 ul li a:hover i {
  color: #3F9AE0; }
[data-primary="color_3"] .bgl-primary {
  background: #f2b3ca;
  border-color: #f2b3ca;
  color: #3F9AE0; }
[data-primary="color_3"] .tdl-holder input[type=checkbox]:checked + i {
  background: #3F9AE0; }
[data-primary="color_3"] .footer .copyright a {
  color: #3F9AE0; }
[data-primary="color_3"] .hamburger .line {
  background: #3F9AE0; }
[data-primary="color_3"] svg.pulse-svg .first-circle, [data-primary="color_3"] svg.pulse-svg .second-circle, [data-primary="color_3"] svg.pulse-svg .third-circle {
  fill: #3F9AE0; }
[data-primary="color_3"] .pulse-css {
  background: #3F9AE0; }
  [data-primary="color_3"] .pulse-css:after, [data-primary="color_3"] .pulse-css:before {
    background-color: #3F9AE0; }
[data-primary="color_3"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #3F9AE0; }
[data-primary="color_3"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_3"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_3"] .header-right .header-profile .dropdown-menu a.active {
  color: #3F9AE0; }
[data-primary="color_3"] .header-right .header-profile .profile_title {
  background: #3F9AE0; }
[data-primary="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #3F9AE0 !important; }
[data-primary="color_3"] .deznav .metismenu > li > a svg {
  color: #3F9AE0; }
[data-primary="color_3"] .deznav .metismenu > li:hover > a, [data-primary="color_3"] .deznav .metismenu > li:focus > a {
  color: #3F9AE0; }
[data-primary="color_3"] .deznav .metismenu > li.mm-active > a {
  color: #3F9AE0; }
[data-primary="color_3"] .deznav .metismenu ul a:hover, [data-primary="color_3"] .deznav .metismenu ul a:focus, [data-primary="color_3"] .deznav .metismenu ul a.mm-active {
  color: #3F9AE0; }
@media (min-width: 767px) {
  [data-primary="color_3"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_3"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_3"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_3"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: white; } }
[data-primary="color_3"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #3F9AE0; }
[data-primary="color_3"] .nav-user {
  background: #3F9AE0; }
[data-primary="color_3"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #3F9AE0; }
  [data-primary="color_3"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #3F9AE0; }
[data-primary="color_3"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"][data-theme-version="dark"] .header-left input:focus {
  border-color: #3F9AE0; }
[data-primary="color_3"][data-theme-version="dark"] .loader__bar {
  background: #3F9AE0; }
[data-primary="color_3"][data-theme-version="dark"] .loader__ball {
  background: #3F9AE0; }
[data-primary="color_3"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #3F9AE0; }
[data-primary="color_3"] .new-arrival-content .price {
  color: #3F9AE0; }
[data-primary="color_3"] .chart-link a i.text-primary {
  color: #3F9AE0; }
[data-primary="color_3"] #user-activity .nav-tabs .nav-link.active {
  background: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"] span#counter {
  color: #3F9AE0; }
[data-primary="color_3"] .welcome-content:after {
  background: #3F9AE0; }
[data-primary="color_3"] .timeline-badge {
  background-color: #3F9AE0; }
[data-primary="color_3"] .page-timeline .timeline-workplan[data-primary="color_3"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(221, 47, 110, 0.4); }
[data-primary="color_3"] .sk-three-bounce .sk-child {
  background-color: #3F9AE0; }
[data-primary="color_3"] .dropdown-item.active,
[data-primary="color_3"] .dropdown-item:active {
  color: #fff;
  background-color: #3F9AE0; }
[data-primary="color_3"] .overlay-box:after {
  background: #3F9AE0; }
[data-primary="color_3"] .btn-primary {
  background-color: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"] .bg-primary {
  background-color: #3F9AE0 !important; }
[data-primary="color_3"] .text-primary {
  color: #3F9AE0 !important; }
[data-primary="color_3"] .btn-primary:hover {
  background-color: #a51b4d;
  border-color: #a51b4d; }
[data-primary="color_3"] .btn-outline-primary {
  color: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"] .btn-outline-primary:hover {
  background-color: #3F9AE0;
  border-color: #3F9AE0; }
[data-primary="color_3"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_3"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_3"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_3"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_3"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_3"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #a51b4d;
  border-color: #a51b4d; }

[data-primary="color_4"] a:hover, [data-primary="color_4"] a:focus, [data-primary="color_4"] a.active {
  color: #4527a0; }
[data-primary="color_4"] [data-class="bg-primary"]:before {
  background: #4527a0; }
[data-primary="color_4"] .email-left-box .intro-title {
  background: rgba(69, 39, 160, 0.1); }
  [data-primary="color_4"] .email-left-box .intro-title i {
    color: #4527a0; }
[data-primary="color_4"] .widget-stat .media .media-body h4 {
  color: #4527a0 !important; }
[data-primary="color_4"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(69, 39, 160, 0.1); }
[data-primary="color_4"] .mail-list .list-group-item.active i {
  color: #4527a0; }
[data-primary="color_4"] .single-mail.active {
  background: #4527a0; }
[data-primary="color_4"] .profile-info h4.text-primary {
  color: #4527a0 !important; }
[data-primary="color_4"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_4"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #4527a0;
  color: #4527a0; }
[data-primary="color_4"] .amChartsInputField {
  border: 0;
  background: #4527a0; }
[data-primary="color_4"] .amcharts-period-input,
[data-primary="color_4"] .amcharts-period-input-selected {
  background: #4527a0; }
[data-primary="color_4"] .morris-hover {
  background: #4527a0; }
[data-primary="color_4"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #4527a0; }
[data-primary="color_4"] .custom-select:focus {
  border-color: #4527a0;
  color: #4527a0; }
[data-primary="color_4"] .daterangepicker td.active {
  background-color: #4527a0; }
  [data-primary="color_4"] .daterangepicker td.active:hover {
    background-color: #4527a0; }
[data-primary="color_4"] .daterangepicker button.applyBtn {
  background-color: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"] .wizard > .steps li.current a {
  background-color: #4527a0; }
[data-primary="color_4"] .wizard .skip-email a {
  color: #4527a0; }
[data-primary="color_4"] .wizard > .actions li:not(.disabled) a {
  background-color: #4527a0; }
[data-primary="color_4"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #4527a0; }
[data-primary="color_4"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #4527a0; }
[data-primary="color_4"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #4527a0; }
[data-primary="color_4"] .is-invalid .input-group-prepend .input-group-text i {
  color: #6642d1; }
[data-primary="color_4"] .datamaps-hoverover {
  color: #4527a0;
  border: 1px solid rgba(69, 39, 160, 0.3); }
[data-primary="color_4"] .jqvmap-zoomin,
[data-primary="color_4"] .jqvmap-zoomout {
  background-color: #4527a0; }
[data-primary="color_4"] .table .thead-primary th {
  background-color: #4527a0; }
[data-primary="color_4"] .table.primary-table-bg-hover thead th {
  background-color: #3c228b; }
[data-primary="color_4"] .table.primary-table-bg-hover tbody tr {
  background-color: #4527a0; }
  [data-primary="color_4"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #4e2cb5; }
  [data-primary="color_4"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_4"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #3c228b; }
[data-primary="color_4"] table.dataTable tr.selected {
  color: #4527a0; }
[data-primary="color_4"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #4527a0 !important;
  background: rgba(69, 39, 160, 0.1); }
[data-primary="color_4"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #4527a0 !important;
  background: rgba(69, 39, 160, 0.1); }
[data-primary="color_4"] .clipboard-btn:hover {
  background-color: #4527a0; }
[data-primary="color_4"] .cd-h-timeline__dates::before {
  background: #4527a0; }
[data-primary="color_4"] .cd-h-timeline__dates::after {
  background: #4527a0; }
[data-primary="color_4"] .cd-h-timeline__line {
  background-color: #4527a0; }
[data-primary="color_4"] .cd-h-timeline__date:after {
  border-color: #4728a4;
  background-color: #4527a0; }
[data-primary="color_4"] .cd-h-timeline__navigation {
  border-color: #4728a4; }
[data-primary="color_4"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #4728a4; }
[data-primary="color_4"] .dd-handle {
  background: #4527a0; }
[data-primary="color_4"] .dd-handle:hover {
  background: #4527a0; }
[data-primary="color_4"] .dd3-content:hover {
  background: #4527a0; }
[data-primary="color_4"] .noUi-connect {
  background-color: #4527a0; }
  [data-primary="color_4"] .noUi-connect.c-3-color {
    background-color: #4527a0; }
[data-primary="color_4"] .noUi-horizontal .noUi-handle, [data-primary="color_4"] .noUi-vertical .noUi-handle {
  background-color: #4527a0; }
[data-primary="color_4"] #slider-toggle.off .noUi-handle {
  border-color: #4527a0; }
[data-primary="color_4"] .pignose-calendar {
  border-color: #4527a0; }
  [data-primary="color_4"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #4527a0; }
[data-primary="color_4"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #4527a0; }
[data-primary="color_4"] .bootstrap-tagsinput .tag {
  background-color: #4527a0; }
[data-primary="color_4"] .toast-success {
  background-color: #4527a0; }
[data-primary="color_4"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #4527a0; }
[data-primary="color_4"] .accordion-header-bg .accordion__header--primary {
  background-color: #4527a0; }
[data-primary="color_4"] .alert-primary {
  background: #876bdb;
  border-color: #876bdb;
  color: #4527a0; }
[data-primary="color_4"] .alert-alt.alert-primary {
  border-left: 4px solid #4527a0; }
[data-primary="color_4"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #190e39 !important; }
[data-primary="color_4"] .alert.alert-primary.solid {
  background: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"] .alert.alert-outline-primary {
  color: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"] .badge-outline-primary {
  border: 1px solid #4527a0;
  color: #4527a0; }
[data-primary="color_4"] .badge-primary {
  background-color: #4527a0; }
[data-primary="color_4"] .page-titles h4 {
  color: #4527a0; }
[data-primary="color_4"] .card-action > a {
  background: black; }
[data-primary="color_4"] .card-action .dropdown {
  background: black;
  color: #4527a0; }
  [data-primary="color_4"] .card-action .dropdown:hover, [data-primary="color_4"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_4"] .card-loader i {
  background: #392083; }
[data-primary="color_4"] .dropdown-outline {
  border: 0.1rem solid #4527a0; }
[data-primary="color_4"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #4527a0; }
[data-primary="color_4"] .card-action .custom-dropdown {
  background: #9880e0; }
  [data-primary="color_4"] .card-action .custom-dropdown.show, [data-primary="color_4"] .card-action .custom-dropdown:focus, [data-primary="color_4"] .card-action .custom-dropdown:hover {
    background: #4527a0; }
[data-primary="color_4"] .label-primary {
  background: #4527a0; }
[data-primary="color_4"] .pagination .page-item .page-link:hover {
  background: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"] .pagination .page-item.active .page-link {
  background-color: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_4"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #4527a0; }
[data-primary="color_4"] .progress-bar {
  background-color: #4527a0; }
[data-primary="color_4"] .progress-bar-primary {
  background-color: #4527a0; }
[data-primary="color_4"] .ribbon__four {
  background-color: #4527a0; }
  [data-primary="color_4"] .ribbon__four:after, [data-primary="color_4"] .ribbon__four:before {
    background-color: #7657d6; }
[data-primary="color_4"] .ribbon__five {
  background-color: #4527a0; }
  [data-primary="color_4"] .ribbon__five::before {
    border-color: transparent transparent #4527a0 transparent; }
[data-primary="color_4"] .ribbon__six {
  background-color: #4527a0; }
[data-primary="color_4"] .multi-steps > li {
  color: #4527a0; }
  [data-primary="color_4"] .multi-steps > li:after {
    background-color: #4527a0; }
  [data-primary="color_4"] .multi-steps > li.is-active:before {
    border-color: #4527a0; }
[data-primary="color_4"] .timeline-badge.primary {
  background-color: #4527a0 !important; }
[data-primary="color_4"] .tooltip-wrapper button:hover {
  background: #4527a0; }
[data-primary="color_4"] .chart_widget_tab_one .nav-link.active {
  background-color: #4527a0;
  border: 1px solid #4527a0; }
  [data-primary="color_4"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #4527a0; }
[data-primary="color_4"] .social-icon2 a {
  border: 0.1rem solid #4527a0; }
[data-primary="color_4"] .social-icon2 i {
  color: #4527a0; }
[data-primary="color_4"] .social-icon3 ul li a:hover i {
  color: #4527a0; }
[data-primary="color_4"] .bgl-primary {
  background: #9880e0;
  border-color: #9880e0;
  color: #4527a0; }
[data-primary="color_4"] .tdl-holder input[type=checkbox]:checked + i {
  background: #4527a0; }
[data-primary="color_4"] .footer .copyright a {
  color: #4527a0; }
[data-primary="color_4"] .hamburger .line {
  background: #4527a0; }
[data-primary="color_4"] svg.pulse-svg .first-circle, [data-primary="color_4"] svg.pulse-svg .second-circle, [data-primary="color_4"] svg.pulse-svg .third-circle {
  fill: #4527a0; }
[data-primary="color_4"] .pulse-css {
  background: #4527a0; }
  [data-primary="color_4"] .pulse-css:after, [data-primary="color_4"] .pulse-css:before {
    background-color: #4527a0; }
[data-primary="color_4"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #4527a0; }
[data-primary="color_4"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_4"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_4"] .header-right .header-profile .dropdown-menu a.active {
  color: #4527a0; }
[data-primary="color_4"] .header-right .header-profile .profile_title {
  background: #4527a0; }
[data-primary="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #4527a0 !important; }
[data-primary="color_4"] .deznav .metismenu > li > a svg {
  color: #4527a0; }
[data-primary="color_4"] .deznav .metismenu > li:hover > a, [data-primary="color_4"] .deznav .metismenu > li:focus > a {
  color: #4527a0; }
[data-primary="color_4"] .deznav .metismenu > li.mm-active > a {
  color: #4527a0; }
[data-primary="color_4"] .deznav .metismenu ul a:hover, [data-primary="color_4"] .deznav .metismenu ul a:focus, [data-primary="color_4"] .deznav .metismenu ul a.mm-active {
  color: #4527a0; }
@media (min-width: 767px) {
  [data-primary="color_4"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_4"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_4"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_4"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: #dad2f4; } }
[data-primary="color_4"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #4527a0; }
[data-primary="color_4"] .nav-user {
  background: #4527a0; }
[data-primary="color_4"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #4527a0; }
  [data-primary="color_4"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #4527a0; }
[data-primary="color_4"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"][data-theme-version="dark"] .header-left input:focus {
  border-color: #4527a0; }
[data-primary="color_4"][data-theme-version="dark"] .loader__bar {
  background: #4527a0; }
[data-primary="color_4"][data-theme-version="dark"] .loader__ball {
  background: #4527a0; }
[data-primary="color_4"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #4527a0; }
[data-primary="color_4"] .new-arrival-content .price {
  color: #4527a0; }
[data-primary="color_4"] .chart-link a i.text-primary {
  color: #4527a0; }
[data-primary="color_4"] #user-activity .nav-tabs .nav-link.active {
  background: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"] span#counter {
  color: #4527a0; }
[data-primary="color_4"] .welcome-content:after {
  background: #4527a0; }
[data-primary="color_4"] .timeline-badge {
  background-color: #4527a0; }
[data-primary="color_4"] .page-timeline .timeline-workplan[data-primary="color_4"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(69, 39, 160, 0.4); }
[data-primary="color_4"] .sk-three-bounce .sk-child {
  background-color: #4527a0; }
[data-primary="color_4"] .dropdown-item.active,
[data-primary="color_4"] .dropdown-item:active {
  color: #fff;
  background-color: #4527a0; }
[data-primary="color_4"] .overlay-box:after {
  background: #4527a0; }
[data-primary="color_4"] .btn-primary {
  background-color: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"] .bg-primary {
  background-color: #4527a0 !important; }
[data-primary="color_4"] .text-primary {
  color: #4527a0 !important; }
[data-primary="color_4"] .btn-primary:hover {
  background-color: #2a1862;
  border-color: #2a1862; }
[data-primary="color_4"] .btn-outline-primary {
  color: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"] .btn-outline-primary:hover {
  background-color: #4527a0;
  border-color: #4527a0; }
[data-primary="color_4"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_4"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_4"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_4"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_4"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_4"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #2a1862;
  border-color: #2a1862; }

[data-primary="color_5"] a:hover, [data-primary="color_5"] a:focus, [data-primary="color_5"] a.active {
  color: #c62828; }
[data-primary="color_5"] [data-class="bg-primary"]:before {
  background: #c62828; }
[data-primary="color_5"] .email-left-box .intro-title {
  background: rgba(198, 40, 40, 0.1); }
  [data-primary="color_5"] .email-left-box .intro-title i {
    color: #c62828; }
[data-primary="color_5"] .widget-stat .media .media-body h4 {
  color: #c62828 !important; }
[data-primary="color_5"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(198, 40, 40, 0.1); }
[data-primary="color_5"] .mail-list .list-group-item.active i {
  color: #c62828; }
[data-primary="color_5"] .single-mail.active {
  background: #c62828; }
[data-primary="color_5"] .profile-info h4.text-primary {
  color: #c62828 !important; }
[data-primary="color_5"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_5"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #c62828;
  color: #c62828; }
[data-primary="color_5"] .amChartsInputField {
  border: 0;
  background: #c62828; }
[data-primary="color_5"] .amcharts-period-input,
[data-primary="color_5"] .amcharts-period-input-selected {
  background: #c62828; }
[data-primary="color_5"] .morris-hover {
  background: #c62828; }
[data-primary="color_5"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #c62828; }
[data-primary="color_5"] .custom-select:focus {
  border-color: #c62828;
  color: #c62828; }
[data-primary="color_5"] .daterangepicker td.active {
  background-color: #c62828; }
  [data-primary="color_5"] .daterangepicker td.active:hover {
    background-color: #c62828; }
[data-primary="color_5"] .daterangepicker button.applyBtn {
  background-color: #c62828;
  border-color: #c62828; }
[data-primary="color_5"] .wizard > .steps li.current a {
  background-color: #c62828; }
[data-primary="color_5"] .wizard .skip-email a {
  color: #c62828; }
[data-primary="color_5"] .wizard > .actions li:not(.disabled) a {
  background-color: #c62828; }
[data-primary="color_5"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #c62828; }
[data-primary="color_5"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #c62828;
  border-color: #c62828; }
[data-primary="color_5"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #c62828; }
[data-primary="color_5"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #c62828; }
[data-primary="color_5"] .is-invalid .input-group-prepend .input-group-text i {
  color: #de5c5c; }
[data-primary="color_5"] .datamaps-hoverover {
  color: #c62828;
  border: 1px solid rgba(198, 40, 40, 0.3); }
[data-primary="color_5"] .jqvmap-zoomin,
[data-primary="color_5"] .jqvmap-zoomout {
  background-color: #c62828; }
[data-primary="color_5"] .table .thead-primary th {
  background-color: #c62828; }
[data-primary="color_5"] .table.primary-table-bg-hover thead th {
  background-color: #b12424; }
[data-primary="color_5"] .table.primary-table-bg-hover tbody tr {
  background-color: #c62828; }
  [data-primary="color_5"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #d63232; }
  [data-primary="color_5"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_5"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #b12424; }
[data-primary="color_5"] table.dataTable tr.selected {
  color: #c62828; }
[data-primary="color_5"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #c62828 !important;
  background: rgba(198, 40, 40, 0.1); }
[data-primary="color_5"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #c62828 !important;
  background: rgba(198, 40, 40, 0.1); }
[data-primary="color_5"] .clipboard-btn:hover {
  background-color: #c62828; }
[data-primary="color_5"] .cd-h-timeline__dates::before {
  background: #c62828; }
[data-primary="color_5"] .cd-h-timeline__dates::after {
  background: #c62828; }
[data-primary="color_5"] .cd-h-timeline__line {
  background-color: #c62828; }
[data-primary="color_5"] .cd-h-timeline__date:after {
  border-color: #ca2929;
  background-color: #c62828; }
[data-primary="color_5"] .cd-h-timeline__navigation {
  border-color: #ca2929; }
[data-primary="color_5"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #ca2929; }
[data-primary="color_5"] .dd-handle {
  background: #c62828; }
[data-primary="color_5"] .dd-handle:hover {
  background: #c62828; }
[data-primary="color_5"] .dd3-content:hover {
  background: #c62828; }
[data-primary="color_5"] .noUi-connect {
  background-color: #c62828; }
  [data-primary="color_5"] .noUi-connect.c-3-color {
    background-color: #c62828; }
[data-primary="color_5"] .noUi-horizontal .noUi-handle, [data-primary="color_5"] .noUi-vertical .noUi-handle {
  background-color: #c62828; }
[data-primary="color_5"] #slider-toggle.off .noUi-handle {
  border-color: #c62828; }
[data-primary="color_5"] .pignose-calendar {
  border-color: #c62828; }
  [data-primary="color_5"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #c62828; }
[data-primary="color_5"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #c62828; }
[data-primary="color_5"] .bootstrap-tagsinput .tag {
  background-color: #c62828; }
[data-primary="color_5"] .toast-success {
  background-color: #c62828; }
[data-primary="color_5"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #c62828; }
[data-primary="color_5"] .accordion-header-bg .accordion__header--primary {
  background-color: #c62828; }
[data-primary="color_5"] .alert-primary {
  background: #e78787;
  border-color: #e78787;
  color: #c62828; }
[data-primary="color_5"] .alert-alt.alert-primary {
  border-left: 4px solid #c62828; }
[data-primary="color_5"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #5c1313 !important; }
[data-primary="color_5"] .alert.alert-primary.solid {
  background: #c62828;
  border-color: #c62828; }
[data-primary="color_5"] .alert.alert-outline-primary {
  color: #c62828;
  border-color: #c62828; }
[data-primary="color_5"] .badge-outline-primary {
  border: 1px solid #c62828;
  color: #c62828; }
[data-primary="color_5"] .badge-primary {
  background-color: #c62828; }
[data-primary="color_5"] .page-titles h4 {
  color: #c62828; }
[data-primary="color_5"] .card-action > a {
  background: black; }
[data-primary="color_5"] .card-action .dropdown {
  background: black;
  color: #c62828; }
  [data-primary="color_5"] .card-action .dropdown:hover, [data-primary="color_5"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_5"] .card-loader i {
  background: #a82222; }
[data-primary="color_5"] .dropdown-outline {
  border: 0.1rem solid #c62828; }
[data-primary="color_5"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #c62828; }
[data-primary="color_5"] .card-action .custom-dropdown {
  background: #eb9c9c; }
  [data-primary="color_5"] .card-action .custom-dropdown.show, [data-primary="color_5"] .card-action .custom-dropdown:focus, [data-primary="color_5"] .card-action .custom-dropdown:hover {
    background: #c62828; }
[data-primary="color_5"] .label-primary {
  background: #c62828; }
[data-primary="color_5"] .pagination .page-item .page-link:hover {
  background: #c62828;
  border-color: #c62828; }
[data-primary="color_5"] .pagination .page-item.active .page-link {
  background-color: #c62828;
  border-color: #c62828; }
[data-primary="color_5"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_5"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #c62828; }
[data-primary="color_5"] .progress-bar {
  background-color: #c62828; }
[data-primary="color_5"] .progress-bar-primary {
  background-color: #c62828; }
[data-primary="color_5"] .ribbon__four {
  background-color: #c62828; }
  [data-primary="color_5"] .ribbon__four:after, [data-primary="color_5"] .ribbon__four:before {
    background-color: #e27272; }
[data-primary="color_5"] .ribbon__five {
  background-color: #c62828; }
  [data-primary="color_5"] .ribbon__five::before {
    border-color: transparent transparent #c62828 transparent; }
[data-primary="color_5"] .ribbon__six {
  background-color: #c62828; }
[data-primary="color_5"] .multi-steps > li {
  color: #c62828; }
  [data-primary="color_5"] .multi-steps > li:after {
    background-color: #c62828; }
  [data-primary="color_5"] .multi-steps > li.is-active:before {
    border-color: #c62828; }
[data-primary="color_5"] .timeline-badge.primary {
  background-color: #c62828 !important; }
[data-primary="color_5"] .tooltip-wrapper button:hover {
  background: #c62828; }
[data-primary="color_5"] .chart_widget_tab_one .nav-link.active {
  background-color: #c62828;
  border: 1px solid #c62828; }
  [data-primary="color_5"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #c62828; }
[data-primary="color_5"] .social-icon2 a {
  border: 0.1rem solid #c62828; }
[data-primary="color_5"] .social-icon2 i {
  color: #c62828; }
[data-primary="color_5"] .social-icon3 ul li a:hover i {
  color: #c62828; }
[data-primary="color_5"] .bgl-primary {
  background: #eb9c9c;
  border-color: #eb9c9c;
  color: #c62828; }
[data-primary="color_5"] .tdl-holder input[type=checkbox]:checked + i {
  background: #c62828; }
[data-primary="color_5"] .footer .copyright a {
  color: #c62828; }
[data-primary="color_5"] .hamburger .line {
  background: #c62828; }
[data-primary="color_5"] svg.pulse-svg .first-circle, [data-primary="color_5"] svg.pulse-svg .second-circle, [data-primary="color_5"] svg.pulse-svg .third-circle {
  fill: #c62828; }
[data-primary="color_5"] .pulse-css {
  background: #c62828; }
  [data-primary="color_5"] .pulse-css:after, [data-primary="color_5"] .pulse-css:before {
    background-color: #c62828; }
[data-primary="color_5"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #c62828; }
[data-primary="color_5"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_5"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_5"] .header-right .header-profile .dropdown-menu a.active {
  color: #c62828; }
[data-primary="color_5"] .header-right .header-profile .profile_title {
  background: #c62828; }
[data-primary="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #c62828 !important; }
[data-primary="color_5"] .deznav .metismenu > li > a svg {
  color: #c62828; }
[data-primary="color_5"] .deznav .metismenu > li:hover > a, [data-primary="color_5"] .deznav .metismenu > li:focus > a {
  color: #c62828; }
[data-primary="color_5"] .deznav .metismenu > li.mm-active > a {
  color: #c62828; }
[data-primary="color_5"] .deznav .metismenu ul a:hover, [data-primary="color_5"] .deznav .metismenu ul a:focus, [data-primary="color_5"] .deznav .metismenu ul a.mm-active {
  color: #c62828; }
@media (min-width: 767px) {
  [data-primary="color_5"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_5"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_5"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_5"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: #fcf1f1; } }
[data-primary="color_5"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #c62828; }
[data-primary="color_5"] .nav-user {
  background: #c62828; }
[data-primary="color_5"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #c62828; }
  [data-primary="color_5"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #c62828; }
[data-primary="color_5"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #c62828;
  border-color: #c62828; }
[data-primary="color_5"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #c62828;
  border-color: #c62828; }
[data-primary="color_5"][data-theme-version="dark"] .header-left input:focus {
  border-color: #c62828; }
[data-primary="color_5"][data-theme-version="dark"] .loader__bar {
  background: #c62828; }
[data-primary="color_5"][data-theme-version="dark"] .loader__ball {
  background: #c62828; }
[data-primary="color_5"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #c62828; }
[data-primary="color_5"] .new-arrival-content .price {
  color: #c62828; }
[data-primary="color_5"] .chart-link a i.text-primary {
  color: #c62828; }
[data-primary="color_5"] #user-activity .nav-tabs .nav-link.active {
  background: #c62828;
  border-color: #c62828; }
[data-primary="color_5"] span#counter {
  color: #c62828; }
[data-primary="color_5"] .welcome-content:after {
  background: #c62828; }
[data-primary="color_5"] .timeline-badge {
  background-color: #c62828; }
[data-primary="color_5"] .page-timeline .timeline-workplan[data-primary="color_5"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(198, 40, 40, 0.4); }
[data-primary="color_5"] .sk-three-bounce .sk-child {
  background-color: #c62828; }
[data-primary="color_5"] .dropdown-item.active,
[data-primary="color_5"] .dropdown-item:active {
  color: #fff;
  background-color: #c62828; }
[data-primary="color_5"] .overlay-box:after {
  background: #c62828; }
[data-primary="color_5"] .btn-primary {
  background-color: #c62828;
  border-color: #c62828; }
[data-primary="color_5"] .bg-primary {
  background-color: #c62828 !important; }
[data-primary="color_5"] .text-primary {
  color: #c62828 !important; }
[data-primary="color_5"] .btn-primary:hover {
  background-color: #861b1b;
  border-color: #861b1b; }
[data-primary="color_5"] .btn-outline-primary {
  color: #c62828;
  border-color: #c62828; }
[data-primary="color_5"] .btn-outline-primary:hover {
  background-color: #c62828;
  border-color: #c62828; }
[data-primary="color_5"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_5"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_5"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_5"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_5"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_5"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #861b1b;
  border-color: #861b1b; }

[data-primary="color_6"] a:hover, [data-primary="color_6"] a:focus, [data-primary="color_6"] a.active {
  color: #283593; }
[data-primary="color_6"] [data-class="bg-primary"]:before {
  background: #283593; }
[data-primary="color_6"] .email-left-box .intro-title {
  background: rgba(40, 53, 147, 0.1); }
  [data-primary="color_6"] .email-left-box .intro-title i {
    color: #283593; }
[data-primary="color_6"] .widget-stat .media .media-body h4 {
  color: #283593 !important; }
[data-primary="color_6"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(40, 53, 147, 0.1); }
[data-primary="color_6"] .mail-list .list-group-item.active i {
  color: #283593; }
[data-primary="color_6"] .single-mail.active {
  background: #283593; }
[data-primary="color_6"] .profile-info h4.text-primary {
  color: #283593 !important; }
[data-primary="color_6"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_6"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #283593;
  color: #283593; }
[data-primary="color_6"] .amChartsInputField {
  border: 0;
  background: #283593; }
[data-primary="color_6"] .amcharts-period-input,
[data-primary="color_6"] .amcharts-period-input-selected {
  background: #283593; }
[data-primary="color_6"] .morris-hover {
  background: #283593; }
[data-primary="color_6"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #283593; }
[data-primary="color_6"] .custom-select:focus {
  border-color: #283593;
  color: #283593; }
[data-primary="color_6"] .daterangepicker td.active {
  background-color: #283593; }
  [data-primary="color_6"] .daterangepicker td.active:hover {
    background-color: #283593; }
[data-primary="color_6"] .daterangepicker button.applyBtn {
  background-color: #283593;
  border-color: #283593; }
[data-primary="color_6"] .wizard > .steps li.current a {
  background-color: #283593; }
[data-primary="color_6"] .wizard .skip-email a {
  color: #283593; }
[data-primary="color_6"] .wizard > .actions li:not(.disabled) a {
  background-color: #283593; }
[data-primary="color_6"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #283593; }
[data-primary="color_6"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #283593;
  border-color: #283593; }
[data-primary="color_6"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #283593; }
[data-primary="color_6"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #283593; }
[data-primary="color_6"] .is-invalid .input-group-prepend .input-group-text i {
  color: #3d4eca; }
[data-primary="color_6"] .datamaps-hoverover {
  color: #283593;
  border: 1px solid rgba(40, 53, 147, 0.3); }
[data-primary="color_6"] .jqvmap-zoomin,
[data-primary="color_6"] .jqvmap-zoomout {
  background-color: #283593; }
[data-primary="color_6"] .table .thead-primary th {
  background-color: #283593; }
[data-primary="color_6"] .table.primary-table-bg-hover thead th {
  background-color: #232e7f; }
[data-primary="color_6"] .table.primary-table-bg-hover tbody tr {
  background-color: #283593; }
  [data-primary="color_6"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #2d3ca7; }
  [data-primary="color_6"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_6"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #232e7f; }
[data-primary="color_6"] table.dataTable tr.selected {
  color: #283593; }
[data-primary="color_6"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #283593 !important;
  background: rgba(40, 53, 147, 0.1); }
[data-primary="color_6"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #283593 !important;
  background: rgba(40, 53, 147, 0.1); }
[data-primary="color_6"] .clipboard-btn:hover {
  background-color: #283593; }
[data-primary="color_6"] .cd-h-timeline__dates::before {
  background: #283593; }
[data-primary="color_6"] .cd-h-timeline__dates::after {
  background: #283593; }
[data-primary="color_6"] .cd-h-timeline__line {
  background-color: #283593; }
[data-primary="color_6"] .cd-h-timeline__date:after {
  border-color: #293697;
  background-color: #283593; }
[data-primary="color_6"] .cd-h-timeline__navigation {
  border-color: #293697; }
[data-primary="color_6"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #293697; }
[data-primary="color_6"] .dd-handle {
  background: #283593; }
[data-primary="color_6"] .dd-handle:hover {
  background: #283593; }
[data-primary="color_6"] .dd3-content:hover {
  background: #283593; }
[data-primary="color_6"] .noUi-connect {
  background-color: #283593; }
  [data-primary="color_6"] .noUi-connect.c-3-color {
    background-color: #283593; }
[data-primary="color_6"] .noUi-horizontal .noUi-handle, [data-primary="color_6"] .noUi-vertical .noUi-handle {
  background-color: #283593; }
[data-primary="color_6"] #slider-toggle.off .noUi-handle {
  border-color: #283593; }
[data-primary="color_6"] .pignose-calendar {
  border-color: #283593; }
  [data-primary="color_6"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #283593; }
[data-primary="color_6"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #283593; }
[data-primary="color_6"] .bootstrap-tagsinput .tag {
  background-color: #283593; }
[data-primary="color_6"] .toast-success {
  background-color: #283593; }
[data-primary="color_6"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #283593; }
[data-primary="color_6"] .accordion-header-bg .accordion__header--primary {
  background-color: #283593; }
[data-primary="color_6"] .alert-primary {
  background: #6573d5;
  border-color: #6573d5;
  color: #283593; }
[data-primary="color_6"] .alert-alt.alert-primary {
  border-left: 4px solid #283593; }
[data-primary="color_6"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #0d112f !important; }
[data-primary="color_6"] .alert.alert-primary.solid {
  background: #283593;
  border-color: #283593; }
[data-primary="color_6"] .alert.alert-outline-primary {
  color: #283593;
  border-color: #283593; }
[data-primary="color_6"] .badge-outline-primary {
  border: 1px solid #283593;
  color: #283593; }
[data-primary="color_6"] .badge-primary {
  background-color: #283593; }
[data-primary="color_6"] .page-titles h4 {
  color: #283593; }
[data-primary="color_6"] .card-action > a {
  background: black; }
[data-primary="color_6"] .card-action .dropdown {
  background: black;
  color: #283593; }
  [data-primary="color_6"] .card-action .dropdown:hover, [data-primary="color_6"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_6"] .card-loader i {
  background: #202b77; }
[data-primary="color_6"] .dropdown-outline {
  border: 0.1rem solid #283593; }
[data-primary="color_6"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #283593; }
[data-primary="color_6"] .card-action .custom-dropdown {
  background: #7985db; }
  [data-primary="color_6"] .card-action .custom-dropdown.show, [data-primary="color_6"] .card-action .custom-dropdown:focus, [data-primary="color_6"] .card-action .custom-dropdown:hover {
    background: #283593; }
[data-primary="color_6"] .label-primary {
  background: #283593; }
[data-primary="color_6"] .pagination .page-item .page-link:hover {
  background: #283593;
  border-color: #283593; }
[data-primary="color_6"] .pagination .page-item.active .page-link {
  background-color: #283593;
  border-color: #283593; }
[data-primary="color_6"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_6"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #283593; }
[data-primary="color_6"] .progress-bar {
  background-color: #283593; }
[data-primary="color_6"] .progress-bar-primary {
  background-color: #283593; }
[data-primary="color_6"] .ribbon__four {
  background-color: #283593; }
  [data-primary="color_6"] .ribbon__four:after, [data-primary="color_6"] .ribbon__four:before {
    background-color: #5161d0; }
[data-primary="color_6"] .ribbon__five {
  background-color: #283593; }
  [data-primary="color_6"] .ribbon__five::before {
    border-color: transparent transparent #283593 transparent; }
[data-primary="color_6"] .ribbon__six {
  background-color: #283593; }
[data-primary="color_6"] .multi-steps > li {
  color: #283593; }
  [data-primary="color_6"] .multi-steps > li:after {
    background-color: #283593; }
  [data-primary="color_6"] .multi-steps > li.is-active:before {
    border-color: #283593; }
[data-primary="color_6"] .timeline-badge.primary {
  background-color: #283593 !important; }
[data-primary="color_6"] .tooltip-wrapper button:hover {
  background: #283593; }
[data-primary="color_6"] .chart_widget_tab_one .nav-link.active {
  background-color: #283593;
  border: 1px solid #283593; }
  [data-primary="color_6"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #283593; }
[data-primary="color_6"] .social-icon2 a {
  border: 0.1rem solid #283593; }
[data-primary="color_6"] .social-icon2 i {
  color: #283593; }
[data-primary="color_6"] .social-icon3 ul li a:hover i {
  color: #283593; }
[data-primary="color_6"] .bgl-primary {
  background: #7985db;
  border-color: #7985db;
  color: #283593; }
[data-primary="color_6"] .tdl-holder input[type=checkbox]:checked + i {
  background: #283593; }
[data-primary="color_6"] .footer .copyright a {
  color: #283593; }
[data-primary="color_6"] .hamburger .line {
  background: #283593; }
[data-primary="color_6"] svg.pulse-svg .first-circle, [data-primary="color_6"] svg.pulse-svg .second-circle, [data-primary="color_6"] svg.pulse-svg .third-circle {
  fill: #283593; }
[data-primary="color_6"] .pulse-css {
  background: #283593; }
  [data-primary="color_6"] .pulse-css:after, [data-primary="color_6"] .pulse-css:before {
    background-color: #283593; }
[data-primary="color_6"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #283593; }
[data-primary="color_6"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_6"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_6"] .header-right .header-profile .dropdown-menu a.active {
  color: #283593; }
[data-primary="color_6"] .header-right .header-profile .profile_title {
  background: #283593; }
[data-primary="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #283593 !important; }
[data-primary="color_6"] .deznav .metismenu > li > a svg {
  color: #283593; }
[data-primary="color_6"] .deznav .metismenu > li:hover > a, [data-primary="color_6"] .deznav .metismenu > li:focus > a {
  color: #283593; }
[data-primary="color_6"] .deznav .metismenu > li.mm-active > a {
  color: #283593; }
[data-primary="color_6"] .deznav .metismenu ul a:hover, [data-primary="color_6"] .deznav .metismenu ul a:focus, [data-primary="color_6"] .deznav .metismenu ul a.mm-active {
  color: #283593; }
@media (min-width: 767px) {
  [data-primary="color_6"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_6"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_6"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_6"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: #cacef0; } }
[data-primary="color_6"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #283593; }
[data-primary="color_6"] .nav-user {
  background: #283593; }
[data-primary="color_6"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #283593; }
  [data-primary="color_6"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #283593; }
[data-primary="color_6"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #283593;
  border-color: #283593; }
[data-primary="color_6"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #283593;
  border-color: #283593; }
[data-primary="color_6"][data-theme-version="dark"] .header-left input:focus {
  border-color: #283593; }
[data-primary="color_6"][data-theme-version="dark"] .loader__bar {
  background: #283593; }
[data-primary="color_6"][data-theme-version="dark"] .loader__ball {
  background: #283593; }
[data-primary="color_6"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #283593; }
[data-primary="color_6"] .new-arrival-content .price {
  color: #283593; }
[data-primary="color_6"] .chart-link a i.text-primary {
  color: #283593; }
[data-primary="color_6"] #user-activity .nav-tabs .nav-link.active {
  background: #283593;
  border-color: #283593; }
[data-primary="color_6"] span#counter {
  color: #283593; }
[data-primary="color_6"] .welcome-content:after {
  background: #283593; }
[data-primary="color_6"] .timeline-badge {
  background-color: #283593; }
[data-primary="color_6"] .page-timeline .timeline-workplan[data-primary="color_6"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(40, 53, 147, 0.4); }
[data-primary="color_6"] .sk-three-bounce .sk-child {
  background-color: #283593; }
[data-primary="color_6"] .dropdown-item.active,
[data-primary="color_6"] .dropdown-item:active {
  color: #fff;
  background-color: #283593; }
[data-primary="color_6"] .overlay-box:after {
  background: #283593; }
[data-primary="color_6"] .btn-primary {
  background-color: #283593;
  border-color: #283593; }
[data-primary="color_6"] .bg-primary {
  background-color: #283593 !important; }
[data-primary="color_6"] .text-primary {
  color: #283593 !important; }
[data-primary="color_6"] .btn-primary:hover {
  background-color: #181f57;
  border-color: #181f57; }
[data-primary="color_6"] .btn-outline-primary {
  color: #283593;
  border-color: #283593; }
[data-primary="color_6"] .btn-outline-primary:hover {
  background-color: #283593;
  border-color: #283593; }
[data-primary="color_6"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_6"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_6"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_6"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_6"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_6"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #181f57;
  border-color: #181f57; }

[data-primary="color_7"] a:hover, [data-primary="color_7"] a:focus, [data-primary="color_7"] a.active {
  color: #7356f1; }
[data-primary="color_7"] [data-class="bg-primary"]:before {
  background: #7356f1; }
[data-primary="color_7"] .email-left-box .intro-title {
  background: rgba(115, 86, 241, 0.1); }
  [data-primary="color_7"] .email-left-box .intro-title i {
    color: #7356f1; }
[data-primary="color_7"] .widget-stat .media .media-body h4 {
  color: #7356f1 !important; }
[data-primary="color_7"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(115, 86, 241, 0.1); }
[data-primary="color_7"] .mail-list .list-group-item.active i {
  color: #7356f1; }
[data-primary="color_7"] .single-mail.active {
  background: #7356f1; }
[data-primary="color_7"] .profile-info h4.text-primary {
  color: #7356f1 !important; }
[data-primary="color_7"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_7"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #7356f1;
  color: #7356f1; }
[data-primary="color_7"] .amChartsInputField {
  border: 0;
  background: #7356f1; }
[data-primary="color_7"] .amcharts-period-input,
[data-primary="color_7"] .amcharts-period-input-selected {
  background: #7356f1; }
[data-primary="color_7"] .morris-hover {
  background: #7356f1; }
[data-primary="color_7"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #7356f1; }
[data-primary="color_7"] .custom-select:focus {
  border-color: #7356f1;
  color: #7356f1; }
[data-primary="color_7"] .daterangepicker td.active {
  background-color: #7356f1; }
  [data-primary="color_7"] .daterangepicker td.active:hover {
    background-color: #7356f1; }
[data-primary="color_7"] .daterangepicker button.applyBtn {
  background-color: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"] .wizard > .steps li.current a {
  background-color: #7356f1; }
[data-primary="color_7"] .wizard .skip-email a {
  color: #7356f1; }
[data-primary="color_7"] .wizard > .actions li:not(.disabled) a {
  background-color: #7356f1; }
[data-primary="color_7"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #7356f1; }
[data-primary="color_7"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #7356f1; }
[data-primary="color_7"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #7356f1; }
[data-primary="color_7"] .is-invalid .input-group-prepend .input-group-text i {
  color: #ae9df7; }
[data-primary="color_7"] .datamaps-hoverover {
  color: #7356f1;
  border: 1px solid rgba(115, 86, 241, 0.3); }
[data-primary="color_7"] .jqvmap-zoomin,
[data-primary="color_7"] .jqvmap-zoomout {
  background-color: #7356f1; }
[data-primary="color_7"] .table .thead-primary th {
  background-color: #7356f1; }
[data-primary="color_7"] .table.primary-table-bg-hover thead th {
  background-color: #5f3eef; }
[data-primary="color_7"] .table.primary-table-bg-hover tbody tr {
  background-color: #7356f1; }
  [data-primary="color_7"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #876ef3; }
  [data-primary="color_7"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_7"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #5f3eef; }
[data-primary="color_7"] table.dataTable tr.selected {
  color: #7356f1; }
[data-primary="color_7"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #7356f1 !important;
  background: rgba(115, 86, 241, 0.1); }
[data-primary="color_7"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #7356f1 !important;
  background: rgba(115, 86, 241, 0.1); }
[data-primary="color_7"] .clipboard-btn:hover {
  background-color: #7356f1; }
[data-primary="color_7"] .cd-h-timeline__dates::before {
  background: #7356f1; }
[data-primary="color_7"] .cd-h-timeline__dates::after {
  background: #7356f1; }
[data-primary="color_7"] .cd-h-timeline__line {
  background-color: #7356f1; }
[data-primary="color_7"] .cd-h-timeline__date:after {
  border-color: #775af1;
  background-color: #7356f1; }
[data-primary="color_7"] .cd-h-timeline__navigation {
  border-color: #775af1; }
[data-primary="color_7"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #775af1; }
[data-primary="color_7"] .dd-handle {
  background: #7356f1; }
[data-primary="color_7"] .dd-handle:hover {
  background: #7356f1; }
[data-primary="color_7"] .dd3-content:hover {
  background: #7356f1; }
[data-primary="color_7"] .noUi-connect {
  background-color: #7356f1; }
  [data-primary="color_7"] .noUi-connect.c-3-color {
    background-color: #7356f1; }
[data-primary="color_7"] .noUi-horizontal .noUi-handle, [data-primary="color_7"] .noUi-vertical .noUi-handle {
  background-color: #7356f1; }
[data-primary="color_7"] #slider-toggle.off .noUi-handle {
  border-color: #7356f1; }
[data-primary="color_7"] .pignose-calendar {
  border-color: #7356f1; }
  [data-primary="color_7"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #7356f1; }
[data-primary="color_7"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #7356f1; }
[data-primary="color_7"] .bootstrap-tagsinput .tag {
  background-color: #7356f1; }
[data-primary="color_7"] .toast-success {
  background-color: #7356f1; }
[data-primary="color_7"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #7356f1; }
[data-primary="color_7"] .accordion-header-bg .accordion__header--primary {
  background-color: #7356f1; }
[data-primary="color_7"] .alert-primary {
  background: #d5ccfb;
  border-color: #d5ccfb;
  color: #7356f1; }
[data-primary="color_7"] .alert-alt.alert-primary {
  border-left: 4px solid #7356f1; }
[data-primary="color_7"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #2f0fb8 !important; }
[data-primary="color_7"] .alert.alert-primary.solid {
  background: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"] .alert.alert-outline-primary {
  color: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"] .badge-outline-primary {
  border: 1px solid #7356f1;
  color: #7356f1; }
[data-primary="color_7"] .badge-primary {
  background-color: #7356f1; }
[data-primary="color_7"] .page-titles h4 {
  color: #7356f1; }
[data-primary="color_7"] .card-action > a {
  background: #090322; }
[data-primary="color_7"] .card-action .dropdown {
  background: #090322;
  color: #7356f1; }
  [data-primary="color_7"] .card-action .dropdown:hover, [data-primary="color_7"] .card-action .dropdown:focus {
    background: #090322; }
[data-primary="color_7"] .card-loader i {
  background: #5835ee; }
[data-primary="color_7"] .dropdown-outline {
  border: 0.1rem solid #7356f1; }
[data-primary="color_7"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #7356f1; }
[data-primary="color_7"] .card-action .custom-dropdown {
  background: #e8e3fd; }
  [data-primary="color_7"] .card-action .custom-dropdown.show, [data-primary="color_7"] .card-action .custom-dropdown:focus, [data-primary="color_7"] .card-action .custom-dropdown:hover {
    background: #7356f1; }
[data-primary="color_7"] .label-primary {
  background: #7356f1; }
[data-primary="color_7"] .pagination .page-item .page-link:hover {
  background: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"] .pagination .page-item.active .page-link {
  background-color: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_7"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #7356f1; }
[data-primary="color_7"] .progress-bar {
  background-color: #7356f1; }
[data-primary="color_7"] .progress-bar-primary {
  background-color: #7356f1; }
[data-primary="color_7"] .ribbon__four {
  background-color: #7356f1; }
  [data-primary="color_7"] .ribbon__four:after, [data-primary="color_7"] .ribbon__four:before {
    background-color: #c1b4f9; }
[data-primary="color_7"] .ribbon__five {
  background-color: #7356f1; }
  [data-primary="color_7"] .ribbon__five::before {
    border-color: transparent transparent #7356f1 transparent; }
[data-primary="color_7"] .ribbon__six {
  background-color: #7356f1; }
[data-primary="color_7"] .multi-steps > li {
  color: #7356f1; }
  [data-primary="color_7"] .multi-steps > li:after {
    background-color: #7356f1; }
  [data-primary="color_7"] .multi-steps > li.is-active:before {
    border-color: #7356f1; }
[data-primary="color_7"] .timeline-badge.primary {
  background-color: #7356f1 !important; }
[data-primary="color_7"] .tooltip-wrapper button:hover {
  background: #7356f1; }
[data-primary="color_7"] .chart_widget_tab_one .nav-link.active {
  background-color: #7356f1;
  border: 1px solid #7356f1; }
  [data-primary="color_7"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #7356f1; }
[data-primary="color_7"] .social-icon2 a {
  border: 0.1rem solid #7356f1; }
[data-primary="color_7"] .social-icon2 i {
  color: #7356f1; }
[data-primary="color_7"] .social-icon3 ul li a:hover i {
  color: #7356f1; }
[data-primary="color_7"] .bgl-primary {
  background: #e8e3fd;
  border-color: #e8e3fd;
  color: #7356f1; }
[data-primary="color_7"] .tdl-holder input[type=checkbox]:checked + i {
  background: #7356f1; }
[data-primary="color_7"] .footer .copyright a {
  color: #7356f1; }
[data-primary="color_7"] .hamburger .line {
  background: #7356f1; }
[data-primary="color_7"] svg.pulse-svg .first-circle, [data-primary="color_7"] svg.pulse-svg .second-circle, [data-primary="color_7"] svg.pulse-svg .third-circle {
  fill: #7356f1; }
[data-primary="color_7"] .pulse-css {
  background: #7356f1; }
  [data-primary="color_7"] .pulse-css:after, [data-primary="color_7"] .pulse-css:before {
    background-color: #7356f1; }
[data-primary="color_7"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #7356f1; }
[data-primary="color_7"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_7"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_7"] .header-right .header-profile .dropdown-menu a.active {
  color: #7356f1; }
[data-primary="color_7"] .header-right .header-profile .profile_title {
  background: #7356f1; }
[data-primary="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #7356f1 !important; }
[data-primary="color_7"] .deznav .metismenu > li > a svg {
  color: #7356f1; }
[data-primary="color_7"] .deznav .metismenu > li:hover > a, [data-primary="color_7"] .deznav .metismenu > li:focus > a {
  color: #7356f1; }
[data-primary="color_7"] .deznav .metismenu > li.mm-active > a {
  color: #7356f1; }
[data-primary="color_7"] .deznav .metismenu ul a:hover, [data-primary="color_7"] .deznav .metismenu ul a:focus, [data-primary="color_7"] .deznav .metismenu ul a.mm-active {
  color: #7356f1; }
@media (min-width: 767px) {
  [data-primary="color_7"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_7"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_7"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_7"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: white; } }
[data-primary="color_7"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #7356f1; }
[data-primary="color_7"] .nav-user {
  background: #7356f1; }
[data-primary="color_7"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #7356f1; }
  [data-primary="color_7"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #7356f1; }
[data-primary="color_7"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"][data-theme-version="dark"] .header-left input:focus {
  border-color: #7356f1; }
[data-primary="color_7"][data-theme-version="dark"] .loader__bar {
  background: #7356f1; }
[data-primary="color_7"][data-theme-version="dark"] .loader__ball {
  background: #7356f1; }
[data-primary="color_7"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #7356f1; }
[data-primary="color_7"] .new-arrival-content .price {
  color: #7356f1; }
[data-primary="color_7"] .chart-link a i.text-primary {
  color: #7356f1; }
[data-primary="color_7"] #user-activity .nav-tabs .nav-link.active {
  background: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"] span#counter {
  color: #7356f1; }
[data-primary="color_7"] .welcome-content:after {
  background: #7356f1; }
[data-primary="color_7"] .timeline-badge {
  background-color: #7356f1; }
[data-primary="color_7"] .page-timeline .timeline-workplan[data-primary="color_7"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(115, 86, 241, 0.4); }
[data-primary="color_7"] .sk-three-bounce .sk-child {
  background-color: #7356f1; }
[data-primary="color_7"] .dropdown-item.active,
[data-primary="color_7"] .dropdown-item:active {
  color: #fff;
  background-color: #7356f1; }
[data-primary="color_7"] .overlay-box:after {
  background: #7356f1; }
[data-primary="color_7"] .btn-primary {
  background-color: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"] .bg-primary {
  background-color: #7356f1 !important; }
[data-primary="color_7"] .text-primary {
  color: #7356f1 !important; }
[data-primary="color_7"] .btn-primary:hover {
  background-color: #3b13e7;
  border-color: #3b13e7; }
[data-primary="color_7"] .btn-outline-primary {
  color: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"] .btn-outline-primary:hover {
  background-color: #7356f1;
  border-color: #7356f1; }
[data-primary="color_7"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_7"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_7"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_7"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_7"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_7"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #3b13e7;
  border-color: #3b13e7; }

[data-primary="color_8"] a:hover, [data-primary="color_8"] a:focus, [data-primary="color_8"] a.active {
  color: #3695eb; }
[data-primary="color_8"] [data-class="bg-primary"]:before {
  background: #3695eb; }
[data-primary="color_8"] .email-left-box .intro-title {
  background: rgba(54, 149, 235, 0.1); }
  [data-primary="color_8"] .email-left-box .intro-title i {
    color: #3695eb; }
[data-primary="color_8"] .widget-stat .media .media-body h4 {
  color: #3695eb !important; }
[data-primary="color_8"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(54, 149, 235, 0.1); }
[data-primary="color_8"] .mail-list .list-group-item.active i {
  color: #3695eb; }
[data-primary="color_8"] .single-mail.active {
  background: #3695eb; }
[data-primary="color_8"] .profile-info h4.text-primary {
  color: #3695eb !important; }
[data-primary="color_8"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_8"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #3695eb;
  color: #3695eb; }
[data-primary="color_8"] .amChartsInputField {
  border: 0;
  background: #3695eb; }
[data-primary="color_8"] .amcharts-period-input,
[data-primary="color_8"] .amcharts-period-input-selected {
  background: #3695eb; }
[data-primary="color_8"] .morris-hover {
  background: #3695eb; }
[data-primary="color_8"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #3695eb; }
[data-primary="color_8"] .custom-select:focus {
  border-color: #3695eb;
  color: #3695eb; }
[data-primary="color_8"] .daterangepicker td.active {
  background-color: #3695eb; }
  [data-primary="color_8"] .daterangepicker td.active:hover {
    background-color: #3695eb; }
[data-primary="color_8"] .daterangepicker button.applyBtn {
  background-color: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"] .wizard > .steps li.current a {
  background-color: #3695eb; }
[data-primary="color_8"] .wizard .skip-email a {
  color: #3695eb; }
[data-primary="color_8"] .wizard > .actions li:not(.disabled) a {
  background-color: #3695eb; }
[data-primary="color_8"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #3695eb; }
[data-primary="color_8"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #3695eb; }
[data-primary="color_8"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #3695eb; }
[data-primary="color_8"] .is-invalid .input-group-prepend .input-group-text i {
  color: #7cbaf2; }
[data-primary="color_8"] .datamaps-hoverover {
  color: #3695eb;
  border: 1px solid rgba(54, 149, 235, 0.3); }
[data-primary="color_8"] .jqvmap-zoomin,
[data-primary="color_8"] .jqvmap-zoomout {
  background-color: #3695eb; }
[data-primary="color_8"] .table .thead-primary th {
  background-color: #3695eb; }
[data-primary="color_8"] .table.primary-table-bg-hover thead th {
  background-color: #1f89e9; }
[data-primary="color_8"] .table.primary-table-bg-hover tbody tr {
  background-color: #3695eb; }
  [data-primary="color_8"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #4da1ed; }
  [data-primary="color_8"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_8"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #1f89e9; }
[data-primary="color_8"] table.dataTable tr.selected {
  color: #3695eb; }
[data-primary="color_8"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #3695eb !important;
  background: rgba(54, 149, 235, 0.1); }
[data-primary="color_8"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #3695eb !important;
  background: rgba(54, 149, 235, 0.1); }
[data-primary="color_8"] .clipboard-btn:hover {
  background-color: #3695eb; }
[data-primary="color_8"] .cd-h-timeline__dates::before {
  background: #3695eb; }
[data-primary="color_8"] .cd-h-timeline__dates::after {
  background: #3695eb; }
[data-primary="color_8"] .cd-h-timeline__line {
  background-color: #3695eb; }
[data-primary="color_8"] .cd-h-timeline__date:after {
  border-color: #3a97eb;
  background-color: #3695eb; }
[data-primary="color_8"] .cd-h-timeline__navigation {
  border-color: #3a97eb; }
[data-primary="color_8"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #3a97eb; }
[data-primary="color_8"] .dd-handle {
  background: #3695eb; }
[data-primary="color_8"] .dd-handle:hover {
  background: #3695eb; }
[data-primary="color_8"] .dd3-content:hover {
  background: #3695eb; }
[data-primary="color_8"] .noUi-connect {
  background-color: #3695eb; }
  [data-primary="color_8"] .noUi-connect.c-3-color {
    background-color: #3695eb; }
[data-primary="color_8"] .noUi-horizontal .noUi-handle, [data-primary="color_8"] .noUi-vertical .noUi-handle {
  background-color: #3695eb; }
[data-primary="color_8"] #slider-toggle.off .noUi-handle {
  border-color: #3695eb; }
[data-primary="color_8"] .pignose-calendar {
  border-color: #3695eb; }
  [data-primary="color_8"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #3695eb; }
[data-primary="color_8"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #3695eb; }
[data-primary="color_8"] .bootstrap-tagsinput .tag {
  background-color: #3695eb; }
[data-primary="color_8"] .toast-success {
  background-color: #3695eb; }
[data-primary="color_8"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #3695eb; }
[data-primary="color_8"] .accordion-header-bg .accordion__header--primary {
  background-color: #3695eb; }
[data-primary="color_8"] .alert-primary {
  background: #aad2f7;
  border-color: #aad2f7;
  color: #3695eb; }
[data-primary="color_8"] .alert-alt.alert-primary {
  border-left: 4px solid #3695eb; }
[data-primary="color_8"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #0f5493 !important; }
[data-primary="color_8"] .alert.alert-primary.solid {
  background: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"] .alert.alert-outline-primary {
  color: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"] .badge-outline-primary {
  border: 1px solid #3695eb;
  color: #3695eb; }
[data-primary="color_8"] .badge-primary {
  background-color: #3695eb; }
[data-primary="color_8"] .page-titles h4 {
  color: #3695eb; }
[data-primary="color_8"] .card-action > a {
  background: black; }
[data-primary="color_8"] .card-action .dropdown {
  background: black;
  color: #3695eb; }
  [data-primary="color_8"] .card-action .dropdown:hover, [data-primary="color_8"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_8"] .card-loader i {
  background: #1784e6; }
[data-primary="color_8"] .dropdown-outline {
  border: 0.1rem solid #3695eb; }
[data-primary="color_8"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #3695eb; }
[data-primary="color_8"] .card-action .custom-dropdown {
  background: #c1def9; }
  [data-primary="color_8"] .card-action .custom-dropdown.show, [data-primary="color_8"] .card-action .custom-dropdown:focus, [data-primary="color_8"] .card-action .custom-dropdown:hover {
    background: #3695eb; }
[data-primary="color_8"] .label-primary {
  background: #3695eb; }
[data-primary="color_8"] .pagination .page-item .page-link:hover {
  background: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"] .pagination .page-item.active .page-link {
  background-color: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_8"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #3695eb; }
[data-primary="color_8"] .progress-bar {
  background-color: #3695eb; }
[data-primary="color_8"] .progress-bar-primary {
  background-color: #3695eb; }
[data-primary="color_8"] .ribbon__four {
  background-color: #3695eb; }
  [data-primary="color_8"] .ribbon__four:after, [data-primary="color_8"] .ribbon__four:before {
    background-color: #93c6f4; }
[data-primary="color_8"] .ribbon__five {
  background-color: #3695eb; }
  [data-primary="color_8"] .ribbon__five::before {
    border-color: transparent transparent #3695eb transparent; }
[data-primary="color_8"] .ribbon__six {
  background-color: #3695eb; }
[data-primary="color_8"] .multi-steps > li {
  color: #3695eb; }
  [data-primary="color_8"] .multi-steps > li:after {
    background-color: #3695eb; }
  [data-primary="color_8"] .multi-steps > li.is-active:before {
    border-color: #3695eb; }
[data-primary="color_8"] .timeline-badge.primary {
  background-color: #3695eb !important; }
[data-primary="color_8"] .tooltip-wrapper button:hover {
  background: #3695eb; }
[data-primary="color_8"] .chart_widget_tab_one .nav-link.active {
  background-color: #3695eb;
  border: 1px solid #3695eb; }
  [data-primary="color_8"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #3695eb; }
[data-primary="color_8"] .social-icon2 a {
  border: 0.1rem solid #3695eb; }
[data-primary="color_8"] .social-icon2 i {
  color: #3695eb; }
[data-primary="color_8"] .social-icon3 ul li a:hover i {
  color: #3695eb; }
[data-primary="color_8"] .bgl-primary {
  background: #c1def9;
  border-color: #c1def9;
  color: #3695eb; }
[data-primary="color_8"] .tdl-holder input[type=checkbox]:checked + i {
  background: #3695eb; }
[data-primary="color_8"] .footer .copyright a {
  color: #3695eb; }
[data-primary="color_8"] .hamburger .line {
  background: #3695eb; }
[data-primary="color_8"] svg.pulse-svg .first-circle, [data-primary="color_8"] svg.pulse-svg .second-circle, [data-primary="color_8"] svg.pulse-svg .third-circle {
  fill: #3695eb; }
[data-primary="color_8"] .pulse-css {
  background: #3695eb; }
  [data-primary="color_8"] .pulse-css:after, [data-primary="color_8"] .pulse-css:before {
    background-color: #3695eb; }
[data-primary="color_8"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #3695eb; }
[data-primary="color_8"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_8"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_8"] .header-right .header-profile .dropdown-menu a.active {
  color: #3695eb; }
[data-primary="color_8"] .header-right .header-profile .profile_title {
  background: #3695eb; }
[data-primary="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #3695eb !important; }
[data-primary="color_8"] .deznav .metismenu > li > a svg {
  color: #3695eb; }
[data-primary="color_8"] .deznav .metismenu > li:hover > a, [data-primary="color_8"] .deznav .metismenu > li:focus > a {
  color: #3695eb; }
[data-primary="color_8"] .deznav .metismenu > li.mm-active > a {
  color: #3695eb; }
[data-primary="color_8"] .deznav .metismenu ul a:hover, [data-primary="color_8"] .deznav .metismenu ul a:focus, [data-primary="color_8"] .deznav .metismenu ul a.mm-active {
  color: #3695eb; }
@media (min-width: 767px) {
  [data-primary="color_8"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_8"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_8"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_8"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: white; } }
[data-primary="color_8"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #3695eb; }
[data-primary="color_8"] .nav-user {
  background: #3695eb; }
[data-primary="color_8"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #3695eb; }
  [data-primary="color_8"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #3695eb; }
[data-primary="color_8"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"][data-theme-version="dark"] .header-left input:focus {
  border-color: #3695eb; }
[data-primary="color_8"][data-theme-version="dark"] .loader__bar {
  background: #3695eb; }
[data-primary="color_8"][data-theme-version="dark"] .loader__ball {
  background: #3695eb; }
[data-primary="color_8"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #3695eb; }
[data-primary="color_8"] .new-arrival-content .price {
  color: #3695eb; }
[data-primary="color_8"] .chart-link a i.text-primary {
  color: #3695eb; }
[data-primary="color_8"] #user-activity .nav-tabs .nav-link.active {
  background: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"] span#counter {
  color: #3695eb; }
[data-primary="color_8"] .welcome-content:after {
  background: #3695eb; }
[data-primary="color_8"] .timeline-badge {
  background-color: #3695eb; }
[data-primary="color_8"] .page-timeline .timeline-workplan[data-primary="color_8"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(54, 149, 235, 0.4); }
[data-primary="color_8"] .sk-three-bounce .sk-child {
  background-color: #3695eb; }
[data-primary="color_8"] .dropdown-item.active,
[data-primary="color_8"] .dropdown-item:active {
  color: #fff;
  background-color: #3695eb; }
[data-primary="color_8"] .overlay-box:after {
  background: #3695eb; }
[data-primary="color_8"] .btn-primary {
  background-color: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"] .bg-primary {
  background-color: #3695eb !important; }
[data-primary="color_8"] .text-primary {
  color: #3695eb !important; }
[data-primary="color_8"] .btn-primary:hover {
  background-color: #136fc1;
  border-color: #136fc1; }
[data-primary="color_8"] .btn-outline-primary {
  color: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"] .btn-outline-primary:hover {
  background-color: #3695eb;
  border-color: #3695eb; }
[data-primary="color_8"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_8"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_8"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_8"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_8"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_8"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #136fc1;
  border-color: #136fc1; }

[data-primary="color_9"] a:hover, [data-primary="color_9"] a:focus, [data-primary="color_9"] a.active {
  color: #00838f; }
[data-primary="color_9"] [data-class="bg-primary"]:before {
  background: #00838f; }
[data-primary="color_9"] .email-left-box .intro-title {
  background: rgba(0, 131, 143, 0.1); }
  [data-primary="color_9"] .email-left-box .intro-title i {
    color: #00838f; }
[data-primary="color_9"] .widget-stat .media .media-body h4 {
  color: #00838f !important; }
[data-primary="color_9"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(0, 131, 143, 0.1); }
[data-primary="color_9"] .mail-list .list-group-item.active i {
  color: #00838f; }
[data-primary="color_9"] .single-mail.active {
  background: #00838f; }
[data-primary="color_9"] .profile-info h4.text-primary {
  color: #00838f !important; }
[data-primary="color_9"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_9"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #00838f;
  color: #00838f; }
[data-primary="color_9"] .amChartsInputField {
  border: 0;
  background: #00838f; }
[data-primary="color_9"] .amcharts-period-input,
[data-primary="color_9"] .amcharts-period-input-selected {
  background: #00838f; }
[data-primary="color_9"] .morris-hover {
  background: #00838f; }
[data-primary="color_9"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #00838f; }
[data-primary="color_9"] .custom-select:focus {
  border-color: #00838f;
  color: #00838f; }
[data-primary="color_9"] .daterangepicker td.active {
  background-color: #00838f; }
  [data-primary="color_9"] .daterangepicker td.active:hover {
    background-color: #00838f; }
[data-primary="color_9"] .daterangepicker button.applyBtn {
  background-color: #00838f;
  border-color: #00838f; }
[data-primary="color_9"] .wizard > .steps li.current a {
  background-color: #00838f; }
[data-primary="color_9"] .wizard .skip-email a {
  color: #00838f; }
[data-primary="color_9"] .wizard > .actions li:not(.disabled) a {
  background-color: #00838f; }
[data-primary="color_9"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #00838f; }
[data-primary="color_9"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #00838f;
  border-color: #00838f; }
[data-primary="color_9"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #00838f; }
[data-primary="color_9"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #00838f; }
[data-primary="color_9"] .is-invalid .input-group-prepend .input-group-text i {
  color: #00c9dc; }
[data-primary="color_9"] .datamaps-hoverover {
  color: #00838f;
  border: 1px solid rgba(0, 131, 143, 0.3); }
[data-primary="color_9"] .jqvmap-zoomin,
[data-primary="color_9"] .jqvmap-zoomout {
  background-color: #00838f; }
[data-primary="color_9"] .table .thead-primary th {
  background-color: #00838f; }
[data-primary="color_9"] .table.primary-table-bg-hover thead th {
  background-color: #006c76; }
[data-primary="color_9"] .table.primary-table-bg-hover tbody tr {
  background-color: #00838f; }
  [data-primary="color_9"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #009aa9; }
  [data-primary="color_9"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_9"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #006c76; }
[data-primary="color_9"] table.dataTable tr.selected {
  color: #00838f; }
[data-primary="color_9"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #00838f !important;
  background: rgba(0, 131, 143, 0.1); }
[data-primary="color_9"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #00838f !important;
  background: rgba(0, 131, 143, 0.1); }
[data-primary="color_9"] .clipboard-btn:hover {
  background-color: #00838f; }
[data-primary="color_9"] .cd-h-timeline__dates::before {
  background: #00838f; }
[data-primary="color_9"] .cd-h-timeline__dates::after {
  background: #00838f; }
[data-primary="color_9"] .cd-h-timeline__line {
  background-color: #00838f; }
[data-primary="color_9"] .cd-h-timeline__date:after {
  border-color: #008794;
  background-color: #00838f; }
[data-primary="color_9"] .cd-h-timeline__navigation {
  border-color: #008794; }
[data-primary="color_9"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #008794; }
[data-primary="color_9"] .dd-handle {
  background: #00838f; }
[data-primary="color_9"] .dd-handle:hover {
  background: #00838f; }
[data-primary="color_9"] .dd3-content:hover {
  background: #00838f; }
[data-primary="color_9"] .noUi-connect {
  background-color: #00838f; }
  [data-primary="color_9"] .noUi-connect.c-3-color {
    background-color: #00838f; }
[data-primary="color_9"] .noUi-horizontal .noUi-handle, [data-primary="color_9"] .noUi-vertical .noUi-handle {
  background-color: #00838f; }
[data-primary="color_9"] #slider-toggle.off .noUi-handle {
  border-color: #00838f; }
[data-primary="color_9"] .pignose-calendar {
  border-color: #00838f; }
  [data-primary="color_9"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #00838f; }
[data-primary="color_9"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #00838f; }
[data-primary="color_9"] .bootstrap-tagsinput .tag {
  background-color: #00838f; }
[data-primary="color_9"] .toast-success {
  background-color: #00838f; }
[data-primary="color_9"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #00838f; }
[data-primary="color_9"] .accordion-header-bg .accordion__header--primary {
  background-color: #00838f; }
[data-primary="color_9"] .alert-primary {
  background: #10ebff;
  border-color: #10ebff;
  color: #00838f; }
[data-primary="color_9"] .alert-alt.alert-primary {
  border-left: 4px solid #00838f; }
[data-primary="color_9"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #000e10 !important; }
[data-primary="color_9"] .alert.alert-primary.solid {
  background: #00838f;
  border-color: #00838f; }
[data-primary="color_9"] .alert.alert-outline-primary {
  color: #00838f;
  border-color: #00838f; }
[data-primary="color_9"] .badge-outline-primary {
  border: 1px solid #00838f;
  color: #00838f; }
[data-primary="color_9"] .badge-primary {
  background-color: #00838f; }
[data-primary="color_9"] .page-titles h4 {
  color: #00838f; }
[data-primary="color_9"] .card-action > a {
  background: black; }
[data-primary="color_9"] .card-action .dropdown {
  background: black;
  color: #00838f; }
  [data-primary="color_9"] .card-action .dropdown:hover, [data-primary="color_9"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_9"] .card-loader i {
  background: #00626b; }
[data-primary="color_9"] .dropdown-outline {
  border: 0.1rem solid #00838f; }
[data-primary="color_9"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #00838f; }
[data-primary="color_9"] .card-action .custom-dropdown {
  background: #29edff; }
  [data-primary="color_9"] .card-action .custom-dropdown.show, [data-primary="color_9"] .card-action .custom-dropdown:focus, [data-primary="color_9"] .card-action .custom-dropdown:hover {
    background: #00838f; }
[data-primary="color_9"] .label-primary {
  background: #00838f; }
[data-primary="color_9"] .pagination .page-item .page-link:hover {
  background: #00838f;
  border-color: #00838f; }
[data-primary="color_9"] .pagination .page-item.active .page-link {
  background-color: #00838f;
  border-color: #00838f; }
[data-primary="color_9"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_9"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #00838f; }
[data-primary="color_9"] .progress-bar {
  background-color: #00838f; }
[data-primary="color_9"] .progress-bar-primary {
  background-color: #00838f; }
[data-primary="color_9"] .ribbon__four {
  background-color: #00838f; }
  [data-primary="color_9"] .ribbon__four:after, [data-primary="color_9"] .ribbon__four:before {
    background-color: #00e0f5; }
[data-primary="color_9"] .ribbon__five {
  background-color: #00838f; }
  [data-primary="color_9"] .ribbon__five::before {
    border-color: transparent transparent #00838f transparent; }
[data-primary="color_9"] .ribbon__six {
  background-color: #00838f; }
[data-primary="color_9"] .multi-steps > li {
  color: #00838f; }
  [data-primary="color_9"] .multi-steps > li:after {
    background-color: #00838f; }
  [data-primary="color_9"] .multi-steps > li.is-active:before {
    border-color: #00838f; }
[data-primary="color_9"] .timeline-badge.primary {
  background-color: #00838f !important; }
[data-primary="color_9"] .tooltip-wrapper button:hover {
  background: #00838f; }
[data-primary="color_9"] .chart_widget_tab_one .nav-link.active {
  background-color: #00838f;
  border: 1px solid #00838f; }
  [data-primary="color_9"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #00838f; }
[data-primary="color_9"] .social-icon2 a {
  border: 0.1rem solid #00838f; }
[data-primary="color_9"] .social-icon2 i {
  color: #00838f; }
[data-primary="color_9"] .social-icon3 ul li a:hover i {
  color: #00838f; }
[data-primary="color_9"] .bgl-primary {
  background: #29edff;
  border-color: #29edff;
  color: #00838f; }
[data-primary="color_9"] .tdl-holder input[type=checkbox]:checked + i {
  background: #00838f; }
[data-primary="color_9"] .footer .copyright a {
  color: #00838f; }
[data-primary="color_9"] .hamburger .line {
  background: #00838f; }
[data-primary="color_9"] svg.pulse-svg .first-circle, [data-primary="color_9"] svg.pulse-svg .second-circle, [data-primary="color_9"] svg.pulse-svg .third-circle {
  fill: #00838f; }
[data-primary="color_9"] .pulse-css {
  background: #00838f; }
  [data-primary="color_9"] .pulse-css:after, [data-primary="color_9"] .pulse-css:before {
    background-color: #00838f; }
[data-primary="color_9"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #00838f; }
[data-primary="color_9"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_9"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_9"] .header-right .header-profile .dropdown-menu a.active {
  color: #00838f; }
[data-primary="color_9"] .header-right .header-profile .profile_title {
  background: #00838f; }
[data-primary="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #00838f !important; }
[data-primary="color_9"] .deznav .metismenu > li > a svg {
  color: #00838f; }
[data-primary="color_9"] .deznav .metismenu > li:hover > a, [data-primary="color_9"] .deznav .metismenu > li:focus > a {
  color: #00838f; }
[data-primary="color_9"] .deznav .metismenu > li.mm-active > a {
  color: #00838f; }
[data-primary="color_9"] .deznav .metismenu ul a:hover, [data-primary="color_9"] .deznav .metismenu ul a:focus, [data-primary="color_9"] .deznav .metismenu ul a.mm-active {
  color: #00838f; }
@media (min-width: 767px) {
  [data-primary="color_9"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_9"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_9"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_9"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: #8ff6ff; } }
[data-primary="color_9"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #00838f; }
[data-primary="color_9"] .nav-user {
  background: #00838f; }
[data-primary="color_9"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #00838f; }
  [data-primary="color_9"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #00838f; }
[data-primary="color_9"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #00838f;
  border-color: #00838f; }
[data-primary="color_9"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #00838f;
  border-color: #00838f; }
[data-primary="color_9"][data-theme-version="dark"] .header-left input:focus {
  border-color: #00838f; }
[data-primary="color_9"][data-theme-version="dark"] .loader__bar {
  background: #00838f; }
[data-primary="color_9"][data-theme-version="dark"] .loader__ball {
  background: #00838f; }
[data-primary="color_9"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #00838f; }
[data-primary="color_9"] .new-arrival-content .price {
  color: #00838f; }
[data-primary="color_9"] .chart-link a i.text-primary {
  color: #00838f; }
[data-primary="color_9"] #user-activity .nav-tabs .nav-link.active {
  background: #00838f;
  border-color: #00838f; }
[data-primary="color_9"] span#counter {
  color: #00838f; }
[data-primary="color_9"] .welcome-content:after {
  background: #00838f; }
[data-primary="color_9"] .timeline-badge {
  background-color: #00838f; }
[data-primary="color_9"] .page-timeline .timeline-workplan[data-primary="color_9"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(0, 131, 143, 0.4); }
[data-primary="color_9"] .sk-three-bounce .sk-child {
  background-color: #00838f; }
[data-primary="color_9"] .dropdown-item.active,
[data-primary="color_9"] .dropdown-item:active {
  color: #fff;
  background-color: #00838f; }
[data-primary="color_9"] .overlay-box:after {
  background: #00838f; }
[data-primary="color_9"] .btn-primary {
  background-color: #00838f;
  border-color: #00838f; }
[data-primary="color_9"] .bg-primary {
  background-color: #00838f !important; }
[data-primary="color_9"] .text-primary {
  color: #00838f !important; }
[data-primary="color_9"] .btn-primary:hover {
  background-color: #003d43;
  border-color: #003d43; }
[data-primary="color_9"] .btn-outline-primary {
  color: #00838f;
  border-color: #00838f; }
[data-primary="color_9"] .btn-outline-primary:hover {
  background-color: #00838f;
  border-color: #00838f; }
[data-primary="color_9"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_9"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_9"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_9"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_9"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_9"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #003d43;
  border-color: #003d43; }

[data-primary="color_10"] a:hover, [data-primary="color_10"] a:focus, [data-primary="color_10"] a.active {
  color: #ff8f16; }
[data-primary="color_10"] [data-class="bg-primary"]:before {
  background: #ff8f16; }
[data-primary="color_10"] .email-left-box .intro-title {
  background: rgba(255, 143, 22, 0.1); }
  [data-primary="color_10"] .email-left-box .intro-title i {
    color: #ff8f16; }
[data-primary="color_10"] .widget-stat .media .media-body h4 {
  color: #ff8f16 !important; }
[data-primary="color_10"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(255, 143, 22, 0.1); }
[data-primary="color_10"] .mail-list .list-group-item.active i {
  color: #ff8f16; }
[data-primary="color_10"] .single-mail.active {
  background: #ff8f16; }
[data-primary="color_10"] .profile-info h4.text-primary {
  color: #ff8f16 !important; }
[data-primary="color_10"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_10"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #ff8f16;
  color: #ff8f16; }
[data-primary="color_10"] .amChartsInputField {
  border: 0;
  background: #ff8f16; }
[data-primary="color_10"] .amcharts-period-input,
[data-primary="color_10"] .amcharts-period-input-selected {
  background: #ff8f16; }
[data-primary="color_10"] .morris-hover {
  background: #ff8f16; }
[data-primary="color_10"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #ff8f16; }
[data-primary="color_10"] .custom-select:focus {
  border-color: #ff8f16;
  color: #ff8f16; }
[data-primary="color_10"] .daterangepicker td.active {
  background-color: #ff8f16; }
  [data-primary="color_10"] .daterangepicker td.active:hover {
    background-color: #ff8f16; }
[data-primary="color_10"] .daterangepicker button.applyBtn {
  background-color: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"] .wizard > .steps li.current a {
  background-color: #ff8f16; }
[data-primary="color_10"] .wizard .skip-email a {
  color: #ff8f16; }
[data-primary="color_10"] .wizard > .actions li:not(.disabled) a {
  background-color: #ff8f16; }
[data-primary="color_10"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #ff8f16; }
[data-primary="color_10"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #ff8f16; }
[data-primary="color_10"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #ff8f16; }
[data-primary="color_10"] .is-invalid .input-group-prepend .input-group-text i {
  color: #ffb463; }
[data-primary="color_10"] .datamaps-hoverover {
  color: #ff8f16;
  border: 1px solid rgba(255, 143, 22, 0.3); }
[data-primary="color_10"] .jqvmap-zoomin,
[data-primary="color_10"] .jqvmap-zoomout {
  background-color: #ff8f16; }
[data-primary="color_10"] .table .thead-primary th {
  background-color: #ff8f16; }
[data-primary="color_10"] .table.primary-table-bg-hover thead th {
  background-color: #fc8300; }
[data-primary="color_10"] .table.primary-table-bg-hover tbody tr {
  background-color: #ff8f16; }
  [data-primary="color_10"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #ff9b30; }
  [data-primary="color_10"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_10"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #fc8300; }
[data-primary="color_10"] table.dataTable tr.selected {
  color: #ff8f16; }
[data-primary="color_10"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #ff8f16 !important;
  background: rgba(255, 143, 22, 0.1); }
[data-primary="color_10"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #ff8f16 !important;
  background: rgba(255, 143, 22, 0.1); }
[data-primary="color_10"] .clipboard-btn:hover {
  background-color: #ff8f16; }
[data-primary="color_10"] .cd-h-timeline__dates::before {
  background: #ff8f16; }
[data-primary="color_10"] .cd-h-timeline__dates::after {
  background: #ff8f16; }
[data-primary="color_10"] .cd-h-timeline__line {
  background-color: #ff8f16; }
[data-primary="color_10"] .cd-h-timeline__date:after {
  border-color: #ff911b;
  background-color: #ff8f16; }
[data-primary="color_10"] .cd-h-timeline__navigation {
  border-color: #ff911b; }
[data-primary="color_10"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #ff911b; }
[data-primary="color_10"] .dd-handle {
  background: #ff8f16; }
[data-primary="color_10"] .dd-handle:hover {
  background: #ff8f16; }
[data-primary="color_10"] .dd3-content:hover {
  background: #ff8f16; }
[data-primary="color_10"] .noUi-connect {
  background-color: #ff8f16; }
  [data-primary="color_10"] .noUi-connect.c-3-color {
    background-color: #ff8f16; }
[data-primary="color_10"] .noUi-horizontal .noUi-handle, [data-primary="color_10"] .noUi-vertical .noUi-handle {
  background-color: #ff8f16; }
[data-primary="color_10"] #slider-toggle.off .noUi-handle {
  border-color: #ff8f16; }
[data-primary="color_10"] .pignose-calendar {
  border-color: #ff8f16; }
  [data-primary="color_10"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #ff8f16; }
[data-primary="color_10"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #ff8f16; }
[data-primary="color_10"] .bootstrap-tagsinput .tag {
  background-color: #ff8f16; }
[data-primary="color_10"] .toast-success {
  background-color: #ff8f16; }
[data-primary="color_10"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #ff8f16; }
[data-primary="color_10"] .accordion-header-bg .accordion__header--primary {
  background-color: #ff8f16; }
[data-primary="color_10"] .alert-primary {
  background: #ffcc96;
  border-color: #ffcc96;
  color: #ff8f16; }
[data-primary="color_10"] .alert-alt.alert-primary {
  border-left: 4px solid #ff8f16; }
[data-primary="color_10"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #964e00 !important; }
[data-primary="color_10"] .alert.alert-primary.solid {
  background: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"] .alert.alert-outline-primary {
  color: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"] .badge-outline-primary {
  border: 1px solid #ff8f16;
  color: #ff8f16; }
[data-primary="color_10"] .badge-primary {
  background-color: #ff8f16; }
[data-primary="color_10"] .page-titles h4 {
  color: #ff8f16; }
[data-primary="color_10"] .card-action > a {
  background: black; }
[data-primary="color_10"] .card-action .dropdown {
  background: black;
  color: #ff8f16; }
  [data-primary="color_10"] .card-action .dropdown:hover, [data-primary="color_10"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_10"] .card-loader i {
  background: #f17d00; }
[data-primary="color_10"] .dropdown-outline {
  border: 0.1rem solid #ff8f16; }
[data-primary="color_10"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #ff8f16; }
[data-primary="color_10"] .card-action .custom-dropdown {
  background: #ffd9af; }
  [data-primary="color_10"] .card-action .custom-dropdown.show, [data-primary="color_10"] .card-action .custom-dropdown:focus, [data-primary="color_10"] .card-action .custom-dropdown:hover {
    background: #ff8f16; }
[data-primary="color_10"] .label-primary {
  background: #ff8f16; }
[data-primary="color_10"] .pagination .page-item .page-link:hover {
  background: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"] .pagination .page-item.active .page-link {
  background-color: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_10"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #ff8f16; }
[data-primary="color_10"] .progress-bar {
  background-color: #ff8f16; }
[data-primary="color_10"] .progress-bar-primary {
  background-color: #ff8f16; }
[data-primary="color_10"] .ribbon__four {
  background-color: #ff8f16; }
  [data-primary="color_10"] .ribbon__four:after, [data-primary="color_10"] .ribbon__four:before {
    background-color: #ffc07c; }
[data-primary="color_10"] .ribbon__five {
  background-color: #ff8f16; }
  [data-primary="color_10"] .ribbon__five::before {
    border-color: transparent transparent #ff8f16 transparent; }
[data-primary="color_10"] .ribbon__six {
  background-color: #ff8f16; }
[data-primary="color_10"] .multi-steps > li {
  color: #ff8f16; }
  [data-primary="color_10"] .multi-steps > li:after {
    background-color: #ff8f16; }
  [data-primary="color_10"] .multi-steps > li.is-active:before {
    border-color: #ff8f16; }
[data-primary="color_10"] .timeline-badge.primary {
  background-color: #ff8f16 !important; }
[data-primary="color_10"] .tooltip-wrapper button:hover {
  background: #ff8f16; }
[data-primary="color_10"] .chart_widget_tab_one .nav-link.active {
  background-color: #ff8f16;
  border: 1px solid #ff8f16; }
  [data-primary="color_10"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #ff8f16; }
[data-primary="color_10"] .social-icon2 a {
  border: 0.1rem solid #ff8f16; }
[data-primary="color_10"] .social-icon2 i {
  color: #ff8f16; }
[data-primary="color_10"] .social-icon3 ul li a:hover i {
  color: #ff8f16; }
[data-primary="color_10"] .bgl-primary {
  background: #ffd9af;
  border-color: #ffd9af;
  color: #ff8f16; }
[data-primary="color_10"] .tdl-holder input[type=checkbox]:checked + i {
  background: #ff8f16; }
[data-primary="color_10"] .footer .copyright a {
  color: #ff8f16; }
[data-primary="color_10"] .hamburger .line {
  background: #ff8f16; }
[data-primary="color_10"] svg.pulse-svg .first-circle, [data-primary="color_10"] svg.pulse-svg .second-circle, [data-primary="color_10"] svg.pulse-svg .third-circle {
  fill: #ff8f16; }
[data-primary="color_10"] .pulse-css {
  background: #ff8f16; }
  [data-primary="color_10"] .pulse-css:after, [data-primary="color_10"] .pulse-css:before {
    background-color: #ff8f16; }
[data-primary="color_10"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #ff8f16; }
[data-primary="color_10"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_10"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_10"] .header-right .header-profile .dropdown-menu a.active {
  color: #ff8f16; }
[data-primary="color_10"] .header-right .header-profile .profile_title {
  background: #ff8f16; }
[data-primary="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #ff8f16 !important; }
[data-primary="color_10"] .deznav .metismenu > li > a svg {
  color: #ff8f16; }
[data-primary="color_10"] .deznav .metismenu > li:hover > a, [data-primary="color_10"] .deznav .metismenu > li:focus > a {
  color: #ff8f16; }
[data-primary="color_10"] .deznav .metismenu > li.mm-active > a {
  color: #ff8f16; }
[data-primary="color_10"] .deznav .metismenu ul a:hover, [data-primary="color_10"] .deznav .metismenu ul a:focus, [data-primary="color_10"] .deznav .metismenu ul a.mm-active {
  color: #ff8f16; }
@media (min-width: 767px) {
  [data-primary="color_10"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_10"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_10"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_10"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: white; } }
[data-primary="color_10"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #ff8f16; }
[data-primary="color_10"] .nav-user {
  background: #ff8f16; }
[data-primary="color_10"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #ff8f16; }
  [data-primary="color_10"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #ff8f16; }
[data-primary="color_10"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"][data-theme-version="dark"] .header-left input:focus {
  border-color: #ff8f16; }
[data-primary="color_10"][data-theme-version="dark"] .loader__bar {
  background: #ff8f16; }
[data-primary="color_10"][data-theme-version="dark"] .loader__ball {
  background: #ff8f16; }
[data-primary="color_10"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #ff8f16; }
[data-primary="color_10"] .new-arrival-content .price {
  color: #ff8f16; }
[data-primary="color_10"] .chart-link a i.text-primary {
  color: #ff8f16; }
[data-primary="color_10"] #user-activity .nav-tabs .nav-link.active {
  background: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"] span#counter {
  color: #ff8f16; }
[data-primary="color_10"] .welcome-content:after {
  background: #ff8f16; }
[data-primary="color_10"] .timeline-badge {
  background-color: #ff8f16; }
[data-primary="color_10"] .page-timeline .timeline-workplan[data-primary="color_10"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(255, 143, 22, 0.4); }
[data-primary="color_10"] .sk-three-bounce .sk-child {
  background-color: #ff8f16; }
[data-primary="color_10"] .dropdown-item.active,
[data-primary="color_10"] .dropdown-item:active {
  color: #fff;
  background-color: #ff8f16; }
[data-primary="color_10"] .overlay-box:after {
  background: #ff8f16; }
[data-primary="color_10"] .btn-primary {
  background-color: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"] .bg-primary {
  background-color: #ff8f16 !important; }
[data-primary="color_10"] .text-primary {
  color: #ff8f16 !important; }
[data-primary="color_10"] .btn-primary:hover {
  background-color: #c96800;
  border-color: #c96800; }
[data-primary="color_10"] .btn-outline-primary {
  color: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"] .btn-outline-primary:hover {
  background-color: #ff8f16;
  border-color: #ff8f16; }
[data-primary="color_10"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_10"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_10"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_10"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_10"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_10"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #c96800;
  border-color: #c96800; }

[data-primary="color_11"] a:hover, [data-primary="color_11"] a:focus, [data-primary="color_11"] a.active {
  color: #6673fd; }
[data-primary="color_11"] [data-class="bg-primary"]:before {
  background: #6673fd; }
[data-primary="color_11"] .email-left-box .intro-title {
  background: rgba(102, 115, 253, 0.1); }
  [data-primary="color_11"] .email-left-box .intro-title i {
    color: #6673fd; }
[data-primary="color_11"] .widget-stat .media .media-body h4 {
  color: #6673fd !important; }
[data-primary="color_11"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(102, 115, 253, 0.1); }
[data-primary="color_11"] .mail-list .list-group-item.active i {
  color: #6673fd; }
[data-primary="color_11"] .single-mail.active {
  background: #6673fd; }
[data-primary="color_11"] .profile-info h4.text-primary {
  color: #6673fd !important; }
[data-primary="color_11"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_11"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #6673fd;
  color: #6673fd; }
[data-primary="color_11"] .amChartsInputField {
  border: 0;
  background: #6673fd; }
[data-primary="color_11"] .amcharts-period-input,
[data-primary="color_11"] .amcharts-period-input-selected {
  background: #6673fd; }
[data-primary="color_11"] .morris-hover {
  background: #6673fd; }
[data-primary="color_11"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #6673fd; }
[data-primary="color_11"] .custom-select:focus {
  border-color: #6673fd;
  color: #6673fd; }
[data-primary="color_11"] .daterangepicker td.active {
  background-color: #6673fd; }
  [data-primary="color_11"] .daterangepicker td.active:hover {
    background-color: #6673fd; }
[data-primary="color_11"] .daterangepicker button.applyBtn {
  background-color: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"] .wizard > .steps li.current a {
  background-color: #6673fd; }
[data-primary="color_11"] .wizard .skip-email a {
  color: #6673fd; }
[data-primary="color_11"] .wizard > .actions li:not(.disabled) a {
  background-color: #6673fd; }
[data-primary="color_11"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #6673fd; }
[data-primary="color_11"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #6673fd; }
[data-primary="color_11"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #6673fd; }
[data-primary="color_11"] .is-invalid .input-group-prepend .input-group-text i {
  color: #b2b8fe; }
[data-primary="color_11"] .datamaps-hoverover {
  color: #6673fd;
  border: 1px solid rgba(102, 115, 253, 0.3); }
[data-primary="color_11"] .jqvmap-zoomin,
[data-primary="color_11"] .jqvmap-zoomout {
  background-color: #6673fd; }
[data-primary="color_11"] .table .thead-primary th {
  background-color: #6673fd; }
[data-primary="color_11"] .table.primary-table-bg-hover thead th {
  background-color: #4d5cfd; }
[data-primary="color_11"] .table.primary-table-bg-hover tbody tr {
  background-color: #6673fd; }
  [data-primary="color_11"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #7f8afd; }
  [data-primary="color_11"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_11"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #4d5cfd; }
[data-primary="color_11"] table.dataTable tr.selected {
  color: #6673fd; }
[data-primary="color_11"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #6673fd !important;
  background: rgba(102, 115, 253, 0.1); }
[data-primary="color_11"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #6673fd !important;
  background: rgba(102, 115, 253, 0.1); }
[data-primary="color_11"] .clipboard-btn:hover {
  background-color: #6673fd; }
[data-primary="color_11"] .cd-h-timeline__dates::before {
  background: #6673fd; }
[data-primary="color_11"] .cd-h-timeline__dates::after {
  background: #6673fd; }
[data-primary="color_11"] .cd-h-timeline__line {
  background-color: #6673fd; }
[data-primary="color_11"] .cd-h-timeline__date:after {
  border-color: #6b77fd;
  background-color: #6673fd; }
[data-primary="color_11"] .cd-h-timeline__navigation {
  border-color: #6b77fd; }
[data-primary="color_11"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #6b77fd; }
[data-primary="color_11"] .dd-handle {
  background: #6673fd; }
[data-primary="color_11"] .dd-handle:hover {
  background: #6673fd; }
[data-primary="color_11"] .dd3-content:hover {
  background: #6673fd; }
[data-primary="color_11"] .noUi-connect {
  background-color: #6673fd; }
  [data-primary="color_11"] .noUi-connect.c-3-color {
    background-color: #6673fd; }
[data-primary="color_11"] .noUi-horizontal .noUi-handle, [data-primary="color_11"] .noUi-vertical .noUi-handle {
  background-color: #6673fd; }
[data-primary="color_11"] #slider-toggle.off .noUi-handle {
  border-color: #6673fd; }
[data-primary="color_11"] .pignose-calendar {
  border-color: #6673fd; }
  [data-primary="color_11"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #6673fd; }
[data-primary="color_11"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #6673fd; }
[data-primary="color_11"] .bootstrap-tagsinput .tag {
  background-color: #6673fd; }
[data-primary="color_11"] .toast-success {
  background-color: #6673fd; }
[data-primary="color_11"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #6673fd; }
[data-primary="color_11"] .accordion-header-bg .accordion__header--primary {
  background-color: #6673fd; }
[data-primary="color_11"] .alert-primary {
  background: #e4e6ff;
  border-color: #e4e6ff;
  color: #6673fd; }
[data-primary="color_11"] .alert-alt.alert-primary {
  border-left: 4px solid #6673fd; }
[data-primary="color_11"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #0316e1 !important; }
[data-primary="color_11"] .alert.alert-primary.solid {
  background: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"] .alert.alert-outline-primary {
  color: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"] .badge-outline-primary {
  border: 1px solid #6673fd;
  color: #6673fd; }
[data-primary="color_11"] .badge-primary {
  background-color: #6673fd; }
[data-primary="color_11"] .page-titles h4 {
  color: #6673fd; }
[data-primary="color_11"] .card-action > a {
  background: #01063f; }
[data-primary="color_11"] .card-action .dropdown {
  background: #01063f;
  color: #6673fd; }
  [data-primary="color_11"] .card-action .dropdown:hover, [data-primary="color_11"] .card-action .dropdown:focus {
    background: #01063f; }
[data-primary="color_11"] .card-loader i {
  background: #4353fd; }
[data-primary="color_11"] .dropdown-outline {
  border: 0.1rem solid #6673fd; }
[data-primary="color_11"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #6673fd; }
[data-primary="color_11"] .card-action .custom-dropdown {
  background: #fdfdff; }
  [data-primary="color_11"] .card-action .custom-dropdown.show, [data-primary="color_11"] .card-action .custom-dropdown:focus, [data-primary="color_11"] .card-action .custom-dropdown:hover {
    background: #6673fd; }
[data-primary="color_11"] .label-primary {
  background: #6673fd; }
[data-primary="color_11"] .pagination .page-item .page-link:hover {
  background: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"] .pagination .page-item.active .page-link {
  background-color: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_11"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #6673fd; }
[data-primary="color_11"] .progress-bar {
  background-color: #6673fd; }
[data-primary="color_11"] .progress-bar-primary {
  background-color: #6673fd; }
[data-primary="color_11"] .ribbon__four {
  background-color: #6673fd; }
  [data-primary="color_11"] .ribbon__four:after, [data-primary="color_11"] .ribbon__four:before {
    background-color: #cbcffe; }
[data-primary="color_11"] .ribbon__five {
  background-color: #6673fd; }
  [data-primary="color_11"] .ribbon__five::before {
    border-color: transparent transparent #6673fd transparent; }
[data-primary="color_11"] .ribbon__six {
  background-color: #6673fd; }
[data-primary="color_11"] .multi-steps > li {
  color: #6673fd; }
  [data-primary="color_11"] .multi-steps > li:after {
    background-color: #6673fd; }
  [data-primary="color_11"] .multi-steps > li.is-active:before {
    border-color: #6673fd; }
[data-primary="color_11"] .timeline-badge.primary {
  background-color: #6673fd !important; }
[data-primary="color_11"] .tooltip-wrapper button:hover {
  background: #6673fd; }
[data-primary="color_11"] .chart_widget_tab_one .nav-link.active {
  background-color: #6673fd;
  border: 1px solid #6673fd; }
  [data-primary="color_11"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #6673fd; }
[data-primary="color_11"] .social-icon2 a {
  border: 0.1rem solid #6673fd; }
[data-primary="color_11"] .social-icon2 i {
  color: #6673fd; }
[data-primary="color_11"] .social-icon3 ul li a:hover i {
  color: #6673fd; }
[data-primary="color_11"] .bgl-primary {
  background: #fdfdff;
  border-color: #fdfdff;
  color: #6673fd; }
[data-primary="color_11"] .tdl-holder input[type=checkbox]:checked + i {
  background: #6673fd; }
[data-primary="color_11"] .footer .copyright a {
  color: #6673fd; }
[data-primary="color_11"] .hamburger .line {
  background: #6673fd; }
[data-primary="color_11"] svg.pulse-svg .first-circle, [data-primary="color_11"] svg.pulse-svg .second-circle, [data-primary="color_11"] svg.pulse-svg .third-circle {
  fill: #6673fd; }
[data-primary="color_11"] .pulse-css {
  background: #6673fd; }
  [data-primary="color_11"] .pulse-css:after, [data-primary="color_11"] .pulse-css:before {
    background-color: #6673fd; }
[data-primary="color_11"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #6673fd; }
[data-primary="color_11"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_11"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_11"] .header-right .header-profile .dropdown-menu a.active {
  color: #6673fd; }
[data-primary="color_11"] .header-right .header-profile .profile_title {
  background: #6673fd; }
[data-primary="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #6673fd !important; }
[data-primary="color_11"] .deznav .metismenu > li > a svg {
  color: #6673fd; }
[data-primary="color_11"] .deznav .metismenu > li:hover > a, [data-primary="color_11"] .deznav .metismenu > li:focus > a {
  color: #6673fd; }
[data-primary="color_11"] .deznav .metismenu > li.mm-active > a {
  color: #6673fd; }
[data-primary="color_11"] .deznav .metismenu ul a:hover, [data-primary="color_11"] .deznav .metismenu ul a:focus, [data-primary="color_11"] .deznav .metismenu ul a.mm-active {
  color: #6673fd; }
@media (min-width: 767px) {
  [data-primary="color_11"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_11"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_11"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_11"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: white; } }
[data-primary="color_11"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #6673fd; }
[data-primary="color_11"] .nav-user {
  background: #6673fd; }
[data-primary="color_11"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #6673fd; }
  [data-primary="color_11"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #6673fd; }
[data-primary="color_11"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"][data-theme-version="dark"] .header-left input:focus {
  border-color: #6673fd; }
[data-primary="color_11"][data-theme-version="dark"] .loader__bar {
  background: #6673fd; }
[data-primary="color_11"][data-theme-version="dark"] .loader__ball {
  background: #6673fd; }
[data-primary="color_11"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #6673fd; }
[data-primary="color_11"] .new-arrival-content .price {
  color: #6673fd; }
[data-primary="color_11"] .chart-link a i.text-primary {
  color: #6673fd; }
[data-primary="color_11"] #user-activity .nav-tabs .nav-link.active {
  background: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"] span#counter {
  color: #6673fd; }
[data-primary="color_11"] .welcome-content:after {
  background: #6673fd; }
[data-primary="color_11"] .timeline-badge {
  background-color: #6673fd; }
[data-primary="color_11"] .page-timeline .timeline-workplan[data-primary="color_11"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(102, 115, 253, 0.4); }
[data-primary="color_11"] .sk-three-bounce .sk-child {
  background-color: #6673fd; }
[data-primary="color_11"] .dropdown-item.active,
[data-primary="color_11"] .dropdown-item:active {
  color: #fff;
  background-color: #6673fd; }
[data-primary="color_11"] .overlay-box:after {
  background: #6673fd; }
[data-primary="color_11"] .btn-primary {
  background-color: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"] .bg-primary {
  background-color: #6673fd !important; }
[data-primary="color_11"] .text-primary {
  color: #6673fd !important; }
[data-primary="color_11"] .btn-primary:hover {
  background-color: #1a2efc;
  border-color: #1a2efc; }
[data-primary="color_11"] .btn-outline-primary {
  color: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"] .btn-outline-primary:hover {
  background-color: #6673fd;
  border-color: #6673fd; }
[data-primary="color_11"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_11"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_11"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_11"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_11"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_11"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #1a2efc;
  border-color: #1a2efc; }

[data-primary="color_12"] a:hover, [data-primary="color_12"] a:focus, [data-primary="color_12"] a.active {
  color: #558b2f; }
[data-primary="color_12"] [data-class="bg-primary"]:before {
  background: #558b2f; }
[data-primary="color_12"] .email-left-box .intro-title {
  background: rgba(85, 139, 47, 0.1); }
  [data-primary="color_12"] .email-left-box .intro-title i {
    color: #558b2f; }
[data-primary="color_12"] .widget-stat .media .media-body h4 {
  color: #558b2f !important; }
[data-primary="color_12"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(85, 139, 47, 0.1); }
[data-primary="color_12"] .mail-list .list-group-item.active i {
  color: #558b2f; }
[data-primary="color_12"] .single-mail.active {
  background: #558b2f; }
[data-primary="color_12"] .profile-info h4.text-primary {
  color: #558b2f !important; }
[data-primary="color_12"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_12"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #558b2f;
  color: #558b2f; }
[data-primary="color_12"] .amChartsInputField {
  border: 0;
  background: #558b2f; }
[data-primary="color_12"] .amcharts-period-input,
[data-primary="color_12"] .amcharts-period-input-selected {
  background: #558b2f; }
[data-primary="color_12"] .morris-hover {
  background: #558b2f; }
[data-primary="color_12"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #558b2f; }
[data-primary="color_12"] .custom-select:focus {
  border-color: #558b2f;
  color: #558b2f; }
[data-primary="color_12"] .daterangepicker td.active {
  background-color: #558b2f; }
  [data-primary="color_12"] .daterangepicker td.active:hover {
    background-color: #558b2f; }
[data-primary="color_12"] .daterangepicker button.applyBtn {
  background-color: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"] .wizard > .steps li.current a {
  background-color: #558b2f; }
[data-primary="color_12"] .wizard .skip-email a {
  color: #558b2f; }
[data-primary="color_12"] .wizard > .actions li:not(.disabled) a {
  background-color: #558b2f; }
[data-primary="color_12"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #558b2f; }
[data-primary="color_12"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #558b2f; }
[data-primary="color_12"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #558b2f; }
[data-primary="color_12"] .is-invalid .input-group-prepend .input-group-text i {
  color: #79c046; }
[data-primary="color_12"] .datamaps-hoverover {
  color: #558b2f;
  border: 1px solid rgba(85, 139, 47, 0.3); }
[data-primary="color_12"] .jqvmap-zoomin,
[data-primary="color_12"] .jqvmap-zoomout {
  background-color: #558b2f; }
[data-primary="color_12"] .table .thead-primary th {
  background-color: #558b2f; }
[data-primary="color_12"] .table.primary-table-bg-hover thead th {
  background-color: #497829; }
[data-primary="color_12"] .table.primary-table-bg-hover tbody tr {
  background-color: #558b2f; }
  [data-primary="color_12"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #619e35; }
  [data-primary="color_12"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_12"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #497829; }
[data-primary="color_12"] table.dataTable tr.selected {
  color: #558b2f; }
[data-primary="color_12"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #558b2f !important;
  background: rgba(85, 139, 47, 0.1); }
[data-primary="color_12"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #558b2f !important;
  background: rgba(85, 139, 47, 0.1); }
[data-primary="color_12"] .clipboard-btn:hover {
  background-color: #558b2f; }
[data-primary="color_12"] .cd-h-timeline__dates::before {
  background: #558b2f; }
[data-primary="color_12"] .cd-h-timeline__dates::after {
  background: #558b2f; }
[data-primary="color_12"] .cd-h-timeline__line {
  background-color: #558b2f; }
[data-primary="color_12"] .cd-h-timeline__date:after {
  border-color: #578e30;
  background-color: #558b2f; }
[data-primary="color_12"] .cd-h-timeline__navigation {
  border-color: #578e30; }
[data-primary="color_12"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #578e30; }
[data-primary="color_12"] .dd-handle {
  background: #558b2f; }
[data-primary="color_12"] .dd-handle:hover {
  background: #558b2f; }
[data-primary="color_12"] .dd3-content:hover {
  background: #558b2f; }
[data-primary="color_12"] .noUi-connect {
  background-color: #558b2f; }
  [data-primary="color_12"] .noUi-connect.c-3-color {
    background-color: #558b2f; }
[data-primary="color_12"] .noUi-horizontal .noUi-handle, [data-primary="color_12"] .noUi-vertical .noUi-handle {
  background-color: #558b2f; }
[data-primary="color_12"] #slider-toggle.off .noUi-handle {
  border-color: #558b2f; }
[data-primary="color_12"] .pignose-calendar {
  border-color: #558b2f; }
  [data-primary="color_12"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #558b2f; }
[data-primary="color_12"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #558b2f; }
[data-primary="color_12"] .bootstrap-tagsinput .tag {
  background-color: #558b2f; }
[data-primary="color_12"] .toast-success {
  background-color: #558b2f; }
[data-primary="color_12"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #558b2f; }
[data-primary="color_12"] .accordion-header-bg .accordion__header--primary {
  background-color: #558b2f; }
[data-primary="color_12"] .alert-primary {
  background: #94cd6c;
  border-color: #94cd6c;
  color: #558b2f; }
[data-primary="color_12"] .alert-alt.alert-primary {
  border-left: 4px solid #558b2f; }
[data-primary="color_12"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #1b2c0f !important; }
[data-primary="color_12"] .alert.alert-primary.solid {
  background: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"] .alert.alert-outline-primary {
  color: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"] .badge-outline-primary {
  border: 1px solid #558b2f;
  color: #558b2f; }
[data-primary="color_12"] .badge-primary {
  background-color: #558b2f; }
[data-primary="color_12"] .page-titles h4 {
  color: #558b2f; }
[data-primary="color_12"] .card-action > a {
  background: black; }
[data-primary="color_12"] .card-action .dropdown {
  background: black;
  color: #558b2f; }
  [data-primary="color_12"] .card-action .dropdown:hover, [data-primary="color_12"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_12"] .card-loader i {
  background: #457026; }
[data-primary="color_12"] .dropdown-outline {
  border: 0.1rem solid #558b2f; }
[data-primary="color_12"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #558b2f; }
[data-primary="color_12"] .card-action .custom-dropdown {
  background: #a2d47f; }
  [data-primary="color_12"] .card-action .custom-dropdown.show, [data-primary="color_12"] .card-action .custom-dropdown:focus, [data-primary="color_12"] .card-action .custom-dropdown:hover {
    background: #558b2f; }
[data-primary="color_12"] .label-primary {
  background: #558b2f; }
[data-primary="color_12"] .pagination .page-item .page-link:hover {
  background: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"] .pagination .page-item.active .page-link {
  background-color: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_12"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #558b2f; }
[data-primary="color_12"] .progress-bar {
  background-color: #558b2f; }
[data-primary="color_12"] .progress-bar-primary {
  background-color: #558b2f; }
[data-primary="color_12"] .ribbon__four {
  background-color: #558b2f; }
  [data-primary="color_12"] .ribbon__four:after, [data-primary="color_12"] .ribbon__four:before {
    background-color: #86c759; }
[data-primary="color_12"] .ribbon__five {
  background-color: #558b2f; }
  [data-primary="color_12"] .ribbon__five::before {
    border-color: transparent transparent #558b2f transparent; }
[data-primary="color_12"] .ribbon__six {
  background-color: #558b2f; }
[data-primary="color_12"] .multi-steps > li {
  color: #558b2f; }
  [data-primary="color_12"] .multi-steps > li:after {
    background-color: #558b2f; }
  [data-primary="color_12"] .multi-steps > li.is-active:before {
    border-color: #558b2f; }
[data-primary="color_12"] .timeline-badge.primary {
  background-color: #558b2f !important; }
[data-primary="color_12"] .tooltip-wrapper button:hover {
  background: #558b2f; }
[data-primary="color_12"] .chart_widget_tab_one .nav-link.active {
  background-color: #558b2f;
  border: 1px solid #558b2f; }
  [data-primary="color_12"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #558b2f; }
[data-primary="color_12"] .social-icon2 a {
  border: 0.1rem solid #558b2f; }
[data-primary="color_12"] .social-icon2 i {
  color: #558b2f; }
[data-primary="color_12"] .social-icon3 ul li a:hover i {
  color: #558b2f; }
[data-primary="color_12"] .bgl-primary {
  background: #a2d47f;
  border-color: #a2d47f;
  color: #558b2f; }
[data-primary="color_12"] .tdl-holder input[type=checkbox]:checked + i {
  background: #558b2f; }
[data-primary="color_12"] .footer .copyright a {
  color: #558b2f; }
[data-primary="color_12"] .hamburger .line {
  background: #558b2f; }
[data-primary="color_12"] svg.pulse-svg .first-circle, [data-primary="color_12"] svg.pulse-svg .second-circle, [data-primary="color_12"] svg.pulse-svg .third-circle {
  fill: #558b2f; }
[data-primary="color_12"] .pulse-css {
  background: #558b2f; }
  [data-primary="color_12"] .pulse-css:after, [data-primary="color_12"] .pulse-css:before {
    background-color: #558b2f; }
[data-primary="color_12"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #558b2f; }
[data-primary="color_12"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_12"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_12"] .header-right .header-profile .dropdown-menu a.active {
  color: #558b2f; }
[data-primary="color_12"] .header-right .header-profile .profile_title {
  background: #558b2f; }
[data-primary="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #558b2f !important; }
[data-primary="color_12"] .deznav .metismenu > li > a svg {
  color: #558b2f; }
[data-primary="color_12"] .deznav .metismenu > li:hover > a, [data-primary="color_12"] .deznav .metismenu > li:focus > a {
  color: #558b2f; }
[data-primary="color_12"] .deznav .metismenu > li.mm-active > a {
  color: #558b2f; }
[data-primary="color_12"] .deznav .metismenu ul a:hover, [data-primary="color_12"] .deznav .metismenu ul a:focus, [data-primary="color_12"] .deznav .metismenu ul a.mm-active {
  color: #558b2f; }
@media (min-width: 767px) {
  [data-primary="color_12"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_12"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_12"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_12"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: #daeecb; } }
[data-primary="color_12"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #558b2f; }
[data-primary="color_12"] .nav-user {
  background: #558b2f; }
[data-primary="color_12"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #558b2f; }
  [data-primary="color_12"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #558b2f; }
[data-primary="color_12"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"][data-theme-version="dark"] .header-left input:focus {
  border-color: #558b2f; }
[data-primary="color_12"][data-theme-version="dark"] .loader__bar {
  background: #558b2f; }
[data-primary="color_12"][data-theme-version="dark"] .loader__ball {
  background: #558b2f; }
[data-primary="color_12"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #558b2f; }
[data-primary="color_12"] .new-arrival-content .price {
  color: #558b2f; }
[data-primary="color_12"] .chart-link a i.text-primary {
  color: #558b2f; }
[data-primary="color_12"] #user-activity .nav-tabs .nav-link.active {
  background: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"] span#counter {
  color: #558b2f; }
[data-primary="color_12"] .welcome-content:after {
  background: #558b2f; }
[data-primary="color_12"] .timeline-badge {
  background-color: #558b2f; }
[data-primary="color_12"] .page-timeline .timeline-workplan[data-primary="color_12"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(85, 139, 47, 0.4); }
[data-primary="color_12"] .sk-three-bounce .sk-child {
  background-color: #558b2f; }
[data-primary="color_12"] .dropdown-item.active,
[data-primary="color_12"] .dropdown-item:active {
  color: #fff;
  background-color: #558b2f; }
[data-primary="color_12"] .overlay-box:after {
  background: #558b2f; }
[data-primary="color_12"] .btn-primary {
  background-color: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"] .bg-primary {
  background-color: #558b2f !important; }
[data-primary="color_12"] .text-primary {
  color: #558b2f !important; }
[data-primary="color_12"] .btn-primary:hover {
  background-color: #32521c;
  border-color: #32521c; }
[data-primary="color_12"] .btn-outline-primary {
  color: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"] .btn-outline-primary:hover {
  background-color: #558b2f;
  border-color: #558b2f; }
[data-primary="color_12"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_12"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_12"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_12"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_12"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_12"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #32521c;
  border-color: #32521c; }

[data-primary="color_13"] a:hover, [data-primary="color_13"] a:focus, [data-primary="color_13"] a.active {
  color: #2a2a2a; }
[data-primary="color_13"] [data-class="bg-primary"]:before {
  background: #2a2a2a; }
[data-primary="color_13"] .email-left-box .intro-title {
  background: rgba(42, 42, 42, 0.1); }
  [data-primary="color_13"] .email-left-box .intro-title i {
    color: #2a2a2a; }
[data-primary="color_13"] .widget-stat .media .media-body h4 {
  color: #2a2a2a !important; }
[data-primary="color_13"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(42, 42, 42, 0.1); }
[data-primary="color_13"] .mail-list .list-group-item.active i {
  color: #2a2a2a; }
[data-primary="color_13"] .single-mail.active {
  background: #2a2a2a; }
[data-primary="color_13"] .profile-info h4.text-primary {
  color: #2a2a2a !important; }
[data-primary="color_13"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_13"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #2a2a2a;
  color: #2a2a2a; }
[data-primary="color_13"] .amChartsInputField {
  border: 0;
  background: #2a2a2a; }
[data-primary="color_13"] .amcharts-period-input,
[data-primary="color_13"] .amcharts-period-input-selected {
  background: #2a2a2a; }
[data-primary="color_13"] .morris-hover {
  background: #2a2a2a; }
[data-primary="color_13"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #2a2a2a; }
[data-primary="color_13"] .custom-select:focus {
  border-color: #2a2a2a;
  color: #2a2a2a; }
[data-primary="color_13"] .daterangepicker td.active {
  background-color: #2a2a2a; }
  [data-primary="color_13"] .daterangepicker td.active:hover {
    background-color: #2a2a2a; }
[data-primary="color_13"] .daterangepicker button.applyBtn {
  background-color: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"] .wizard > .steps li.current a {
  background-color: #2a2a2a; }
[data-primary="color_13"] .wizard .skip-email a {
  color: #2a2a2a; }
[data-primary="color_13"] .wizard > .actions li:not(.disabled) a {
  background-color: #2a2a2a; }
[data-primary="color_13"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #2a2a2a; }
[data-primary="color_13"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #2a2a2a; }
[data-primary="color_13"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #2a2a2a; }
[data-primary="color_13"] .is-invalid .input-group-prepend .input-group-text i {
  color: #505050; }
[data-primary="color_13"] .datamaps-hoverover {
  color: #2a2a2a;
  border: 1px solid rgba(42, 42, 42, 0.3); }
[data-primary="color_13"] .jqvmap-zoomin,
[data-primary="color_13"] .jqvmap-zoomout {
  background-color: #2a2a2a; }
[data-primary="color_13"] .table .thead-primary th {
  background-color: #2a2a2a; }
[data-primary="color_13"] .table.primary-table-bg-hover thead th {
  background-color: #1d1d1d; }
[data-primary="color_13"] .table.primary-table-bg-hover tbody tr {
  background-color: #2a2a2a; }
  [data-primary="color_13"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #373737; }
  [data-primary="color_13"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_13"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #1d1d1d; }
[data-primary="color_13"] table.dataTable tr.selected {
  color: #2a2a2a; }
[data-primary="color_13"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #2a2a2a !important;
  background: rgba(42, 42, 42, 0.1); }
[data-primary="color_13"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #2a2a2a !important;
  background: rgba(42, 42, 42, 0.1); }
[data-primary="color_13"] .clipboard-btn:hover {
  background-color: #2a2a2a; }
[data-primary="color_13"] .cd-h-timeline__dates::before {
  background: #2a2a2a; }
[data-primary="color_13"] .cd-h-timeline__dates::after {
  background: #2a2a2a; }
[data-primary="color_13"] .cd-h-timeline__line {
  background-color: #2a2a2a; }
[data-primary="color_13"] .cd-h-timeline__date:after {
  border-color: #2c2c2c;
  background-color: #2a2a2a; }
[data-primary="color_13"] .cd-h-timeline__navigation {
  border-color: #2c2c2c; }
[data-primary="color_13"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #2c2c2c; }
[data-primary="color_13"] .dd-handle {
  background: #2a2a2a; }
[data-primary="color_13"] .dd-handle:hover {
  background: #2a2a2a; }
[data-primary="color_13"] .dd3-content:hover {
  background: #2a2a2a; }
[data-primary="color_13"] .noUi-connect {
  background-color: #2a2a2a; }
  [data-primary="color_13"] .noUi-connect.c-3-color {
    background-color: #2a2a2a; }
[data-primary="color_13"] .noUi-horizontal .noUi-handle, [data-primary="color_13"] .noUi-vertical .noUi-handle {
  background-color: #2a2a2a; }
[data-primary="color_13"] #slider-toggle.off .noUi-handle {
  border-color: #2a2a2a; }
[data-primary="color_13"] .pignose-calendar {
  border-color: #2a2a2a; }
  [data-primary="color_13"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #2a2a2a; }
[data-primary="color_13"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #2a2a2a; }
[data-primary="color_13"] .bootstrap-tagsinput .tag {
  background-color: #2a2a2a; }
[data-primary="color_13"] .toast-success {
  background-color: #2a2a2a; }
[data-primary="color_13"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #2a2a2a; }
[data-primary="color_13"] .accordion-header-bg .accordion__header--primary {
  background-color: #2a2a2a; }
[data-primary="color_13"] .alert-primary {
  background: #6a6a6a;
  border-color: #6a6a6a;
  color: #2a2a2a; }
[data-primary="color_13"] .alert-alt.alert-primary {
  border-left: 4px solid #2a2a2a; }
[data-primary="color_13"] .alert-alt.alert-primary.solid {
  border-left: 4px solid black !important; }
[data-primary="color_13"] .alert.alert-primary.solid {
  background: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"] .alert.alert-outline-primary {
  color: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"] .badge-outline-primary {
  border: 1px solid #2a2a2a;
  color: #2a2a2a; }
[data-primary="color_13"] .badge-primary {
  background-color: #2a2a2a; }
[data-primary="color_13"] .page-titles h4 {
  color: #2a2a2a; }
[data-primary="color_13"] .card-action > a {
  background: black; }
[data-primary="color_13"] .card-action .dropdown {
  background: black;
  color: #2a2a2a; }
  [data-primary="color_13"] .card-action .dropdown:hover, [data-primary="color_13"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_13"] .card-loader i {
  background: #181818; }
[data-primary="color_13"] .dropdown-outline {
  border: 0.1rem solid #2a2a2a; }
[data-primary="color_13"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #2a2a2a; }
[data-primary="color_13"] .card-action .custom-dropdown {
  background: #777777; }
  [data-primary="color_13"] .card-action .custom-dropdown.show, [data-primary="color_13"] .card-action .custom-dropdown:focus, [data-primary="color_13"] .card-action .custom-dropdown:hover {
    background: #2a2a2a; }
[data-primary="color_13"] .label-primary {
  background: #2a2a2a; }
[data-primary="color_13"] .pagination .page-item .page-link:hover {
  background: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"] .pagination .page-item.active .page-link {
  background-color: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_13"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #2a2a2a; }
[data-primary="color_13"] .progress-bar {
  background-color: #2a2a2a; }
[data-primary="color_13"] .progress-bar-primary {
  background-color: #2a2a2a; }
[data-primary="color_13"] .ribbon__four {
  background-color: #2a2a2a; }
  [data-primary="color_13"] .ribbon__four:after, [data-primary="color_13"] .ribbon__four:before {
    background-color: #5d5d5d; }
[data-primary="color_13"] .ribbon__five {
  background-color: #2a2a2a; }
  [data-primary="color_13"] .ribbon__five::before {
    border-color: transparent transparent #2a2a2a transparent; }
[data-primary="color_13"] .ribbon__six {
  background-color: #2a2a2a; }
[data-primary="color_13"] .multi-steps > li {
  color: #2a2a2a; }
  [data-primary="color_13"] .multi-steps > li:after {
    background-color: #2a2a2a; }
  [data-primary="color_13"] .multi-steps > li.is-active:before {
    border-color: #2a2a2a; }
[data-primary="color_13"] .timeline-badge.primary {
  background-color: #2a2a2a !important; }
[data-primary="color_13"] .tooltip-wrapper button:hover {
  background: #2a2a2a; }
[data-primary="color_13"] .chart_widget_tab_one .nav-link.active {
  background-color: #2a2a2a;
  border: 1px solid #2a2a2a; }
  [data-primary="color_13"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #2a2a2a; }
[data-primary="color_13"] .social-icon2 a {
  border: 0.1rem solid #2a2a2a; }
[data-primary="color_13"] .social-icon2 i {
  color: #2a2a2a; }
[data-primary="color_13"] .social-icon3 ul li a:hover i {
  color: #2a2a2a; }
[data-primary="color_13"] .bgl-primary {
  background: #777777;
  border-color: #777777;
  color: #2a2a2a; }
[data-primary="color_13"] .tdl-holder input[type=checkbox]:checked + i {
  background: #2a2a2a; }
[data-primary="color_13"] .footer .copyright a {
  color: #2a2a2a; }
[data-primary="color_13"] .hamburger .line {
  background: #2a2a2a; }
[data-primary="color_13"] svg.pulse-svg .first-circle, [data-primary="color_13"] svg.pulse-svg .second-circle, [data-primary="color_13"] svg.pulse-svg .third-circle {
  fill: #2a2a2a; }
[data-primary="color_13"] .pulse-css {
  background: #2a2a2a; }
  [data-primary="color_13"] .pulse-css:after, [data-primary="color_13"] .pulse-css:before {
    background-color: #2a2a2a; }
[data-primary="color_13"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #2a2a2a; }
[data-primary="color_13"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_13"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_13"] .header-right .header-profile .dropdown-menu a.active {
  color: #2a2a2a; }
[data-primary="color_13"] .header-right .header-profile .profile_title {
  background: #2a2a2a; }
[data-primary="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #2a2a2a !important; }
[data-primary="color_13"] .deznav .metismenu > li > a svg {
  color: #2a2a2a; }
[data-primary="color_13"] .deznav .metismenu > li:hover > a, [data-primary="color_13"] .deznav .metismenu > li:focus > a {
  color: #2a2a2a; }
[data-primary="color_13"] .deznav .metismenu > li.mm-active > a {
  color: #2a2a2a; }
[data-primary="color_13"] .deznav .metismenu ul a:hover, [data-primary="color_13"] .deznav .metismenu ul a:focus, [data-primary="color_13"] .deznav .metismenu ul a.mm-active {
  color: #2a2a2a; }
@media (min-width: 767px) {
  [data-primary="color_13"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_13"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_13"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_13"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: #aaaaaa; } }
[data-primary="color_13"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #2a2a2a; }
[data-primary="color_13"] .nav-user {
  background: #2a2a2a; }
[data-primary="color_13"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #2a2a2a; }
  [data-primary="color_13"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #2a2a2a; }
[data-primary="color_13"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"][data-theme-version="dark"] .header-left input:focus {
  border-color: #2a2a2a; }
[data-primary="color_13"][data-theme-version="dark"] .loader__bar {
  background: #2a2a2a; }
[data-primary="color_13"][data-theme-version="dark"] .loader__ball {
  background: #2a2a2a; }
[data-primary="color_13"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #2a2a2a; }
[data-primary="color_13"] .new-arrival-content .price {
  color: #2a2a2a; }
[data-primary="color_13"] .chart-link a i.text-primary {
  color: #2a2a2a; }
[data-primary="color_13"] #user-activity .nav-tabs .nav-link.active {
  background: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"] span#counter {
  color: #2a2a2a; }
[data-primary="color_13"] .welcome-content:after {
  background: #2a2a2a; }
[data-primary="color_13"] .timeline-badge {
  background-color: #2a2a2a; }
[data-primary="color_13"] .page-timeline .timeline-workplan[data-primary="color_13"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(42, 42, 42, 0.4); }
[data-primary="color_13"] .sk-three-bounce .sk-child {
  background-color: #2a2a2a; }
[data-primary="color_13"] .dropdown-item.active,
[data-primary="color_13"] .dropdown-item:active {
  color: #fff;
  background-color: #2a2a2a; }
[data-primary="color_13"] .overlay-box:after {
  background: #2a2a2a; }
[data-primary="color_13"] .btn-primary {
  background-color: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"] .bg-primary {
  background-color: #2a2a2a !important; }
[data-primary="color_13"] .text-primary {
  color: #2a2a2a !important; }
[data-primary="color_13"] .btn-primary:hover {
  background-color: #040404;
  border-color: #040404; }
[data-primary="color_13"] .btn-outline-primary {
  color: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"] .btn-outline-primary:hover {
  background-color: #2a2a2a;
  border-color: #2a2a2a; }
[data-primary="color_13"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_13"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_13"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_13"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_13"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_13"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #040404;
  border-color: #040404; }

[data-primary="color_14"] a:hover, [data-primary="color_14"] a:focus, [data-primary="color_14"] a.active {
  color: #1367c8; }
[data-primary="color_14"] [data-class="bg-primary"]:before {
  background: #1367c8; }
[data-primary="color_14"] .email-left-box .intro-title {
  background: rgba(19, 103, 200, 0.1); }
  [data-primary="color_14"] .email-left-box .intro-title i {
    color: #1367c8; }
[data-primary="color_14"] .widget-stat .media .media-body h4 {
  color: #1367c8 !important; }
[data-primary="color_14"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(19, 103, 200, 0.1); }
[data-primary="color_14"] .mail-list .list-group-item.active i {
  color: #1367c8; }
[data-primary="color_14"] .single-mail.active {
  background: #1367c8; }
[data-primary="color_14"] .profile-info h4.text-primary {
  color: #1367c8 !important; }
[data-primary="color_14"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_14"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #1367c8;
  color: #1367c8; }
[data-primary="color_14"] .amChartsInputField {
  border: 0;
  background: #1367c8; }
[data-primary="color_14"] .amcharts-period-input,
[data-primary="color_14"] .amcharts-period-input-selected {
  background: #1367c8; }
[data-primary="color_14"] .morris-hover {
  background: #1367c8; }
[data-primary="color_14"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #1367c8; }
[data-primary="color_14"] .custom-select:focus {
  border-color: #1367c8;
  color: #1367c8; }
[data-primary="color_14"] .daterangepicker td.active {
  background-color: #1367c8; }
  [data-primary="color_14"] .daterangepicker td.active:hover {
    background-color: #1367c8; }
[data-primary="color_14"] .daterangepicker button.applyBtn {
  background-color: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"] .wizard > .steps li.current a {
  background-color: #1367c8; }
[data-primary="color_14"] .wizard .skip-email a {
  color: #1367c8; }
[data-primary="color_14"] .wizard > .actions li:not(.disabled) a {
  background-color: #1367c8; }
[data-primary="color_14"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #1367c8; }
[data-primary="color_14"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #1367c8; }
[data-primary="color_14"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #1367c8; }
[data-primary="color_14"] .is-invalid .input-group-prepend .input-group-text i {
  color: #3b8dec; }
[data-primary="color_14"] .datamaps-hoverover {
  color: #1367c8;
  border: 1px solid rgba(19, 103, 200, 0.3); }
[data-primary="color_14"] .jqvmap-zoomin,
[data-primary="color_14"] .jqvmap-zoomout {
  background-color: #1367c8; }
[data-primary="color_14"] .table .thead-primary th {
  background-color: #1367c8; }
[data-primary="color_14"] .table.primary-table-bg-hover thead th {
  background-color: #115bb1; }
[data-primary="color_14"] .table.primary-table-bg-hover tbody tr {
  background-color: #1367c8; }
  [data-primary="color_14"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #1573df; }
  [data-primary="color_14"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_14"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #115bb1; }
[data-primary="color_14"] table.dataTable tr.selected {
  color: #1367c8; }
[data-primary="color_14"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #1367c8 !important;
  background: rgba(19, 103, 200, 0.1); }
[data-primary="color_14"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #1367c8 !important;
  background: rgba(19, 103, 200, 0.1); }
[data-primary="color_14"] .clipboard-btn:hover {
  background-color: #1367c8; }
[data-primary="color_14"] .cd-h-timeline__dates::before {
  background: #1367c8; }
[data-primary="color_14"] .cd-h-timeline__dates::after {
  background: #1367c8; }
[data-primary="color_14"] .cd-h-timeline__line {
  background-color: #1367c8; }
[data-primary="color_14"] .cd-h-timeline__date:after {
  border-color: #1369cc;
  background-color: #1367c8; }
[data-primary="color_14"] .cd-h-timeline__navigation {
  border-color: #1369cc; }
[data-primary="color_14"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #1369cc; }
[data-primary="color_14"] .dd-handle {
  background: #1367c8; }
[data-primary="color_14"] .dd-handle:hover {
  background: #1367c8; }
[data-primary="color_14"] .dd3-content:hover {
  background: #1367c8; }
[data-primary="color_14"] .noUi-connect {
  background-color: #1367c8; }
  [data-primary="color_14"] .noUi-connect.c-3-color {
    background-color: #1367c8; }
[data-primary="color_14"] .noUi-horizontal .noUi-handle, [data-primary="color_14"] .noUi-vertical .noUi-handle {
  background-color: #1367c8; }
[data-primary="color_14"] #slider-toggle.off .noUi-handle {
  border-color: #1367c8; }
[data-primary="color_14"] .pignose-calendar {
  border-color: #1367c8; }
  [data-primary="color_14"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #1367c8; }
[data-primary="color_14"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #1367c8; }
[data-primary="color_14"] .bootstrap-tagsinput .tag {
  background-color: #1367c8; }
[data-primary="color_14"] .toast-success {
  background-color: #1367c8; }
[data-primary="color_14"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #1367c8; }
[data-primary="color_14"] .accordion-header-bg .accordion__header--primary {
  background-color: #1367c8; }
[data-primary="color_14"] .alert-primary {
  background: #6aa8f1;
  border-color: #6aa8f1;
  color: #1367c8; }
[data-primary="color_14"] .alert-alt.alert-primary {
  border-left: 4px solid #1367c8; }
[data-primary="color_14"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #082b54 !important; }
[data-primary="color_14"] .alert.alert-primary.solid {
  background: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"] .alert.alert-outline-primary {
  color: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"] .badge-outline-primary {
  border: 1px solid #1367c8;
  color: #1367c8; }
[data-primary="color_14"] .badge-primary {
  background-color: #1367c8; }
[data-primary="color_14"] .page-titles h4 {
  color: #1367c8; }
[data-primary="color_14"] .card-action > a {
  background: black; }
[data-primary="color_14"] .card-action .dropdown {
  background: black;
  color: #1367c8; }
  [data-primary="color_14"] .card-action .dropdown:hover, [data-primary="color_14"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_14"] .card-loader i {
  background: #1056a7; }
[data-primary="color_14"] .dropdown-outline {
  border: 0.1rem solid #1367c8; }
[data-primary="color_14"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #1367c8; }
[data-primary="color_14"] .card-action .custom-dropdown {
  background: #81b6f3; }
  [data-primary="color_14"] .card-action .custom-dropdown.show, [data-primary="color_14"] .card-action .custom-dropdown:focus, [data-primary="color_14"] .card-action .custom-dropdown:hover {
    background: #1367c8; }
[data-primary="color_14"] .label-primary {
  background: #1367c8; }
[data-primary="color_14"] .pagination .page-item .page-link:hover {
  background: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"] .pagination .page-item.active .page-link {
  background-color: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_14"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #1367c8; }
[data-primary="color_14"] .progress-bar {
  background-color: #1367c8; }
[data-primary="color_14"] .progress-bar-primary {
  background-color: #1367c8; }
[data-primary="color_14"] .ribbon__four {
  background-color: #1367c8; }
  [data-primary="color_14"] .ribbon__four:after, [data-primary="color_14"] .ribbon__four:before {
    background-color: #529bef; }
[data-primary="color_14"] .ribbon__five {
  background-color: #1367c8; }
  [data-primary="color_14"] .ribbon__five::before {
    border-color: transparent transparent #1367c8 transparent; }
[data-primary="color_14"] .ribbon__six {
  background-color: #1367c8; }
[data-primary="color_14"] .multi-steps > li {
  color: #1367c8; }
  [data-primary="color_14"] .multi-steps > li:after {
    background-color: #1367c8; }
  [data-primary="color_14"] .multi-steps > li.is-active:before {
    border-color: #1367c8; }
[data-primary="color_14"] .timeline-badge.primary {
  background-color: #1367c8 !important; }
[data-primary="color_14"] .tooltip-wrapper button:hover {
  background: #1367c8; }
[data-primary="color_14"] .chart_widget_tab_one .nav-link.active {
  background-color: #1367c8;
  border: 1px solid #1367c8; }
  [data-primary="color_14"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #1367c8; }
[data-primary="color_14"] .social-icon2 a {
  border: 0.1rem solid #1367c8; }
[data-primary="color_14"] .social-icon2 i {
  color: #1367c8; }
[data-primary="color_14"] .social-icon3 ul li a:hover i {
  color: #1367c8; }
[data-primary="color_14"] .bgl-primary {
  background: #81b6f3;
  border-color: #81b6f3;
  color: #1367c8; }
[data-primary="color_14"] .tdl-holder input[type=checkbox]:checked + i {
  background: #1367c8; }
[data-primary="color_14"] .footer .copyright a {
  color: #1367c8; }
[data-primary="color_14"] .hamburger .line {
  background: #1367c8; }
[data-primary="color_14"] svg.pulse-svg .first-circle, [data-primary="color_14"] svg.pulse-svg .second-circle, [data-primary="color_14"] svg.pulse-svg .third-circle {
  fill: #1367c8; }
[data-primary="color_14"] .pulse-css {
  background: #1367c8; }
  [data-primary="color_14"] .pulse-css:after, [data-primary="color_14"] .pulse-css:before {
    background-color: #1367c8; }
[data-primary="color_14"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #1367c8; }
[data-primary="color_14"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_14"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_14"] .header-right .header-profile .dropdown-menu a.active {
  color: #1367c8; }
[data-primary="color_14"] .header-right .header-profile .profile_title {
  background: #1367c8; }
[data-primary="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #1367c8 !important; }
[data-primary="color_14"] .deznav .metismenu > li > a svg {
  color: #1367c8; }
[data-primary="color_14"] .deznav .metismenu > li:hover > a, [data-primary="color_14"] .deznav .metismenu > li:focus > a {
  color: #1367c8; }
[data-primary="color_14"] .deznav .metismenu > li.mm-active > a {
  color: #1367c8; }
[data-primary="color_14"] .deznav .metismenu ul a:hover, [data-primary="color_14"] .deznav .metismenu ul a:focus, [data-primary="color_14"] .deznav .metismenu ul a.mm-active {
  color: #1367c8; }
@media (min-width: 767px) {
  [data-primary="color_14"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_14"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_14"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_14"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: #deecfc; } }
[data-primary="color_14"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #1367c8; }
[data-primary="color_14"] .nav-user {
  background: #1367c8; }
[data-primary="color_14"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #1367c8; }
  [data-primary="color_14"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #1367c8; }
[data-primary="color_14"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"][data-theme-version="dark"] .header-left input:focus {
  border-color: #1367c8; }
[data-primary="color_14"][data-theme-version="dark"] .loader__bar {
  background: #1367c8; }
[data-primary="color_14"][data-theme-version="dark"] .loader__ball {
  background: #1367c8; }
[data-primary="color_14"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #1367c8; }
[data-primary="color_14"] .new-arrival-content .price {
  color: #1367c8; }
[data-primary="color_14"] .chart-link a i.text-primary {
  color: #1367c8; }
[data-primary="color_14"] #user-activity .nav-tabs .nav-link.active {
  background: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"] span#counter {
  color: #1367c8; }
[data-primary="color_14"] .welcome-content:after {
  background: #1367c8; }
[data-primary="color_14"] .timeline-badge {
  background-color: #1367c8; }
[data-primary="color_14"] .page-timeline .timeline-workplan[data-primary="color_14"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(19, 103, 200, 0.4); }
[data-primary="color_14"] .sk-three-bounce .sk-child {
  background-color: #1367c8; }
[data-primary="color_14"] .dropdown-item.active,
[data-primary="color_14"] .dropdown-item:active {
  color: #fff;
  background-color: #1367c8; }
[data-primary="color_14"] .overlay-box:after {
  background: #1367c8; }
[data-primary="color_14"] .btn-primary {
  background-color: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"] .bg-primary {
  background-color: #1367c8 !important; }
[data-primary="color_14"] .text-primary {
  color: #1367c8 !important; }
[data-primary="color_14"] .btn-primary:hover {
  background-color: #0c4382;
  border-color: #0c4382; }
[data-primary="color_14"] .btn-outline-primary {
  color: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"] .btn-outline-primary:hover {
  background-color: #1367c8;
  border-color: #1367c8; }
[data-primary="color_14"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_14"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_14"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_14"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_14"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_14"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #0c4382;
  border-color: #0c4382; }

[data-primary="color_15"] a:hover, [data-primary="color_15"] a:focus, [data-primary="color_15"] a.active {
  color: #ed0b4c; }
[data-primary="color_15"] [data-class="bg-primary"]:before {
  background: #ed0b4c; }
[data-primary="color_15"] .email-left-box .intro-title {
  background: rgba(237, 11, 76, 0.1); }
  [data-primary="color_15"] .email-left-box .intro-title i {
    color: #ed0b4c; }
[data-primary="color_15"] .widget-stat .media .media-body h4 {
  color: #ed0b4c !important; }
[data-primary="color_15"] .email-right-box .right-box-border {
  border-right: 2px solid rgba(237, 11, 76, 0.1); }
[data-primary="color_15"] .mail-list .list-group-item.active i {
  color: #ed0b4c; }
[data-primary="color_15"] .single-mail.active {
  background: #ed0b4c; }
[data-primary="color_15"] .profile-info h4.text-primary {
  color: #ed0b4c !important; }
[data-primary="color_15"] .profile-tab .nav-item .nav-link:hover, [data-primary="color_15"] .profile-tab .nav-item .nav-link.active {
  border-bottom: 0.2px solid #ed0b4c;
  color: #ed0b4c; }
[data-primary="color_15"] .amChartsInputField {
  border: 0;
  background: #ed0b4c; }
[data-primary="color_15"] .amcharts-period-input,
[data-primary="color_15"] .amcharts-period-input-selected {
  background: #ed0b4c; }
[data-primary="color_15"] .morris-hover {
  background: #ed0b4c; }
[data-primary="color_15"] .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #ed0b4c; }
[data-primary="color_15"] .custom-select:focus {
  border-color: #ed0b4c;
  color: #ed0b4c; }
[data-primary="color_15"] .daterangepicker td.active {
  background-color: #ed0b4c; }
  [data-primary="color_15"] .daterangepicker td.active:hover {
    background-color: #ed0b4c; }
[data-primary="color_15"] .daterangepicker button.applyBtn {
  background-color: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"] .wizard > .steps li.current a {
  background-color: #ed0b4c; }
[data-primary="color_15"] .wizard .skip-email a {
  color: #ed0b4c; }
[data-primary="color_15"] .wizard > .actions li:not(.disabled) a {
  background-color: #ed0b4c; }
[data-primary="color_15"] .step-form-horizontal .wizard .steps li.done a .number {
  background: #ed0b4c; }
[data-primary="color_15"] .step-form-horizontal .wizard .steps li.current a .number {
  color: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"] .step-form-horizontal .wizard .steps li.disabled a .number {
  color: #ed0b4c; }
[data-primary="color_15"] .step-form-horizontal .wizard .steps li:not(:last-child)::after {
  background-color: #ed0b4c; }
[data-primary="color_15"] .is-invalid .input-group-prepend .input-group-text i {
  color: #f74e7e; }
[data-primary="color_15"] .datamaps-hoverover {
  color: #ed0b4c;
  border: 1px solid rgba(237, 11, 76, 0.3); }
[data-primary="color_15"] .jqvmap-zoomin,
[data-primary="color_15"] .jqvmap-zoomout {
  background-color: #ed0b4c; }
[data-primary="color_15"] .table .thead-primary th {
  background-color: #ed0b4c; }
[data-primary="color_15"] .table.primary-table-bg-hover thead th {
  background-color: #d50a44; }
[data-primary="color_15"] .table.primary-table-bg-hover tbody tr {
  background-color: #ed0b4c; }
  [data-primary="color_15"] .table.primary-table-bg-hover tbody tr:hover {
    background-color: #f51d5b; }
  [data-primary="color_15"] .table.primary-table-bg-hover tbody tr:not(:last-child) td, [data-primary="color_15"] .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #d50a44; }
[data-primary="color_15"] table.dataTable tr.selected {
  color: #ed0b4c; }
[data-primary="color_15"] .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #ed0b4c !important;
  background: rgba(237, 11, 76, 0.1); }
[data-primary="color_15"] .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #ed0b4c !important;
  background: rgba(237, 11, 76, 0.1); }
[data-primary="color_15"] .clipboard-btn:hover {
  background-color: #ed0b4c; }
[data-primary="color_15"] .cd-h-timeline__dates::before {
  background: #ed0b4c; }
[data-primary="color_15"] .cd-h-timeline__dates::after {
  background: #ed0b4c; }
[data-primary="color_15"] .cd-h-timeline__line {
  background-color: #ed0b4c; }
[data-primary="color_15"] .cd-h-timeline__date:after {
  border-color: #f10b4d;
  background-color: #ed0b4c; }
[data-primary="color_15"] .cd-h-timeline__navigation {
  border-color: #f10b4d; }
[data-primary="color_15"] .cd-h-timeline__navigation--inactive:hover {
  border-color: #f10b4d; }
[data-primary="color_15"] .dd-handle {
  background: #ed0b4c; }
[data-primary="color_15"] .dd-handle:hover {
  background: #ed0b4c; }
[data-primary="color_15"] .dd3-content:hover {
  background: #ed0b4c; }
[data-primary="color_15"] .noUi-connect {
  background-color: #ed0b4c; }
  [data-primary="color_15"] .noUi-connect.c-3-color {
    background-color: #ed0b4c; }
[data-primary="color_15"] .noUi-horizontal .noUi-handle, [data-primary="color_15"] .noUi-vertical .noUi-handle {
  background-color: #ed0b4c; }
[data-primary="color_15"] #slider-toggle.off .noUi-handle {
  border-color: #ed0b4c; }
[data-primary="color_15"] .pignose-calendar {
  border-color: #ed0b4c; }
  [data-primary="color_15"] .pignose-calendar .pignose-calendar-top-date {
    background-color: #ed0b4c; }
[data-primary="color_15"] .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #ed0b4c; }
[data-primary="color_15"] .bootstrap-tagsinput .tag {
  background-color: #ed0b4c; }
[data-primary="color_15"] .toast-success {
  background-color: #ed0b4c; }
[data-primary="color_15"] .twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: #ed0b4c; }
[data-primary="color_15"] .accordion-header-bg .accordion__header--primary {
  background-color: #ed0b4c; }
[data-primary="color_15"] .alert-primary {
  background: #f97ea2;
  border-color: #f97ea2;
  color: #ed0b4c; }
[data-primary="color_15"] .alert-alt.alert-primary {
  border-left: 4px solid #ed0b4c; }
[data-primary="color_15"] .alert-alt.alert-primary.solid {
  border-left: 4px solid #730525 !important; }
[data-primary="color_15"] .alert.alert-primary.solid {
  background: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"] .alert.alert-outline-primary {
  color: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"] .badge-outline-primary {
  border: 1px solid #ed0b4c;
  color: #ed0b4c; }
[data-primary="color_15"] .badge-primary {
  background-color: #ed0b4c; }
[data-primary="color_15"] .page-titles h4 {
  color: #ed0b4c; }
[data-primary="color_15"] .card-action > a {
  background: black; }
[data-primary="color_15"] .card-action .dropdown {
  background: black;
  color: #ed0b4c; }
  [data-primary="color_15"] .card-action .dropdown:hover, [data-primary="color_15"] .card-action .dropdown:focus {
    background: black; }
[data-primary="color_15"] .card-loader i {
  background: #cb0941; }
[data-primary="color_15"] .dropdown-outline {
  border: 0.1rem solid #ed0b4c; }
[data-primary="color_15"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
  color: #ed0b4c; }
[data-primary="color_15"] .card-action .custom-dropdown {
  background: #fa97b3; }
  [data-primary="color_15"] .card-action .custom-dropdown.show, [data-primary="color_15"] .card-action .custom-dropdown:focus, [data-primary="color_15"] .card-action .custom-dropdown:hover {
    background: #ed0b4c; }
[data-primary="color_15"] .label-primary {
  background: #ed0b4c; }
[data-primary="color_15"] .pagination .page-item .page-link:hover {
  background: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"] .pagination .page-item.active .page-link {
  background-color: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"] .bootstrap-popover-wrapper .bootstrap-popover button:hover,
[data-primary="color_15"] .bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: #ed0b4c; }
[data-primary="color_15"] .progress-bar {
  background-color: #ed0b4c; }
[data-primary="color_15"] .progress-bar-primary {
  background-color: #ed0b4c; }
[data-primary="color_15"] .ribbon__four {
  background-color: #ed0b4c; }
  [data-primary="color_15"] .ribbon__four:after, [data-primary="color_15"] .ribbon__four:before {
    background-color: #f86690; }
[data-primary="color_15"] .ribbon__five {
  background-color: #ed0b4c; }
  [data-primary="color_15"] .ribbon__five::before {
    border-color: transparent transparent #ed0b4c transparent; }
[data-primary="color_15"] .ribbon__six {
  background-color: #ed0b4c; }
[data-primary="color_15"] .multi-steps > li {
  color: #ed0b4c; }
  [data-primary="color_15"] .multi-steps > li:after {
    background-color: #ed0b4c; }
  [data-primary="color_15"] .multi-steps > li.is-active:before {
    border-color: #ed0b4c; }
[data-primary="color_15"] .timeline-badge.primary {
  background-color: #ed0b4c !important; }
[data-primary="color_15"] .tooltip-wrapper button:hover {
  background: #ed0b4c; }
[data-primary="color_15"] .chart_widget_tab_one .nav-link.active {
  background-color: #ed0b4c;
  border: 1px solid #ed0b4c; }
  [data-primary="color_15"] .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #ed0b4c; }
[data-primary="color_15"] .social-icon2 a {
  border: 0.1rem solid #ed0b4c; }
[data-primary="color_15"] .social-icon2 i {
  color: #ed0b4c; }
[data-primary="color_15"] .social-icon3 ul li a:hover i {
  color: #ed0b4c; }
[data-primary="color_15"] .bgl-primary {
  background: #fa97b3;
  border-color: #fa97b3;
  color: #ed0b4c; }
[data-primary="color_15"] .tdl-holder input[type=checkbox]:checked + i {
  background: #ed0b4c; }
[data-primary="color_15"] .footer .copyright a {
  color: #ed0b4c; }
[data-primary="color_15"] .hamburger .line {
  background: #ed0b4c; }
[data-primary="color_15"] svg.pulse-svg .first-circle, [data-primary="color_15"] svg.pulse-svg .second-circle, [data-primary="color_15"] svg.pulse-svg .third-circle {
  fill: #ed0b4c; }
[data-primary="color_15"] .pulse-css {
  background: #ed0b4c; }
  [data-primary="color_15"] .pulse-css:after, [data-primary="color_15"] .pulse-css:before {
    background-color: #ed0b4c; }
[data-primary="color_15"] .notification_dropdown .dropdown-menu-right .notification_title {
  background: #ed0b4c; }
[data-primary="color_15"] .header-right .header-profile .dropdown-menu a:hover, [data-primary="color_15"] .header-right .header-profile .dropdown-menu a:focus, [data-primary="color_15"] .header-right .header-profile .dropdown-menu a.active {
  color: #ed0b4c; }
[data-primary="color_15"] .header-right .header-profile .profile_title {
  background: #ed0b4c; }
[data-primary="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #ed0b4c !important; }
[data-primary="color_15"] .deznav .metismenu > li > a svg {
  color: #ed0b4c; }
[data-primary="color_15"] .deznav .metismenu > li:hover > a, [data-primary="color_15"] .deznav .metismenu > li:focus > a {
  color: #ed0b4c; }
[data-primary="color_15"] .deznav .metismenu > li.mm-active > a {
  color: #ed0b4c; }
[data-primary="color_15"] .deznav .metismenu ul a:hover, [data-primary="color_15"] .deznav .metismenu ul a:focus, [data-primary="color_15"] .deznav .metismenu ul a.mm-active {
  color: #ed0b4c; }
@media (min-width: 767px) {
  [data-primary="color_15"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:hover > a, [data-primary="color_15"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:focus > a, [data-primary="color_15"] [data-sidebar-style="modern"] .deznav .metismenu > li > a:active > a, [data-primary="color_15"] [data-sidebar-style="modern"] .deznav .metismenu > li > a.mm-active > a {
    background-color: #fff8fa; } }
[data-primary="color_15"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #ed0b4c; }
[data-primary="color_15"] .nav-user {
  background: #ed0b4c; }
[data-primary="color_15"] .sidebar-right .sidebar-right .sidebar-right-trigger {
  color: #ed0b4c; }
  [data-primary="color_15"] .sidebar-right .sidebar-right .sidebar-right-trigger:hover {
    color: #ed0b4c; }
[data-primary="color_15"][data-theme-version="dark"] .pagination .page-item .page-link:hover {
  background: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"][data-theme-version="dark"] .pagination .page-item.active .page-link {
  background: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"][data-theme-version="dark"] .header-left input:focus {
  border-color: #ed0b4c; }
[data-primary="color_15"][data-theme-version="dark"] .loader__bar {
  background: #ed0b4c; }
[data-primary="color_15"][data-theme-version="dark"] .loader__ball {
  background: #ed0b4c; }
[data-primary="color_15"][data-theme-version="transparent"] .header-left input:focus {
  border-color: #ed0b4c; }
[data-primary="color_15"] .new-arrival-content .price {
  color: #ed0b4c; }
[data-primary="color_15"] .chart-link a i.text-primary {
  color: #ed0b4c; }
[data-primary="color_15"] #user-activity .nav-tabs .nav-link.active {
  background: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"] span#counter {
  color: #ed0b4c; }
[data-primary="color_15"] .welcome-content:after {
  background: #ed0b4c; }
[data-primary="color_15"] .timeline-badge {
  background-color: #ed0b4c; }
[data-primary="color_15"] .page-timeline .timeline-workplan[data-primary="color_15"] .page-timeline .timeline .timeline-badge:after {
  background-color: rgba(237, 11, 76, 0.4); }
[data-primary="color_15"] .sk-three-bounce .sk-child {
  background-color: #ed0b4c; }
[data-primary="color_15"] .dropdown-item.active,
[data-primary="color_15"] .dropdown-item:active {
  color: #fff;
  background-color: #ed0b4c; }
[data-primary="color_15"] .overlay-box:after {
  background: #ed0b4c; }
[data-primary="color_15"] .btn-primary {
  background-color: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"] .bg-primary {
  background-color: #ed0b4c !important; }
[data-primary="color_15"] .text-primary {
  color: #ed0b4c !important; }
[data-primary="color_15"] .btn-primary:hover {
  background-color: #a40835;
  border-color: #a40835; }
[data-primary="color_15"] .btn-outline-primary {
  color: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"] .btn-outline-primary:hover {
  background-color: #ed0b4c;
  border-color: #ed0b4c; }
[data-primary="color_15"] .btn-primary:not(:disabled):not(.disabled):active,
[data-primary="color_15"] .btn-primary:not(:disabled):not(.disabled).active,
[data-primary="color_15"] .show > .btn-primary.dropdown-toggle,
[data-primary="color_15"] .btn-outline-primary:not(:disabled):not(.disabled):active,
[data-primary="color_15"] .btn-outline-primary:not(:disabled):not(.disabled).active,
[data-primary="color_15"] .show > .btn-outline-primary.dropdown-toggle {
  background-color: #a40835;
  border-color: #a40835; }

.bg-primary-alt {
  background-color: rgba(221, 47, 110, 0.1); }

.bg-secondary-alt {
  background-color: rgba(57, 37, 64, 0.1); }

.bg-success-alt {
  background-color: rgba(43, 193, 85, 0.1); }

.bg-info-alt {
  background-color: rgba(47, 76, 221, 0.1); }

.bg-warning-alt {
  background-color: rgba(255, 184, 0, 0.1); }

.bg-danger-alt {
  background-color: rgba(243, 87, 87, 0.1); }

.bg-light-alt {
  background-color: rgba(244, 244, 244, 0.1); }

.bg-dark-alt {
  background-color: rgba(62, 73, 84, 0.1); }

[data-typography="opensans"] {
  font-family: 'Open Sans', sans-serif; }

[data-typography="poppins"] {
  font-family: 'poppins', sans-serif; }

[data-typography="roboto"] {
  font-family: 'Roboto', sans-serif; }

[data-typography="helvetica"] {
  font-family: 'HelveticaNeue'; }

/*$primary  : #7356f1 !default;*/
[data-theme-version="transparent"] {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff; }
  [data-theme-version="transparent"] h1,
  [data-theme-version="transparent"] h2,
  [data-theme-version="transparent"] h3,
  [data-theme-version="transparent"] h4,
  [data-theme-version="transparent"] h5,
  [data-theme-version="transparent"] h6 {
    color: #fff !important; }
  [data-theme-version="transparent"] a.link {
    color: #ddd; }
  [data-theme-version="transparent"] a.link:focus,
  [data-theme-version="transparent"] a.link:hover {
    color: #2F4CDD; }
  [data-theme-version="transparent"] a {
    color: #fff; }
    [data-theme-version="transparent"] a:hover {
      color: #828690; }
  [data-theme-version="transparent"] .border-right {
    border-right: 1px solid #333a54 !important; }
  [data-theme-version="transparent"] .border-left {
    border-left: 1px solid #333a54 !important; }
  [data-theme-version="transparent"] .border-top {
    border-top: 1px solid #333a54 !important; }
  [data-theme-version="transparent"] .border-bottom {
    border-bottom: 1px solid #333a54 !important; }
  [data-theme-version="transparent"] .border {
    border: 1px solid #333a54 !important; }

[data-theme-version="transparent"] .dropdown-menu {
  background-color: #1e2746; }
[data-theme-version="transparent"] .dropdown-item:hover, [data-theme-version="transparent"] .dropdown-item:focus {
  background-color: #181f39;
  color: #fff; }
[data-theme-version="transparent"] .form-control {
  background-color: #181f39;
  border-color: #333a54;
  color: #fff; }
[data-theme-version="transparent"] .card {
  background-color: rgba(0, 0, 0, 0.15); }

[data-theme-version="transparent"] .header {
  background-color: rgba(0, 0, 0, 0.15); }
[data-theme-version="transparent"] .header-left input {
  border-color: #333a54;
  color: #fff; }
  [data-theme-version="transparent"] .header-left input:focus {
    box-shadow: none;
    border-color: #3F9AE0; }
  [data-theme-version="transparent"] .header-left input::placeholder {
    color: #fff; }
[data-theme-version="transparent"] .header-right .dropdown .nav-link:hover {
  color: #fff; }
[data-theme-version="transparent"] .header-right .notification_dropdown .dropdown-item a {
  color: #fff; }

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important; }
[data-theme-version="transparent"] .nav-control {
  color: #fff; }
[data-theme-version="transparent"] .brand-logo {
  color: #fff; }
  [data-theme-version="transparent"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important; }
[data-theme-version="transparent"] .nav-control {
  color: #fff; }
[data-theme-version="transparent"] .brand-logo {
  color: #fff; }
  [data-theme-version="transparent"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="transparent"] .deznav {
  background-color: rgba(0, 0, 0, 0.15) !important; }
  [data-theme-version="transparent"] .deznav .metismenu > li > a {
    color: rgba(0, 0, 0, 0.15); }
  [data-theme-version="transparent"] .deznav .metismenu > li:hover > a, [data-theme-version="transparent"] .deznav .metismenu > li:focus > a, [data-theme-version="transparent"] .deznav .metismenu > li.mm-active > a {
    background-color: rgba(0, 0, 0, 0.15) !important;
    color: #fff; }
    [data-theme-version="transparent"] .deznav .metismenu > li:hover > a::after, [data-theme-version="transparent"] .deznav .metismenu > li:focus > a::after, [data-theme-version="transparent"] .deznav .metismenu > li.mm-active > a::after {
      border-color: transparent transparent #fff transparent; }
  [data-theme-version="transparent"] .deznav .metismenu > li.mm-active ul ul {
    background-color: transparent; }
  [data-theme-version="transparent"] .deznav .metismenu ul {
    background-color: rgba(0, 0, 0, 0.15); }
    [data-theme-version="transparent"] .deznav .metismenu ul a:hover, [data-theme-version="transparent"] .deznav .metismenu ul a:focus, [data-theme-version="transparent"] .deznav .metismenu ul a.mm-active {
      color: #fff; }
  [data-theme-version="transparent"] .deznav .metismenu a {
    color: rgba(0, 0, 0, 0.15); }
  [data-theme-version="transparent"] .deznav .metismenu ul {
    background-color: rgba(0, 0, 0, 0.15) !important; }
  [data-theme-version="transparent"] .deznav .metismenu .has-arrow:after {
    border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent; }

/*$primary  : #7356f1 !default;*/
[data-theme-version="dark"] {
  background: #181f39;
  color: #828690; }
  [data-theme-version="dark"] h1,
  [data-theme-version="dark"] h2,
  [data-theme-version="dark"] h3,
  [data-theme-version="dark"] h4,
  [data-theme-version="dark"] h5,
  [data-theme-version="dark"] h6 {
    color: #fff !important; }
  [data-theme-version="dark"] a.link {
    color: #ddd; }
  [data-theme-version="dark"] a.link:focus,
  [data-theme-version="dark"] a.link:hover {
    color: #2F4CDD; }
  [data-theme-version="dark"] a:hover {
    color: #fff; }
  [data-theme-version="dark"] .border-right {
    border-right: 1px solid #333a54 !important; }
  [data-theme-version="dark"] .border-left {
    border-left: 1px solid #333a54 !important; }
  [data-theme-version="dark"] .border-top {
    border-top: 1px solid #333a54 !important; }
  [data-theme-version="dark"] .border-bottom {
    border-bottom: 1px solid #333a54 !important; }
  [data-theme-version="dark"] .border {
    border: 1px solid #333a54 !important; }

[data-theme-version="dark"] .dropdown-menu {
  background-color: #181f39;
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1); }
  [data-theme-version="dark"] .dropdown-menu .dropdown-item.selected, [data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item:hover, [data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
    background-color: #1e2746;
    color: #7e7e7e; }
[data-theme-version="dark"] .form-control {
  background-color: #181f39;
  border-color: #333a54;
  color: #fff; }
[data-theme-version="dark"] .card {
  background-color: #1e2746;
  box-shadow: none; }
[data-theme-version="dark"] .btn-outline-dark:hover {
  background-color: #181f39;
  border-color: #181f39; }
[data-theme-version="dark"] .tdl-holder input[type=text],
[data-theme-version="dark"] .tdl-holder li {
  background-color: #1e2746;
  border-color: #333a54; }
[data-theme-version="dark"] .tdl-holder input[type=text]::placeholder {
  color: #828690; }
[data-theme-version="dark"] .tdl-holder label:hover {
  background-color: #333a54;
  color: #fff; }
[data-theme-version="dark"] .text-muted {
  color: #828690 !important; }
[data-theme-version="dark"] .modal-content {
  background: #1e2746; }
[data-theme-version="dark"] .modal-footer,
[data-theme-version="dark"] .modal-header {
  border-color: #333a54; }
[data-theme-version="dark"] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none; }
  [data-theme-version="dark"] .close:hover {
    color: #fff; }
[data-theme-version="dark"] .table strong {
  color: #fff; }
[data-theme-version="dark"] .text-dark {
  color: #828690 !important; }
[data-theme-version="dark"] .star-rating .product-review,
[data-theme-version="dark"] .new-arrival-content .item {
  color: #fff; }
[data-theme-version="dark"] .custom-dropdown .dropdown-menu {
  border-color: #333a54; }
[data-theme-version="dark"] .widget-stat .media > span {
  background: #333a54;
  border-color: #333a54;
  color: #fff; }
[data-theme-version="dark"] .plus-minus-input .custom-btn {
  background: #181f39;
  border-color: #333a54; }
[data-theme-version="dark"] .dropdown-divider,
[data-theme-version="dark"] .size-filter ul li {
  border-color: #333a54; }
[data-theme-version="dark"] .custom-select {
  border-color: #333a54;
  color: #828690;
  background: #181f39; }
[data-theme-version="dark"] .nav-tabs {
  border-color: #333a54; }
[data-theme-version="dark"] .mail-list .list-group-item.active i {
  color: #fff; }
[data-theme-version="dark"] hr {
  border-color: #333a54; }
[data-theme-version="dark"] .grid-col {
  background: #181f39; }
[data-theme-version="dark"] .noUi-target {
  border-color: #333a54;
  border-radius: 8px;
  box-shadow: none; }
  [data-theme-version="dark"] .noUi-target .noUi-connects {
    background: #333a54; }
[data-theme-version="dark"] .noUi-marker-large,
[data-theme-version="dark"] .noUi-marker {
  background: #333a54; }
[data-theme-version="dark"] .input-group-text {
  background: #333a54;
  color: #7e7e7e; }
[data-theme-version="dark"] .note-editor.note-frame {
  border-color: #333a54; }
  [data-theme-version="dark"] .note-editor.note-frame .btn {
    color: #fff; }
  [data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
    color: #fff; }
[data-theme-version="dark"] .widget-media .timeline .timeline-panel {
  border-color: #333a54; }
[data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
  border-color: #333a54; }
[data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
  border-color: #333a54; }
[data-theme-version="dark"] .list-group-item-action {
  color: #7e7e7e; }
[data-theme-version="dark"] .list-group-item-action:focus,
[data-theme-version="dark"] .list-group-item-action:hover,
[data-theme-version="dark"] .list-group-item-action:focus {
  background-color: #181f39;
  border-color: #181f39; }
[data-theme-version="dark"] .list-group-item.active {
  color: #fff;
  border-color: #3F9AE0; }
  [data-theme-version="dark"] .list-group-item.active:focus, [data-theme-version="dark"] .list-group-item.active:hover, [data-theme-version="dark"] .list-group-item.active:focus {
    background-color: #3F9AE0;
    border-color: #3F9AE0;
    color: #fff; }
[data-theme-version="dark"] .swal2-popup {
  background: #1e2746; }

[data-theme-version="dark"] .header {
  background-color: #1e2746; }
[data-theme-version="dark"] .header-left input {
  background: #181f39 !important;
  border-color: transparent;
  color: #fff !important; }
  [data-theme-version="dark"] .header-left input:focus {
    box-shadow: none;
    border-color: #3F9AE0; }
  [data-theme-version="dark"] .header-left input::placeholder {
    color: #fff; }
[data-theme-version="dark"] .header-left .search_icon {
  background: #181f39 !important; }
  [data-theme-version="dark"] .header-left .search_icon i {
    color: #fff; }
[data-theme-version="dark"] .header-right .right-sidebar a {
  color: #3F9AE0;
  border-color: #333a54; }
[data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  background: #181f39; }
[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff; }
  [data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
    color: #fff; }
[data-theme-version="dark"] .header-right .notification_dropdown .dropdown-item a {
  color: #fff; }

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
  background-color: #181f39; }
[data-theme-version="dark"] .nav-control {
  color: #fff; }
[data-theme-version="dark"] .brand-logo {
  color: #fff; }
  [data-theme-version="dark"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
  background-color: #181f39; }
[data-theme-version="dark"] .nav-control {
  color: #fff; }
[data-theme-version="dark"] .brand-logo {
  color: #fff; }
  [data-theme-version="dark"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li > ul {
  background-color: #1e2746; }
[data-theme-version="dark"] .fixed-content-box {
  background-color: #1e2746; }
  [data-theme-version="dark"] .fixed-content-box .head-name {
    background: #1e2746;
    color: #fff;
    border-color: #333a54; }
  [data-theme-version="dark"] .fixed-content-box + .header + .deznav {
    background-color: #181f39; }
[data-theme-version="dark"] .deznav {
  background-color: #1e2746; }
  [data-theme-version="dark"] .deznav .metismenu > li > a {
    color: #b3b3b3; }
  [data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-theme-version="dark"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-theme-version="dark"] .deznav .metismenu > li:hover > a::after, [data-theme-version="dark"] .deznav .metismenu > li:focus > a::after, [data-theme-version="dark"] .deznav .metismenu > li.mm-active > a::after {
      border-color: #fff; }
  [data-theme-version="dark"] .deznav .metismenu > li.mm-active ul ul {
    background-color: transparent; }
  [data-theme-version="dark"] .deznav .metismenu ul:after {
    background-color: #333a54; }
  [data-theme-version="dark"] .deznav .metismenu ul a:hover, [data-theme-version="dark"] .deznav .metismenu ul a:focus, [data-theme-version="dark"] .deznav .metismenu ul a.mm-active {
    color: #fff; }
  [data-theme-version="dark"] .deznav .metismenu ul a:before {
    background-color: #fff; }
  [data-theme-version="dark"] .deznav .metismenu a {
    color: #b3b3b3; }
  [data-theme-version="dark"] .deznav .metismenu .has-arrow:after {
    border-color: #fff; }

[data-theme-version="dark"] .sidebar-right {
  background-color: #1d2544; }
  [data-theme-version="dark"] .sidebar-right .nav-tabs {
    background-color: #1d2544;
    border-bottom: 1px solid #181f39; }
    [data-theme-version="dark"] .sidebar-right .nav-tabs .nav-link.active {
      background-color: #181f39; }

[data-theme-version="dark"] .footer {
  background-color: transparent; }
  [data-theme-version="dark"] .footer .copyright {
    background-color: transparent; }
    [data-theme-version="dark"] .footer .copyright p {
      color: #fff; }

/* =============
   Calendar
============= */
.app-fullcalender button {
  border-radius: 0px;
  color: #3e4954; }
.app-fullcalender td {
  border-color: #d7dae3; }

.calendar {
  float: left;
  margin-bottom: 0; }

.fc-view {
  margin-top: 1.875rem; }

.fc-toolbar {
  margin-bottom: 0.3125rem;
  margin-top: 0.9375rem; }
  @media (max-width: 575.98px) {
    .fc-toolbar .fc-left {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.625rem;
      float: none; } }
  @media (max-width: 575.98px) {
    .fc-toolbar .fc-right {
      display: flex;
      justify-content: center;
      float: none;
      margin-bottom: 0.3125rem; } }
  @media (max-width: 575.98px) {
    .fc-toolbar .fc-center {
      display: flex;
      justify-content: center; }
      .fc-toolbar .fc-center * {
        float: none; } }

.fc-toolbar h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-transform: uppercase; }

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
  box-shadow: none; }

.fc-widget-header {
  border: 1px solid #d7dae3;
  border-bottom: 0 !important; }

.fc th.fc-widget-header {
  background: #d7dae3 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem 0;
  text-transform: uppercase; }

.fc-button {
  border: 1px solid #d7dae3;
  text-transform: capitalize; }
  .fc-button.active {
    box-shadow: none !important; }

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem; }

.fc-event {
  border-radius: 0.125rem;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 0.3125rem 0.4375rem;
  padding: 0.3125rem;
  text-align: center; }

.external-event {
  cursor: move;
  margin: 0.625rem 0;
  padding: 0.125rem 0; }

.fc-basic-view td.fc-week-number span {
  padding-right: 0.3125rem; }

.fc-basic-view td.fc-day-number {
  padding-right: 0.3125rem; }

#drop-remove {
  margin-right: 8px;
  top: 0.1875rem; }

#add-category .modal-dialog,
#event-modal .modal-dialog {
  max-width: 37.5rem; }

.fc-content {
  color: #fff; }

.fc th.fc-widget-header {
  background: transparent !important; }

.fc-button {
  background: #fff; }

.fc-state-hover {
  background: #fff !important; }

.fc-state-highlight {
  background: #f2f4fa !important; }
  [data-theme-version="dark"] .fc-state-highlight {
    color: #fff !important; }

.fc-cell-overlay {
  background: #fff !important; }

.fc-unthemed .fc-today {
  background: #f2f4fa !important; }

.fc-day-top {
  color: #3e4954 !important; }
  [data-theme-version="dark"] .fc-day-top {
    color: #fff !important; }

.external-event {
  color: #fff; }
  [data-theme-version="dark"] .external-event {
    color: #fff !important; }

.fc-basic-view .fc-body .fc-row {
  min-height: 1rem; }

.fc-scroller.fc-day-grid-container {
  height: 490px !important; }

.fc-row.fc-week.fc-widget-content.fc-rigid {
  height: 81px !important; }

@media only screen and (max-width: 1440px) {
  .email_left_pane {
    display: none; } }

#external-events .external-event:before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: .9rem;
  position: relative;
  top: 2px; }
#external-events [data-class="bg-primary"] {
  background-color: transparent !important;
  color: #3e4954; }
  [data-theme-version="dark"] #external-events [data-class="bg-primary"] {
    color: #fff !important; }
  #external-events [data-class="bg-primary"]::before {
    background: #3F9AE0; }
#external-events [data-class="bg-success"] {
  background-color: transparent !important;
  color: #3e4954; }
  #external-events [data-class="bg-success"]::before {
    background: #2bc155; }
#external-events [data-class="bg-warning"] {
  background-color: transparent !important;
  color: #3e4954; }
  #external-events [data-class="bg-warning"]::before {
    background: #ffb800; }
#external-events [data-class="bg-dark"] {
  background-color: transparent !important;
  color: #3e4954; }
  #external-events [data-class="bg-dark"]::before {
    background: #3e4954; }
#external-events [data-class="bg-danger"] {
  background-color: transparent !important;
  color: #3e4954; }
  #external-events [data-class="bg-danger"]::before {
    background: #f35757; }
#external-events [data-class="bg-info"] {
  background-color: transparent !important;
  color: #3e4954; }
  #external-events [data-class="bg-info"]::before {
    background: #2F4CDD; }
#external-events [data-class="bg-pink"] {
  background-color: transparent !important;
  color: #3e4954; }
  #external-events [data-class="bg-pink"]::before {
    background: #e83e8c; }

.fc .fc-row .fc-content-skeleton table, .fc .fc-row .fc-content-skeleton td, .fc .fc-row .fc-helper-skeleton td {
  border-color: #d7dae3; }
  [data-theme-version="dark"] .fc .fc-row .fc-content-skeleton table,
  [data-theme-version="dark"] .fc .fc-row .fc-content-skeleton td,
  [data-theme-version="dark"] .fc .fc-row .fc-helper-skeleton td {
    border-color: #333a54; }

[data-theme-version="dark"] .fc-unthemed .fc-content, [data-theme-version="dark"] .fc-unthemed .fc-divider, [data-theme-version="dark"] .fc-unthemed .fc-list-heading td, [data-theme-version="dark"] .fc-unthemed .fc-list-view, [data-theme-version="dark"] .fc-unthemed .fc-popover, [data-theme-version="dark"] .fc-unthemed .fc-row, [data-theme-version="dark"] .fc-unthemed tbody, [data-theme-version="dark"] .fc-unthemed td, [data-theme-version="dark"] .fc-unthemed th, [data-theme-version="dark"] .fc-unthemed thead {
  border-color: #333a54; }

.fc .fc-toolbar-title {
  font-size: 20px;
  margin-bottom: 10px; }

.fc .fc-toolbar {
  display: block;
  text-align: center; }

.email-left-box {
  width: 15rem;
  float: left;
  padding: 0 1.25rem 1.25rem 1rem;
  border-top: 0;
  border-left: 0; }
  @media (min-width: 576px) and (max-width: 991.98px) {
    .email-left-box {
      width: 100%;
      padding-bottom: 0px !important; } }
  @media (max-width: 575.98px) {
    .email-left-box {
      width: 100%;
      float: none;
      border: none;
      padding-bottom: 30px !important; } }
  .email-left-box .intro-title {
    background: rgba(221, 47, 110, 0.1);
    padding: 1rem;
    margin: 1.875rem 0 1.25rem 0; }
    .email-left-box .intro-title h5 {
      margin-bottom: 0;
      color: #6a707e;
      font-size: 14px; }
      .email-left-box .intro-title h5 i {
        font-size: 0.75rem;
        position: relative;
        bottom: 1px; }
    .email-left-box .intro-title i {
      color: #3F9AE0; }

.email-right-box {
  padding-left: 15rem;
  padding-right: 1rem; }
  @media (min-width: 576px) and (max-width: 991.98px) {
    .email-right-box {
      padding-left: 0;
      margin-left: 0 !important;
      clear: both; } }
  @media (max-width: 575.98px) {
    .email-right-box {
      padding-left: 0;
      padding-right: 0; } }
  .email-right-box .right-box-border {
    border-right: 2px solid rgba(221, 47, 110, 0.1); }
  @media screen and (min-width: 649px) and (max-width: 1200px) {
    .email-right-box .right-box-padding {
      padding-left: 1.25rem; } }
  @media (min-width: 1700px) {
    .email-right-box .right-box-padding {
      padding-left: 0.9375rem; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    .email-right-box .right-box-padding {
      padding-left: 0; } }

.toolbar .btn-group .btn {
  border: 0; }
.toolbar .btn-group input {
  position: relative;
  top: 2px; }

.read-content textarea {
  height: 150px; }
.read-content-email {
  font-size: 0.875rem; }
.read-content h5 {
  color: #6a707e; }
.read-content p strong {
  color: #6a707e; }
.read-content-body p {
  margin-bottom: 1.875rem; }
.read-content-attachment {
  padding: 0.5rem 0; }
  .read-content-attachment h6 {
    font-size: 1.125rem;
    color: #6a707e; }
    .read-content-attachment h6 i {
      padding-right: 0.3125rem; }
  .read-content-attachment .attachment > div:not(:last-child) {
    border-right: 1px solid #DDDFE1; }

.compose-content .wysihtml5-toolbar {
  border-color: #eaeaea; }
.compose-content .dropzone {
  background: #f2f4fa !important; }
.compose-content h5 {
  font-size: 1.0625rem;
  color: #6a707e; }
  .compose-content h5 i {
    font-size: 1.125rem;
    transform: rotate(90deg); }
.compose-content .dropzone {
  border: 1px dashed #DDDFE1;
  min-height: 13.125rem;
  position: relative; }
  .compose-content .dropzone .dz-message {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.email-list {
  display: block;
  padding-left: 0; }
  .email-list .message {
    position: relative;
    display: block;
    height: 3.125rem;
    line-height: 3.125rem;
    cursor: default;
    transition-duration: 0.3s; }
    .email-list .message a {
      color: #828690; }
    .email-list .message-single .custom-checkbox {
      margin-top: 2px; }
    .email-list .message-single i {
      color: #89879f;
      font-size: 1.125rem;
      padding-left: .4rem; }
    .email-list .message:hover {
      transition-duration: 0.05s;
      background: rgba(152, 166, 173, 0.15); }
    .email-list .message .col-mail {
      float: left;
      position: relative; }
    .email-list .message .col-mail-1 {
      width: 5.625rem; }
      .email-list .message .col-mail-1 .star-toggle {
        display: block;
        float: left;
        margin-top: 1.125rem;
        font-size: 1rem;
        margin-left: 0.3125rem; }
      .email-list .message .col-mail-1 .email-checkbox {
        display: block;
        float: left;
        margin: 0.9375rem 0.625rem 0 1.25rem; }
      .email-list .message .col-mail-1 .dot {
        display: block;
        float: left;
        border: .4rem solid transparent;
        border-radius: 6.25rem;
        margin: 1.375rem 1.625rem 0;
        height: 0;
        width: 0;
        line-height: 0;
        font-size: 0; }
    .email-list .message .col-mail-2 {
      position: absolute;
      top: 0;
      left: 5.625rem;
      right: 0;
      bottom: 0; }
      .email-list .message .col-mail-2 .subject {
        position: absolute;
        top: 0;
        left: 0;
        right: 5.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .email-list .message .col-mail-2 .date {
        position: absolute;
        top: 0;
        right: 0; }

.email-checkbox {
  cursor: pointer;
  height: 0.9375rem;
  width: 0.9375rem;
  position: relative;
  display: inline-block;
  border-radius: .1rem;
  position: relative;
  top: 0.3125rem;
  box-shadow: inset 0 0 0 0.1rem #828690; }
  .email-checkbox input {
    opacity: 0;
    cursor: pointer; }
    .email-checkbox input:checked label {
      opacity: 1; }
  .email-checkbox label {
    position: absolute;
    top: .3rem;
    left: .3rem;
    right: .3rem;
    bottom: .3rem;
    cursor: pointer;
    opacity: 0;
    margin-bottom: 0 !important;
    transition-duration: 0.05s; }

.mail-list a {
  vertical-align: middle;
  padding: 0.625rem 0.9375rem;
  display: block;
  background: transparent;
  color: #464a53;
  font-weight: 600; }
.mail-list .list-group-item {
  color: #6a707e;
  padding: 0.75rem 1.0625rem; }
  .mail-list .list-group-item i {
    font-size: 1rem;
    padding-right: 0.625rem;
    color: #cccccc; }
  .mail-list .list-group-item.active {
    color: #fff; }
    .mail-list .list-group-item.active i {
      color: #fff; }

.chat-wrap {
  padding: 1.0625rem 1.875rem; }
  .chat-wrap .media .media-body h6 {
    font-size: 1.0625rem;
    color: #6a707e; }
  .chat-wrap .media .media-body p {
    font-size: 0.875rem; }

@media (min-width: 648px) {
  .email-filter {
    padding-left: 1.25rem; } }
@media (min-width: 1700px) {
  .email-filter {
    padding-left: 1.875rem; } }
.email-filter .input-group-prepend i {
  font-size: 0.875rem;
  color: #89879f; }
.email-filter .input-group-prepend .input-group-text {
  border: 0;
  border-bottom: 1px solid #DDDFE1 !important;
  background: transparent; }
.email-filter .input-group .form-control {
  padding: 0 0 0 0.3125rem;
  border: 0;
  font-size: 0.875rem;
  height: 1.875rem;
  color: #89879f;
  border-bottom: 1px solid #DDDFE1; }
  .email-filter .input-group .form-control::placeholder {
    font-size: 0.875rem;
    color: #89879f; }
.email-filter .input-group > .form-control {
  min-height: 1.875rem; }

.single-mail {
  display: block;
  padding: 1.5625rem 0; }
  .single-mail .media {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
    @media (min-width: 1700px) {
      .single-mail .media {
        padding-left: 1.875rem;
        padding-right: 1.875rem; } }
    .single-mail .media img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      margin-right: 0.9375rem; }
      @media (min-width: 1700px) {
        .single-mail .media img {
          margin-right: 1.875rem; } }
    .single-mail .media-body h6 {
      color: #abafb3; }
    .single-mail .media-body h4 {
      font-size: 1rem;
      color: #6a707e; }
      .single-mail .media-body h4 button i {
        font-size: 1.125rem;
        color: #abafb3;
        font-weight: bold;
        transform: rotate(90deg); }
    .single-mail .media-body p {
      font-size: 0.875rem;
      color: #abafb3; }
  .single-mail.active {
    background: #3F9AE0; }
    .single-mail.active h6, .single-mail.active h4, .single-mail.active p, .single-mail.active i {
      color: #fff !important; }

[direction="rtl"] .email-right-box {
  padding-left: 1rem;
  padding-right: 15rem; }
  @media only screen and (max-width: 991px) {
    [direction="rtl"] .email-right-box {
      padding-left: 1rem;
      padding-right: 12rem; } }
  @media only screen and (max-width: 575px) {
    [direction="rtl"] .email-right-box {
      padding-left: 0;
      padding-right: 0; } }
[direction="rtl"] .email-left-box {
  float: right; }
[direction="rtl"] .email-list .message .col-mail-2 {
  right: 5.625rem;
  left: 0;
  float: right; }
  [direction="rtl"] .email-list .message .col-mail-2 .date {
    right: auto;
    left: 0; }
  [direction="rtl"] .email-list .message .col-mail-2 .subject {
    right: 0;
    left: 5.5rem; }

.photo-content {
  position: relative; }
  .photo-content .cover-photo {
    background: url(../images/profile/cover.jpg);
    background-size: cover;
    background-position: center;
    min-height: 250px;
    width: 100%; }

.profile .profile-photo {
  max-width: 100px;
  position: relative;
  z-index: 1;
  margin-top: -40px;
  margin-right: 10px; }
  @media only screen and (max-width: 575px) {
    .profile .profile-photo {
      width: 80px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px; } }

[direction="rtl"] .profile .photo-content .profile-photo {
  left: auto;
  right: 50px; }
[direction="rtl"] .profile .profile-photo {
  left: auto;
  right: 30px; }
  @media only screen and (max-width: 1199px) {
    [direction="rtl"] .profile .profile-photo {
      right: 15px; } }
  @media only screen and (max-width: 575px) {
    [direction="rtl"] .profile .profile-photo {
      width: 80px;
      right: calc(50% - 40px);
      top: -100px; } }

.profile-info {
  padding: 15px 20px; }
  @media only screen and (max-width: 575px) {
    .profile-info {
      padding: 0 0 20px;
      text-align: center; } }
  .profile-info h4 {
    color: #464a53 !important; }
  .profile-info h4.text-primary {
    color: #3F9AE0 !important; }
  .profile-info p {
    color: #828690; }
  .profile-info .prf-col {
    min-width: 250px;
    padding: 10px 50px 0; }

.profile-interest .row {
  margin: -1px; }
  .profile-interest .row img {
    width: 100%; }
  .profile-interest .row .int-col {
    padding: 1px; }
    .profile-interest .row .int-col .interest-cat {
      margin-bottom: 1.40px;
      position: relative;
      display: block; }
      .profile-interest .row .int-col .interest-cat:after {
        background: #000;
        bottom: 0;
        content: "";
        left: 0;
        opacity: 0.5;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0; }
      .profile-interest .row .int-col .interest-cat p {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 5px;
        left: 0;
        margin: 0;
        z-index: 1;
        color: white;
        font-size: 1.20px; }

.profile-tab .nav-item .nav-link {
  font-size: 16px;
  margin-right: 30px;
  transition: all 0.5s ease-in-out;
  border: none;
  border-bottom: .20px solid transparent;
  color: #828690; }
  .profile-tab .nav-item .nav-link:hover, .profile-tab .nav-item .nav-link.active {
    border: 0;
    background: transparent;
    background: transparent;
    border-bottom: 0.2px solid #3F9AE0;
    color: #3F9AE0; }
  @media only screen and (max-width: 575px) {
    .profile-tab .nav-item .nav-link {
      margin-right: 0; } }

.profile-info {
  display: flex; }
  @media only screen and (max-width: 575px) {
    .profile-info {
      display: block; } }
  .profile-info .profile-details {
    display: flex;
    width: 100%; }
    @media only screen and (max-width: 575px) {
      .profile-info .profile-details {
        display: block; }
        .profile-info .profile-details .dropdown {
          position: absolute;
          top: 30px;
          right: 30px; } }

.post-input {
  margin-bottom: 30px; }
  .post-input .form-control {
    height: 75px;
    font-weight: 400;
    margin: 15px 0; }

.profile-uoloaded-post img {
  margin-bottom: 20px; }
.profile-uoloaded-post a h4 {
  margin-bottom: 10px;
  color: #464a53; }

.post-meta li {
  display: inline-block;
  margin-right: 10px; }

.event-chat-ryt .chat-area .chat-reciver,
.event-chat-ryt .chat-area .chat-sender {
  margin-bottom: 1.875rem;
  padding: 0; }
  .event-chat-ryt .chat-area .chat-reciver img,
  .event-chat-ryt .chat-area .chat-sender img {
    border-radius: 30px; }
.event-chat-ryt .chat-area .media {
  position: relative; }
  .event-chat-ryt .chat-area .media-body p {
    margin: 0;
    max-width: 100%;
    display: inline-block;
    position: relative; }
    .event-chat-ryt .chat-area .media-body p span {
      padding: 1rem;
      display: inline-block;
      top: 103%;
      position: relative;
      border: 1px solid #d7dae3; }
.event-chat-ryt .chat-reciver {
  padding: .5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }
  .event-chat-ryt .chat-reciver .media {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    .event-chat-ryt .chat-reciver .media .media-body {
      margin-right: 2rem;
      text-align: right; }
      [direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body {
        text-align: left;
        margin-left: 2rem;
        margin-right: auto; }
      .event-chat-ryt .chat-reciver .media .media-body p {
        background: #fff;
        margin-bottom: 0;
        border-radius: 5px 5px 0 5px; }
        .event-chat-ryt .chat-reciver .media .media-body p span {
          text-align: left;
          border: 1px solid #d7dae3; }
          .event-chat-ryt .chat-reciver .media .media-body p span::after {
            content: "";
            width: 20px;
            height: 20px;
            border-bottom: 1px solid #d7dae3;
            border-right: 1px solid #d7dae3;
            position: absolute;
            right: 0;
            bottom: 0;
            background: #fff;
            -webkit-transform: rotate(-45deg) translateX(15px);
            transform: rotate(-45deg) translateX(15px); }
            [direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p span::after {
              left: 0;
              right: auto;
              -webkit-transform: rotate(135deg) translateY(15px);
              transform: rotate(135deg) translateY(15px); }
        .event-chat-ryt .chat-reciver .media .media-body p .time {
          position: absolute;
          font-size: 12px;
          color: #7e7e7e;
          font-weight: 400;
          bottom: 0;
          left: -80px; }
          [direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p .time {
            right: -5rem;
            left: auto; }
.event-chat-ryt .chat-sender {
  text-align: left;
  padding: .5rem 1rem; }
  .event-chat-ryt .chat-sender .media .media-body {
    margin-left: 2rem; }
    [direction="rtl"] .event-chat-ryt .chat-sender .media .media-body {
      text-align: right;
      margin-right: 2rem;
      margin-left: auto; }
    .event-chat-ryt .chat-sender .media .media-body p {
      background-color: #fff;
      margin-bottom: 0; }
      .event-chat-ryt .chat-sender .media .media-body p span::after {
        content: "";
        width: 20px;
        height: 20px;
        border-bottom: 1px solid #d7dae3;
        border-left: 1px solid #d7dae3;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #fff;
        -webkit-transform: rotate(45deg) translateX(-15px);
        transform: rotate(45deg) translateX(-15px); }
        [direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p span::after {
          left: auto;
          right: 0;
          -webkit-transform: rotate(-135deg) translateY(15px);
          transform: rotate(-135deg) translateY(15px); }
      .event-chat-ryt .chat-sender .media .media-body p .time {
        position: absolute;
        font-size: 10px;
        color: #7e7e7e;
        font-weight: 400;
        bottom: 0;
        right: -5rem; }
        [direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p .time {
          left: -5rem;
          right: auto; }

.char-type {
  padding-top: 30px;
  padding-bottom: 30px; }
  .char-type form .form-control {
    height: 45px;
    padding-left: 18px;
    background: #ffffff;
    border-right: 0; }
  .char-type form .input-group-append i {
    color: #898989;
    font-size: 18px; }
  .char-type form .input-group-append .input-group-text {
    padding-left: .7rem;
    padding-right: .7rem;
    background: #ffffff;
    border-color: #d7dae3;
    border-left: 0; }
    .char-type form .input-group-append .input-group-text:last-child {
      padding-right: 1.8rem; }

.media-avatar {
  padding: 25px 0;
  border-bottom: 1px solid #d7dae3; }
  .media-avatar:last-child {
    border-bottom: 0px; }
  .media-avatar p {
    margin-bottom: 0; }
  .media-avatar .avatar-status {
    position: relative; }
    .media-avatar .avatar-status i {
      position: absolute;
      right: 0;
      bottom: 0; }

.ct-golden-section:before {
  float: none; }

.ct-chart {
  max-height: 15.7rem; }

.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 0.75rem;
  line-height: 1; }

.ct-grid {
  stroke: rgba(49, 58, 70, 0.1); }

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 0.625rem; }

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: #3F9AE0; }

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #2bc155; }

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #ffb800; }

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #f35757; }

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #2F4CDD; }

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #3e4954; }

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #8d6e63; }

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #392540; }

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #00A2FF; }

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #ff9800; }

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #ff9800; }

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 0.625rem;
  padding: 2px 0.625rem;
  border-radius: 3px;
  background: #313a46;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear; }

.chartist-tooltip.tooltip-show {
  opacity: 1; }

#donught_graph .ct-series.ct-series-a .ct-slice-donut {
  stroke: #3FC6D4; }
#donught_graph .ct-series.ct-series-b .ct-slice-donut {
  stroke: #333333; }
#donught_graph .ct-series.ct-series-c .ct-slice-donut {
  stroke: #F63465; }

#visitor_graph {
  height: 253px !important; }

#user_rating_graph {
  height: 280px !important; }

#activity {
  height: 270px !important; }

#visitorOnline {
  height: 72px !important; }

#trendMeter {
  height: 72px !important; }

#widget-revenue1,
#widget-revenue2,
#widget-revenue3 {
  height: 117px !important; }

#widget-profit1,
#widget-profit2,
#widget-profit3 {
  height: 160px !important; }

#comparison-rate {
  height: 230px !important; }

#session_day {
  height: 175px !important;
  width: auto !important;
  margin: 0 auto; }

#walet-status {
  height: 140px !important; }

#bar1 {
  height: 150px !important; }

#sold-product {
  height: 230px !important; }

#chart-venue-expenses,
#chart-online-sale,
#chart-gross-sale {
  height: 150px !important; }

#areaChart_3 {
  height: 295px !important; }

.chart-point {
  display: flex;
  align-items: center; }
  .chart-point .check-point-area {
    width: 100px;
    height: 100px;
    margin-top: -10px;
    margin-left: -10px; }
  .chart-point .chart-point-list {
    margin: 0;
    padding-left: 5px; }
    .chart-point .chart-point-list li {
      list-style: none;
      font-size: 13px;
      padding: 2px 0; }
      .chart-point .chart-point-list li i {
        margin-right: 5px;
        font-size: 11px;
        position: relative;
        top: -1px; }

.c3 {
  height: 250px; }

.c3-legend-item {
  fill: #9fabb1; }

.c3 .c3-axis-x path,
.c3 .c3-axis-x line,
.c3 .c3-axis-y path,
.c3 .c3-axis-y line,
.tick text {
  stroke: #fff; }

.flot-chart {
  height: 15.7rem; }

.tooltipflot {
  background-color: transparent;
  font-size: 1.4rem;
  padding: .5rem 1rem;
  color: rgba(255, 255, 255, 0.7);
  border-radius: .2rem; }

.legendColorBox > div {
  border: 0 !important;
  padding: 0 !important; }

.legendLabel {
  font-size: 0.825rem;
  padding-left: .5rem;
  color: #fff; }

.flotTip {
  background: #000;
  border: 1px solid #000;
  color: #fff; }

.legend > div {
  background: transparent !important; }

#balance_graph {
  height: 260px; }

.morris-hover {
  position: absolute;
  z-index: 1;
  background: #3F9AE0;
  color: #fff; }

.morris-hover .morris-hover-point {
  color: #fff !important;
  margin: 3px 0;
  text-align: center;
  padding: 0 25px; }

.morris-hover .morris-hover-row-label {
  background-color: #3e4954;
  text-align: center;
  padding: 5px;
  margin-bottom: 5px; }

.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden; }

svg text {
  font-weight: 600 !important; }

#morris_donught,
#morris_donught_2,
#line_chart_2,
#morris_bar,
#morris_bar_stalked,
#morris_bar_2,
#morris_area_2,
#morris_area {
  height: 240px !important; }

#morris_line {
  height: 278px !important; }

#crypto-btc-card,
#crypto-eth-card,
#crypto-rpl-card,
#crypto-ltc-card {
  height: 9.375rem; }

#daily-sales, #comparison-rate, #usage-chart, #walet-status {
  width: 100%;
  display: block; }
  #daily-sales canvas, #comparison-rate canvas, #usage-chart canvas, #walet-status canvas {
    max-width: 100% !important;
    width: 100% !important; }

#sparkline-composite-chart canvas,
#composite-bar canvas,
#sparkline11 canvas,
#StackedBarChart canvas,
#spark-bar canvas,
#tristate canvas {
  height: 100px !important; }

#sparkline11 canvas {
  width: 100px !important; }

.easy-pie-chart {
  position: relative;
  text-align: center; }
  .easy-pie-chart .inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block; }
  .easy-pie-chart img {
    width: 75px;
    height: 75px;
    border-radius: 50%; }
  .easy-pie-chart canvas {
    display: block;
    margin: 0 auto; }

#revenue-chart {
  height: 27.7rem; }

#duration-value-axis {
  height: 27.6rem;
  width: 100%; }

#combined-bullet {
  height: 28.125rem;
  width: 100%; }

#zoomable-chart {
  height: 28.125rem;
  width: 100%; }

#chartMap {
  height: 28.125rem;
  width: 100%; }

#professional-candlesticks {
  width: 100%;
  height: 28.125rem; }

#comparing-stock-indices {
  width: 100%;
  height: 28.125rem; }

#multiple-panel-data {
  width: 100%;
  height: 28.125rem; }

#depth-chart {
  width: 100%;
  height: 28.125rem; }

.amcharts-export-menu {
  display: none; }

.amcharts-data-set-selector-div {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 16.875rem;
  margin: 0 auto; }
  .amcharts-data-set-selector-div select {
    border: 0;
    margin-left: 0.625rem;
    background: #ddd;
    color: #000; }

.amChartsInputField {
  border: 0;
  background: #3F9AE0;
  color: #fff;
  padding: 0.3125rem 0.9375rem;
  margin: 0 0.9375rem; }

.amcharts-data-set-select {
  border: 0;
  background: #ddd;
  color: #000; }

.amcharts-period-input,
.amcharts-period-input-selected {
  border: 0;
  margin-left: 0.625rem;
  background: #3F9AE0;
  color: #fff;
  padding: 0.3125rem 0.9375rem; }

.amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 3px 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite; }

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem; } }
@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem; } }
.lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite; }

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px; }
  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem; } }
@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px; }
  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem; } }
.amcharts-graph-column-front {
  -webkit-transition: all .3s .3s ease-out;
  transition: all .3s .3s ease-out; }

.amcharts-graph-column-front:hover {
  fill: #496375;
  stroke: #496375;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out; }

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%; }
  100% {
    stroke-dashoffset: 0%; } }
@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%; }
  100% {
    stroke-dashoffset: 0%; } }
@media only screen and (max-width: 991px) {
  .amChartsPeriodSelector > fieldset > div {
    float: none !important;
    display: block !important;
    margin-bottom: 0.625rem; } }

.highcharts-root text {
  font-weight: 300 !important; }

.highcharts-credits {
  display: none; }

#chart_employee_gender, #chart_employee_status {
  width: auto;
  height: 350px; }

.form-control {
  background: #fff;
  border: 1px solid #d7dae3;
  color: #3e4954;
  height: 56px; }
  @media only screen and (max-width: 1400px) {
    .form-control {
      height: 41px; } }
  .form-control:hover, .form-control:focus, .form-control.active {
    box-shadow: none;
    background: #fff;
    color: #3e4954; }

.input-rounded {
  border-radius: 100px; }

[data-theme-version="light"] .input-primary .form-control,
[data-theme-version="dark"] .input-primary .form-control {
  border-color: #3F9AE0; }
[data-theme-version="light"] .input-primary .input-group-text,
[data-theme-version="dark"] .input-primary .input-group-text {
  background-color: #3F9AE0;
  color: #fff; }
[data-theme-version="light"] .input-danger .form-control,
[data-theme-version="dark"] .input-danger .form-control {
  border-color: #f35757; }
[data-theme-version="light"] .input-danger .input-group-text,
[data-theme-version="dark"] .input-danger .input-group-text {
  background-color: #f35757;
  color: #fff; }
[data-theme-version="light"] .input-info .form-control,
[data-theme-version="dark"] .input-info .form-control {
  border-color: #2F4CDD; }
[data-theme-version="light"] .input-info .input-group-text,
[data-theme-version="dark"] .input-info .input-group-text {
  background-color: #2F4CDD;
  color: #fff; }
[data-theme-version="light"] .input-success .form-control,
[data-theme-version="dark"] .input-success .form-control {
  border-color: #2bc155; }
[data-theme-version="light"] .input-success .input-group-text,
[data-theme-version="dark"] .input-success .input-group-text {
  background-color: #2bc155;
  color: #fff; }
[data-theme-version="light"] .input-warning .form-control,
[data-theme-version="dark"] .input-warning .form-control {
  border-color: #ffb800; }
[data-theme-version="light"] .input-warning .input-group-text,
[data-theme-version="dark"] .input-warning .input-group-text {
  background-color: #ffb800;
  color: #fff; }

[data-theme-version="light"] .input-primary-o .form-control,
[data-theme-version="dark"] .input-primary-o .form-control {
  border-color: #3F9AE0; }
[data-theme-version="light"] .input-primary-o .input-group-text,
[data-theme-version="dark"] .input-primary-o .input-group-text {
  background-color: transparent;
  border-color: #3F9AE0;
  color: #3F9AE0; }
[data-theme-version="light"] .input-danger-o .form-control,
[data-theme-version="dark"] .input-danger-o .form-control {
  border-color: #f35757; }
[data-theme-version="light"] .input-danger-o .input-group-text,
[data-theme-version="dark"] .input-danger-o .input-group-text {
  background-color: transparent;
  border-color: #f35757;
  color: #f35757; }
[data-theme-version="light"] .input-info-o .form-control,
[data-theme-version="dark"] .input-info-o .form-control {
  border-color: #2F4CDD; }
[data-theme-version="light"] .input-info-o .input-group-text,
[data-theme-version="dark"] .input-info-o .input-group-text {
  background-color: transparent;
  border-color: #2F4CDD;
  color: #2F4CDD; }
[data-theme-version="light"] .input-success-o .form-control,
[data-theme-version="dark"] .input-success-o .form-control {
  border-color: #2bc155; }
[data-theme-version="light"] .input-success-o .input-group-text,
[data-theme-version="dark"] .input-success-o .input-group-text {
  background-color: transparent;
  border-color: #2bc155;
  color: #2bc155; }
[data-theme-version="light"] .input-warning-o .form-control,
[data-theme-version="dark"] .input-warning-o .form-control {
  border-color: #ffb800; }
[data-theme-version="light"] .input-warning-o .input-group-text,
[data-theme-version="dark"] .input-warning-o .input-group-text {
  background-color: transparent;
  border-color: #ffb800;
  color: #ffb800; }

.input-group-text {
  background: #d7dae3;
  border: 1px solid transparent;
  min-width: 50px;
  display: flex;
  justify-content: center;
  padding: 0.532rem 0.75rem; }
  .input-group-text i {
    font-size: 16px; }

.custom-file-label {
  height: 40px;
  padding: 0.5rem 0.75rem; }

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0; }

.custom-select {
  background: none;
  border-color: #d7dae3;
  color: #3e4954; }
  .custom-select:focus {
    box-shadow: none;
    border-color: #3F9AE0;
    color: #3F9AE0; }

.custom-file-label:after {
  background: #656C73;
  border: 1px solid #d7dae3;
  color: #fff; }
  [data-theme-version="dark"] .custom-file-label:after {
    background: #333a54;
    border-color: #333a54;
    color: #7e7e7e; }

.custom_file_input .custom-file-label::after {
  height: 100%; }

.form-control:disabled,
.form-control[readonly] {
  background: #fff;
  opacity: 1; }

.custom-file-label {
  background: #fff;
  border-color: #d7dae3; }
  [data-theme-version="dark"] .custom-file-label {
    background: #181f39;
    border-color: #333a54; }

/*$primary  : #7356f1 !default;*/
input[type="checkbox"]:after {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  margin-top: 0px;
  margin-left: -1px;
  border: 1px solid transparent;
  border-radius: 3px;
  background: #d4d7da;
  line-height: 1.3; }
input[type="checkbox"]:checked:after {
  width: 1rem;
  height: 1rem;
  display: block;
  content: "\f00c";
  font-family: 'FontAwesome';
  color: #fff;
  font-weight: 100;
  font-size: 12px;
  text-align: center;
  border-radius: 3px;
  background: #3F9AE0; }

.form-check-label {
  margin-left: 5px;
  margin-top: 3px; }

.form-check-inline .form-check-input {
  margin-right: 0.625rem; }

.custom-control-label:before, .custom-control-label:after {
  top: 2px;
  width: 1.25rem;
  height: 1.25rem;
  border-color: #e7e7e7; }

.rtl .custom-control-label:before, .rtl .custom-control-label:after {
  right: -1.5rem !important;
  left: inherit; }

.custom-control {
  line-height: normal; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important; }

.custom-control-label::before {
  background-color: transparent;
  border-color: #e8e8e8;
  border-width: 2px;
  border-radius: 0.25rem !important; }
  [data-theme-version="dark"] .custom-control-label::before {
    background-color: transparent;
    border-color: #333a54; }

.check-xs .custom-control-label:before,
.check-xs .custom-control-label:after {
  width: 18px;
  height: 18px; }

.check-lg .custom-control-label:before,
.check-lg .custom-control-label:after {
  width: 24px;
  height: 24px; }

.check-xl .custom-control-label:before,
.check-xl .custom-control-label:after {
  width: 28px;
  height: 28px; }

.checkbox-info .custom-control-label::before {
  background-color: transparent;
  border-color: #dee3fa; }
  [data-theme-version="dark"] .checkbox-info .custom-control-label::before {
    background-color: rgba(47, 76, 221, 0.1);
    border-color: transparent; }
.checkbox-info .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2F4CDD;
  border-color: #2F4CDD; }
.checkbox-danger .custom-control-label::before {
  background-color: transparent;
  border-color: #fde6e6; }
  [data-theme-version="dark"] .checkbox-danger .custom-control-label::before {
    background-color: rgba(243, 87, 87, 0.15);
    border-color: transparent; }
.checkbox-danger .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f35757;
  border-color: #f35757; }
.checkbox-success .custom-control-label::before {
  background-color: transparent;
  border-color: #e7faec; }
  [data-theme-version="dark"] .checkbox-success .custom-control-label::before {
    background-color: rgba(43, 193, 85, 0.1);
    border-color: transparent; }
.checkbox-success .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #2bc155;
  border-color: #2bc155; }
.checkbox-warning .custom-control-label::before {
  background-color: transparent;
  border-color: #fff6e0; }
  [data-theme-version="dark"] .checkbox-warning .custom-control-label::before {
    background-color: rgba(255, 184, 0, 0.1);
    border-color: transparent; }
.checkbox-warning .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffb800;
  border-color: #ffb800; }
.checkbox-secondary .custom-control-label::before {
  background-color: transparent;
  border-color: #e8deec; }
  [data-theme-version="dark"] .checkbox-secondary .custom-control-label::before {
    background-color: rgba(57, 37, 64, 0.5);
    border-color: transparent; }
.checkbox-secondary .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #392540;
  border-color: #392540; }

.js-switch + .switchery {
  border-radius: 50px;
  margin-right: 4rem; }
  @media (max-width: 767.98px) {
    .js-switch + .switchery {
      margin-right: 1rem; } }
  .js-switch + .switchery > small {
    top: 2px; }
.js-switch.js-switch-lg + .switchery {
  height: 2rem;
  width: 4.5rem; }
  .js-switch.js-switch-lg + .switchery > small {
    width: 1.75rem;
    height: 1.75rem; }
.js-switch.js-switch-md + .switchery {
  height: 1.5rem;
  width: 3.5rem; }
  .js-switch.js-switch-md + .switchery > small {
    width: 1.25rem;
    height: 1.25rem; }
.js-switch.js-switch-sm + .switchery {
  height: 1rem;
  width: 2.2rem; }
  .js-switch.js-switch-sm + .switchery > small {
    width: 0.875rem;
    height: 0.875rem;
    top: 1px; }
.js-switch-square + .switchery {
  border-radius: 0; }
  .js-switch-square + .switchery > small {
    border-radius: 0;
    top: 2px; }
.js-switch-square.js-switch-lg + .switchery {
  height: 2rem;
  width: 4.5rem; }
  .js-switch-square.js-switch-lg + .switchery > small {
    width: 1.75rem;
    height: 1.75rem; }
.js-switch-square.js-switch-md + .switchery {
  height: 1.5rem;
  width: 3.5rem; }
  .js-switch-square.js-switch-md + .switchery > small {
    width: 1.25rem;
    height: 1.25rem; }
.js-switch-square.js-switch-sm + .switchery {
  height: 1rem;
  width: 2.2rem; }
  .js-switch-square.js-switch-sm + .switchery > small {
    width: 0.875rem;
    height: 0.875rem;
    top: 1px; }

.form-control.is-valid {
  border-color: #2bc155 !important;
  border-right: 0px !important; }
  .form-control.is-valid:focus {
    box-shadow: none; }

.form-control.is-warning {
  border-color: #ffb800 !important;
  border-right: 0px !important; }
  .form-control.is-warning:focus {
    box-shadow: none; }

.form-control.is-invalid {
  border-color: #f35757 !important;
  border-right: 0px !important; }
  .form-control.is-invalid:focus {
    box-shadow: none; }

.is-valid .input-group-prepend .input-group-text i {
  color: #2bc155; }

.is-invalid .input-group-prepend .input-group-text i {
  color: #e8719c; }

.asColorPicker-dropdown {
  max-width: 26rem; }

.asColorPicker-trigger {
  border: 0 none;
  height: 100%;
  position: absolute;
  left: 99%;
  top: 0;
  width: 2.1875rem;
  background: transparent; }

[direction="rtl"] .asColorPicker-trigger {
  right: 99%;
  left: auto; }

.asColorPicker-clear {
  display: none;
  position: absolute;
  right: 1rem;
  text-decoration: none;
  top: .5rem; }

.asColorPicker-trigger span {
  border-radius: 0 0.75rem 0.75rem 0; }

.daterangepicker td.active {
  background-color: #3F9AE0; }
  .daterangepicker td.active:hover {
    background-color: #3F9AE0; }
.daterangepicker button.applyBtn {
  background-color: #3F9AE0;
  border-color: #3F9AE0; }

.datepicker.datepicker-dropdown {
  background: #f2f4fa;
  border-radius: 1px;
  border: 1px solid #EEEEEE; }
  .datepicker.datepicker-dropdown td.day, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev {
    height: 30px;
    width: 30px !important;
    padding: 0;
    text-align: center;
    font-weight: 300;
    border-radius: 50px; }
    .datepicker.datepicker-dropdown td.day:hover, .datepicker.datepicker-dropdown th.next:hover, .datepicker.datepicker-dropdown th.prev:hover {
      box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
      color: #fff; }
  .datepicker.datepicker-dropdown th.datepicker-switch, .datepicker.datepicker-dropdown th.next, .datepicker.datepicker-dropdown th.prev {
    font-weight: 300;
    color: #333; }
  .datepicker.datepicker-dropdown th.dow {
    font-weight: 300; }
.datepicker table tr td.selected, .datepicker table tr td.active.active {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  border: 0; }
.datepicker table tr td.today {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #ffffff; }
  .datepicker table tr td.today:hover {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #ffffff; }
.datepicker table tr td.today.disabled {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #ffffff; }
  .datepicker table tr td.today.disabled:hover {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #ffffff; }

.picker__select--month, .picker__select--year {
  height: 2.5em; }
.picker__input {
  background-color: transparent !important; }
  [data-theme-version="dark"] .picker__input {
    background-color: transparent !important;
    border: 1px solid #333a54; }

.asColorPicker-wrap .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

#image {
  max-width: 100%; }

.docs-options .dropdown-menu {
  padding: 1.5rem; }

.docs-preview {
  margin-bottom: 3rem; }
  .docs-preview .img-preview {
    float: left;
    margin-right: .5rem;
    margin-bottom: .5rem;
    overflow: hidden;
    max-width: 100% !important; }
    .docs-preview .img-preview > img {
      max-width: 100% !important; }
    .docs-preview .img-preview.preview-lg {
      width: 16rem;
      height: 9rem; }
    .docs-preview .img-preview.preview-md {
      width: 8rem;
      height: 4.5rem; }
    .docs-preview .img-preview.preview-sm {
      width: 4rem;
      height: 2.25rem; }
    .docs-preview .img-preview.preview-xs {
      width: 2rem;
      height: 1.125rem;
      margin-right: 0; }

.select2-container {
  width: 100% !important; }

.select2-container--default .select2-selection--single {
  border-radius: 0.75rem;
  border: 1px solid #f4f4f4;
  height: 40px;
  background: #fff; }
  [data-theme-version="dark"] .select2-container--default .select2-selection--single {
    background: #181f39;
    border-color: #333a54; }
  .select2-container--default .select2-selection--single:hover, .select2-container--default .select2-selection--single:focus, .select2-container--default .select2-selection--single.active {
    box-shadow: none; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px;
  color: #7e7e7e;
  padding-left: 15px;
  min-height: 40px; }

.select2-container--default .select2-selection--multiple {
  border-color: #d7dae3;
  border-radius: 0; }

.select2-dropdown {
  border-radius: 0; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #3F9AE0; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #d7dae3;
  background: #fff; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 6px;
  right: 15px; }

.select2-container .select2-selection--multiple {
  min-height: 40px;
  color: #7e7e7e;
  border-radius: 0.75rem;
  border: 1px solid #f4f4f4; }
  [data-theme-version="dark"] .select2-container .select2-selection--multiple {
    background: #181f39;
    border-color: #333a54; }

[data-theme-version="dark"] .select2-search--dropdown .select2-search__field {
  background: #1e2746;
  border-color: #333a54; }

.select2-dropdown {
  border-color: #f4f4f4; }
  [data-theme-version="dark"] .select2-dropdown {
    background: #181f39;
    border-color: #333a54; }

.swal2-popup .swal2-content {
  color: #7e7e7e; }

/*$primary  : #7356f1 !default;*/
/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
.wizard > .content > .body input.error {
  position: absolute;
  top: 100% !important; }

.wizard {
  position: relative; }
  .wizard > .steps {
    width: 100%;
    margin-bottom: 20px; }
    @media (min-width: 1200px) {
      .wizard > .steps {
        width: 100%; } }
    .wizard > .steps li.current a {
      background-color: #3F9AE0;
      color: #fff; }
    .wizard > .steps li.disabled a, .wizard > .steps li.done a {
      background-color: #fff;
      color: #333; }
      .wizard > .steps li.disabled a:hover, .wizard > .steps li.done a:hover {
        margin: 0;
        background-color: #fff;
        border-radius: 0;
        color: #333; }
    .wizard > .steps li a {
      text-align: center;
      margin: 0;
      border-radius: 0;
      background-color: #fff; }
      .wizard > .steps li a:hover {
        margin: 0;
        background-color: #fff;
        border-radius: 0;
        color: #333; }
      @media only screen and (max-width: 767px) {
        .wizard > .steps li a {
          padding: 1em 4px; } }
      .wizard > .steps li a .number {
        display: none; }
  .wizard .content {
    min-height: 32rem;
    margin: 0; }
    @media (max-width: 991.98px) {
      .wizard .content {
        min-height: 41rem; } }
    .wizard .content > .body {
      background-color: transparent;
      width: 100%;
      height: 100%;
      padding: 0; }
      @media only screen and (max-width: 575px) {
        .wizard .content > .body {
          padding: 0; } }
      .wizard .content > .body .form-control {
        border: 1px solid #d7dae3; }
        [data-theme-version="dark"] .wizard .content > .body .form-control {
          border-color: #333a54; }
      .wizard .content > .body input.error {
        position: relative; }
      .wizard .content > .body label.error {
        margin-top: 5px;
        margin-left: 0;
        margin-bottom: 0; }
  .wizard .emial-setup label.mailclinet {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    cursor: pointer;
    background-color: #EEF5F9;
    text-align: center;
    margin: auto; }
    [data-theme-version="dark"] .wizard .emial-setup label.mailclinet {
      background-color: #181f39; }
    @media only screen and (max-width: 575px) {
      .wizard .emial-setup label.mailclinet {
        width: 7rem;
        height: 7rem; } }
    .wizard .emial-setup label.mailclinet .mail-icon {
      font-size: 3rem;
      display: inline-block;
      line-height: 1;
      margin-top: -1rem; }
      @media only screen and (max-width: 575px) {
        .wizard .emial-setup label.mailclinet .mail-icon {
          font-size: 2rem; } }
    .wizard .emial-setup label.mailclinet .mail-text {
      font-size: 1rem;
      text-align: center;
      margin-top: .5rem; }
      @media only screen and (max-width: 575px) {
        .wizard .emial-setup label.mailclinet .mail-text {
          font-size: 16px;
          line-height: 20px; } }
    .wizard .emial-setup label.mailclinet input[type="radio"] {
      display: none; }
  .wizard .skip-email {
    margin-top: 6rem; }
    .wizard .skip-email p {
      margin-bottom: 1rem; }
    .wizard .skip-email a {
      color: #3F9AE0;
      text-decoration: underline; }
  .wizard > .actions {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2rem; }
    .wizard > .actions li:not(.disabled) a {
      background-color: #3F9AE0;
      color: #fff; }

.wizard .actions ul li.disabled a {
  color: #333333 !important;
  background: #F7FAFC !important;
  border: 1px solid #EEEEEE; }
  [data-theme-version="dark"] .wizard .actions ul li.disabled a {
    background: #181f39 !important;
    border-color: #333a54;
    color: #fff !important; }
.wizard .actions ul li a {
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  padding: 0.55em 2em; }

.wizard > .actions a, .wizard > .actions a:hover, .wizard > .actions a:active {
  padding: 0.55em 2em; }

.wizard > .content {
  background: transparent; }

.wizard, .tabcontrol {
  overflow: visible; }

.step-form-horizontal .wizard {
  padding-top: 3.5rem; }
  .step-form-horizontal .wizard .steps li {
    position: relative; }
    .step-form-horizontal .wizard .steps li.done a {
      text-align: center;
      background-color: #fff;
      color: #3e4954; }
      [data-theme-version="dark"] .step-form-horizontal .wizard .steps li.done a {
        background-color: #181f39;
        color: #7e7e7e; }
      .step-form-horizontal .wizard .steps li.done a:hover {
        text-align: center;
        background-color: #fff;
        color: #3e4954; }
      .step-form-horizontal .wizard .steps li.done a .number {
        color: #fff;
        background: #3F9AE0;
        border-color: transparent; }
        [data-theme-version="dark"] .step-form-horizontal .wizard .steps li.done a .number {
          border-color: #3F9AE0; }
    .step-form-horizontal .wizard .steps li.current a, .step-form-horizontal .wizard .steps li.disabled a {
      background-color: transparent;
      text-align: center;
      color: #fff; }
      .step-form-horizontal .wizard .steps li.current a:hover, .step-form-horizontal .wizard .steps li.current a:active, .step-form-horizontal .wizard .steps li.disabled a:hover, .step-form-horizontal .wizard .steps li.disabled a:active {
        background-color: transparent;
        color: #fff; }
    .step-form-horizontal .wizard .steps li.current a .number {
      color: #3F9AE0;
      background: #fff;
      border: 2px solid #3F9AE0; }
    .step-form-horizontal .wizard .steps li.disabled a .number {
      color: #3F9AE0; }
    .step-form-horizontal .wizard .steps li a {
      position: relative;
      z-index: 1; }
      @media (max-width: 767.98px) {
        .step-form-horizontal .wizard .steps li a {
          padding-left: 0;
          padding-right: 0;
          margin: 0; } }
      @media (max-width: 575.98px) {
        .step-form-horizontal .wizard .steps li a {
          font-size: 0.75rem;
          padding-top: 0.9375rem; } }
      .step-form-horizontal .wizard .steps li a .number {
        position: absolute;
        top: -55px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        border: 3px solid #d7dae3;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        border-radius: 50px;
        width: 3rem; }
        [data-theme-version="dark"] .step-form-horizontal .wizard .steps li a .number {
          background: #181f39;
          border-color: #333a54; }
        @media only screen and (max-width: 575px) {
          .step-form-horizontal .wizard .steps li a .number {
            top: -45px; } }
    .step-form-horizontal .wizard .steps li:not(:last-child) {
      z-index: 1; }
      .step-form-horizontal .wizard .steps li:not(:last-child)::after {
        position: absolute;
        width: 100%;
        height: 0.375rem;
        content: "";
        left: 50%;
        top: -2.1rem;
        background-color: #3F9AE0;
        z-index: -1; }
        @media (max-width: 575.98px) {
          .step-form-horizontal .wizard .steps li:not(:last-child)::after {
            height: 0.375rem;
            top: -1.3125rem; } }
      .step-form-horizontal .wizard .steps li:not(:last-child).current::after {
        background-color: #F3F3F3; }
        [data-theme-version="dark"] .step-form-horizontal .wizard .steps li:not(:last-child).current::after {
          background: #181f39; }
      .step-form-horizontal .wizard .steps li:not(:last-child).current ~ li::after {
        background-color: #F3F3F3; }
        [data-theme-version="dark"] .step-form-horizontal .wizard .steps li:not(:last-child).current ~ li::after {
          background: #181f39; }
  .step-form-horizontal .wizard .actions {
    padding: 0; }
    @media only screen and (max-width: 575px) {
      .step-form-horizontal .wizard .actions {
        padding: 0; } }

.step-form-horizontal ol {
  position: relative;
  display: flex !important;
  justify-content: space-between; }
  .step-form-horizontal ol li {
    position: relative;
    border: 0;
    margin-bottom: 0;
    background: transparent !important;
    z-index: 2;
    padding: 0 !important;
    width: 33.33%; }
    .step-form-horizontal ol li:after {
      content: none !important; }
    .step-form-horizontal ol li:before {
      content: "";
      height: 3px;
      width: 0;
      left: 0;
      top: 23px;
      position: absolute;
      background: #3F9AE0;
      -webkit-transition: all 0.5s;
      -ms-transition: all 0.5s;
      transition: all 0.5s;
      z-index: 1; }
    .step-form-horizontal ol li.go767851704 {
      text-align: center;
      background-color: #fff;
      color: #3e4954; }
      .step-form-horizontal ol li.go767851704:before {
        width: 100%; }
      [data-theme-version="dark"] .step-form-horizontal ol li.go767851704 {
        background-color: #181f39;
        color: #7e7e7e; }
      .step-form-horizontal ol li.go767851704:hover {
        text-align: center;
        background-color: #fff;
        color: #3e4954; }
      .step-form-horizontal ol li.go767851704 span {
        color: #fff;
        background: #3F9AE0;
        border-color: transparent; }
        [data-theme-version="dark"] .step-form-horizontal ol li.go767851704 span {
          border-color: #3F9AE0; }
    .step-form-horizontal ol li.active, .step-form-horizontal ol li.go2478912419, .step-form-horizontal ol li.disabled {
      background-color: transparent;
      text-align: center;
      color: #fff;
      border: 0; }
      .step-form-horizontal ol li.active:hover, .step-form-horizontal ol li.active:active, .step-form-horizontal ol li.go2478912419:hover, .step-form-horizontal ol li.go2478912419:active, .step-form-horizontal ol li.disabled:hover, .step-form-horizontal ol li.disabled:active {
        background-color: transparent;
        color: #fff; }
    .step-form-horizontal ol li.active span, .step-form-horizontal ol li.go2478912419 span {
      color: #3F9AE0;
      background: #fff;
      border: 2px solid #3F9AE0; }
    .step-form-horizontal ol li.disabled span {
      color: #3F9AE0; }
    @media (max-width: 767.98px) {
      .step-form-horizontal ol li {
        padding-left: 0;
        padding-right: 0;
        margin: 0; } }
    @media (max-width: 575.98px) {
      .step-form-horizontal ol li {
        font-size: 0.75rem;
        padding-top: 0.9375rem; } }
    .step-form-horizontal ol li span {
      position: relative;
      background-color: #fff;
      border: 3px solid #d7dae3;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3rem;
      border-radius: 50px;
      width: 3rem;
      padding: 0 !important;
      box-shadow: 0 0 0 3px #fff;
      z-index: 2;
      position: relative; }
      [data-theme-version="dark"] .step-form-horizontal ol li span {
        background: #181f39;
        border-color: #333a54; }
    .step-form-horizontal ol li:last-child {
      width: auto; }
  .step-form-horizontal ol::after {
    position: absolute;
    width: 100%;
    height: 4px;
    content: "";
    left: 0;
    top: 23px;
    background-color: #eee;
    z-index: 1; }
.step-form-horizontal .actions {
  padding: 0; }
  @media only screen and (max-width: 575px) {
    .step-form-horizontal .actions {
      padding: 0; } }

label.mailclinet {
  width: 150px;
  height: 150px;
  background: #eee;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  font-size: 14px; }
  @media only screen and (max-width: 575px) {
    label.mailclinet {
      font-size: 12px;
      width: 120px;
      height: 120px; } }
  label.mailclinet input {
    visibility: hidden;
    position: absolute; }
  label.mailclinet .mail-icon {
    display: block;
    font-size: 24px; }

.MuiInput-underline:after,
.MuiInput-underline:before {
  content: none !important; }

.calendar__head {
  background: #3F9AE0; }

body .picker-input__text,
body .MuiInputBase-input {
  box-sizing: border-box;
  border: 1px solid #eee;
  height: 56px;
  padding: 10px 20px; }

.calendar__day--start,
.calendar__day--end,
.calendar__day--selected {
  background: #3F9AE0; }

.calendar__day--range {
  background: #f6c8d9; }

.note-icon-caret,
.note-popover {
  display: none; }

.note-editor.note-frame .panel-heading {
  padding: .6rem 1rem 1rem;
  z-index: 1; }

.note-editor.note-frame {
  border-color: #d7dae3; }

.note-editor.note-frame .note-editing-area .note-editable {
  background-color: #fff; }
  [data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
    background-color: #181f39; }

.note-editor.note-frame .note-statusbar {
  background-color: #fff; }

.ql-container {
  height: 25rem; }

#world-datamap {
  padding-bottom: 46% !important; }

.datamaps-hoverover {
  background: #fff;
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  font-family: 'Roboto' !important;
  color: #3F9AE0;
  border: 1px solid rgba(221, 47, 110, 0.3); }

@media only screen and (max-width: 1440px) {
  .world_map_card ul.list-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 35px; } }

.jqvmap-zoomin,
.jqvmap-zoomout {
  height: 20px;
  width: 20px;
  line-height: 14px;
  background-color: #3F9AE0;
  color: #fff; }

.jqvmap-zoomout {
  top: 35px; }

#world-map {
  height: 350px; }
  @media only screen and (max-width: 575px) {
    #world-map {
      height: 200px; } }

.svg-map__location {
  fill: #eff2f4;
  stroke: #eff2f4; }
  .svg-map__location:hover {
    fill: #000000; }

.blockUI.blockMsg.blockPage {
  border: 0 !important; }

#loginForm {
  cursor: auto; }

.blockMsg {
  border: 0px !important;
  width: 20% !important; }
  .blockMsg h1 {
    font-size: 16px;
    padding: 8px 0;
    margin-bottom: 0; }

.bootstrap-select {
  margin-bottom: 0; }
  .bootstrap-select .btn {
    border: 1px solid #d7dae3 !important;
    background-color: transparent !important;
    font-weight: 400;
    color: #7e7e7e !important; }
    [data-theme-version="dark"] .bootstrap-select .btn {
      border-color: #333a54 !important; }
    .bootstrap-select .btn:active, .bootstrap-select .btn:focus, .bootstrap-select .btn:hover {
      outline: none !important;
      outline-offset: 0; }
      [data-theme-version="dark"] .bootstrap-select .btn:active, [data-theme-version="dark"] .bootstrap-select .btn:focus, [data-theme-version="dark"] .bootstrap-select .btn:hover {
        color: #7e7e7e !important; }
  .bootstrap-select .dropdown-menu {
    border-color: #d7dae3 !important;
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1); }
    .bootstrap-select .dropdown-menu .dropdown-item {
      padding: 0.25rem 1rem; }
    [data-theme-version="dark"] .bootstrap-select .dropdown-menu {
      border-color: #d7dae3 !important; }

.input-group > .bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/*$primary  : #7356f1 !default;*/
.clipboard-btn {
  transition: all .1s ease-in-out; }
  .clipboard-btn:hover {
    background-color: #3F9AE0;
    color: #fff; }

.crypto-ticker {
  background: rgba(0, 0, 0, 0.5);
  margin-top: 20px;
  padding: 10px 20px;
  border-radius: 3px;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15); }
  [data-theme-version="dark"] .crypto-ticker {
    background: #1e2746; }

#webticker-big {
  font: inherit !important;
  font-size: inherit !important;
  font-weight: normal !important; }
  #webticker-big li i {
    font-size: 18px;
    margin-right: 7px; }
  #webticker-big li p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 700; }

/*$primary  : #7356f1 !default;*/
.twitter-typeahead {
  width: 100%; }
  .twitter-typeahead .tt-dataset.tt-dataset-states {
    border: 1px solid #d7dae3; }
  .twitter-typeahead .tt-menu {
    width: 100%;
    background-color: #fff; }
    .twitter-typeahead .tt-menu .tt-suggestion {
      padding: 0.625rem;
      cursor: pointer; }
      .twitter-typeahead .tt-menu .tt-suggestion:hover {
        background-color: #3F9AE0;
        color: #fff; }

.weather-one i {
  font-size: 8rem;
  position: relative;
  top: .5rem; }
.weather-one h2 {
  display: inline-block;
  float: right;
  font-size: 4.8rem; }
.weather-one .city {
  position: relative;
  text-align: right;
  top: -2.5rem; }
.weather-one .currently {
  font-size: 1.6rem;
  font-weight: 400;
  position: relative;
  top: 2.5rem; }
.weather-one .celcious {
  text-align: right;
  font-size: 2rem; }

/*$primary  : #7356f1 !default;*/
.noUi-target {
  border-color: transparent;
  border-radius: 0; }

.noUi-connect {
  background-color: #3F9AE0; }
  .noUi-connects {
    background-color: #D2D6DE; }
  .noUi-connect.c-1-color {
    background-color: #2bc155; }
  .noUi-connect.c-2-color {
    background-color: #2F4CDD; }
  .noUi-connect.c-3-color {
    background-color: #3F9AE0; }
  .noUi-connect.c-4-color {
    background-color: #ffb800; }

.noUi-vertical {
  width: 0.375rem; }

.noUi-horizontal {
  height: 0.375rem;
  margin-bottom: 10px; }

.noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {
  height: 12px;
  width: 12px;
  border-radius: 50px;
  box-shadow: none;
  border: none;
  background-color: #3F9AE0; }
  .noUi-horizontal .noUi-handle::after, .noUi-horizontal .noUi-handle::before, .noUi-vertical .noUi-handle::after, .noUi-vertical .noUi-handle::before {
    display: none; }

.noUi-vertical .noUi-handle {
  left: -4px;
  top: -6px; }

.noUi-horizontal .noUi-handle {
  top: -4px; }

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -6px; }

#slider-toggle {
  height: 50px; }

#slider-toggle.off .noUi-handle {
  border-color: #3F9AE0; }

.colorpicker-slider .sliders.noUi-target#red, .colorpicker-slider .sliders.noUi-target#green, .colorpicker-slider .sliders.noUi-target#blue {
  margin: 10px;
  display: inline-block;
  height: 200px; }
.colorpicker-slider .sliders.noUi-target#red .noUi-connect {
  background: #c0392b; }
.colorpicker-slider .sliders.noUi-target#green .noUi-connect {
  background: #27ae60; }
.colorpicker-slider .sliders.noUi-target#blue .noUi-connect {
  background: #2980b9; }
.colorpicker-slider #result {
  margin: 60px 26px;
  height: 100px;
  width: 100px;
  display: inline-block;
  vertical-align: top;
  color: #7f7f7f;
  background: #7f7f7f;
  border: 1px solid #fff;
  box-shadow: 0 0 10px; }

.slider-vertical {
  height: 18rem; }

.nestable-cart {
  overflow: hidden; }

.dd-handle {
  color: #fff;
  background: #3F9AE0;
  border-radius: 0;
  border: 1px solid #d7dae3; }

.dd-handle:hover {
  color: #fff;
  background: #3F9AE0; }

.dd3-content:hover {
  color: #fff;
  background: #3F9AE0; }

.dd3-content {
  color: #fff; }

.dd .nestable-item-name {
  color: #fff;
  background: #3F9AE0;
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 13px;
  margin-bottom: -4px; }

.pignose-calendar {
  box-shadow: none;
  width: 100%;
  max-width: none;
  border-color: #3F9AE0; }
  .pignose-calendar .pignose-calendar-top-date {
    background-color: #3F9AE0; }
    .pignose-calendar .pignose-calendar-top-date .pignose-calendar-top-month {
      color: #fff; }

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: #3F9AE0;
  box-shadow: none; }

.pignose-calendar .pignose-calendar-top {
  box-shadow: none;
  border-bottom: 0; }

.pignose-calendar.pignose-calendar-blue {
  background-color: rgba(0, 0, 0, 0.15); }

.pignose-calendar .pignose-calendar-unit {
  height: 4.8em; }

.cd-h-timeline {
  opacity: 0;
  transition: opacity 0.2s; }

.cd-h-timeline--loaded {
  opacity: 1; }

.cd-h-timeline__container {
  position: relative;
  height: 100px;
  max-width: 800px; }

.cd-h-timeline__dates {
  position: relative;
  height: 100%;
  margin: 0 40px;
  overflow: hidden; }
  .cd-h-timeline__dates::after, .cd-h-timeline__dates::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 20px; }
  .cd-h-timeline__dates::before {
    left: 0;
    background: #3F9AE0; }
  .cd-h-timeline__dates::after {
    right: 0;
    background: #3F9AE0; }

.cd-h-timeline__line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 2px;
  background-color: #3F9AE0;
  transition: transform 0.4s; }

.cd-h-timeline__filling-line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #2bc155;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.3s; }

.cd-h-timeline__date {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 0.8em;
  padding-bottom: var(--space-sm);
  color: var(--cd-color-1);
  user-select: none;
  text-decoration: none; }
  .cd-h-timeline__date::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: #de3371;
    background-color: #3F9AE0;
    transition: background-color 0.3s, border-color .3s; }
  .cd-h-timeline__date:hover::after {
    background-color: #2bc155;
    border-color: #2bc155; }

.cd-h-timeline__date--selected {
  pointer-events: none; }
  .cd-h-timeline__date--selected::after {
    background-color: #2bc155;
    border-color: #2bc155; }

.cd-h-timeline__date--older-event::after {
  border-color: #2bc155; }

.cd-h-timeline__navigation {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: #de3371;
  transition: border-color 0.3s; }
  .cd-h-timeline__navigation::after {
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }
  .cd-h-timeline__navigation:hover {
    border-color: #2bc155; }

.cd-h-timeline__navigation--prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg); }

.cd-h-timeline__navigation--next {
  right: 0; }

.cd-h-timeline__navigation--inactive {
  cursor: not-allowed; }
  .cd-h-timeline__navigation--inactive::after {
    background-position: 0 -16px; }
  .cd-h-timeline__navigation--inactive:hover {
    border-color: #de3371; }

.cd-h-timeline__events {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: height .4s; }

.cd-h-timeline__event {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  padding: 1px 5%;
  opacity: 0;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out; }

.cd-h-timeline__event--selected {
  position: relative;
  z-index: 2;
  opacity: 1;
  transform: translateX(0); }

.cd-h-timeline__event--enter-right,
.cd-h-timeline__event--leave-right {
  animation-name: cd-enter-right; }

.cd-h-timeline__event--enter-left,
.cd-h-timeline__event--leave-left {
  animation-name: cd-enter-left; }

.cd-h-timeline__event--leave-right,
.cd-h-timeline__event--leave-left {
  animation-direction: reverse; }

.cd-h-timeline__event-content {
  max-width: 800px; }

.cd-h-timeline__event-title {
  color: var(--cd-color-1);
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: var(--text-xxxl); }

.cd-h-timeline__event-date {
  display: block;
  font-style: italic;
  margin: var(--space-xs) auto; }
  .cd-h-timeline__event-date::before {
    content: '- '; }

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: translateX(0%); } }
@keyframes cd-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: translateX(0%); } }
.toast-success {
  background-color: #3F9AE0; }
.toast-info {
  background-color: #2F4CDD; }
.toast-warning {
  background-color: #ffb800; }
.toast-error {
  background-color: #f35757; }

#toast-container > div {
  box-shadow: none;
  border-radius: 0;
  width: auto;
  max-width: 250px;
  opacity: 1; }
  [direction="rtl"] #toast-container > div {
    padding: 15px 50px 15px 15px;
    background-position: calc(100% - 15px);
    text-align: right; }
  #toast-container > div:hover {
    box-shadow: none; }
#toast-container .toast-title {
  margin-bottom: 5px;
  font-weight: 600; }
#toast-container .toast-message {
  font-size: 12px; }
#toast-container .toast-close-button {
  opacity: 1;
  font-size: 8px;
  font-weight: normal;
  text-shadow: none; }

[direction="rtl"] .toast-top-right.demo_rtl_class {
  left: 12px;
  right: auto; }

.jqvmap-zoomin, .jqvmap-zoomout {
  position: absolute;
  left: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #000000;
  padding: 3px;
  color: white;
  width: 17px;
  height: 17px;
  cursor: pointer;
  line-height: 10px;
  text-align: center; }

.jqvmap-zoomin {
  top: 10px; }

.jqvmap-zoomout {
  top: 30px; }

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
  background-color: transparent;
  opacity: 0.9; }

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #DAE2F3;
  width: 4px; }

.ps__thumb-y {
  background-color: #DAE2F3;
  width: 4px; }

.total-average {
  position: relative;
  height: 300px; }

.widget-chat {
  position: relative;
  height: 250px; }

.widget-todo {
  position: relative;
  height: 210px; }

.widget-team {
  position: relative;
  height: 285px; }

.widget-timeline {
  position: relative; }

.widget-comments {
  position: relative;
  height: 400px; }

.sidebar-right-inner {
  position: relative;
  height: 100%; }

.widget-team .ps .ps__rail-x:hover,
.widget-team .ps .ps__rail-y:hover,
.widget-team .ps .ps__rail-x:focus,
.widget-team .ps .ps__rail-y:focus,
.widget-team .ps .ps__rail-x.ps--clicking,
.widget-team .ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9; }

.accordion-primary .accordion__header {
  background: #3F9AE0;
  border-color: #3F9AE0;
  color: #fff;
  box-shadow: 0 15px 20px 0 rgba(221, 47, 110, 0.15); }
  .accordion-primary .accordion__header.collapsed {
    background: #fdf4f8;
    border-color: #fdf4f8;
    color: #211c37;
    box-shadow: none; }
    [data-theme-version="dark"] .accordion-primary .accordion__header.collapsed {
      background: rgba(221, 47, 110, 0.2);
      border-color: rgba(221, 47, 110, 0.2);
      color: #7e7e7e; }
.accordion-primary-solid .accordion__header {
  background: #3F9AE0;
  border-color: #3F9AE0;
  color: #fff;
  box-shadow: 0 -10px 20px 0 rgba(221, 47, 110, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .accordion-primary-solid .accordion__header.collapsed {
    background: #fdf4f8;
    border-color: #fdf4f8;
    color: #211c37;
    box-shadow: none;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem; }
    [data-theme-version="dark"] .accordion-primary-solid .accordion__header.collapsed {
      background: rgba(221, 47, 110, 0.2);
      border-color: rgba(221, 47, 110, 0.2);
      color: #7e7e7e; }
.accordion-primary-solid .accordion__body {
  border: 2px solid #3F9AE0;
  border-top: none;
  box-shadow: 0 15px 20px 0 rgba(221, 47, 110, 0.15);
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }
.accordion-danger .accordion__header {
  background: #f35757;
  border-color: #f35757;
  color: #fff;
  box-shadow: 0 15px 20px 0 rgba(243, 87, 87, 0.15); }
  .accordion-danger .accordion__header.collapsed {
    background: #fde6e6;
    border-color: #fde6e6;
    color: #211c37;
    box-shadow: none; }
.accordion-danger-solid .accordion__header {
  background: #f35757;
  border-color: #f35757;
  color: #fff;
  box-shadow: 0 -10px 20px 0 rgba(243, 87, 87, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .accordion-danger-solid .accordion__header.collapsed {
    background: #fde6e6;
    border-color: #fde6e6;
    color: #211c37;
    box-shadow: none;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem; }
    [data-theme-version="dark"] .accordion-danger-solid .accordion__header.collapsed {
      background: rgba(243, 87, 87, 0.15);
      border-color: rgba(243, 87, 87, 0.15);
      color: #7e7e7e; }
.accordion-danger-solid .accordion__body {
  border: 2px solid #f35757;
  border-top: none;
  box-shadow: 0 15px 20px 0 rgba(243, 87, 87, 0.15);
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }
.accordion__item {
  margin-bottom: 1.25rem; }
.accordion__header {
  padding: 1rem 1.75rem;
  border: 1px solid #d7dae3;
  cursor: pointer;
  position: relative;
  color: #333;
  font-weight: 400;
  border-radius: 0.75rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  [data-theme-version="dark"] .accordion__header {
    color: #fff;
    border-color: #333a54; }
  .accordion__header--indicator {
    font-family: 'themify';
    position: absolute;
    right: 1.5625rem;
    top: 50%;
    transform: translateY(-50%); }
    [direction="rtl"] .accordion__header--indicator {
      right: auto;
      left: 1.5625rem; }
    .accordion__header--indicator.indicator_bordered {
      display: inline-block;
      width: 25px;
      text-align: center;
      height: 25px;
      border: 1px solid #d7dae3;
      border-radius: 50%;
      line-height: 25px; }
  .accordion__header:not(.collapsed) .accordion__header--indicator::before {
    content: "\e622"; }
  .accordion__header:not(.collapsed) .accordion__header--indicator.style_two::before {
    content: "\e648"; }
  .accordion__header.collapsed .accordion__header--indicator::before {
    content: "\e61a"; }
  .accordion__header.collapsed .accordion__header--indicator.style_two::before {
    content: "\e64b"; }
.accordion__body--text {
  padding: 0.875rem 1.25rem; }
.accordion-bordered .accordion__body {
  border: 1px solid #d7dae3;
  border-top: none;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }
  [data-theme-version="dark"] .accordion-bordered .accordion__body {
    border-color: #333a54; }
.accordion-bordered .accordion__header.collapsed {
  border-radius: 0.75rem; }
.accordion-bordered .accordion__header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
.accordion-no-gutter .accordion__item {
  margin-bottom: 0; }
  .accordion-no-gutter .accordion__item .accordion__header.collapsed {
    border-bottom: none; }
  .accordion-no-gutter .accordion__item:last-child .accordion__header {
    border-bottom: 1px solid #d7dae3; }
    [data-theme-version="dark"] .accordion-no-gutter .accordion__item:last-child .accordion__header {
      border-color: #333a54; }
.accordion-no-gutter.accordion__bordered .accordion__item:not(:last-child) .accordion__body {
  border-bottom: none; }
.accordion-left-indicator .accordion__header--text {
  padding-left: 2.5rem; }
.accordion-left-indicator .accordion__header--indicator {
  right: auto;
  left: 1.5625rem; }
.accordion-with-icon .accordion__header--text {
  padding-left: 2.5rem; }
  [direction="rtl"] .accordion-with-icon .accordion__header--text {
    padding-left: 0;
    padding-right: 2.5rem; }
.accordion-with-icon .accordion__header--icon {
  position: absolute;
  right: auto;
  left: 1.5625rem;
  font-family: 'themify'; }
  [direction="rtl"] .accordion-with-icon .accordion__header--icon {
    left: auto;
    right: 1.5625rem; }
  .accordion-with-icon .accordion__header--icon::before {
    content: "\e645"; }
.accordion-header-bg .accordion__header {
  background-color: #f4f4f4; }
  [data-theme-version="dark"] .accordion-header-bg .accordion__header {
    background-color: #181f39;
    color: #fff; }
  .accordion-header-bg .accordion__header--primary {
    background-color: #3F9AE0;
    color: #fff;
    border-color: #3F9AE0; }
    [data-theme-version="dark"] .accordion-header-bg .accordion__header--primary {
      background-color: #3F9AE0; }
  .accordion-header-bg .accordion__header--info {
    background-color: #2F4CDD;
    color: #fff;
    border-color: #2F4CDD; }
    [data-theme-version="dark"] .accordion-header-bg .accordion__header--info {
      background-color: #2F4CDD; }
  .accordion-header-bg .accordion__header--success {
    background-color: #2bc155;
    color: #fff;
    border-color: #2bc155; }
    [data-theme-version="dark"] .accordion-header-bg .accordion__header--success {
      background-color: #2bc155; }
.accordion-header-bg.accordion-no-gutter .accordion__header {
  border-color: transparent;
  border-radius: 0; }
.accordion-header-bg.accordion-no-gutter .accordion__item:first-child .accordion__header {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem; }
.accordion-header-bg.accordion-no-gutter .accordion__item:last-child .accordion__header {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }
.accordion.accordion-no-gutter .accordion__header {
  border-radius: 0; }
.accordion.accordion-no-gutter .accordion__header.collapsed {
  border-radius: 0; }
.accordion.accordion-no-gutter .accordion__body {
  border-radius: 0; }
.accordion.accordion-no-gutter .accordion__item:first-child .accordion__header {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem; }
.accordion.accordion-no-gutter .accordion__item:last-child .accordion__header.collapsed {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }
.accordion.accordion-no-gutter .accordion__item:last-child .accordion__body {
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }
.accordion-solid-bg .accordion__header {
  border-color: transparent;
  background-color: #fdf4f8;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  [data-theme-version="dark"] .accordion-solid-bg .accordion__header {
    background-color: #181f39; }
  .accordion-solid-bg .accordion__header.collapsed {
    border-radius: 0.75rem; }
.accordion-solid-bg .accordion__body {
  border-color: transparent;
  background-color: #fdf4f8;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem; }
  [data-theme-version="dark"] .accordion-solid-bg .accordion__body {
    background-color: #181f39; }
.accordion-active-header .accordion__header:not(.collapsed) {
  background-color: #2F4CDD;
  border-color: #2F4CDD;
  color: #fff; }
.accordion-header-shadow .accordion__header {
  border: none;
  box-shadow: 0 0 0.9375rem -3px rgba(0, 0, 0, 0.3); }
.accordion-rounded-stylish .accordion__header {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem; }
.accordion-rounded-stylish .accordion__body {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem; }
.accordion-rounded .accordion__header {
  border-radius: 0.3125rem; }
.accordion-gradient .accordion__header {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }
  .accordion-gradient .accordion__header.collapsed {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem; }
.accordion-gradient .accordion__body {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent; }

.alert {
  border-radius: 0.75rem;
  padding: 1rem 1.5rem; }
  .alert p {
    line-height: 1.5; }

.alert-rounded {
  border-radius: 30px; }

.alert-primary {
  background: #fdf4f8;
  border-color: #fdf4f8;
  color: #3F9AE0; }
  [data-theme-version="dark"] .alert-primary {
    background: rgba(221, 47, 110, 0.2);
    border-color: rgba(221, 47, 110, 0.2); }

.alert-secondary {
  background: #e8deec;
  border-color: #e8deec;
  color: #392540; }
  [data-theme-version="dark"] .alert-secondary {
    background: rgba(57, 37, 64, 0.5);
    border-color: rgba(57, 37, 64, 0.5);
    color: #7e7e7e; }

.alert-success {
  background: #e7faec;
  border-color: #e7faec;
  color: #2bc155; }
  [data-theme-version="dark"] .alert-success {
    background: rgba(43, 193, 85, 0.1);
    border-color: rgba(43, 193, 85, 0.1); }

.alert-warning {
  background: #fff6e0;
  border-color: #fff6e0;
  color: #ffb800; }
  [data-theme-version="dark"] .alert-warning {
    background: rgba(255, 184, 0, 0.1);
    border-color: rgba(255, 184, 0, 0.1); }

.alert-danger {
  background: #fde6e6;
  border-color: #fde6e6;
  color: #f35757; }
  [data-theme-version="dark"] .alert-danger {
    background: rgba(243, 87, 87, 0.15);
    border-color: rgba(243, 87, 87, 0.15); }

.alert-info {
  background: #dee3fa;
  border-color: #dee3fa;
  color: #2F4CDD; }
  [data-theme-version="dark"] .alert-info {
    background: rgba(47, 76, 221, 0.1);
    border-color: rgba(47, 76, 221, 0.1); }

.alert-dark {
  background: #dee2e6;
  border-color: #dee2e6;
  color: #3e4954; }
  [data-theme-version="dark"] .alert-dark {
    background: rgba(62, 73, 84, 0.35);
    border-color: rgba(62, 73, 84, 0.35);
    color: #7e7e7e; }

.alert-light {
  background: #f4f4f4;
  border-color: #f4f4f4;
  color: #3e4954; }

.alert-alt.alert-primary {
  border-left: 4px solid #3F9AE0; }

.alert-alt.alert-secondary {
  border-left: 4px solid #392540; }

.alert-alt.alert-success {
  border-left: 4px solid #2bc155; }

.alert-alt.alert-warning {
  border-left: 4px solid #ffb800; }

.alert-alt.alert-danger {
  border-left: 4px solid #f35757; }

.alert-alt.alert-info {
  border-left: 4px solid #2F4CDD; }

.alert-alt.alert-dark {
  border-left: 4px solid #3e4954; }

.alert-alt.alert-light {
  border-left: 4px solid #cecece; }

.alert-alt.alert-primary.solid {
  border-left: 4px solid #791438 !important; }

.alert-alt.alert-secondary.solid {
  border-left: 4px solid black !important; }

.alert-alt.alert-success.solid {
  border-left: 4px solid #145927 !important; }

.alert-alt.alert-warning.solid {
  border-left: 4px solid #805c00 !important; }

.alert-alt.alert-danger.solid {
  border-left: 4px solid #bd0e0e !important; }

.alert-alt.alert-info.solid {
  border-left: 4px solid #142579 !important; }

.alert-alt.alert-dark.solid {
  border-left: 4px solid #08090b !important; }

.alert-alt.alert-light.solid {
  border-left: 4px solid #b4b4b4 !important; }

.alert-dismissible.solid .close:hover {
  color: #fff;
  opacity: 1; }

.alert.alert-primary.solid {
  background: #3F9AE0;
  color: #fff;
  border-color: #3F9AE0; }

.alert.alert-secondary.solid {
  background: #392540;
  color: #fff;
  border-color: #392540; }

.alert.alert-success.solid {
  background: #2bc155;
  color: #fff;
  border-color: #2bc155; }

.alert.alert-warning.solid {
  background: #ffb800;
  color: #fff;
  border-color: #ffb800; }

.alert.alert-danger.solid {
  background: #f35757;
  color: #fff;
  border-color: #f35757; }

.alert.alert-info.solid {
  background: #2F4CDD;
  color: #fff;
  border-color: #2F4CDD; }

.alert.alert-dark.solid {
  background: #3e4954;
  color: #fff;
  border-color: #3e4954; }

.alert.alert-light.solid {
  background: #f4f4f4;
  color: #3e4954;
  border-color: #f4f4f4; }

.alert-right-icon > span i {
  font-size: 18px;
  margin-right: 5px; }
.alert-right-icon .close i {
  font-size: 16px; }

.alert.alert-outline-primary {
  background: transparent;
  color: #3F9AE0;
  border-color: #3F9AE0; }

.alert.alert-outline-secondary {
  background: transparent;
  color: #7e7e7e;
  border-color: #392540; }

.alert.alert-outline-success {
  background: transparent;
  color: #2bc155;
  border-color: #2bc155; }

.alert.alert-outline-info {
  background: transparent;
  color: #2F4CDD;
  border-color: #2F4CDD; }

.alert.alert-outline-warning {
  background: transparent;
  color: #ffb800;
  border-color: #ffb800; }

.alert.alert-outline-danger {
  background: transparent;
  color: #f35757;
  border-color: #f35757; }

.alert.alert-outline-dark {
  background: transparent;
  color: #7e7e7e;
  border-color: #3e4954; }

.alert.alert-outline-light {
  background: transparent;
  color: #3e4954;
  border-color: #f4f4f4; }

.alert-social {
  color: #fff; }
  .alert-social .alert-social-icon {
    align-self: center;
    margin-right: 0.9375rem; }
    .alert-social .alert-social-icon i {
      font-size: 42px; }
  .alert-social.facebook {
    background-color: #3b5998; }
  .alert-social.twitter {
    background-color: #1da1f2; }
  .alert-social.linkedin {
    background-color: #007bb6; }
  .alert-social.google-plus {
    background-color: #db4439; }
  .alert-social .close:hover {
    opacity: 1 !important;
    color: #fff !important; }

.left-icon-big .alert-left-icon-big {
  align-self: center;
  margin-right: 0.9375rem; }
  .left-icon-big .alert-left-icon-big i {
    font-size: 35px;
    line-height: 1; }

[direction="rtl"] .left-icon-big .alert-left-icon-big,
[direction="rtl"] .alert-social .alert-social-icon {
  margin-left: 0;
  margin-right: 0.9375rem; }

.badge {
  line-height: 1.5;
  border-radius: 0.75rem;
  padding: 4px 10px;
  border: 1px solid transparent; }

.badge-rounded {
  border-radius: 20px;
  padding: 3px 13px; }

.badge-circle {
  border-radius: 100px;
  padding: 3px 7px; }

.badge-outline-primary {
  border: 1px solid #3F9AE0;
  color: #3F9AE0; }

.badge-outline-secondary {
  border: 1px solid #392540;
  color: #392540; }
  [data-theme-version="dark"] .badge-outline-secondary {
    color: #7e7e7e; }

.badge-outline-success {
  border: 1px solid #2bc155;
  color: #2bc155; }

.badge-outline-info {
  border: 1px solid #2F4CDD;
  color: #2F4CDD; }

.badge-outline-warning {
  border: 1px solid #ffb800;
  color: #ffb800; }

.badge-outline-danger {
  border: 1px solid #f35757;
  color: #f35757; }

.badge-outline-light {
  border: 1px solid #d7dae3;
  color: #3e4954; }
  [data-theme-version="dark"] .badge-outline-light {
    color: #7e7e7e; }

.badge-outline-dark {
  border: 1px solid #3e4954;
  color: #3e4954; }
  [data-theme-version="dark"] .badge-outline-dark {
    color: #7e7e7e; }

.badge-xs {
  font-size: 10px;
  padding: 0px 5px;
  line-height: 18px; }

.badge-sm {
  font-size: 11px;
  padding: 5px 8px;
  line-height: 11px; }

.badge-lg {
  font-size: 14px;
  padding: 0px 10px;
  line-height: 30px; }

.badge-xl {
  font-size: 15px;
  padding: 0px 15px;
  line-height: 35px; }

.badge-default {
  background: #ADB6C7; }

.badge-success {
  background-color: #2bc155; }

.badge-info {
  background-color: #2F4CDD; }

.badge-primary {
  background-color: #3F9AE0; }

.badge-warning {
  background-color: #ffb800; }

.badge-danger {
  background-color: #f35757; }

.badge-dark {
  background-color: #3e4954; }

.badge-light {
  background-color: #f4f4f4; }

.light.badge-default {
  background: #ADB6C7; }

.light.badge-success {
  background-color: #e7faec;
  color: #2bc155; }
  [data-theme-version="dark"] .light.badge-success {
    background-color: rgba(43, 193, 85, 0.1); }

.light.badge-info {
  background-color: #dee3fa;
  color: #2F4CDD; }
  [data-theme-version="dark"] .light.badge-info {
    background-color: rgba(47, 76, 221, 0.1); }

.light.badge-primary {
  background-color: #fdf4f8;
  color: #3F9AE0; }
  [data-theme-version="dark"] .light.badge-primary {
    background-color: rgba(221, 47, 110, 0.2); }

.light.badge-secondary {
  background-color: #e8deec;
  color: #392540; }
  [data-theme-version="dark"] .light.badge-secondary {
    background-color: rgba(57, 37, 64, 0.5);
    color: #7e7e7e; }

.light.badge-warning {
  background-color: #fff6e0;
  color: #ffb800; }
  [data-theme-version="dark"] .light.badge-warning {
    background-color: rgba(255, 184, 0, 0.1); }

.light.badge-danger {
  background-color: #fde6e6;
  color: #f35757; }
  [data-theme-version="dark"] .light.badge-danger {
    background-color: rgba(243, 87, 87, 0.15); }

.light.badge-dark {
  background-color: #dee2e6;
  color: #3e4954; }
  [data-theme-version="dark"] .light.badge-dark {
    background-color: rgba(62, 73, 84, 0.35);
    color: #7e7e7e; }

.bootstrap-label .label {
  display: inline-block;
  margin-right: 1rem; }
  .bootstrap-label .label:last-child {
    margin-right: 0; }

.badge-demo .badge {
  margin-right: 5px;
  margin-bottom: 5px; }
  .badge-demo .badge:last-child {
    margin-right: 0; }

.bootstrap-badge-buttons button {
  margin-right: .2rem;
  margin-bottom: 1rem; }
  .bootstrap-badge-buttons button:last-child {
    margin-right: 0; }

.page-titles {
  padding: 0.9375rem 1.25rem;
  background: #fff;
  margin-bottom: 30px;
  border-radius: 5px; }
  [data-theme-version="dark"] .page-titles {
    background: #1e2746; }
  @media only screen and (max-width: 767px) {
    .page-titles {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 15px;
      padding: 15px 0; } }
  .page-titles .justify-content-sm-end {
    align-items: center; }
  .page-titles h4 {
    margin-bottom: 0;
    margin-top: 0;
    color: #3F9AE0;
    font-size: 1.25rem; }
    .page-titles h4 span {
      font-size: 0.875rem;
      font-weight: 400; }
  .page-titles .breadcrumb {
    margin-bottom: 0;
    padding: 0;
    background: transparent; }
    .page-titles .breadcrumb li {
      margin-top: 0;
      margin-bottom: 0; }
      .page-titles .breadcrumb li a {
        color: #828690; }
      .page-titles .breadcrumb li.active {
        color: #555555; }
    .page-titles .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
      content: "\e606";
      font-family: 'simple-line-icons';
      font-size: 10px;
      line-height: 26px; }
    .page-titles .breadcrumb-datepicker {
      font-size: 0.75rem;
      color: #89879f; }
      .page-titles .breadcrumb-datepicker__icon {
        font-size: 0.875rem; }
  .page-titles .breadcrumb-widget .border-dark {
    border-color: #dee2e6 !important; }
  .page-titles .breadcrumb-widget h4 {
    color: #646c9a;
    font-weight: 600; }
  @media only screen and (max-width: 575px) {
    .page-titles .breadcrumb-widget {
      text-align: left !important;
      margin-bottom: 0.9375rem; } }

button {
  cursor: pointer; }
  button:focus {
    outline: 0;
    box-shadow: none; }

.btn {
  padding: 0.938rem 1.5rem;
  border-radius: 0.75rem;
  font-weight: 500;
  font-size: 1rem; }
  .btn:hover, .btn:focus, .btn:active, .btn.active {
    outline: 0 !important; }
  @media only screen and (max-width: 1400px) {
    .btn {
      padding: 0.625rem 1rem;
      font-size: 0.813rem; } }
  .btn-transparent {
    background-color: transparent; }

.sharp {
  min-width: 40px;
  padding: 7px;
  height: 40px;
  min-height: 40px; }

.sharp.btn-xs {
  padding: 3px;
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px; }

.light.tp-btn {
  background-color: transparent; }
.light.btn-default {
  background: #ADB6C7; }
.light.btn-success {
  background-color: #e7faec;
  border-color: #e7faec;
  color: #2bc155; }
  .light.btn-success g [fill] {
    fill: #2bc155; }
  [data-theme-version="dark"] .light.btn-success {
    background-color: rgba(43, 193, 85, 0.1);
    border-color: transparent; }
  .light.btn-success:hover {
    background-color: #2bc155;
    border-color: #2bc155;
    color: #fff; }
    .light.btn-success:hover g [fill] {
      fill: #fff; }
.light.btn-info {
  background-color: #dee3fa;
  border-color: #dee3fa;
  color: #2F4CDD; }
  .light.btn-info g [fill] {
    fill: #2F4CDD; }
  [data-theme-version="dark"] .light.btn-info {
    background-color: rgba(47, 76, 221, 0.1);
    border-color: transparent; }
  .light.btn-info:hover {
    background-color: #2F4CDD;
    border-color: #2F4CDD;
    color: #fff; }
    .light.btn-info:hover g [fill] {
      fill: #fff; }
.light.btn-primary {
  background-color: #fdf4f8;
  border-color: #fdf4f8;
  color: #3F9AE0; }
  .light.btn-primary g [fill] {
    fill: #3F9AE0; }
  [data-theme-version="dark"] .light.btn-primary {
    background-color: rgba(221, 47, 110, 0.2);
    border-color: transparent; }
  .light.btn-primary:hover {
    background-color: #3F9AE0;
    border-color: #3F9AE0;
    color: #fff; }
    .light.btn-primary:hover g [fill] {
      fill: #fff; }
.light.btn-secondary {
  background-color: #e8deec;
  border-color: #e8deec;
  color: #392540; }
  .light.btn-secondary g [fill] {
    fill: #392540; }
  [data-theme-version="dark"] .light.btn-secondary {
    background-color: rgba(57, 37, 64, 0.5);
    border-color: transparent;
    color: #fff; }
  .light.btn-secondary:hover {
    background-color: #392540;
    border-color: #392540;
    color: #fff; }
    .light.btn-secondary:hover g [fill] {
      fill: #fff; }
.light.btn-warning {
  background-color: #fff6e0;
  border-color: #fff6e0;
  color: #ffb800; }
  .light.btn-warning g [fill] {
    fill: #ffb800; }
  [data-theme-version="dark"] .light.btn-warning {
    background-color: rgba(255, 184, 0, 0.1);
    border-color: transparent; }
  .light.btn-warning:hover {
    background-color: #ffb800;
    border-color: #ffb800;
    color: #fff; }
    .light.btn-warning:hover g [fill] {
      fill: #fff; }
.light.btn-danger {
  background-color: #fde6e6;
  border-color: #fde6e6;
  color: #f35757; }
  .light.btn-danger g [fill] {
    fill: #f35757; }
  [data-theme-version="dark"] .light.btn-danger {
    background-color: rgba(243, 87, 87, 0.15);
    border-color: transparent; }
  .light.btn-danger:hover {
    background-color: #f35757;
    border-color: #f35757;
    color: #fff; }
    .light.btn-danger:hover g [fill] {
      fill: #fff; }
.light.btn-dark {
  background-color: #dee2e6;
  border-color: #dee2e6;
  color: #3e4954; }
  .light.btn-dark g [fill] {
    fill: #3e4954; }
  [data-theme-version="dark"] .light.btn-dark {
    background-color: rgba(62, 73, 84, 0.35);
    border-color: transparent;
    color: #fff; }
  .light.btn-dark:hover {
    background-color: #3e4954;
    border-color: #3e4954;
    color: #fff; }
    .light.btn-dark:hover g [fill] {
      fill: #fff; }

.btn.tp-btn {
  background-color: transparent;
  border-color: transparent; }
  .btn.tp-btn.btn-default {
    background: #ADB6C7; }
  .btn.tp-btn.btn-success {
    color: #2bc155; }
    .btn.tp-btn.btn-success g [fill] {
      fill: #2bc155; }
    .btn.tp-btn.btn-success:hover {
      background-color: #2bc155;
      border-color: #2bc155;
      color: #fff; }
      .btn.tp-btn.btn-success:hover g [fill] {
        fill: #fff; }
  .btn.tp-btn.btn-info {
    color: #2F4CDD; }
    .btn.tp-btn.btn-info g [fill] {
      fill: #2F4CDD; }
    .btn.tp-btn.btn-info:hover {
      background-color: #2F4CDD;
      border-color: #2F4CDD;
      color: #fff; }
      .btn.tp-btn.btn-info:hover g [fill] {
        fill: #fff; }
  .btn.tp-btn.btn-primary {
    color: #3F9AE0; }
    .btn.tp-btn.btn-primary g [fill] {
      fill: #3F9AE0; }
    .btn.tp-btn.btn-primary:hover {
      background-color: #3F9AE0;
      border-color: #3F9AE0;
      color: #fff; }
      .btn.tp-btn.btn-primary:hover g [fill] {
        fill: #fff; }
  .btn.tp-btn.btn-secondary {
    color: #392540; }
    .btn.tp-btn.btn-secondary g [fill] {
      fill: #392540; }
    .btn.tp-btn.btn-secondary:hover {
      background-color: #392540;
      border-color: #392540;
      color: #fff; }
      .btn.tp-btn.btn-secondary:hover g [fill] {
        fill: #fff; }
  .btn.tp-btn.btn-warning {
    color: #ffb800; }
    .btn.tp-btn.btn-warning g [fill] {
      fill: #ffb800; }
    .btn.tp-btn.btn-warning:hover {
      background-color: #ffb800;
      border-color: #ffb800;
      color: #fff; }
      .btn.tp-btn.btn-warning:hover g [fill] {
        fill: #fff; }
  .btn.tp-btn.btn-danger {
    color: #f35757; }
    .btn.tp-btn.btn-danger g [fill] {
      fill: #f35757; }
    .btn.tp-btn.btn-danger:hover {
      background-color: #f35757;
      border-color: #f35757;
      color: #fff; }
      .btn.tp-btn.btn-danger:hover g [fill] {
        fill: #fff; }
  .btn.tp-btn.btn-light {
    color: #3e4954; }
    .btn.tp-btn.btn-light g [fill] {
      fill: #3e4954; }
    .btn.tp-btn.btn-light:hover {
      background-color: #f4f4f4;
      border-color: #f4f4f4;
      color: #3e4954; }
      .btn.tp-btn.btn-light:hover g [fill] {
        fill: #fff; }
  .btn.tp-btn.btn-dark {
    color: #3e4954; }
    .btn.tp-btn.btn-dark g [fill] {
      fill: #3e4954; }
    .btn.tp-btn.btn-dark:hover {
      background-color: #3e4954;
      border-color: #3e4954;
      color: #fff; }
      .btn.tp-btn.btn-dark:hover g [fill] {
        fill: #fff; }

.btn.tp-btn-light {
  background-color: transparent;
  border-color: transparent; }
  .btn.tp-btn-light.btn-success {
    color: #2bc155; }
    .btn.tp-btn-light.btn-success g [fill] {
      fill: #2bc155; }
    .btn.tp-btn-light.btn-success:hover {
      background-color: #e7faec;
      border-color: #e7faec;
      color: #2bc155; }
      .btn.tp-btn-light.btn-success:hover g [fill] {
        fill: #2bc155; }
  .btn.tp-btn-light.btn-info {
    color: #2F4CDD; }
    .btn.tp-btn-light.btn-info g [fill] {
      fill: #2F4CDD; }
    .btn.tp-btn-light.btn-info:hover {
      background-color: #dee3fa;
      border-color: #dee3fa;
      color: #2F4CDD; }
      .btn.tp-btn-light.btn-info:hover g [fill] {
        fill: #2F4CDD; }
  .btn.tp-btn-light.btn-primary {
    color: #3F9AE0; }
    .btn.tp-btn-light.btn-primary g [fill] {
      fill: #3F9AE0; }
    .btn.tp-btn-light.btn-primary:hover {
      background-color: #fdf4f8;
      border-color: #fdf4f8;
      color: #3F9AE0; }
      .btn.tp-btn-light.btn-primary:hover g [fill] {
        fill: #3F9AE0; }
  .btn.tp-btn-light.btn-secondary {
    color: #392540; }
    .btn.tp-btn-light.btn-secondary g [fill] {
      fill: #392540; }
    .btn.tp-btn-light.btn-secondary:hover {
      background-color: #e8deec;
      border-color: #e8deec;
      color: #392540; }
      .btn.tp-btn-light.btn-secondary:hover g [fill] {
        fill: #392540; }
  .btn.tp-btn-light.btn-warning {
    color: #ffb800; }
    .btn.tp-btn-light.btn-warning g [fill] {
      fill: #ffb800; }
    .btn.tp-btn-light.btn-warning:hover {
      background-color: #fff6e0;
      border-color: #fff6e0;
      color: #ffb800; }
      .btn.tp-btn-light.btn-warning:hover g [fill] {
        fill: #ffb800; }
  .btn.tp-btn-light.btn-danger {
    color: #f35757; }
    .btn.tp-btn-light.btn-danger g [fill] {
      fill: #f35757; }
    .btn.tp-btn-light.btn-danger:hover {
      background-color: #fde6e6;
      border-color: #fde6e6;
      color: #f35757; }
      .btn.tp-btn-light.btn-danger:hover g [fill] {
        fill: #fff; }
  .btn.tp-btn-light.btn-dark {
    color: #3e4954; }
    .btn.tp-btn-light.btn-dark g [fill] {
      fill: #3e4954; }
    .btn.tp-btn-light.btn-dark:hover {
      background-color: #dee2e6;
      border-color: #dee2e6;
      color: #3e4954; }
      .btn.tp-btn-light.btn-dark:hover g [fill] {
        fill: #fff; }

.shadow.btn-primary {
  -webkit-box-shadow: 0 5px 15px 0 rgba(221, 47, 110, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(221, 47, 110, 0.2) !important; }

.shadow.btn-secondary {
  -webkit-box-shadow: 0 5px 15px 0 rgba(57, 37, 64, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(57, 37, 64, 0.2) !important; }

.shadow.btn-warning {
  -webkit-box-shadow: 0 5px 15px 0 rgba(255, 184, 0, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(255, 184, 0, 0.2) !important; }

.shadow.btn-danger {
  -webkit-box-shadow: 0 5px 15px 0 rgba(243, 87, 87, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(243, 87, 87, 0.2) !important; }

.shadow.btn-info {
  -webkit-box-shadow: 0 5px 15px 0 rgba(47, 76, 221, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(47, 76, 221, 0.2) !important; }

.shadow.btn-success {
  -webkit-box-shadow: 0 5px 15px 0 rgba(43, 193, 85, 0.2) !important;
  box-shadow: 0 5px 15px 0 rgba(43, 193, 85, 0.2) !important; }

.btn-xxs {
  padding: 6px 15px;
  font-size: 11px;
  line-height: 1.3; }

.btn-xs {
  font-size: 0.75rem;
  padding: 0.438rem 1rem;
  font-weight: 600; }

.btn-sm, .btn-group-sm > .btn {
  font-size: 0.813rem  !important;
  padding: 0.625rem 1rem; }

.btn-md {
  font-size: 0.813rem  !important;
  padding: 0.875rem 1.25rem; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 2rem;
  font-size: 1.125rem !important; }
  @media only screen and (max-width: 575px) {
    .btn-lg, .btn-group-lg > .btn {
      padding: 0.75rem 1.25rem; } }

.btn-xl {
  padding: 0.6rem 1rem; }
  .btn-xl.btn-default {
    font-weight: 600; }

.btn-square {
  border-radius: 0; }

.btn-rounded {
  border-radius: 30px !important; }

.btn-icon-right {
  border-left: 1px solid white;
  display: inline-block;
  margin: -.8rem 0 -.8rem 1rem;
  padding: 0.4375rem 0 0.4375rem 1rem;
  margin: -1rem -0.25rem -1rem 1rem;
  padding: 1rem 0 1rem 1.25rem; }
  @media only screen and (max-width: 1400px) {
    .btn-icon-right {
      padding: 1rem 0 1rem 0.6rem; } }

.btn-icon-left {
  background: #fff;
  border-radius: 10rem;
  display: inline-block;
  margin: -0.5rem 0.75rem -0.5rem -1.188rem;
  padding: 0.5rem 0.80rem 0.5rem;
  float: left; }
  @media only screen and (max-width: 1199px) {
    .btn-icon-left {
      margin: -0.5rem 0.75rem -0.5rem -0.800rem; } }

[direction="rtl"] .btn-icon-left {
  margin: -.5rem -1.5rem -.5rem 1rem; }
[direction="rtl"] .btn-icon-right {
  border-left: 0px solid white;
  display: inline-block;
  margin: -.8rem 1rem -.8rem 0;
  padding: 0.4375rem 1rem 0.4375rem 0;
  border-right: 1px solid white; }

.toggle-dropdown::after {
  margin-left: 0.755em; }

.social-btn-icon .btn {
  min-width: 7.5rem;
  margin-bottom: 1.5rem; }

.social-icon .btn {
  padding: .7rem 1.4rem; }

.btn-circle {
  height: 5rem;
  width: 5rem;
  border-radius: 50% !important; }
  .btn-circle-sm {
    width: 4.5rem;
    height: 4.5rem;
    font-size: 1.8rem; }
  .btn-circle-md {
    width: 6rem;
    height: 6rem;
    font-size: 2.5rem; }
    .btn-circle-md i {
      font-size: 2.4rem; }
  .btn-circle-lg {
    width: 8rem;
    height: 8rem;
    font-size: 3.2rem; }
    .btn-circle-lg i {
      font-size: 3.1rem; }

.btn-page .btn {
  min-width: 110px;
  margin-right: 4px;
  margin-bottom: 8px; }

.size-1 {
  min-width: 160px !important;
  font-size: 24px;
  padding: 0.68rem 0.75rem; }

.size-2 {
  font-size: 20px;
  min-width: 130px !important;
  padding: 0.57rem 0.75rem; }

.size-3 {
  font-size: 14px;
  min-width: 110px !important;
  padding: 0.536rem 0.75rem; }

.size-4 {
  font-size: 14px;
  min-width: 100px !important; }

.size-5 {
  font-size: 14px;
  min-width: 90px !important;
  padding: .22rem 0.75rem; }

.size-6 {
  font-size: 13px;
  min-width: 80px !important;
  padding: 0.097rem 0.75rem; }

.size-7 {
  font-size: 12px;
  min-width: 60px !important;
  padding: 0.001rem 0.75rem; }

.btn-light {
  background: #f4f4f4;
  border-color: #f4f4f4; }
  .btn-light:active, .btn-light:focus, .btn-light:hover {
    background: white;
    color: #3e4954;
    border-color: white; }

.btn-outline-light {
  color: #3e4954; }
  [data-theme-version="dark"] .btn-outline-light {
    color: #7e7e7e; }

[data-theme-version="dark"] .btn-outline-secondary {
  color: #7e7e7e; }

[data-theme-version="dark"] .btn-outline-dark {
  color: #7e7e7e; }

.btn-dark {
  background: #3e4954;
  border-color: #3e4954;
  color: #fff; }
  .btn-dark:active, .btn-dark:focus, .btn-dark:hover {
    background: #283037;
    color: #fff;
    border-color: #283037; }

.btn-group.btn-rounded .btn:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px; }
.btn-group.btn-rounded .btn:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px; }

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff; }
  .btn-facebook:active, .btn-facebook:focus, .btn-facebook:hover {
    background: #2d4373;
    color: #fff;
    border-color: #2d4373; }

.btn-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff; }
  .btn-twitter:active, .btn-twitter:focus, .btn-twitter:hover {
    background: #0c85d0;
    color: #fff;
    border-color: #0c85d0; }

.btn-youtube {
  background: #FF0000;
  border-color: #FF0000;
  color: #fff; }
  .btn-youtube:active, .btn-youtube:focus, .btn-youtube:hover {
    background: #cc0000;
    color: #fff;
    border-color: #cc0000; }

.btn-instagram {
  background: #c32aa3;
  border-color: #c32aa3;
  color: #fff; }
  .btn-instagram:active, .btn-instagram:focus, .btn-instagram:hover {
    background: #992180;
    color: #fff;
    border-color: #992180; }

.btn-pinterest {
  background: #bd081c;
  border-color: #bd081c;
  color: #fff; }
  .btn-pinterest:active, .btn-pinterest:focus, .btn-pinterest:hover {
    background: #8c0615;
    color: #fff;
    border-color: #8c0615; }

.btn-linkedin {
  background: #007bb6;
  border-color: #007bb6;
  color: #fff; }
  .btn-linkedin:active, .btn-linkedin:focus, .btn-linkedin:hover {
    background: #005983;
    color: #fff;
    border-color: #005983; }

.btn-google-plus {
  background: #db4439;
  border-color: #db4439;
  color: #fff; }
  .btn-google-plus:active, .btn-google-plus:focus, .btn-google-plus:hover {
    background: #be2d23;
    color: #fff;
    border-color: #be2d23; }

.btn-google {
  background: #4285f4;
  border-color: #4285f4;
  color: #fff; }
  .btn-google:active, .btn-google:focus, .btn-google:hover {
    background: #1266f1;
    color: #fff;
    border-color: #1266f1; }

.btn-snapchat {
  background: #fffc00;
  border-color: #fffc00;
  color: #000; }
  .btn-snapchat:active, .btn-snapchat:focus, .btn-snapchat:hover {
    background: #ccca00;
    color: #000;
    border-color: #ccca00; }

.btn-whatsapp {
  background: #25d366;
  border-color: #25d366;
  color: #fff; }
  .btn-whatsapp:active, .btn-whatsapp:focus, .btn-whatsapp:hover {
    background: #1da851;
    color: #fff;
    border-color: #1da851; }

.btn-tumblr {
  background: #35465d;
  border-color: #35465d;
  color: #fff; }
  .btn-tumblr:active, .btn-tumblr:focus, .btn-tumblr:hover {
    background: #222e3d;
    color: #fff;
    border-color: #222e3d; }

.btn-reddit {
  background: #ff4500;
  border-color: #ff4500;
  color: #fff; }
  .btn-reddit:active, .btn-reddit:focus, .btn-reddit:hover {
    background: #cc3700;
    color: #fff;
    border-color: #cc3700; }

.btn-spotify {
  background: #1ed760;
  border-color: #1ed760;
  color: #fff; }
  .btn-spotify:active, .btn-spotify:focus, .btn-spotify:hover {
    background: #18aa4c;
    color: #fff;
    border-color: #18aa4c; }

.btn-yahoo {
  background: #430297;
  border-color: #430297;
  color: #fff; }
  .btn-yahoo:active, .btn-yahoo:focus, .btn-yahoo:hover {
    background: #2d0165;
    color: #fff;
    border-color: #2d0165; }

.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: #fff; }
  .btn-dribbble:active, .btn-dribbble:focus, .btn-dribbble:hover {
    background: #e51e6b;
    color: #fff;
    border-color: #e51e6b; }

.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: #fff; }
  .btn-skype:active, .btn-skype:focus, .btn-skype:hover {
    background: #008abd;
    color: #fff;
    border-color: #008abd; }

.btn-quora {
  background: #aa2200;
  border-color: #aa2200;
  color: #fff; }
  .btn-quora:active, .btn-quora:focus, .btn-quora:hover {
    background: #771800;
    color: #fff;
    border-color: #771800; }

.btn-vimeo {
  background: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff; }
  .btn-vimeo:active, .btn-vimeo:focus, .btn-vimeo:hover {
    background: #1295bf;
    color: #fff;
    border-color: #1295bf; }

.card {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all .5s ease-in-out;
  position: relative;
  border: 0px solid transparent;
  border-radius: 0.75rem;
  box-shadow: 0px 12px 33px 0px rgba(62, 73, 84, 0.08);
  height: calc(100% - 30px); }
  @media only screen and (max-width: 575px) {
    .card {
      margin-bottom: 0.938rem;
      height: calc(100% - 0.938rem); } }
  .card-body {
    padding: 1.875rem; }
    @media only screen and (max-width: 575px) {
      .card-body {
        padding: 1rem; } }
  .card-title {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize; }
    .card-title--large {
      font-size: 1.5rem; }
    .card-title--medium {
      font-size: 1rem; }
    .card-title--small {
      font-size: 0.875rem; }
  .card-header {
    border-color: #d7dae3;
    position: relative;
    background: transparent;
    padding: 1.5rem 1.875rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 575px) {
      .card-header {
        padding: 1.25rem 1rem 1.25rem; } }
    [data-theme-version="dark"] .card-header {
      border-color: #333a54; }
    .card-header .card-title {
      margin-bottom: 0px; }
    .card-header .subtitle {
      padding-top: 5px;
      font-size: 14px;
      line-height: 1.5; }
  .card-footer {
    border-color: #d7dae3;
    background: transparent;
    padding: 1.25rem 1.875rem 1.25rem; }
    [data-theme-version="dark"] .card-footer {
      border-color: #333a54; }

.transparent-card.card {
  background: transparent;
  border: 1px solid transparent;
  box-shadow: none; }

.card-action > a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  border-color: transparent;
  text-align: center;
  background: black;
  color: #fff;
  margin-right: 8px; }
  [data-theme-version="dark"] .card-action > a {
    background: #181f39; }
  .card-action > a:last-child {
    margin-right: 0; }
  .card-action > a:hover, .card-action > a:focus {
    background: black; }
    [data-theme-version="dark"] .card-action > a:hover, [data-theme-version="dark"] .card-action > a:focus {
      background: #181f39; }
  .card-action > a i, .card-action > a span {
    font-size: 1rem; }
.card-action .dropdown {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border-color: transparent;
  text-align: center;
  margin-right: 8px;
  top: -2px;
  position: relative;
  display: inline-block;
  background: black;
  color: #3F9AE0; }
  [data-theme-version="dark"] .card-action .dropdown {
    background: #181f39; }
  .card-action .dropdown:hover, .card-action .dropdown:focus {
    background: black; }
    [data-theme-version="dark"] .card-action .dropdown:hover, [data-theme-version="dark"] .card-action .dropdown:focus {
      background: #181f39; }
  .card-action .dropdown .btn {
    padding: 0;
    line-height: 27px;
    color: #fff; }
    .card-action .dropdown .btn:focus {
      box-shadow: none; }

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto; }

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999; }

.card-loader i {
  margin: 0 auto;
  background: #c8215d;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem; }

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block; }

.card-header .date_picker {
  display: inline-block;
  padding: 8px;
  border: 1px solid #d7dae3;
  cursor: pointer;
  border-radius: .375rem; }
.card-header .border-0 {
  padding-bottom: 0; }

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.carousel-custom-prev, .carousel-custom-next {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  text-align: center; }
  .carousel-custom-prev i, .carousel-custom-next i {
    font-size: 1rem; }
  .carousel-custom-prev:hover, .carousel-custom-next:hover {
    background: linear-gradient(to right, rgba(245, 60, 121, 0.99) 0%, rgba(246, 104, 47, 0.99) 100%);
    color: #fff; }
.carousel-custom-next {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%); }
.carousel-custom-prev {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%); }

.carousel-caption {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.78);
  z-index: 0; }
  .carousel-caption h5 {
    color: #fff;
    font-size: 1.8rem; }
  .carousel-caption p {
    margin-bottom: 0; }

.dropdown-toggle:focus {
  box-shadow: none !important; }

.dropdown-outline {
  border: 0.1rem solid #3F9AE0; }

.dropdown-menu {
  font-size: inherit;
  border: 0;
  box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1);
  margin-top: 0; }
  .dropdown-menu .dropdown-item {
    font-size: 16px;
    color: #7e7e7e;
    padding: 0.5rem 1.75rem; }
    @media only screen and (max-width: 1400px) {
      .dropdown-menu .dropdown-item {
        padding: 0.375rem 1rem;
        font-size: 14px; } }
    .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item.active {
      color: #514e5f; }
    .dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active {
      color: #fff; }
  [direction="rtl"] .dropdown-menu {
    right: auto !important; }

.dropdown-toggle-split {
  padding: 0 10px;
  opacity: 0.85; }
  .dropdown-toggle-split:after {
    margin-left: 0 !important; }
  .dropdown-toggle-split:active, .dropdown-toggle-split:focus, .dropdown-toggle-split:hover {
    opacity: 1; }

.dropleft .dropdown-toggle:before,
.dropright .dropdown-toggle:before,
.dropup .dropdown-toggle:after,
.dropdown-toggle:after {
  content: "\f107";
  font-family: FontAwesome;
  border: 0;
  vertical-align: middle;
  margin-left: 0.25em;
  line-height: 1; }

.dropup .dropdown-toggle:after {
  content: "\f106"; }

.dropleft .dropdown-toggle::before {
  content: "\f104"; }

.dropright .dropdown-toggle::before {
  content: "\f105"; }

.dropright .dropdown-toggle::after {
  content: none; }

.custom-dropdown {
  display: inline-block;
  margin-bottom: 1rem; }
  .custom-dropdown .dropdown-menu {
    border: 0px;
    min-width: 160px; }

.card-action .custom-dropdown {
  margin: 0px;
  background: #f2b3ca; }
  .card-action .custom-dropdown.show, .card-action .custom-dropdown:focus, .card-action .custom-dropdown:hover {
    background: #3F9AE0;
    color: #fff; }
  .card-action .custom-dropdown i {
    display: inline-block;
    padding-top: 9px; }

.dropdown .dropdown-dots {
  position: relative;
  height: 5px;
  width: 5px;
  background: rgba(62, 73, 84, 0.4);
  border-radius: 5px;
  display: block; }
  .dropdown .dropdown-dots:after, .dropdown .dropdown-dots:before {
    content: "";
    height: 5px;
    width: 5px;
    background: rgba(62, 73, 84, 0.4);
    position: absolute;
    border-radius: 5px; }
  .dropdown .dropdown-dots:after {
    right: -8px; }
  .dropdown .dropdown-dots:before {
    left: -8px; }
  .dropdown .dropdown-dots.text-white {
    background: rgba(255, 255, 255, 0.7); }
    .dropdown .dropdown-dots.text-white:after, .dropdown .dropdown-dots.text-white:before {
      background: rgba(255, 255, 255, 0.7); }

.grid-col {
  padding: 0.5rem !important;
  background: #f2f4fa; }

.row.grid {
  margin-bottom: 1.5rem;
  text-align: center; }

.row.grid .grid-col:first-child {
  text-align: left; }

.label {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: .2rem .8rem; }
  .label-fixed {
    width: 7.5rem;
    padding: .6rem 0; }
    .label-fixed-lg {
      width: 9.5rem;
      padding: .6rem 0; }
  .label-big {
    width: 16.8rem;
    font-size: 1.4rem;
    padding: 1.1rem 0; }
  .label-xl {
    width: 10.5rem;
    padding: 1.1rem 0;
    font-size: 1.5rem; }
  .label-lg {
    width: 9.5rem;
    padding: 1.1rem 0; }
  .label-md {
    width: 8.5rem;
    padding: 1.1rem 0; }
  .label-sm {
    width: 7.5rem;
    padding: 1.1rem 0; }

.label-default {
  background: #ADB6C7; }

.label-primary {
  background: #3F9AE0;
  color: #fff; }

.label-secondary {
  background: #392540;
  color: #fff; }

.label-info {
  background: #2F4CDD;
  color: #fff; }

.label-success {
  background: #2bc155;
  color: #fff; }

.label-warning {
  background: #ffb800;
  color: #fff; }

.label-danger {
  background: #f35757;
  color: #fff; }

.label-light {
  background: #f4f4f4;
  color: #000; }

.label-dark {
  background: #3e4954;
  color: #fff; }

code {
  word-break: break-word;
  padding: 2px 5px;
  border-radius: 3px;
  background: #fef4f4;
  color: #f35757; }
  [data-theme-version="dark"] code {
    background: rgba(243, 87, 87, 0.1); }

.heading-labels {
  color: #333333; }
  .heading-labels > * {
    margin-bottom: .8rem; }
  .heading-labels h1 .label {
    font-size: 18px;
    font-weight: normal;
    padding: .4rem .9rem; }
  .heading-labels h2 .label {
    font-size: 16px;
    font-weight: normal;
    padding: 0.3rem .9rem; }
  .heading-labels h3 .label {
    font-size: 14px;
    font-weight: normal; }
  .heading-labels h4 .label {
    font-size: 12px;
    font-weight: normal; }
  .heading-labels h5 .label {
    font-size: 12px;
    font-weight: normal; }
  .heading-labels h6 .label {
    font-size: 12px;
    font-weight: normal; }

.list-group-item {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #d7dae3;
  padding: 1rem 1.5rem; }
  [data-theme-version="dark"] .list-group-item {
    border-color: #333a54; }

.list-group-item.disabled, .list-group-item:disabled {
  color: #fff;
  background-color: #392540;
  border-color: #392540; }

[class*="bg-"] .list-group-item {
  border-color: rgba(255, 255, 255, 0.05); }

.bg-warning .list-group-item {
  border-color: rgba(0, 0, 0, 0.05); }

.media img {
  border-radius: 3px; }

.vertical-card__menu:hover {
  box-shadow: none; }
.vertical-card__menu--image {
  text-align: center; }
  .vertical-card__menu--image img {
    width: 100%;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
.vertical-card__menu--status.closed {
  background: #f35757; }
  .vertical-card__menu--status.closed:after {
    border-top: 10px solid #f35757; }
  .vertical-card__menu--status.closed .ribbon-curve {
    border-top: 11px solid #f35757;
    border-bottom: 10px solid #f35757; }
.vertical-card__menu--desc p {
  margin-bottom: 0.625rem; }
.vertical-card__menu--price {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 700; }
.vertical-card__menu--rating {
  font-size: 0.75rem; }
.vertical-card__menu--title {
  font-weight: 700; }
.vertical-card__menu--button button {
  font-size: 0.75rem; }
.vertical-card__menu .card-footer {
  padding: 15px 20px;
  background-color: #d7dae3;
  border-top: none; }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .vertical-card__menu .card-footer {
      padding: 15px; } }
  .vertical-card__menu .card-footer span {
    color: #3e4954;
    margin-right: 0.3125rem; }

.horizontal-card__menu {
  box-shadow: 0 0 7px rgba(173, 173, 173, 0.32);
  display: flex;
  flex-direction: row; }
  @media (max-width: 575.98px) {
    .horizontal-card__menu {
      display: block; } }
  .horizontal-card__menu .card-body {
    padding: 0;
    padding: 20px 30px; }
  .horizontal-card__menu--image {
    flex-basis: 30%;
    max-width: 30%; }
    .horizontal-card__menu--image img {
      height: 100%;
      width: 100%; }
    @media (max-width: 575.98px) {
      .horizontal-card__menu--image {
        max-width: unset;
        flex-basis: 100%; } }
  .horizontal-card__menu--title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.3125rem; }
  .horizontal-card__menu--fav {
    margin-right: 0.5rem; }
  .horizontal-card__menu--price {
    margin: 0;
    font-size: 1rem;
    font-weight: 700; }
  .horizontal-card__menu--rating {
    font-size: 0.625rem; }
  .horizontal-card__menu--footer {
    margin-top: 10px; }

.prev_price {
  text-decoration: line-through;
  font-size: 80%;
  opacity: 0.5; }

.modal-header {
  padding: 1rem 1.875rem; }
  .modal-header .close {
    padding: 0.875rem 1.815rem;
    margin: 0;
    position: absolute;
    right: 0;
    float: none;
    top: 0;
    font-size: 30px;
    font-weight: 100; }

.modal-body {
  padding: 1.875rem; }

.modal-footer {
  padding: 1rem 1.875rem; }

.modal-content {
  border-radius: 0.75rem; }

.modal-backdrop {
  z-index: 10 !important; }

.pagination .page-item .page-link:hover {
  background: #3F9AE0;
  border-color: #3F9AE0;
  color: #fff; }
.pagination .page-item.active .page-link {
  background: #3F9AE0;
  border-color: #3F9AE0;
  color: #fff !important; }

.pagination {
  margin-bottom: 20px; }
  .pagination .page-item.page-indicator .page-link {
    padding: .65rem .8rem;
    font-size: 14px; }
  .pagination .page-item.page-indicator:hover .page-link {
    color: #3e4954; }
  .pagination .page-item .page-link {
    text-align: center;
    padding: 0.55rem 1rem;
    font-size: 1rem;
    background: rgba(255, 255, 255, 0.15);
    color: #3e4954;
    z-index: 0;
    border: 1px solid #d7dae3; }
    [data-theme-version="dark"] .pagination .page-item .page-link {
      border-color: #333a54;
      color: #828690;
      background: rgba(255, 255, 255, 0); }
    .pagination .page-item .page-link:hover i, .pagination .page-item .page-link span {
      color: #fff; }
    .pagination .page-item .page-link:focus {
      outline: 0;
      box-shadow: none; }
    .pagination .page-item .page-link:hover {
      background: #3F9AE0;
      color: #fff;
      border-color: #3F9AE0; }
  .pagination .page-item.active .page-link {
    background-color: #3F9AE0;
    border-color: #3F9AE0;
    color: #fff;
    box-shadow: 0 10px 20px 0px rgba(221, 47, 110, 0.2); }
    [data-theme-version="dark"] .pagination .page-item.active .page-link {
      color: #fff; }
  .pagination .page-item .page-link {
    color: #3e4954;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
  .pagination .page-item:last-child .page-link {
    margin-right: 0; }
  [direction="rtl"] .pagination .page-item:first-child .page-link {
    margin-right: 0; }
  .pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
    background: transparent;
    border-color: transparent; }
  .pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
    background: #3F9AE0;
    border-color: #3F9AE0; }
    [data-theme-version="dark"] .pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
      background: #3F9AE0;
      border-color: #3F9AE0; }
  .pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
    background: #f35757;
    border-color: #f35757; }
    [data-theme-version="dark"] .pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
      background: #f35757;
      border-color: #f35757; }
  .pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
    background: #2F4CDD;
    border-color: #2F4CDD; }
    [data-theme-version="dark"] .pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
      background: #2F4CDD;
      border-color: #2F4CDD; }
  .pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
    background: #ffb800;
    border-color: #ffb800; }
    [data-theme-version="dark"] .pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
      background: #ffb800;
      border-color: #ffb800; }
  .pagination-primary .page-item .page-link {
    background: #fdf4f8;
    border-color: #fdf4f8;
    color: #3F9AE0; }
    [data-theme-version="dark"] .pagination-primary .page-item .page-link {
      background: rgba(221, 47, 110, 0.2);
      border-color: transparent;
      color: #3F9AE0; }
  .pagination-primary .page-item:hover .page-link, .pagination-primary .page-item.active .page-link {
    background: #3F9AE0;
    border-color: #3F9AE0;
    box-shadow: 0 10px 20px 0px rgba(221, 47, 110, 0.2); }
    [data-theme-version="dark"] .pagination-primary .page-item:hover .page-link, [data-theme-version="dark"] .pagination-primary .page-item.active .page-link {
      color: #fff; }
  .pagination-danger .page-item .page-link {
    background: #fde6e6;
    border-color: #fde6e6;
    color: #f35757; }
    [data-theme-version="dark"] .pagination-danger .page-item .page-link {
      background: rgba(243, 87, 87, 0.15);
      border-color: transparent;
      color: #f35757; }
  .pagination-danger .page-item:hover .page-link, .pagination-danger .page-item.active .page-link {
    background: #f35757;
    border-color: #f35757;
    box-shadow: 0 10px 20px 0px rgba(243, 87, 87, 0.2); }
    [data-theme-version="dark"] .pagination-danger .page-item:hover .page-link, [data-theme-version="dark"] .pagination-danger .page-item.active .page-link {
      color: #fff; }
  .pagination-info .page-item .page-link {
    background: #dee3fa;
    border-color: #dee3fa;
    color: #2F4CDD; }
    [data-theme-version="dark"] .pagination-info .page-item .page-link {
      background: rgba(47, 76, 221, 0.1);
      border-color: transparent;
      color: #2F4CDD; }
  .pagination-info .page-item:hover .page-link, .pagination-info .page-item.active .page-link {
    background: #2F4CDD;
    border-color: #2F4CDD;
    box-shadow: 0 10px 20px 0px rgba(47, 76, 221, 0.2); }
  .pagination-warning .page-item .page-link {
    background: #fff6e0;
    border-color: #fff6e0;
    color: #ffb800; }
    [data-theme-version="dark"] .pagination-warning .page-item .page-link {
      background: rgba(255, 184, 0, 0.1);
      border-color: transparent;
      color: #ffb800; }
  .pagination-warning .page-item:hover .page-link, .pagination-warning .page-item.active .page-link {
    background: #ffb800;
    border-color: #ffb800;
    box-shadow: 0 10px 20px 0px rgba(255, 184, 0, 0.2); }
  .pagination-gutter .page-item {
    margin-right: 7px; }
    .pagination-gutter .page-item .page-link {
      border-radius: 0.75rem !important; }
  .pagination-circle .page-item {
    margin-right: 7px; }
    .pagination-circle .page-item .page-link, .pagination-circle .page-item.page-indicator .page-link {
      width: 40px;
      height: 40px;
      padding: 0;
      line-height: 40px;
      border-radius: 50% !important;
      padding: 0; }
  .pagination.pagination-md .page-item .page-link {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px; }
  .pagination.pagination-sm .page-item.page-indicator .page-link {
    font-size: 12px; }
  .pagination.pagination-sm .page-item .page-link {
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px; }
  .pagination.pagination-xs .page-item.page-indicator .page-link {
    font-size: 10px; }
  .pagination.pagination-xs .page-item .page-link {
    padding: 0;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 12px; }

.popover {
  border: 2px solid #392540;
  min-width: 210px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1); }
  [data-theme-version="dark"] .popover {
    background-color: #181f39; }
  .popover-header {
    background: #392540;
    color: #fff;
    font-weight: 300; }
    .popover-header::before {
      border-bottom: 0 !important; }
  .popover-body {
    font-size: 0.75rem; }
  [data-theme-version="dark"] .popover .popover-header {
    border-color: #1e2746; }

.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
  margin-right: 8px; }
.bootstrap-popover-wrapper .bootstrap-popover {
  margin-bottom: .5rem; }
  .bootstrap-popover-wrapper .bootstrap-popover button:hover, .bootstrap-popover-wrapper .bootstrap-popover button:focus {
    background: #3F9AE0;
    color: #fff;
    box-shadow: none; }

.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 0px;
  border-top-color: #392540; }

.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 0px;
  border-left-color: #392540; }

.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 0px;
  border-right-color: #392540; }

.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 0px;
  border-bottom-color: #392540; }

.note-popover {
  display: none; }

.progress-bar {
  background-color: #3F9AE0; }

.progress-bar-primary {
  background-color: #3F9AE0; }

.progress-bar-success {
  background-color: #2bc155; }

.progress-bar-info {
  background-color: #2F4CDD; }

.progress-bar-danger {
  background-color: #f35757; }

.progress-bar-warning {
  background-color: #ffb800; }

.progress-bar-pink {
  background-color: #e83e8c; }

.progress {
  height: 6px;
  overflow: hidden; }
  [data-theme-version="dark"] .progress {
    background-color: #181f39; }
  .progress-bar {
    border-radius: 4px; }

.progress-vertical {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative; }
  @media (max-width: 991.98px) {
    .progress-vertical {
      margin-right: 1rem; } }

.progress-vertical-bottom {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
  transform: rotate(180deg); }
  @media (max-width: 991.98px) {
    .progress-vertical-bottom {
      margin-right: 1rem; } }

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s; }

@keyframes myanimation {
  0% {
    width: 0; } }
@keyframes myanimation {
  0% {
    width: 0; } }
.ribbon {
  position: absolute;
  z-index: 1;
  text-transform: uppercase; }
  .ribbon__one {
    top: 15px;
    left: -11px;
    min-height: 20px;
    min-width: 52px;
    text-align: center;
    padding: 3px 10px;
    background: #3AB54B;
    color: #fff;
    font-size: 0.625rem; }
    .ribbon__one::after {
      position: absolute;
      width: 0;
      height: 0;
      border-top: 10px solid #239132;
      border-left: 11px solid transparent;
      left: 0;
      content: "";
      bottom: -10px; }
  .ribbon__two {
    width: 50px;
    height: 50px;
    display: inline-block;
    background: #ffb800;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    right: 15px;
    top: 15px;
    border-radius: 3px; }
  .ribbon__three {
    left: -1.875rem;
    top: 0.875rem;
    width: 6.875rem;
    height: 1.5625rem;
    background-color: #f35757;
    clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px; }
  .ribbon__four {
    left: 8px;
    top: -8px;
    width: 110px;
    height: 50px;
    background-color: #3F9AE0;
    z-index: auto;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .ribbon__four:after {
      right: -5px; }
    .ribbon__four:before {
      left: -5px; }
    .ribbon__four:after, .ribbon__four:before {
      z-index: -1;
      background-color: #eb87ab;
      top: 3px;
      transform: rotate(45deg);
      content: "";
      height: 10px;
      width: 10px;
      position: absolute; }
  .ribbon__five {
    left: -1.875rem;
    top: 0.625rem;
    width: 6.875rem;
    height: 1.875rem;
    background-color: #3F9AE0;
    transform: rotate(-45deg);
    font-size: 0.75rem;
    color: #fff;
    padding-bottom: 0.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem; }
    .ribbon__five::before {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 50px 50px 50px;
      border-color: transparent transparent #3F9AE0 transparent;
      left: 0.25rem;
      top: -2.8125rem; }
  .ribbon__six {
    left: 0;
    top: 1.125rem;
    width: 6.875rem;
    height: 2.375rem;
    background-color: #3F9AE0;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
    display: flex;
    font-size: 1.25rem;
    align-items: center;
    justify-content: center;
    color: #fff; }
  .ribbon-curve {
    position: absolute;
    top: 0;
    right: -6px;
    width: 10px;
    height: 11px;
    border-top: 11px solid #3AB54B;
    border-bottom: 10px solid #3AB54B;
    border-right: 5px solid transparent; }

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  background-color: #F5F5F5;
  width: 8px;
  height: 8px; }
.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5; }
.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #7e7e7e; }

.multi-steps > li.is-active:before, .multi-steps > li.is-active ~ li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 400; }
.multi-steps > li.is-active:after, .multi-steps > li.is-active ~ li:after {
  background-color: #d7dae3; }

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%; }
  .multi-steps > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: #3F9AE0; }
    @media (max-width: 575.98px) {
      .multi-steps > li {
        font-size: 0.75rem; } }
    .multi-steps > li:before {
      content: '\2713';
      display: block;
      margin: 0 auto 4px;
      background-color: #fff;
      width: 25px;
      height: 25px;
      line-height: 22px;
      text-align: center;
      font-weight: bold;
      position: relative;
      z-index: 1;
      border-width: 2px;
      border-style: solid;
      border-color: #3F9AE0;
      border-radius: 5px; }
      @media (max-width: 575.98px) {
        .multi-steps > li:before {
          width: 25px;
          height: 25px;
          line-height: 21px; } }
    .multi-steps > li:after {
      content: '';
      height: 2px;
      width: 100%;
      background-color: #3F9AE0;
      position: absolute;
      top: 12px;
      left: 50%; }
      [direction="rtl"] .multi-steps > li:after {
        left: auto;
        right: 50%; }
      @media (max-width: 575.98px) {
        .multi-steps > li:after {
          top: 12px; } }
    .multi-steps > li:last-child:after {
      display: none; }
    .multi-steps > li.is-active:before {
      background-color: #fff;
      border-color: #3F9AE0; }
    .multi-steps > li.is-active ~ li {
      color: #7e7e7e; }
      .multi-steps > li.is-active ~ li:before {
        background-color: #d7dae3;
        border-color: #d7dae3; }

.nav-pills .nav-link {
  border-radius: 0.75rem;
  padding: 0.75rem 1.25rem; }

.default-tab .nav-link {
  background: transparent;
  border-radius: 0px;
  font-weight: 500; }
  .default-tab .nav-link i {
    display: inline-block;
    transform: scale(1.5);
    color: #3F9AE0; }
  .default-tab .nav-link:focus, .default-tab .nav-link:hover, .default-tab .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff #ebeef6;
    border-radius: 0.75rem 0.75rem 0 0;
    color: #3F9AE0; }
    [data-theme-version="dark"] .default-tab .nav-link:focus, [data-theme-version="dark"] .default-tab .nav-link:hover, [data-theme-version="dark"] .default-tab .nav-link.active {
      background-color: rgba(221, 47, 110, 0.2);
      border-color: transparent transparent #333a54 transparent; }

.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0px;
  font-weight: 500;
  border-bottom: 3px solid transparent;
  border-width: 0 0px 3px 0; }
  .custom-tab-1 .nav-link i {
    display: inline-block;
    transform: scale(1.5);
    color: #3F9AE0; }
  .custom-tab-1 .nav-link:focus, .custom-tab-1 .nav-link:hover, .custom-tab-1 .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #3F9AE0;
    border-radius: 0;
    color: #3F9AE0;
    border-width: 0 0px 3px 0; }
    [data-theme-version="dark"] .custom-tab-1 .nav-link:focus, [data-theme-version="dark"] .custom-tab-1 .nav-link:hover, [data-theme-version="dark"] .custom-tab-1 .nav-link.active {
      background-color: rgba(221, 47, 110, 0.2); }

.nav-pills.light .nav-link.active, .nav-pills.light .show > .nav-link {
  background: #fdf4f8;
  color: #3F9AE0;
  box-shadow: none; }
  [data-theme-version="dark"] .nav-pills.light .nav-link.active,
  [data-theme-version="dark"] .nav-pills.light .show > .nav-link {
    background: rgba(221, 47, 110, 0.2); }
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  box-shadow: 0 0 20px 0 rgba(221, 47, 110, 0.2); }

.tooltip-wrapper button:not(:last-child) {
  margin-right: 8px; }
.tooltip-wrapper button:hover {
  background: #3F9AE0;
  color: #fff; }
.tooltip-wrapper button {
  margin-bottom: .5rem; }
  .tooltip-wrapper button:focus {
    box-shadow: none; }
.tooltip-inner {
  border-radius: 0;
  background: #333333;
  font-size: 12px;
  font-weight: 300;
  padding: 0.35rem 0.7rem; }

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #333333; }

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #333333; }

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #333333; }

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #333333; }

.widget-timeline .timeline {
  list-style: none;
  position: relative; }
  .widget-timeline .timeline:before {
    top: 20px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    left: 9px;
    margin-right: -1.5px;
    background: #f4f4f4; }
    [data-theme-version="dark"] .widget-timeline .timeline:before {
      background: #333a54; }
    [direction="rtl"] .widget-timeline .timeline:before {
      left: auto;
      right: 9px;
      margin-right: auto;
      margin-left: -1.5px; }
  .widget-timeline .timeline > li {
    margin-bottom: 15px;
    position: relative; }
    .widget-timeline .timeline > li:before {
      content: " ";
      display: table; }
    .widget-timeline .timeline > li:after {
      content: " ";
      display: table;
      clear: both; }
    .widget-timeline .timeline > li > .timeline-panel {
      border-radius: 0.75rem;
      padding: 15px 20px;
      position: relative;
      display: block;
      margin-left: 40px;
      border-width: 1px;
      border-style: solid; }
      .widget-timeline .timeline > li > .timeline-panel span {
        font-size: 12px;
        display: block;
        margin-bottom: 5px;
        opacity: 0.8;
        letter-spacing: 1px; }
      .widget-timeline .timeline > li > .timeline-panel p {
        font-size: 14px; }
      .widget-timeline .timeline > li > .timeline-panel:after {
        content: "";
        width: 10px;
        height: 10px;
        background: inherit;
        border-color: inherit;
        border-style: solid;
        border-width: 0px 0px 1px 1px;
        display: block;
        position: absolute;
        left: -5px;
        transform: rotate(45deg);
        top: 15px; }
    .widget-timeline .timeline > li > .timeline-badge {
      border-radius: 50%;
      height: 22px;
      left: 0;
      position: absolute;
      top: 10px;
      width: 22px;
      border-width: 2px;
      border-style: solid;
      background: #fff;
      padding: 4px; }
      [data-theme-version="dark"] .widget-timeline .timeline > li > .timeline-badge {
        background-color: #1e2746; }
      .widget-timeline .timeline > li > .timeline-badge:after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 100%;
        display: block; }
      [direction="rtl"] .widget-timeline .timeline > li > .timeline-badge {
        left: auto;
        right: 19px; }
.widget-timeline .timeline-body > p {
  font-size: 12px; }
.widget-timeline .timeline-badge.primary {
  border-color: #fdf4f8; }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.primary {
    border-color: rgba(221, 47, 110, 0.2); }
  .widget-timeline .timeline-badge.primary:after {
    background-color: #3F9AE0 !important;
    box-shadow: 0 5px 10px 0 rgba(221, 47, 110, 0.2); }
  .widget-timeline .timeline-badge.primary + .timeline-panel {
    background: #fdf4f8;
    border-color: #fdf4f8; }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.primary + .timeline-panel {
      border-color: transparent;
      background-color: rgba(221, 47, 110, 0.2); }
.widget-timeline .timeline-badge.success {
  border-color: #e7faec; }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.success {
    border-color: rgba(43, 193, 85, 0.1); }
  .widget-timeline .timeline-badge.success:after {
    background-color: #2bc155 !important;
    box-shadow: 0 5px 10px 0 rgba(43, 193, 85, 0.2); }
  .widget-timeline .timeline-badge.success + .timeline-panel {
    background: #e7faec;
    border-color: #e7faec; }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.success + .timeline-panel {
      background-color: rgba(43, 193, 85, 0.1);
      border-color: transparent; }
.widget-timeline .timeline-badge.warning {
  border-color: #fff6e0; }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.warning {
    border-color: rgba(255, 184, 0, 0.1); }
  .widget-timeline .timeline-badge.warning:after {
    background-color: #ffb800 !important;
    box-shadow: 0 5px 10px 0 rgba(255, 184, 0, 0.2); }
  .widget-timeline .timeline-badge.warning + .timeline-panel {
    background: #fff6e0;
    border-color: #fff6e0; }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.warning + .timeline-panel {
      background-color: rgba(255, 184, 0, 0.1);
      border-color: transparent; }
.widget-timeline .timeline-badge.danger {
  border-color: #fde6e6; }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.danger {
    border-color: rgba(243, 87, 87, 0.15); }
  .widget-timeline .timeline-badge.danger:after {
    background-color: #f35757 !important;
    box-shadow: 0 5px 10px 0 rgba(243, 87, 87, 0.2); }
  .widget-timeline .timeline-badge.danger + .timeline-panel {
    background: #fde6e6;
    border-color: #fde6e6; }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.danger + .timeline-panel {
      background-color: rgba(243, 87, 87, 0.15);
      border-color: transparent; }
.widget-timeline .timeline-badge.info {
  border-color: #dee3fa; }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.info {
    border-color: rgba(47, 76, 221, 0.1); }
  .widget-timeline .timeline-badge.info:after {
    background-color: #2F4CDD !important;
    box-shadow: 0 5px 10px 0 rgba(47, 76, 221, 0.2); }
  .widget-timeline .timeline-badge.info + .timeline-panel {
    background: #dee3fa;
    border-color: #dee3fa; }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.info + .timeline-panel {
      background-color: rgba(47, 76, 221, 0.1);
      border-color: transparent; }
.widget-timeline .timeline-badge.dark {
  border-color: #dee2e6; }
  [data-theme-version="dark"] .widget-timeline .timeline-badge.dark {
    border-color: rgba(62, 73, 84, 0.35); }
  .widget-timeline .timeline-badge.dark:after {
    background-color: #3e4954 !important;
    box-shadow: 0 5px 10px 0 rgba(62, 73, 84, 0.2); }
  .widget-timeline .timeline-badge.dark + .timeline-panel {
    background: #dee2e6;
    border-color: #dee2e6; }
    [data-theme-version="dark"] .widget-timeline .timeline-badge.dark + .timeline-panel {
      background-color: rgba(62, 73, 84, 0.35);
      border-color: transparent; }
.widget-timeline.style-1 .timeline-panel {
  background: transparent; }
.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel {
  background: transparent !important;
  border-style: solid;
  border-width: 0 0 0 5px;
  border-radius: 0;
  padding: 5px 10px 5px 15px; }
  .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel:after {
    border: 0;
    left: -9px;
    width: 7px;
    height: 7px; }
.widget-timeline.style-1 .timeline .timeline-badge.primary + .timeline-panel {
  border-color: #3F9AE0; }
  .widget-timeline.style-1 .timeline .timeline-badge.primary + .timeline-panel:after {
    background: #3F9AE0; }
.widget-timeline.style-1 .timeline .timeline-badge.success + .timeline-panel {
  border-color: #2bc155; }
  .widget-timeline.style-1 .timeline .timeline-badge.success + .timeline-panel:after {
    background: #2bc155; }
.widget-timeline.style-1 .timeline .timeline-badge.warning + .timeline-panel {
  border-color: #ffb800; }
  .widget-timeline.style-1 .timeline .timeline-badge.warning + .timeline-panel:after {
    background: #ffb800; }
.widget-timeline.style-1 .timeline .timeline-badge.danger + .timeline-panel {
  border-color: #f35757; }
  .widget-timeline.style-1 .timeline .timeline-badge.danger + .timeline-panel:after {
    background: #f35757; }
.widget-timeline.style-1 .timeline .timeline-badge.info + .timeline-panel {
  border-color: #2F4CDD; }
  .widget-timeline.style-1 .timeline .timeline-badge.info + .timeline-panel:after {
    background: #2F4CDD; }
.widget-timeline.style-1 .timeline .timeline-badge.dark + .timeline-panel {
  border-color: #3e4954; }
  .widget-timeline.style-1 .timeline .timeline-badge.dark + .timeline-panel:after {
    background: #3e4954; }

#chart_widget_4 {
  height: 255px !important; }

#chart_widget_5 .ct-series-a .ct-line,
#chart_widget_5 .ct-series-a .ct-point {
  stroke: rgba(255, 255, 255, 0.5); }
#chart_widget_5 .ct-line {
  stroke-width: 1px; }
#chart_widget_5 .ct-point {
  stroke-width: 2px; }
#chart_widget_5 .ct-series-a .ct-area {
  fill: rgba(255, 255, 255, 0.1); }
#chart_widget_5 .ct-area {
  fill-opacity: 1; }

#chart_widget_6 .ct-series-a .ct-line,
#chart_widget_6 .ct-series-a .ct-point {
  stroke: #2F4CDD; }
#chart_widget_6 .ct-line {
  stroke-width: 2px; }
#chart_widget_6 .ct-point {
  stroke-width: 5px; }
#chart_widget_6 .ct-series-a .ct-area {
  fill: #2F4CDD; }
#chart_widget_6 .ct-area {
  fill-opacity: .5; }

#chart_widget_8 {
  height: 255px; }
  #chart_widget_8 .ct-series-a .ct-line,
  #chart_widget_8 .ct-series-a .ct-point {
    stroke: #2F4CDD; }
  #chart_widget_8 .ct-line {
    stroke-width: 2px; }
  #chart_widget_8 .ct-point {
    stroke-width: 5px; }
  #chart_widget_8 .ct-series-a .ct-area {
    fill: #2F4CDD; }
  #chart_widget_8 .ct-area {
    fill-opacity: .5; }

#chart_widget_9, #chart_widget_10 {
  height: 250px !important; }

#chart_widget_11 .ct-slice-donut, #chart_widget_12 .ct-slice-donut, #chart_widget_13 .ct-slice-donut {
  stroke-width: 25px !important; }

#chart_widget_11 {
  height: 270px !important; }

#chart_widget_17 {
  height: 150px !important; }

.chart_widget_tab_one .nav-link {
  border: 1px solid #ddd; }
  .chart_widget_tab_one .nav-link.active {
    background-color: #3F9AE0;
    border: 1px solid #3F9AE0;
    color: #fff; }
    .chart_widget_tab_one .nav-link.active:hover {
      border: 1px solid #3F9AE0; }
  .chart_widget_tab_one .nav-link:hover {
    border: 1px solid #ddd; }

[data-theme-version="dark"] .ccc-widget > div {
  background: #1e2746 !important;
  border-color: #333a54 !important; }

.social-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  background: #ffffff;
  margin-bottom: 0.5rem;
  font-size: 20px; }
  .social-icon i {
    color: #fff; }
  .social-icon.youtube {
    background: #FF0000; }
  .social-icon.facebook {
    background: #3b5998; }
  .social-icon.twitter {
    background: #1da1f2; }

.social-icon2 a {
  border: 0.1rem solid #3F9AE0;
  display: block;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  border-radius: .3rem;
  font-size: 1.6rem; }
.social-icon2 i {
  font-size: 12px;
  color: #3F9AE0; }

.social-icon3 ul li {
  display: inline-block; }
  .social-icon3 ul li a {
    display: block; }
    .social-icon3 ul li a i {
      font-size: 1.4rem;
      padding: 1rem .7rem;
      color: #464a53; }
    .social-icon3 ul li a:hover i {
      color: #3F9AE0; }

.social-icons-muted ul li a i {
  color: #89879f; }

.social-links a {
  padding: .5rem; }

.widget-stat .media {
  padding: 0px 0;
  align-items: center; }
  .widget-stat .media > span {
    height: 85px;
    width: 85px;
    border-radius: 50px;
    padding: 10px 12px;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #464a53;
    min-width: 85px; }
  .widget-stat .media .media-body p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px; }
    [data-theme-version="dark"] .widget-stat .media .media-body p {
      color: #c4c9d5; }
  .widget-stat .media .media-body h3 {
    font-size: 40px;
    font-weight: 600;
    margin: 0;
    line-height: 1.2; }
  .widget-stat .media .media-body h4 {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle; }
  .widget-stat .media .media-body span {
    margin-left: 5px; }
.widget-stat[class*="bg-"] .media > span {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff; }
.widget-stat[class*="bg-"] .progress {
  background-color: rgba(255, 255, 255, 0.25) !important; }

[direction="rtl"] .widget-stat .media .media-body span {
  margin-left: 0;
  margin-right: 10px; }

.dez-widget-1 .card {
  background: #FFE7DB; }
  .dez-widget-1 .card .card-body p {
    color: #f87533; }
.dez-widget-1 .icon {
  float: right;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 28px; }

.bgl-primary {
  background: #fdf4f8;
  border-color: #fdf4f8; }
  [data-theme-version="dark"] .bgl-primary {
    background-color: rgba(221, 47, 110, 0.2);
    border-color: rgba(221, 47, 110, 0.2); }

.bgl-secondary {
  background: #e8deec;
  border-color: #e8deec; }
  [data-theme-version="dark"] .bgl-secondary {
    background-color: rgba(57, 37, 64, 0.5);
    border-color: rgba(57, 37, 64, 0.5); }

.bgl-success {
  background: #e7faec;
  border-color: #e7faec; }
  [data-theme-version="dark"] .bgl-success {
    background-color: rgba(43, 193, 85, 0.1);
    border-color: rgba(43, 193, 85, 0.1); }

.bgl-warning {
  background: #fff6e0;
  border-color: #fff6e0; }
  [data-theme-version="dark"] .bgl-warning {
    background-color: rgba(255, 184, 0, 0.1);
    border-color: rgba(255, 184, 0, 0.1); }

.bgl-danger {
  background: #fde6e6;
  border-color: #fde6e6; }
  [data-theme-version="dark"] .bgl-danger {
    background-color: rgba(243, 87, 87, 0.15);
    border-color: rgba(243, 87, 87, 0.15); }

.bgl-info {
  background: #dee3fa;
  border-color: #dee3fa; }
  [data-theme-version="dark"] .bgl-info {
    background-color: rgba(47, 76, 221, 0.1);
    border-color: rgba(47, 76, 221, 0.1); }

.bg-primary-light {
  background: rgba(253, 244, 248, 0.5); }
  [data-theme-version="dark"] .bg-primary-light {
    background-color: rgba(221, 47, 110, 0.05); }

.bg-secondary-light {
  background: rgba(232, 222, 236, 0.5); }
  [data-theme-version="dark"] .bg-secondary-light {
    background-color: rgba(57, 37, 64, 0.05); }

.bg-success-light {
  background: rgba(231, 250, 236, 0.5); }
  [data-theme-version="dark"] .bg-success-light {
    background-color: rgba(43, 193, 85, 0.05); }

.bg-warning-light {
  background: rgba(255, 246, 224, 0.5); }
  [data-theme-version="dark"] .bg-warning-light {
    background-color: rgba(255, 184, 0, 0.05); }

.bg-danger-light {
  background: rgba(253, 230, 230, 0.5); }
  [data-theme-version="dark"] .bg-danger-light {
    background-color: rgba(243, 87, 87, 0.05); }

.bg-info-light {
  background: rgba(222, 227, 250, 0.5); }
  [data-theme-version="dark"] .bg-info-light {
    background-color: rgba(47, 76, 221, 0.05); }

.bgl-dark {
  background: #dee2e6;
  border-color: #dee2e6; }

.bgl-light {
  background: #f4f4f4;
  border-color: #f4f4f4; }

.overlay-box {
  position: relative;
  z-index: 1; }
  .overlay-box:after {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    opacity: 0.85;
    background: #3F9AE0;
    z-index: -1; }

.rating-bar {
  font-size: 13px; }

.tdl-holder {
  margin: 0 auto; }
  .tdl-holder ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .tdl-holder li {
    background-color: #fff;
    border-bottom: 1px solid #d7dae3;
    list-style: none none;
    margin: 0;
    padding: 0; }
    .tdl-holder li span {
      margin-left: 35px;
      font-size: 1rem;
      vertical-align: middle;
      transition: all 0.2s linear; }
      [direction="rtl"] .tdl-holder li span {
        margin-left: auto;
        margin-right: 35px; }
  .tdl-holder label {
    cursor: pointer;
    display: block;
    line-height: 50px;
    padding-left: 1.5rem;
    position: relative;
    margin: 0 !important; }
    [direction="rtl"] .tdl-holder label {
      padding-left: 0;
      padding-right: 1.5rem; }
    .tdl-holder label:hover {
      background-color: #eef1f6;
      color: #3e4954; }
      .tdl-holder label:hover a {
        color: #f35757; }
    .tdl-holder label a {
      color: #fff;
      display: inline-block;
      line-height: normal;
      height: 100%;
      text-align: center;
      text-decoration: none;
      width: 50px;
      -webkit-transition: all .2s linear;
      -moz-transition: all .2s linear;
      -o-transition: all .2s linear;
      transition: all 0.2s linear;
      padding: 18px 0px;
      font-size: 18px;
      position: absolute;
      right: 0; }
      [direction="rtl"] .tdl-holder label a {
        right: auto;
        left: 0; }
  .tdl-holder input[type=checkbox] {
    cursor: pointer;
    opacity: 0;
    position: absolute; }
    .tdl-holder input[type=checkbox] + i {
      background-color: #fff;
      border: 1px solid #e7e7e7;
      display: block;
      height: 20px;
      position: absolute;
      top: 15px;
      width: 20px;
      z-index: 1;
      border-radius: 00px;
      font-size: 11px;
      border-radius: 4px; }
    .tdl-holder input[type=checkbox]:checked + i {
      background: #3F9AE0;
      border-color: transparent; }
    .tdl-holder input[type=checkbox]:checked + i::after {
      content: "\f00c";
      font-family: 'fontAwesome';
      display: block;
      left: 2px;
      position: absolute;
      top: -14px;
      z-index: 2;
      color: #fff; }
    .tdl-holder input[type=checkbox]:checked ~ span {
      text-decoration: line-through;
      position: relative; }
  .tdl-holder input[type=text] {
    background-color: #fff;
    height: 50px;
    margin-top: 29px;
    border-radius: 3px; }
    .tdl-holder input[type=text]::placeholder {
      color: #3e4954; }

.widget-message p {
  font-size: 14px;
  line-height: 1.5; }

.picker .picker__frame {
  min-width: 450px !important;
  max-width: 450px !important; }
  .picker .picker__frame .picker__box {
    padding: 20px;
    border: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); }
    .picker .picker__frame .picker__box .picker__header {
      margin-top: 0; }
      .picker .picker__frame .picker__box .picker__header .picker__year {
        color: #3F9AE0;
        font-style: inherit;
        font-size: 20px; }
      .picker .picker__frame .picker__box .picker__header .picker__nav--prev,
      .picker .picker__frame .picker__box .picker__header .picker__nav--next {
        width: 40px;
        height: 40px;
        padding: 0;
        line-height: 40px;
        border-radius: 2px; }
        .picker .picker__frame .picker__box .picker__header .picker__nav--prev:before,
        .picker .picker__frame .picker__box .picker__header .picker__nav--next:before {
          content: none !important; }
        .picker .picker__frame .picker__box .picker__header .picker__nav--prev:after,
        .picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
          font-family: fontawesome;
          border: 0;
          margin: 0 auto;
          font-size: 22px; }
        .picker .picker__frame .picker__box .picker__header .picker__nav--prev:hover,
        .picker .picker__frame .picker__box .picker__header .picker__nav--next:hover {
          background-color: #f2b3ca !important;
          color: #3F9AE0 !important; }
      .picker .picker__frame .picker__box .picker__header .picker__nav--prev {
        left: 0; }
        .picker .picker__frame .picker__box .picker__header .picker__nav--prev:after {
          content: "\f0d9"; }
      .picker .picker__frame .picker__box .picker__header .picker__nav--next {
        right: 0; }
        .picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
          content: "\f0da"; }
    .picker .picker__frame .picker__box .picker__table .picker__weekday {
      padding: 10px 0;
      font-size: 16px; }
    .picker .picker__frame .picker__box .picker__table .picker__day {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      padding: 0 !important;
      font-size: 16px;
      line-height: 40px;
      margin: auto;
      border: 0 !important; }
      .picker .picker__frame .picker__box .picker__table .picker__day.picker__day--today:before {
        content: none !important; }
      .picker .picker__frame .picker__box .picker__table .picker__day.picker__day--highlighted {
        border: 0 !important;
        padding: 0;
        background-color: #f2b3ca !important;
        color: #fff !important; }
      .picker .picker__frame .picker__box .picker__table .picker__day:hover {
        background-color: #f2b3ca !important;
        color: #fff !important; }
    .picker .picker__frame .picker__box .picker__footer .picker__button--today {
      background-color: #f2b3ca !important;
      color: #fff !important; }
      .picker .picker__frame .picker__box .picker__footer .picker__button--today:before {
        content: none !important; }
    .picker .picker__frame .picker__box .picker__footer .picker__button--clear,
    .picker .picker__frame .picker__box .picker__footer .picker__button--close,
    .picker .picker__frame .picker__box .picker__footer .picker__button--today {
      border: 0;
      border-radius: 2px;
      font-size: 16px; }
      .picker .picker__frame .picker__box .picker__footer .picker__button--clear:hover,
      .picker .picker__frame .picker__box .picker__footer .picker__button--close:hover,
      .picker .picker__frame .picker__box .picker__footer .picker__button--today:hover {
        background-color: #f2b3ca !important;
        color: #fff !important; }
  @media only screen and (max-width: 575px) {
    .picker .picker__frame {
      min-width: 100% !important;
      max-width: 100% !important; }
      .picker .picker__frame .picker__box {
        padding: 15px;
        margin: 0 10px; } }

.card-list {
  overflow: unset;
  height: calc(100% - 50px); }
  .card-list .card-header {
    padding-top: 0;
    padding-bottom: 0; }
    .card-list .card-header .photo {
      overflow: hidden;
      border-radius: 5px;
      width: 100%; }
  @media only screen and (max-width: 1199px) {
    .card-list {
      height: calc(100% - 40px); } }
  .card-list.list-left {
    margin-top: 15px;
    margin-left: 15px; }
    .card-list.list-left .card-header {
      padding-left: 0; }
      .card-list.list-left .card-header .photo {
        margin: -15px 15px 0 -15px; }
  .card-list.list-right {
    margin-top: 15px;
    margin-right: 15px;
    text-align: right; }
    .card-list.list-right .card-header {
      padding-right: 0; }
      .card-list.list-right .card-header .photo {
        margin: -15px -15px 0 15px; }
        .card-list.list-right .card-header .photo img {
          width: 100%; }
  .card-list.list-center {
    margin-top: 15px; }
    .card-list.list-center .card-header .photo {
      margin: -15px 0 0 0; }
  .card-list .photo img {
    width: 100%; }

.card-profile .profile-photo {
  margin-top: -20px; }

.widget-media .timeline .timeline-panel {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
  margin-bottom: 15px; }
  .widget-media .timeline .timeline-panel .media {
    width: 50px;
    height: 50px;
    background: #eee;
    border-radius: 12px;
    overflow: hidden;
    font-size: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    align-self: start; }
    .widget-media .timeline .timeline-panel .media-primary {
      background: #fdf4f8;
      color: #3F9AE0; }
      [data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-primary {
        background-color: rgba(221, 47, 110, 0.2); }
    .widget-media .timeline .timeline-panel .media-info {
      background: #dee3fa;
      color: #2F4CDD; }
      [data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-info {
        background-color: rgba(47, 76, 221, 0.1); }
    .widget-media .timeline .timeline-panel .media-warning {
      background: #fff6e0;
      color: #ffb800; }
      [data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-warning {
        background-color: rgba(255, 184, 0, 0.1); }
    .widget-media .timeline .timeline-panel .media-danger {
      background: #fde6e6;
      color: #f35757; }
      [data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-danger {
        background-color: rgba(243, 87, 87, 0.15); }
    .widget-media .timeline .timeline-panel .media-success {
      background: #e7faec;
      color: #2bc155; }
      [data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-success {
        background-color: rgba(43, 193, 85, 0.1); }
  .widget-media .timeline .timeline-panel .media-body p {
    font-size: 14px;
    line-height: 1.5; }
  .widget-media .timeline .timeline-panel .dropdown {
    align-self: self-end;
    margin-top: 5px; }
.widget-media .timeline li:last-child .timeline-panel {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0; }

.card[class*="bg-"] .timeline .timeline-panel {
  border-color: rgba(255, 255, 255, 0.2) !important; }

.table {
  color: strong;
    color-color: #3e4954; }
  .table th {
    padding: 25px 30px;
    border-bottom: 1px solid rgba(215, 218, 227, 0.3);
    border-top: none; }
    @media only screen and (max-width: 767px) {
      .table th {
        padding: 10px 15px; } }
  .table td {
    padding: 15px 30px;
    border-color: rgba(215, 218, 227, 0.5);
    border-bottom: 1px solid;
    border-top: none; }
    @media only screen and (max-width: 767px) {
      .table td {
        padding: 10px 15px; } }
    [data-theme-version="dark"] .table td {
      border-color: #333a54; }
  .table.table-striped tbody tr:nth-of-type(odd), .table.table-hover tr:hover {
    background-color: #f2f4fa; }
    [data-theme-version="dark"] .table.table-striped tbody tr:nth-of-type(odd), [data-theme-version="dark"] .table.table-hover tr:hover {
      background-color: #181f39; }
  .table thead th {
    border-bottom-width: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-color: rgba(215, 218, 227, 0.3); }
  .table tbody tr td {
    vertical-align: middle;
    border-color: rgba(215, 218, 227, 0.3); }
    [data-theme-version="dark"] .table tbody tr td {
      border-color: #333a54; }
  .table:not(.table-bordered) thead th {
    border-top: none; }
  .table .thead-primary th {
    background-color: #3F9AE0;
    color: #fff; }
  .table .thead-info th {
    background-color: #2F4CDD;
    color: #fff; }
  .table .thead-warning th {
    background-color: #ffb800;
    color: #fff; }
  .table .thead-danger th {
    background-color: #f35757;
    color: #fff; }
  .table .thead-success th {
    background-color: #2bc155;
    color: #fff; }
  .table.primary-table-bordered {
    border: 1px solid #d7dae3; }
    [data-theme-version="dark"] .table.primary-table-bordered {
      border-color: #333a54; }
  .table.primary-table-bg-hover thead th {
    background-color: #d02261;
    color: #fff;
    border-bottom: none; }
  .table.primary-table-bg-hover tbody tr {
    background-color: #3F9AE0;
    color: #fff;
    transition: all .1s ease; }
    .table.primary-table-bg-hover tbody tr:hover {
      background-color: #e1457d; }
    .table.primary-table-bg-hover tbody tr:not(:last-child) td, .table.primary-table-bg-hover tbody tr:not(:last-child) th {
      border-bottom: 1px solid #d02261; }
  .table-responsive-tiny {
    min-width: 18.75rem; }
  .table-responsive-sm {
    min-width: 30rem !important; }
  .table-responsive-md {
    min-width: 36rem !important; }
  .table-responsive-lg {
    min-width: 60.9375rem !important; }
  .table-responsive-xl {
    min-width: 70.9375rem !important; }

.table-primary, .table-primary > th, .table-primary > td {
  background-color: #fdf4f8;
  color: #3F9AE0; }
  [data-theme-version="dark"] .table-primary,
  [data-theme-version="dark"] .table-primary > th,
  [data-theme-version="dark"] .table-primary > td {
    background-color: rgba(221, 47, 110, 0.2); }

.table-success, .table-success > th, .table-success > td {
  background-color: #e7faec;
  color: #2bc155; }
  [data-theme-version="dark"] .table-success,
  [data-theme-version="dark"] .table-success > th,
  [data-theme-version="dark"] .table-success > td {
    background-color: rgba(43, 193, 85, 0.1); }

.table-info, .table-info > th, .table-info > td {
  background-color: #dee3fa;
  color: #2F4CDD; }
  [data-theme-version="dark"] .table-info,
  [data-theme-version="dark"] .table-info > th,
  [data-theme-version="dark"] .table-info > td {
    background-color: rgba(47, 76, 221, 0.1); }

.table-warning, .table-warning > th, .table-warning > td {
  background-color: #fff6e0;
  color: #ffb800; }
  [data-theme-version="dark"] .table-warning,
  [data-theme-version="dark"] .table-warning > th,
  [data-theme-version="dark"] .table-warning > td {
    background-color: rgba(255, 184, 0, 0.1); }

.table-danger, .table-danger > th, .table-danger > td {
  background-color: #fde6e6;
  color: #f35757; }
  [data-theme-version="dark"] .table-danger,
  [data-theme-version="dark"] .table-danger > th,
  [data-theme-version="dark"] .table-danger > td {
    background-color: rgba(243, 87, 87, 0.15); }

.table-active, .table-active > th, .table-active > td {
  background-color: #f2f4fa; }
  [data-theme-version="dark"] .table-active,
  [data-theme-version="dark"] .table-active > th,
  [data-theme-version="dark"] .table-active > td {
    background-color: #181f39; }

.bootgrid-header {
  padding: 0 !important;
  margin: 0; }
  @media only screen and (max-width: 575px) {
    .bootgrid-header .actionBar {
      padding: 0; }
    .bootgrid-header .search {
      margin: 0 0 10px 0; } }

table#example {
  padding: 0rem 0 2rem 0; }

table.dataTable {
  color: #3e4954; }

#example2_wrapper .dataTables_scrollBody {
  max-height: 33.25rem !important; }

#employees, #custommers {
  padding: .5rem 0 1rem 0; }

.dataTables_wrapper .dataTables_paginate {
  padding: 10px 0; }
  @media only screen and (max-width: 1199px) {
    .dataTables_wrapper .dataTables_paginate {
      padding: 5px 0; } }

table.dataTable thead th,
table.dataTable thead td {
  padding: 15px 30px;
  border-bottom: 1px solid #d7dae3; }
  @media only screen and (max-width: 1199px) {
    table.dataTable thead th,
    table.dataTable thead td {
      padding: 8px 15px; } }

table.dataTable tfoot th,
table.dataTable tfoot td {
  border-top: 0; }

table.dataTable tbody tr, table.dataTable tbody td {
  background: transparent !important; }

table.dataTable thead th {
  color: #000;
  font-size: 18px;
  font-weight: 600; }
  [data-theme-version="dark"] table.dataTable thead th {
    color: #fff; }
  @media only screen and (max-width: 1199px) {
    table.dataTable thead th {
      font-size: 16px; } }

table.dataTable tbody td {
  padding: 15px 30px; }
  @media only screen and (max-width: 1199px) {
    table.dataTable tbody td {
      padding: 8px 15px; } }

table.dataTable tr.selected {
  color: #3F9AE0; }

table.dataTable tfoot th {
  color: #3e4954;
  font-weight: 600; }
  [data-theme-version="dark"] table.dataTable tfoot th {
    color: #fff; }

.dataTables_wrapper input[type="search"], .dataTables_wrapper input[type="text"], .dataTables_wrapper select {
  border: 1px solid #e2e2e2;
  padding: .3rem 0.5rem;
  color: #715d5d;
  border-radius: 5px; }
  [data-theme-version="dark"] .dataTables_wrapper input[type="search"], [data-theme-version="dark"] .dataTables_wrapper input[type="text"], [data-theme-version="dark"] .dataTables_wrapper select {
    background: #181f39;
    border-color: #333a54;
    color: #fff; }
.dataTables_wrapper .dataTables_length {
  margin-bottom: 15px; }
  .dataTables_wrapper .dataTables_length .bootstrap-select {
    width: 80px !important;
    margin: 0 5px; }

table.dataTable.no-footer {
  border-bottom: 0; }

.rounded-lg {
  min-width: 30px; }

.dataTables_scroll {
  padding: 1rem 0; }
  .dataTables_scrollFoot {
    padding-top: 1rem; }

@media (max-width: 991.98px) {
  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    text-align: left; } }

.dataTablesCard {
  background-color: #fff;
  box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
  border-radius: 20px; }
  .dataTablesCard tbody tr:hover {
    background: #f5f7ff; }

.dataTables_wrapper .dataTables_info {
  padding: 10px 0; }
  @media only screen and (max-width: 1199px) {
    .dataTables_wrapper .dataTables_info {
      padding: 8px 0; } }

.no-data-img {
  background-image: none !important; }

table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
  border-color: #d7dae3; }
  [data-theme-version="dark"] table.dataTable.row-border tbody th,
  [data-theme-version="dark"] table.dataTable.row-border tbody td,
  [data-theme-version="dark"] table.dataTable.display tbody th,
  [data-theme-version="dark"] table.dataTable.display tbody td {
    border-color: #333a54; }

table.dataTable thead .sorting {
  background-position: center right 15px; }

.dataTables_wrapper .dataTables_paginate span {
  background: #e3e4eb;
  border-radius: 0.75rem;
  display: inline-block;
  margin: 0 10px; }
  .dataTables_wrapper .dataTables_paginate span a {
    color: #3F9AE0;
    background: transparent !important; }
.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: 0 !important;
  padding: 10px 20px;
  background: #000;
  border-radius: 6px;
  color: #3F9AE0 !important;
  font-size: 16px;
  display: inline-block; }
  @media only screen and (max-width: 575px) {
    .dataTables_wrapper .dataTables_paginate .paginate_button {
      padding: 8px 15px;
      font-size: 14px; } }
  .dataTables_wrapper .dataTables_paginate .paginate_button:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.current {
    color: #392540 !important; }
  .dataTables_wrapper .dataTables_paginate .paginate_button.previous, .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    background: #969ba0;
    color: #fff !important; }
    .dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
      background: #3F9AE0;
      color: #fff !important; }

.dataTables_wrapper .dataTables_paginate span {
  color: #000 !important;
  background: unset; }

.customer-list-table thead th {
  text-transform: capitalize;
  font-size: 16px !important; }
.customer-list-table tr th:first-child,
.customer-list-table tr td:first-child {
  padding-left: 0;
  padding-right: 0; }
.customer-list-table .sorting_1 {
  display: inline-block; }

table.dataTable thead th {
  font-size: 16px;
  text-transform: capitalize; }

.dataTables_wrapper .bs-select label {
  display: flex;
  white-space: pre;
  align-items: center; }

.fooicon {
  font-size: 1.25rem;
  color: #3e4954; }

.jsgrid-table .jsgrid-header-row > .jsgrid-header-cell {
  color: #3e4954; }
.jsgrid-table > tbody > tr > td {
  padding: 1.2em; }
.jsgrid-table .jsgrid-insert-row input, .jsgrid-table .jsgrid-insert-row select, .jsgrid-table .jsgrid-edit-row input, .jsgrid-table .jsgrid-edit-row select {
  border: 1px solid #DDDFE1; }

.jsgrid .jsgrid-button {
  border: 0 !important;
  margin-left: 10px; }

.error-page .error-text {
  font-size: 150px;
  line-height: 1; }
  @media only screen and (max-width: 575px) {
    .error-page .error-text {
      font-size: 80px; } }
.error-page h4 {
  font-size: 40px;
  margin-bottom: 5px; }
  @media only screen and (max-width: 575px) {
    .error-page h4 {
      font-size: 20px; } }
.error-page p {
  font-size: 16px; }
  @media only screen and (max-width: 575px) {
    .error-page p {
      font-size: 14px; } }

.authincation-content {
  background: #fff;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  border-radius: 5px; }
  [data-theme-version="dark"] .authincation-content {
    background: #1e2746;
    box-shadow: none; }

.welcome-content {
  background: url("./../images/1.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 75px 50px;
  position: relative;
  z-index: 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }
  .welcome-content::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #3F9AE0;
    opacity: 0.75;
    z-index: -1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .welcome-content .welcome-title {
    color: #fff;
    font-weight: 500;
    font-size: 20px; }
  .welcome-content p {
    color: #fff; }
  .welcome-content .brand-logo a {
    display: inline-block;
    margin-bottom: 20px;
    font-weight: 700;
    color: #fff;
    font-size: 20px; }
    .welcome-content .brand-logo a img {
      width: 100px; }
  .welcome-content .intro-social {
    position: absolute;
    bottom: 75px; }
    .welcome-content .intro-social ul {
      margin-bottom: 0; }
      .welcome-content .intro-social ul li {
        display: inline-block; }
        .welcome-content .intro-social ul li a {
          color: #fff;
          font-size: 14px;
          padding: 0px 7px; }

.auth-form {
  padding: 50px 50px; }
  @media only screen and (max-width: 575px) {
    .auth-form {
      padding: 30px; } }
  .auth-form .btn {
    height: 50px;
    font-weight: 700; }
  .auth-form .page-back {
    display: inline-block;
    margin-bottom: 15px; }

.login-main-page {
  padding: 100px;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1; }
  .login-main-page .login-wrapper {
    background: #3F9AE0;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto; }
  .login-main-page .text-black {
    color: #222 !important; }
  .login-main-page:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1; }
  @media only screen and (max-width: 991px) {
    .login-main-page {
      padding: 50px; } }
  @media only screen and (max-width: 575px) {
    .login-main-page {
      padding: 0px; } }

.login-wrapper {
  display: flex;
  min-height: 600px;
  height: calc(100vh - 200px);
  box-shadow: 0 0 60px 10px rgba(85, 44, 44, 0.2); }
  .login-wrapper .login-aside-left {
    max-width: 50%;
    flex: 0 0 50%;
    position: relative;
    display: table;
    padding: 50px;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #fff; }
    .login-wrapper .login-aside-left:after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      content: "";
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
      /* IE6-9 */ }
    .login-wrapper .login-aside-left .login-description {
      display: table-cell;
      vertical-align: bottom;
      padding-bottom: 40px; }
  .login-wrapper .login-aside-right {
    max-width: 50%;
    flex: 0 0 50%; }
  .login-wrapper .social-icons {
    display: flex; }
    .login-wrapper .social-icons li {
      margin-right: 10px; }
      .login-wrapper .social-icons li a {
        height: 40px;
        width: 40px;
        color: var(--primary);
        border-radius: 40px;
        line-height: 38px;
        font-size: 18px;
        display: inline-block;
        text-align: center;
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        transition: all 0.5s;
        border: 1px solid var(--primary); }
        .login-wrapper .social-icons li a:hover {
          background: #fff;
          color: #000; }
  .login-wrapper .login-logo {
    position: absolute; }
  .login-wrapper .authincation-content {
    background-color: transparent;
    box-shadow: none; }
  .login-wrapper .form-control,
  .login-wrapper .btn {
    border-radius: 8px; }
  @media only screen and (max-width: 1199px) {
    .login-wrapper .login-aside-left {
      width: 470px; } }
  @media only screen and (max-width: 991px) {
    .login-wrapper {
      height: calc(100vh - 100px); } }
  @media only screen and (max-width: 767px) {
    .login-wrapper {
      display: block;
      height: auto; }
      .login-wrapper .login-aside-left,
      .login-wrapper .login-aside-right {
        max-width: 100%;
        flex: 0 0 100%; }
      .login-wrapper .login-logo {
        position: relative;
        margin-bottom: 20px;
        display: block; }
      .login-wrapper .social-icons {
        justify-content: center; }
        .login-wrapper .social-icons li {
          margin-left: 5px;
          margin-right: 5px; }
      .login-wrapper .login-aside-left {
        text-align: center;
        width: 100%;
        display: block; }
      .login-wrapper .authincation-content {
        padding: 30px 10px; } }
  @media only screen and (max-width: 575px) {
    .login-wrapper .login-aside-left {
      padding: 50px 30px; }
      .login-wrapper .login-aside-left .login-description {
        padding-bottom: 0; }
    .login-wrapper h2, .login-wrapper .h2 {
      font-size: 1.5rem; }
    .login-wrapper h4, .login-wrapper .h4 {
      font-size: 0.8rem; } }

.pricing_table_content {
  background: #fff;
  text-align: center;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  padding: 25px;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out; }
  .pricing_table_content .package {
    font-weight: 700;
    font-size: 18px; }
  .pricing_table_content .price {
    font-weight: 700;
    font-size: 50px;
    line-height: 100px;
    color: #3e4954; }
  .pricing_table_content hr {
    margin: 0; }
  .pricing_table_content .price_list {
    padding: 30px 0;
    text-align: left;
    max-width: 175px;
    margin: 0 auto; }
    .pricing_table_content .price_list li {
      color: #909093;
      font-size: 14px;
      line-height: 25px;
      padding: 7px 0; }
      .pricing_table_content .price_list li i {
        margin-right: 15px; }
  .pricing_table_content .price-btn {
    padding: 15px 50px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 25px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out; }
  .pricing_table_content:focus, .pricing_table_content:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.175); }
    .pricing_table_content:focus .price-btn, .pricing_table_content:hover .price-btn {
      color: #f35757; }

.page-timeline .timeline {
  list-style: none;
  padding: 40px 0;
  position: relative; }
  .page-timeline .timeline-workplan.page-timeline .timeline {
    padding-bottom: 0;
    margin-bottom: 0; }
    .page-timeline .timeline-workplan.page-timeline .timeline.timeline > li > .timeline-badge {
      top: 4.5rem; }
    .page-timeline .timeline-workplan.page-timeline .timeline > li {
      margin-bottom: 2.6rem; }
    @media only screen and (min-width: 1200px) and (max-width: 1650px) {
      .page-timeline .timeline-workplan.page-timeline .timeline li > p {
        max-width: 8rem; } }
    @media only screen and (max-width: 1199px) {
      .page-timeline .timeline-workplan.page-timeline .timeline li > p {
        max-width: 7rem; } }
    @media only screen and (max-width: 991px) {
      .page-timeline .timeline-workplan.page-timeline .timeline li > p {
        max-width: 100%; } }
    @media only screen and (max-width: 575px) {
      .page-timeline .timeline-workplan.page-timeline .timeline li > p {
        max-width: 7rem; } }
    .page-timeline .timeline-workplan.page-timeline .timeline::before {
      left: 20%;
      top: 6rem; }
      [direction="rtl"] .page-timeline .timeline-workplan.page-timeline .timeline::before {
        right: 22%;
        left: auto; }
      @media only screen and (max-width: 575px) {
        .page-timeline .timeline-workplan.page-timeline .timeline::before {
          left: 22%; } }
    .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
      left: 21.4%;
      height: 0.9rem;
      width: 0.9rem;
      background-color: #3F9AE0; }
      [direction="rtl"] .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
        right: 19.2%;
        left: auto; }
      @media only screen and (min-width: 1200px) and (max-width: 1650px) {
        .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
          left: 22.4%; } }
      @media only screen and (max-width: 1199px) {
        .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
          left: 22.4%; } }
      @media only screen and (max-width: 991px) {
        .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
          left: 21.7%; } }
      @media only screen and (max-width: 767px) {
        .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
          left: 19.5%; } }
      @media only screen and (max-width: 575px) {
        .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
          left: 21.4%; } }
      .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge::after {
        position: absolute;
        width: 1.9rem;
        height: 1.9rem;
        background-color: rgba(221, 47, 110, 0.4);
        content: "";
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
    .page-timeline .timeline-workplan.page-timeline .timeline .timeline-panel {
      width: 70%; }
.page-timeline .timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  background-color: #ECEFF2;
  left: 50%;
  margin-left: -1.5px; }
.page-timeline .timeline > li {
  margin-bottom: 20px;
  position: relative; }
.page-timeline .timeline > li:after,
.page-timeline .timeline > li:before {
  content: " ";
  display: table; }
.page-timeline .timeline > li:after {
  clear: both; }
.page-timeline .timeline > li:after,
.page-timeline .timeline > li:before {
  content: " ";
  display: table; }
.page-timeline .timeline > li:after {
  clear: both; }
.page-timeline .timeline > li > .timeline-panel {
  width: 46%;
  float: left;
  border-radius: 2px;
  position: relative; }
.page-timeline .timeline > li > .timeline-badge {
  background-color: #f2f4fa;
  border: 1px solid #DDDFE1;
  border-radius: 50%;
  color: #3e4954;
  height: 40px;
  left: 50%;
  line-height: 40px;
  margin-left: -13px;
  position: absolute;
  text-align: center;
  top: 30px;
  width: 40px;
  z-index: 1;
  transform: translate(-25%, -3rem); }
  @media (min-width: 576px) {
    .page-timeline .timeline > li > .timeline-badge {
      width: 50px;
      height: 50px;
      line-height: 50px; } }
.page-timeline .timeline > li.timeline-inverted > .timeline-panel {
  float: right; }
.page-timeline .timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto; }
.page-timeline .timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto; }
.page-timeline .timeline-title {
  margin-top: 0;
  color: inherit; }
.page-timeline .event_time {
  font-size: 14px;
  font-weight: 600; }
.page-timeline .event_vanue {
  font-size: 14px;
  margin: 5px 0;
  font-weight: 600; }
.page-timeline .timeline_img {
  height: 100px;
  width: 100px; }
.page-timeline .timeline-body > p,
.page-timeline .timeline-body > ul {
  margin-bottom: 0; }
@media (max-width: 767px) {
  .page-timeline ul.timeline:before {
    left: 40px; }
  .page-timeline ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -webkit-calc(100% - 90px); }
  .page-timeline ul.timeline > li > .timeline-badge {
    left: 28px;
    margin-left: 0;
    top: 16px; }
  .page-timeline ul.timeline > li > .timeline-panel {
    float: right; }
  .page-timeline ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto; }
  .page-timeline ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto; }
  .page-timeline .timeline_img {
    height: 30%;
    width: 30%; } }
.page-timeline .timeline-timestamp {
  text-align: center; }
  .page-timeline .timeline-timestamp .badge {
    padding: .8rem 2rem;
    border-radius: 50px;
    font-size: 0.8125rem; }

.review-table {
  padding: 25px;
  border-radius: 0;
  height: auto;
  margin-bottom: 0; }
  .review-table .disease {
    border-left: 1px solid #eee;
    padding-left: 20px; }
    @media only screen and (max-width: 991px) {
      .review-table .disease {
        border-left: 0;
        padding-left: 0;
        margin-right: 10px !important;
        margin-left: 0; } }
  .review-table .star-review i {
    font-size: 20px;
    margin-left: 2px;
    margin-right: 2px; }
    @media only screen and (max-width: 991px) {
      .review-table .star-review i {
        font-size: 216x; } }
  .review-table .media-body p {
    color: #3e4954;
    font-size: 18px;
    line-height: 1.5; }
    @media only screen and (max-width: 991px) {
      .review-table .media-body p {
        font-size: 14px; } }
  @media only screen and (max-width: 991px) {
    .review-table img {
      float: left;
      width: 80px; } }
  @media only screen and (max-width: 991px) {
    .review-table {
      padding: 15px; } }

.review-tab.nav-pills {
  margin-bottom: 0; }
  .review-tab.nav-pills li {
    display: inline-block; }
    .review-tab.nav-pills li a.nav-link {
      color: #6b6b6b;
      background: #e9e9e9;
      box-shadow: none;
      border-radius: 0;
      font-weight: 600;
      font-size: 16px;
      padding: 15px 40px;
      margin-right: 1px; }
      .review-tab.nav-pills li a.nav-link.active {
        color: #3F9AE0;
        background: #fff; }
      @media only screen and (max-width: 991px) {
        .review-tab.nav-pills li a.nav-link {
          font-size: 14px;
          padding: 10px 15px; } }
    .review-tab.nav-pills li:first-child a.nav-link {
      border-radius: 0.75rem 0 0 0; }
    .review-tab.nav-pills li:last-child a.nav-link {
      border-radius: 0 0.75rem 0 0; }

.order-request tbody tr {
  display: block;
  padding: 0 20px;
  position: relative;
  box-shadow: 0 1px 0 0 #f8f8f8;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  .order-request tbody tr.active, .order-request tbody tr:hover {
    box-shadow: 0 0 20px -3px rgba(0, 0, 0, 0.15);
    z-index: 1; }
  .order-request tbody tr th,
  .order-request tbody tr td {
    border: 0;
    padding: 18px 8px; }
    .order-request tbody tr th:nth-child(1),
    .order-request tbody tr td:nth-child(1) {
      width: 35%; }
      @media only screen and (max-width: 1400px) {
        .order-request tbody tr th:nth-child(1),
        .order-request tbody tr td:nth-child(1) {
          width: 40%; } }
    .order-request tbody tr th:nth-child(2),
    .order-request tbody tr td:nth-child(2) {
      width: 17%; }
      @media only screen and (max-width: 1400px) {
        .order-request tbody tr th:nth-child(2),
        .order-request tbody tr td:nth-child(2) {
          width: 20%; } }
    .order-request tbody tr th:nth-child(4),
    .order-request tbody tr td:nth-child(4) {
      width: 18%; }
  @media only screen and (max-width: 767px) {
    .order-request tbody tr .media {
      width: 250px; } }
  .order-request tbody tr .media img {
    min-width: 75px; }
@media only screen and (max-width: 575px) {
  .order-request {
    width: 500px; } }

.wspace-no {
  white-space: nowrap; }

.star-review i {
  font-size: 16px; }
  .star-review i.text-gray {
    color: #d9d9d9; }
.star-review span {
  color: #000; }

.chart-deta .col {
  display: flex;
  padding: 0;
  margin-right: 15px;
  margin-bottom: 30px; }
  .chart-deta .col:last-child {
    margin-bottom: 0; }
  @media only screen and (max-width: 575px) {
    .chart-deta .col {
      margin-bottom: 5px; } }
  .chart-deta .col p {
    margin-bottom: 5px;
    line-height: 1.5;
    font-weight: 500; }
    @media only screen and (max-width: 575px) {
      .chart-deta .col p {
        font-size: 14px;
        display: inline-block; } }
  .chart-deta .col h3 {
    font-size: 34px;
    margin-bottom: 0;
    color: #000; }
    @media only screen and (max-width: 575px) {
      .chart-deta .col h3 {
        font-size: 14px;
        display: inline-block; } }
  .chart-deta .col [class*="bg-"] {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    margin-right: 25px; }
    @media only screen and (max-width: 575px) {
      .chart-deta .col [class*="bg-"] {
        height: 16px;
        width: 16px;
        margin-right: 10px;
        margin-top: 5px; } }

.trending-menus .timeline .timeline-panel {
  padding: 25px 30px 30px 30px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-bottom: 1px solid #EEEEEE;
  margin-bottom: 0; }
  @media only screen and (max-width: 575px) {
    .trending-menus .timeline .timeline-panel {
      display: block;
      padding: 15px 15px 30px 15px; } }
  .trending-menus .timeline .timeline-panel.active, .trending-menus .timeline .timeline-panel:hover {
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.05); }
  .trending-menus .timeline .timeline-panel .media {
    width: 60px;
    height: 60px;
    position: relative;
    overflow: unset;
    border-radius: 0.75rem; }
    @media only screen and (max-width: 575px) {
      .trending-menus .timeline .timeline-panel .media {
        width: 80px;
        height: 80px;
        float: left;
        margin-right: 15px !important; } }
    .trending-menus .timeline .timeline-panel .media img {
      border-radius: 0.75rem;
      width: 100%; }
    .trending-menus .timeline .timeline-panel .media .number {
      width: 42px;
      height: 42px;
      border-radius: 40px;
      position: absolute;
      bottom: -25px;
      left: 50%;
      border: 3px solid #fff;
      line-height: 38px;
      transform: translateX(-50%);
      text-align: center;
      background: #ffb800;
      color: #000;
      font-size: 16px;
      font-weight: 600; }
@media only screen and (max-width: 575px) {
  .trending-menus .timeline .media-body {
    margin-bottom: 10px; } }
.trending-menus .timeline .social-media a {
  padding: 0;
  font-size: 18px !important;
  width: 40px;
  height: 40px;
  line-height: 40px; }
.trending-menus .timeline li:last-child .timeline-panel {
  padding-bottom: 20px; }

.form-head .search-area {
  max-width: 250px;
  border-radius: 3rem;
  border: 1px solid #EEEEEE; }
  @media only screen and (max-width: 1400px) {
    .form-head .search-area {
      max-width: 180px; } }
  @media only screen and (max-width: 575px) {
    .form-head .search-area {
      max-width: 250px; } }
  .form-head .search-area .form-control {
    border: 0;
    background: transparent;
    padding: 10px 30px;
    font-size: 16px; }
    @media only screen and (max-width: 1400px) {
      .form-head .search-area .form-control {
        height: 41px;
        padding: 10px 15px;
        font-size: 13px; } }
  .form-head .search-area .input-group-append .input-group-text {
    background: transparent;
    padding: 0 30px; }
    @media only screen and (max-width: 1400px) {
      .form-head .search-area .input-group-append .input-group-text {
        padding: 0 15px; } }
    .form-head .search-area .input-group-append .input-group-text i {
      font-size: 24px;
      color: #7e7e7e; }
      @media only screen and (max-width: 1400px) {
        .form-head .search-area .input-group-append .input-group-text i {
          font-size: 18px; } }
.form-head .dropdown .btn i {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block; }
.form-head .btn i {
  line-height: 1;
  transform: scale(1.3);
  display: inline-block;
  margin-right: 5px; }
.form-head .btn-outline-primary {
  border-color: #eee;
  color: #000; }
  .form-head .btn-outline-primary:hover {
    border-color: #3F9AE0;
    color: #fff; }
    .form-head .btn-outline-primary:hover svg g path {
      fill: #fff; }
    .form-head .btn-outline-primary:hover:after {
      color: #fff; }
  .form-head .btn-outline-primary:after {
    color: #3F9AE0;
    font-size: 18px; }
@media only screen and (max-width: 1400px) {
  .form-head > * {
    margin-right: 5px !important;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: baseline; } }
@media only screen and (max-width: 991px) {
  .form-head > * {
    margin-left: 0 !important; } }
@media only screen and (max-width: 767px) {
  .form-head {
    display: block !important; } }

.donut-chart-sale {
  position: relative; }
  .donut-chart-sale small {
    font-size: 16px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    top: 0;
    justify-content: center;
    font-weight: 600;
    color: #525252; }

.rounded {
  border-radius: 0.75rem !important; }

.btn-link {
  font-weight: 500;
  font-size: 16px; }

.plus-box {
  background: #bb1e57;
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 30px 30px;
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 50px;
  border-radius: 1.75rem;
  margin-bottom: 40px;
  margin-top: 40px; }
  .plus-box p {
    margin-bottom: 0;
    line-height: 1.5; }
  @media only screen and (max-width: 767px) {
    .plus-box {
      margin: 0 20px;
      margin-bottom: 20px;
      margin-top: 20px; } }
  .plus-box:before, .plus-box:after {
    content: "";
    position: absolute; }
  .plus-box:before {
    left: 15px;
    top: 15px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-image: url("../images/dots.png");
    opacity: 1; }
  .plus-box:after {
    right: 15px;
    bottom: 15px;
    height: 40px;
    width: 40px;
    background-image: url("../images/dots2.png");
    background-repeat: no-repeat;
    opacity: 1; }

.deznav .copyright {
  font-size: 12px;
  padding: 0 50px;
  color: #fff;
  margin-bottom: 40px; }
  .deznav .copyright p {
    margin-bottom: 10px; }
  @media only screen and (max-width: 767px) {
    .deznav .copyright {
      padding: 0 20px; } }

.recentOrderTable table {
  margin-bottom: 0;
  overflow: hidden; }
  .recentOrderTable table thead th {
    font-size: 12px; }
  .recentOrderTable table tbody td {
    color: #3e4954;
    font-weight: 500;
    line-height: 40px; }
    [data-theme-version="dark"] .recentOrderTable table tbody td {
      color: #828690; }
    .recentOrderTable table tbody td img {
      border-radius: 50px; }
    .recentOrderTable table tbody td .custom-dropdown i {
      padding: 0 10px;
      cursor: pointer; }

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9; }

#user-activity .nav-tabs {
  border-bottom: 0px; }
  #user-activity .nav-tabs .nav-link {
    border-radius: 0;
    margin-left: -1px;
    padding: 5px 15px;
    border: 1px solid #f4f4f4; }
    #user-activity .nav-tabs .nav-link.active {
      background: #3F9AE0;
      color: #fff;
      border-color: #3F9AE0; }
  #user-activity .nav-tabs li:first-child a {
    border-radius: 0.75rem 0 0 0.75rem; }
  #user-activity .nav-tabs li:last-child a {
    border-radius: 0 0.75rem 0.75rem 0; }

#activeUser {
  height: 215px !important; }

#activeUser {
  height: 180px !important; }

span#counter {
  font-size: 30px;
  font-weight: 700;
  color: #3F9AE0; }

.social-graph-wrapper {
  text-align: center;
  padding: 20px;
  position: relative;
  color: #fff;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem; }
  .social-graph-wrapper.widget-facebook {
    background-color: #3b5998; }
  .social-graph-wrapper.widget-twitter {
    background-color: #1da1f2; }
  .social-graph-wrapper.widget-linkedin {
    background-color: #007bb6; }
  .social-graph-wrapper.widget-googleplus {
    background-color: #db4439; }
  .social-graph-wrapper .s-icon {
    font-size: 24px;
    position: relative;
    padding: 0 10px; }

.recent-stats i {
  font-size: 11px;
  position: relative;
  top: -1px; }

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  #lineChart_3Kk {
    height: 140px !important; } }
.card-tabs .nav-tabs {
  border: 1px solid #EEEEEE;
  background: transparent;
  padding: 5px;
  border-radius: 30px;
  flex-wrap: unset; }
  .card-tabs .nav-tabs .nav-link {
    border-radius: 30px;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 14px;
    border: 0; }
    .card-tabs .nav-tabs .nav-link.active {
      background: #3f4953;
      color: #fff; }
    @media only screen and (max-width: 1400px) {
      .card-tabs .nav-tabs .nav-link {
        padding: 5px 10px;
        font-size: 12px; } }
  .card-tabs .nav-tabs .nav-item {
    display: inline-block; }
  @media only screen and (max-width: 575px) {
    .card-tabs .nav-tabs {
      display: block; } }
  @media only screen and (max-width: 575px) {
    .card-tabs .nav-tabs {
      border-radius: 15px; } }

.iconbox {
  position: relative;
  padding: 15px 30px 15px 70px;
  border-radius: 0.75rem;
  display: inline-block;
  background: #838d97;
  text-align: left; }
  .iconbox i {
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 32px;
    color: #fff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
  .iconbox p {
    margin: 0;
    color: #fff;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500; }
  .iconbox small {
    margin-bottom: 5px;
    font-size: 16px;
    display: block;
    color: #fff; }

.widget-timeline-icon {
  padding: 50px; }
  @media only screen and (max-width: 1400px) {
    .widget-timeline-icon {
      padding: 30px; } }
  @media only screen and (max-width: 575px) {
    .widget-timeline-icon {
      overflow: scroll;
      padding: 15px; } }
  .widget-timeline-icon .timeline {
    display: flex; }
    @media only screen and (max-width: 575px) {
      .widget-timeline-icon .timeline {
        display: block;
        margin-left: 10px; } }
  .widget-timeline-icon li {
    position: relative;
    border-top: 3px solid #3F9AE0; }
    @media only screen and (max-width: 575px) {
      .widget-timeline-icon li {
        border-top: 0;
        border-left: 3px solid #3F9AE0; } }
    .widget-timeline-icon li a {
      padding: 25px 50px 0px 0px;
      display: block; }
      @media only screen and (max-width: 1400px) {
        .widget-timeline-icon li a {
          padding: 15px 25px 0px 0px; } }
      @media only screen and (max-width: 575px) {
        .widget-timeline-icon li a {
          padding: 0px 0px 30px 30px; } }
    .widget-timeline-icon li .icon {
      position: absolute;
      width: 20px;
      height: 20px;
      font-size: 24px;
      color: #fff;
      text-align: center;
      line-height: 56px;
      border-radius: 56px;
      left: -2px;
      top: -11px; }
      @media only screen and (max-width: 575px) {
        .widget-timeline-icon li .icon {
          left: -12px;
          top: -4px; } }
    .widget-timeline-icon li:last-child {
      border-top: 0; }
      @media only screen and (max-width: 575px) {
        .widget-timeline-icon li:last-child {
          border-left: 3px solid #eee; } }

@media only screen and (max-width: 767px) {
  .order-list .media {
    width: 290px; } }

.full-map-area {
  position: relative;
  border-radius: 12px;
  overflow: hidden; }
  .full-map-area a {
    position: absolute;
    bottom: 30px;
    right: 30px;
    background: #3f4953;
    padding: 12px;
    border-radius: 0.75rem; }
  .full-map-area .map-text {
    position: absolute;
    top: 30px;
    right: 30px; }

.favorites-items img {
  width: 110px; }

.image-placeholder {
  position: relative;
  max-width: 130px;
  margin: 10px auto; }
  .image-placeholder .avatar-edit {
    position: absolute;
    right: -10px;
    z-index: 1;
    top: 0px; }
    .image-placeholder .avatar-edit input {
      display: none; }
      .image-placeholder .avatar-edit input + label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #FFFFFF;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all .2s ease-in-out; }
        .image-placeholder .avatar-edit input + label:hover {
          background: #f1f1f1;
          border-color: #d6d6d6; }
        .image-placeholder .avatar-edit input + label:after {
          content: "\f040";
          font-family: 'FontAwesome';
          color: #757575;
          position: absolute;
          top: 6px;
          left: 0;
          right: 0;
          text-align: center;
          margin: auto; }
  .image-placeholder .avatar-preview {
    width: 130px;
    height: 95px;
    position: relative;
    border-radius: 30px;
    border: 3px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); }
    .image-placeholder .avatar-preview > div {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden; }
      .image-placeholder .avatar-preview > div img {
        height: 100%;
        width: 100%;
        object-fit: cover; }

.project-boxed {
  overflow: hidden; }

/*
===================================
    Product detail
===================================*/
.produtct-detail-tag {
  display: inline-block; }
  .produtct-detail-tag a {
    font-style: 13px;
    color: #3e4954; }

.product-detail-content .item-tag {
  background: #828690;
  border-radius: 6px;
  display: inline-block;
  font-size: 12px;
  margin-right: 4px;
  padding: 2px 12px;
  color: #fff; }

.filtaring-area h4 {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  text-transform: lowercase; }

.plus-minus-input .input-icon {
  font-size: 13px;
  color: #3e4954; }

.plus-minus-input {
  display: flex;
  width: 120px; }
  .plus-minus-input .custom-btn {
    border-radius: 0;
    height: 40px;
    padding: 12px 8px;
    background: #fff;
    border: 1px solid #d7dae3; }
  .plus-minus-input .form-control:hover, .plus-minus-input .form-control:focus, .plus-minus-input .form-control:active {
    border: 1px solid #d7dae3; }

.btn-reveal-trigger .avatar-xl {
  min-width: 30px; }

.share-view {
  display: inline-block; }
  .share-view ul li {
    display: inline-block; }
  .share-view .share-icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: 1px solid #d7dae3;
    text-align: center;
    line-height: 40px;
    font-style: 16px;
    color: #d7dae3;
    margin-right: 8px; }

/*--------tab----------*/
.veritical-line {
  padding: 20px 30px;
  border-top: 1px solid #d7dae3;
  border-right: 1px solid #d7dae3;
  border-bottom: 1px solid #d7dae3;
  position: relative; }
  .veritical-line:before {
    background: #d7dae3;
    bottom: 0;
    content: "";
    height: 100%;
    left: -1px;
    max-height: 40%;
    position: absolute;
    width: 1px; }

.tab-content-text p {
  color: #3e4954;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 25px; }

.tab-item-list li a {
  background: #fff;
  border-top: 1px solid #d7dae3;
  border-left: 1px solid #d7dae3;
  border-right: 1px solid #d7dae3;
  color: #3e4954;
  display: block;
  font-size: 16px;
  padding: 16px;
  text-transform: uppercase; }
  .tab-item-list li a:hover, .tab-item-list li a:focus {
    background: #fff;
    color: #3e4954;
    border-right: 0px; }
.tab-item-list li:last-child {
  border-bottom: 1px solid #d7dae3; }

.tab-list li {
  margin-bottom: 7px;
  font-size: 13px; }
  .tab-list li i {
    font-size: 13px;
    margin-right: 14px; }

.slide-item-list {
  text-align: center;
  margin: 0 -5px; }
  .slide-item-list li {
    display: inline-block;
    flex: 0 0 25%;
    width: 25%;
    padding: 0 5px; }
    .slide-item-list li a {
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      background: transparent;
      padding-bottom: 0px; }
      .slide-item-list li a:hover, .slide-item-list li a:focus {
        background: transparent; }
      .slide-item-list li a img {
        width: 100%; }

.product-detail-text {
  padding-top: 28px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 70px; }

.star-rating .product-review {
  font-style: 13px;
  color: #3e4954;
  font-weight: 400;
  text-decoration: underline !important; }

.product-detail .tab-content img {
  display: inline-block;
  width: 100%; }

.popular-tag ul {
  margin: 0px;
  padding: 0px; }
  .popular-tag ul li {
    display: inline-block;
    padding: 8px 15px;
    background: #f8f8f8;
    font-size: 13px;
    color: #fff;
    margin-right: 10px;
    margin-bottom: 10px; }

.size-filter ul li {
  display: inline-block; }

.intro {
  border: 1px solid red;
  color: #1d1d1d; }

#listResults .slider {
  margin: 25px 0; }
#listResults .slider-box {
  width: 90%;
  margin: 25px auto; }
#listResults input {
  width: 10%; }
#listResults label {
  border: none;
  display: inline-block;
  margin-right: -4px;
  vertical-align: top;
  width: 30%; }

.plus-minus-input .input-icon {
  font-size: 13px;
  color: #aaaaaa; }
.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 40px;
  padding: 8px 12px;
  background: #ffffff;
  border: 1px solid #c8c8c8; }
  .plus-minus-input .custom-btn:hover, .plus-minus-input .custom-btn:focus, .plus-minus-input .custom-btn.active {
    box-shadow: none;
    outline: none; }
.plus-minus-input .form-control {
  height: 40px;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  border-left: 0px solid #c8c8c8;
  border-right: 1px solid #c8c8c8; }
  .plus-minus-input .form-control:hover, .plus-minus-input .form-control:focus, .plus-minus-input .form-control:active {
    border-top: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    border-left: 0px solid #c8c8c8;
    border-right: 0px solid #c8c8c8; }

.new-arrival-product .new-arrivals-img-contnent {
  overflow: hidden; }
  .new-arrival-product .new-arrivals-img-contnent img {
    width: 100%;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
.new-arrival-product:hover .new-arrivals-img-contnent img {
  transform: scale(1.5) translateY(12%);
  -moz-transform: scale(1.5) translateY(12%);
  -webkit-transform: scale(1.5) translateY(12%);
  -ms-transform: scale(1.5) translateY(12%);
  -o-transform: scale(1.5) translateY(12%); }

/*
===================================
    list view
===================================*/
.new-arrival-content .item {
  font-size: 12px;
  color: #3e4954; }
.new-arrival-content h4 {
  font-size: 16px;
  color: #828690;
  font-weight: 600;
  margin-bottom: 10px; }
.new-arrival-content .price {
  font-weight: 600;
  color: #3F9AE0;
  font-size: 24px;
  margin-bottom: 0;
  float: right; }
.new-arrival-content p {
  font-size: 14px;
  color: #828690;
  margin-bottom: 6px;
  line-height: 24px; }
.new-arrival-content .text-content {
  margin-top: 18px; }
.new-arrival-content.text-center .price {
  float: unset; }
@media only screen and (max-width: 575px) {
  .new-arrival-content .price {
    float: left;
    margin-top: 10px; } }

.success-icon {
  color: #2bc155;
  font-size: 16px; }

.comment-review {
  margin-bottom: 15px;
  display: table;
  width: 100%; }
  .comment-review .client-review {
    color: #828690;
    padding-right: 20px;
    text-decoration: underline !important;
    font-size: 14px; }
  .comment-review .span {
    color: #828690;
    font-size: 14px; }

.star-rating li {
  display: inline-block; }
  .star-rating li i {
    color: gold; }

.rtl {
  text-align: right;
  direction: rtl; }
  .rtl .nav {
    padding-right: 0; }
  .rtl .navbar-nav .nav-item {
    float: right; }
  .rtl .navbar-nav .nav-item + .nav-item {
    margin-right: 1rem;
    margin-left: inherit; }
  .rtl th {
    text-align: right; }
  .rtl .alert-dismissible {
    padding-right: 1.25rem;
    padding-left: 4rem; }
  .rtl .dropdown-menu {
    right: 0;
    text-align: right; }
  .rtl .checkbox label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .btn-group > .btn:last-child:not(:first-child),
  .rtl .btn-group > .dropdown-toggle:not(:first-child) {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .custom-control-label::after,
  .rtl .custom-control-label::before {
    right: 0;
    left: inherit; }
  .rtl .custom-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    background: #fff url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") no-repeat left 0.75rem center;
    background-size: 8px 10px; }
  .rtl .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .rtl .input-group > .input-group-append:last-child > .input-group-text:not(:last-child),
  .rtl .input-group > .input-group-append:not(:last-child) > .btn,
  .rtl .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .rtl .input-group > .input-group-prepend > .btn,
  .rtl .input-group > .input-group-prepend > .input-group-text {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .input-group > .input-group-append > .btn,
  .rtl .input-group > .input-group-append > .input-group-text,
  .rtl .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .rtl .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
  .rtl .input-group > .input-group-prepend:not(:first-child) > .btn,
  .rtl .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .input-group > .custom-select:not(:first-child),
  .rtl .input-group > .form-control:not(:first-child) {
    border-radius: 0.75rem 0 0 0.75rem; }
  .rtl .input-group > .custom-select:not(:last-child),
  .rtl .input-group > .form-control:not(:last-child) {
    border-radius: 0 0.75rem 0.75rem 0; }
  .rtl .input-group > .custom-select:not(:last-child):not(:first-child),
  .rtl .input-group > .form-control:not(:last-child):not(:first-child) {
    border-radius: 0; }
  .rtl .custom-control {
    padding-right: 1.5rem;
    padding-left: inherit;
    margin-right: inherit;
    margin-left: 1rem; }
  .rtl .custom-control-indicator {
    right: 0;
    left: inherit; }
  .rtl .custom-file-label::after {
    right: initial;
    left: -1px;
    border-radius: .25rem 0 0 .25rem; }
  .rtl .radio input,
  .rtl .radio-inline,
  .rtl .checkbox input,
  .rtl .checkbox-inline input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .list-group {
    padding-right: 0;
    padding-left: 40px; }
  .rtl .close {
    float: left; }
  .rtl .modal-header .close {
    margin: -15px auto -15px -15px; }
  .rtl .modal-footer > :not(:first-child) {
    margin-right: .25rem; }
  .rtl .alert-dismissible .close {
    right: inherit;
    left: 0; }
  .rtl .dropdown-toggle::after {
    margin-right: .255em;
    margin-left: 0; }
  .rtl .form-check-input {
    margin-right: -1.25rem;
    margin-left: inherit; }
  .rtl .form-check-label {
    padding-right: 1.25rem;
    padding-left: inherit; }
  .rtl .offset-1 {
    margin-right: 8.3333333333%;
    margin-left: 0; }
  .rtl .offset-2 {
    margin-right: 16.6666666667%;
    margin-left: 0; }
  .rtl .offset-3 {
    margin-right: 25%;
    margin-left: 0; }
  .rtl .offset-4 {
    margin-right: 33.3333333333%;
    margin-left: 0; }
  .rtl .offset-5 {
    margin-right: 41.6666666667%;
    margin-left: 0; }
  .rtl .offset-6 {
    margin-right: 50%;
    margin-left: 0; }
  .rtl .offset-7 {
    margin-right: 58.3333333333%;
    margin-left: 0; }
  .rtl .offset-8 {
    margin-right: 66.6666666667%;
    margin-left: 0; }
  .rtl .offset-9 {
    margin-right: 75%;
    margin-left: 0; }
  .rtl .offset-10 {
    margin-right: 83.3333333333%;
    margin-left: 0; }
  .rtl .offset-11 {
    margin-right: 91.6666666667%;
    margin-left: 0; }
  @media (min-width: 576px) {
    .rtl .offset-sm-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-sm-1 {
      margin-right: 8.3333333333%;
      margin-left: 0; }
    .rtl .offset-sm-2 {
      margin-right: 16.6666666667%;
      margin-left: 0; }
    .rtl .offset-sm-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-sm-4 {
      margin-right: 33.3333333333%;
      margin-left: 0; }
    .rtl .offset-sm-5 {
      margin-right: 41.6666666667%;
      margin-left: 0; }
    .rtl .offset-sm-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-sm-7 {
      margin-right: 58.3333333333%;
      margin-left: 0; }
    .rtl .offset-sm-8 {
      margin-right: 66.6666666667%;
      margin-left: 0; }
    .rtl .offset-sm-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-sm-10 {
      margin-right: 83.3333333333%;
      margin-left: 0; }
    .rtl .offset-sm-11 {
      margin-right: 91.6666666667%;
      margin-left: 0; } }
  @media (min-width: 768px) {
    .rtl .offset-md-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-md-1 {
      margin-right: 8.3333333333%;
      margin-left: 0; }
    .rtl .offset-md-2 {
      margin-right: 16.6666666667%;
      margin-left: 0; }
    .rtl .offset-md-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-md-4 {
      margin-right: 33.3333333333%;
      margin-left: 0; }
    .rtl .offset-md-5 {
      margin-right: 41.6666666667%;
      margin-left: 0; }
    .rtl .offset-md-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-md-7 {
      margin-right: 58.3333333333%;
      margin-left: 0; }
    .rtl .offset-md-8 {
      margin-right: 66.6666666667%;
      margin-left: 0; }
    .rtl .offset-md-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-md-10 {
      margin-right: 83.3333333333%;
      margin-left: 0; }
    .rtl .offset-md-11 {
      margin-right: 91.6666666667%;
      margin-left: 0; } }
  @media (min-width: 992px) {
    .rtl .offset-lg-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-lg-1 {
      margin-right: 8.3333333333%;
      margin-left: 0; }
    .rtl .offset-lg-2 {
      margin-right: 16.6666666667%;
      margin-left: 0; }
    .rtl .offset-lg-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-lg-4 {
      margin-right: 33.3333333333%;
      margin-left: 0; }
    .rtl .offset-lg-5 {
      margin-right: 41.6666666667%;
      margin-left: 0; }
    .rtl .offset-lg-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-lg-7 {
      margin-right: 58.3333333333%;
      margin-left: 0; }
    .rtl .offset-lg-8 {
      margin-right: 66.6666666667%;
      margin-left: 0; }
    .rtl .offset-lg-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-lg-10 {
      margin-right: 83.3333333333%;
      margin-left: 0; }
    .rtl .offset-lg-11 {
      margin-right: 91.6666666667%;
      margin-left: 0; } }
  @media (min-width: 1200px) {
    .rtl .offset-xl-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-xl-1 {
      margin-right: 8.3333333333%;
      margin-left: 0; }
    .rtl .offset-xl-2 {
      margin-right: 16.6666666667%;
      margin-left: 0; }
    .rtl .offset-xl-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-xl-4 {
      margin-right: 33.3333333333%;
      margin-left: 0; }
    .rtl .offset-xl-5 {
      margin-right: 41.6666666667%;
      margin-left: 0; }
    .rtl .offset-xl-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-xl-7 {
      margin-right: 58.3333333333%;
      margin-left: 0; }
    .rtl .offset-xl-8 {
      margin-right: 66.6666666667%;
      margin-left: 0; }
    .rtl .offset-xl-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-xl-10 {
      margin-right: 83.3333333333%;
      margin-left: 0; }
    .rtl .offset-xl-11 {
      margin-right: 91.6666666667%;
      margin-left: 0; } }
  @media (min-width: 1440) {
    .rtl .offset-xxl-0 {
      margin-right: 0;
      margin-left: 0; }
    .rtl .offset-xxl-1 {
      margin-right: 8.3333333333%;
      margin-left: 0; }
    .rtl .offset-xxl-2 {
      margin-right: 16.6666666667%;
      margin-left: 0; }
    .rtl .offset-xxl-3 {
      margin-right: 25%;
      margin-left: 0; }
    .rtl .offset-xxl-4 {
      margin-right: 33.3333333333%;
      margin-left: 0; }
    .rtl .offset-xxl-5 {
      margin-right: 41.6666666667%;
      margin-left: 0; }
    .rtl .offset-xxl-6 {
      margin-right: 50%;
      margin-left: 0; }
    .rtl .offset-xxl-7 {
      margin-right: 58.3333333333%;
      margin-left: 0; }
    .rtl .offset-xxl-8 {
      margin-right: 66.6666666667%;
      margin-left: 0; }
    .rtl .offset-xxl-9 {
      margin-right: 75%;
      margin-left: 0; }
    .rtl .offset-xxl-10 {
      margin-right: 83.3333333333%;
      margin-left: 0; }
    .rtl .offset-xxl-11 {
      margin-right: 91.6666666667%;
      margin-left: 0; } }
  .rtl .mr-0,
  .rtl .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .rtl .ml-0,
  .rtl .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .rtl .mr-1,
  .rtl .mx-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important; }
  .rtl .ml-1,
  .rtl .mx-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important; }
  .rtl .mr-2,
  .rtl .mx-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important; }
  .rtl .ml-2,
  .rtl .mx-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important; }
  .rtl .mr-3,
  .rtl .mx-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important; }
  .rtl .ml-3,
  .rtl .mx-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important; }
  .rtl .mr-4,
  .rtl .mx-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important; }
  .rtl .ml-4,
  .rtl .mx-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important; }
  .rtl .mr-5,
  .rtl .mx-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important; }
  .rtl .ml-5,
  .rtl .mx-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important; }
  .rtl .pr-0,
  .rtl .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .rtl .pl-0,
  .rtl .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .rtl .pr-1,
  .rtl .px-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important; }
  .rtl .pl-1,
  .rtl .px-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important; }
  .rtl .pr-2,
  .rtl .px-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important; }
  .rtl .pl-2,
  .rtl .px-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important; }
  .rtl .pr-3,
  .rtl .px-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important; }
  .rtl .pl-3,
  .rtl .px-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important; }
  .rtl .pr-4,
  .rtl .px-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important; }
  .rtl .pl-4,
  .rtl .px-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important; }
  .rtl .pr-5,
  .rtl .px-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important; }
  .rtl .pl-5,
  .rtl .px-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important; }
  .rtl .mr-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .ml-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  @media (min-width: 576px) {
    .rtl .mr-sm-0,
    .rtl .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-sm-0,
    .rtl .mx-sm-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-sm-1,
    .rtl .mx-sm-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-sm-1,
    .rtl .mx-sm-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-sm-2,
    .rtl .mx-sm-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-sm-2,
    .rtl .mx-sm-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-sm-3,
    .rtl .mx-sm-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-sm-3,
    .rtl .mx-sm-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-sm-4,
    .rtl .mx-sm-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-sm-4,
    .rtl .mx-sm-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-sm-5,
    .rtl .mx-sm-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-sm-5,
    .rtl .mx-sm-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-sm-0,
    .rtl .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-sm-0,
    .rtl .px-sm-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-sm-1,
    .rtl .px-sm-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-sm-1,
    .rtl .px-sm-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-sm-2,
    .rtl .px-sm-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-sm-2,
    .rtl .px-sm-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-sm-3,
    .rtl .px-sm-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-sm-3,
    .rtl .px-sm-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-sm-4,
    .rtl .px-sm-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-sm-4,
    .rtl .px-sm-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-sm-5,
    .rtl .px-sm-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-sm-5,
    .rtl .px-sm-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-sm-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-sm-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 768px) {
    .rtl .mr-md-0,
    .rtl .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-md-0,
    .rtl .mx-md-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-md-1,
    .rtl .mx-md-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-md-1,
    .rtl .mx-md-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-md-2,
    .rtl .mx-md-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-md-2,
    .rtl .mx-md-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-md-3,
    .rtl .mx-md-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-md-3,
    .rtl .mx-md-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-md-4,
    .rtl .mx-md-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-md-4,
    .rtl .mx-md-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-md-5,
    .rtl .mx-md-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-md-5,
    .rtl .mx-md-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-md-0,
    .rtl .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-md-0,
    .rtl .px-md-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-md-1,
    .rtl .px-md-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-md-1,
    .rtl .px-md-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-md-2,
    .rtl .px-md-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-md-2,
    .rtl .px-md-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-md-3,
    .rtl .px-md-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-md-3,
    .rtl .px-md-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-md-4,
    .rtl .px-md-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-md-4,
    .rtl .px-md-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-md-5,
    .rtl .px-md-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-md-5,
    .rtl .px-md-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-md-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-md-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 992px) {
    .rtl .mr-lg-0,
    .rtl .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-lg-0,
    .rtl .mx-lg-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-lg-1,
    .rtl .mx-lg-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-lg-1,
    .rtl .mx-lg-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-lg-2,
    .rtl .mx-lg-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-lg-2,
    .rtl .mx-lg-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-lg-3,
    .rtl .mx-lg-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-lg-3,
    .rtl .mx-lg-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-lg-4,
    .rtl .mx-lg-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-lg-4,
    .rtl .mx-lg-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-lg-5,
    .rtl .mx-lg-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-lg-5,
    .rtl .mx-lg-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-lg-0,
    .rtl .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-lg-0,
    .rtl .px-lg-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-lg-1,
    .rtl .px-lg-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-lg-1,
    .rtl .px-lg-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-lg-2,
    .rtl .px-lg-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-lg-2,
    .rtl .px-lg-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-lg-3,
    .rtl .px-lg-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-lg-3,
    .rtl .px-lg-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-lg-4,
    .rtl .px-lg-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-lg-4,
    .rtl .px-lg-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-lg-5,
    .rtl .px-lg-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-lg-5,
    .rtl .px-lg-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-lg-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-lg-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 1200px) {
    .rtl .mr-xl-0,
    .rtl .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-xl-0,
    .rtl .mx-xl-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-xl-1,
    .rtl .mx-xl-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-xl-1,
    .rtl .mx-xl-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-xl-2,
    .rtl .mx-xl-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-xl-2,
    .rtl .mx-xl-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-xl-3,
    .rtl .mx-xl-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-xl-3,
    .rtl .mx-xl-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-xl-4,
    .rtl .mx-xl-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-xl-4,
    .rtl .mx-xl-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-xl-5,
    .rtl .mx-xl-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-xl-5,
    .rtl .mx-xl-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-xl-0,
    .rtl .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-xl-0,
    .rtl .px-xl-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-xl-1,
    .rtl .px-xl-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-xl-1,
    .rtl .px-xl-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-xl-2,
    .rtl .px-xl-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-xl-2,
    .rtl .px-xl-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-xl-3,
    .rtl .px-xl-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-xl-3,
    .rtl .px-xl-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-xl-4,
    .rtl .px-xl-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-xl-4,
    .rtl .px-xl-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-xl-5,
    .rtl .px-xl-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-xl-5,
    .rtl .px-xl-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-xl-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-xl-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  @media (min-width: 1440) {
    .rtl .mr-xxl-0,
    .rtl .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .rtl .ml-xxl-0,
    .rtl .mx-xxl-0 {
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .rtl .mr-xxl-1,
    .rtl .mx-xxl-1 {
      margin-right: 0 !important;
      margin-left: 0.25rem !important; }
    .rtl .ml-xxl-1,
    .rtl .mx-xxl-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important; }
    .rtl .mr-xxl-2,
    .rtl .mx-xxl-2 {
      margin-right: 0 !important;
      margin-left: 0.5rem !important; }
    .rtl .ml-xxl-2,
    .rtl .mx-xxl-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important; }
    .rtl .mr-xxl-3,
    .rtl .mx-xxl-3 {
      margin-right: 0 !important;
      margin-left: 1rem !important; }
    .rtl .ml-xxl-3,
    .rtl .mx-xxl-3 {
      margin-left: 0 !important;
      margin-right: 1rem !important; }
    .rtl .mr-xxl-4,
    .rtl .mx-xxl-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important; }
    .rtl .ml-xxl-4,
    .rtl .mx-xxl-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important; }
    .rtl .mr-xxl-5,
    .rtl .mx-xxl-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important; }
    .rtl .ml-xxl-5,
    .rtl .mx-xxl-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important; }
    .rtl .pr-xxl-0,
    .rtl .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .rtl .pl-xxl-0,
    .rtl .px-xxl-0 {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .rtl .pr-xxl-1,
    .rtl .px-xxl-1 {
      padding-right: 0 !important;
      padding-left: 0.25rem !important; }
    .rtl .pl-xxl-1,
    .rtl .px-xxl-1 {
      padding-left: 0 !important;
      padding-right: 0.25rem !important; }
    .rtl .pr-xxl-2,
    .rtl .px-xxl-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important; }
    .rtl .pl-xxl-2,
    .rtl .px-xxl-2 {
      padding-left: 0 !important;
      padding-right: 0.5rem !important; }
    .rtl .pr-xxl-3,
    .rtl .px-xxl-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important; }
    .rtl .pl-xxl-3,
    .rtl .px-xxl-3 {
      padding-left: 0 !important;
      padding-right: 1rem !important; }
    .rtl .pr-xxl-4,
    .rtl .px-xxl-4 {
      padding-right: 0 !important;
      padding-left: 1.5rem !important; }
    .rtl .pl-xxl-4,
    .rtl .px-xxl-4 {
      padding-left: 0 !important;
      padding-right: 1.5rem !important; }
    .rtl .pr-xxl-5,
    .rtl .px-xxl-5 {
      padding-right: 0 !important;
      padding-left: 3rem !important; }
    .rtl .pl-xxl-5,
    .rtl .px-xxl-5 {
      padding-left: 0 !important;
      padding-right: 3rem !important; }
    .rtl .mr-xxl-auto {
      margin-right: 0 !important;
      margin-left: auto !important; }
    .rtl .ml-xxl-auto {
      margin-right: auto !important;
      margin-left: 0 !important; }
    .rtl .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important; } }
  .rtl .text-right {
    text-align: left !important; }
  .rtl .text-left {
    text-align: right !important; }
  @media (min-width: 576px) {
    .rtl .text-sm-right {
      text-align: left !important; }
    .rtl .text-sm-left {
      text-align: right !important; } }
  @media (min-width: 768px) {
    .rtl .text-md-right {
      text-align: left !important; }
    .rtl .text-md-left {
      text-align: right !important; } }
  @media (min-width: 992px) {
    .rtl .text-lg-right {
      text-align: left !important; }
    .rtl .text-lg-left {
      text-align: right !important; } }
  @media (min-width: 1200px) {
    .rtl .text-xl-right {
      text-align: left !important; }
    .rtl .text-xl-left {
      text-align: right !important; } }
  @media (min-width: 1440) {
    .rtl .text-xxl-right {
      text-align: left !important; }
    .rtl .text-xxl-left {
      text-align: right !important; } }

.rtl .mx-0 {
  margin-right: auto;
  margin-left: 0 !important; }
.rtl .mx-0 {
  margin-left: auto;
  margin-right: 0 !important; }
.rtl .mx-1 {
  margin-right: auto;
  margin-left: 0.25rem !important; }
.rtl .mx-1 {
  margin-left: auto;
  margin-right: 0.25rem !important; }
.rtl .mx-2 {
  margin-right: auto;
  margin-left: 0.5rem !important; }
.rtl .mx-2 {
  margin-left: auto;
  margin-right: 0.5rem !important; }
.rtl .mx-3 {
  margin-right: auto;
  margin-left: 1rem !important; }
.rtl .mx-3 {
  margin-left: auto;
  margin-right: 1rem !important; }
.rtl .mx-4 {
  margin-right: auto;
  margin-left: 1.5rem !important; }
.rtl .mx-4 {
  margin-left: auto;
  margin-right: 1.5rem !important; }
.rtl .mx-5 {
  margin-right: auto;
  margin-left: 3rem !important; }
.rtl .mx-5 {
  margin-left: auto;
  margin-right: 3rem !important; }
.rtl .px-0 {
  padding-right: auto;
  padding-left: 0 !important; }
.rtl .px-0 {
  padding-left: auto;
  padding-right: 0 !important; }
.rtl .px-1 {
  padding-right: auto;
  padding-left: 0.25rem !important; }
.rtl .px-1 {
  padding-left: auto;
  padding-right: 0.25rem !important; }
.rtl .px-2 {
  padding-right: auto;
  padding-left: 0.5rem !important; }
.rtl .px-2 {
  padding-left: auto;
  padding-right: 0.5rem !important; }
.rtl .px-3 {
  padding-right: auto;
  padding-left: 1rem !important; }
.rtl .px-3 {
  padding-left: auto;
  padding-right: 1rem !important; }
.rtl .px-4 {
  padding-right: auto;
  padding-left: 1.5rem !important; }
.rtl .px-4 {
  padding-left: auto;
  padding-right: 1.5rem !important; }
.rtl .px-5 {
  padding-right: auto;
  padding-left: 3rem !important; }
.rtl .px-5 {
  padding-left: auto;
  padding-right: 3rem !important; }
.rtl .mr-auto {
  margin-right: 0 !important;
  margin-left: auto !important; }
.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }
.rtl .ml-auto {
  margin-right: auto !important;
  margin-left: 0 !important; }
.rtl .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }
@media (min-width: 576px) {
  .rtl .mx-sm-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-sm-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-sm-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-sm-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-sm-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-sm-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-sm-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-sm-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-sm-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-sm-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-sm-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-sm-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-sm-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-sm-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-sm-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-sm-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-sm-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-sm-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-sm-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-sm-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-sm-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-sm-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-sm-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-sm-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-sm-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-sm-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }
@media (min-width: 768px) {
  .rtl .mx-md-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-md-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-md-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-md-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-md-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-md-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-md-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-md-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-md-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-md-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-md-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-md-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-md-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-md-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-md-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-md-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-md-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-md-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-md-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-md-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-md-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-md-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-md-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-md-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-md-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-md-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }
@media (min-width: 992px) {
  .rtl .mx-lg-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-lg-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-lg-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-lg-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-lg-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-lg-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-lg-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-lg-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-lg-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-lg-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-lg-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-lg-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-lg-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-lg-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-lg-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-lg-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-lg-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-lg-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-lg-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-lg-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-lg-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-lg-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-lg-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-lg-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-lg-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-lg-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }
@media (min-width: 1200px) {
  .rtl .mx-xl-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-xl-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-xl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-xl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-xl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-xl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-xl-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-xl-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-xl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-xl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-xl-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-xl-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-xl-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-xl-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-xl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-xl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-xl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-xl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-xl-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-xl-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-xl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-xl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-xl-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-xl-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-xl-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-xl-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }
@media (min-width: 1440) {
  .rtl .mx-xxl-0 {
    margin-right: auto;
    margin-left: 0 !important; }
  .rtl .mx-xxl-0 {
    margin-left: auto;
    margin-right: 0 !important; }
  .rtl .mx-xxl-1 {
    margin-right: auto;
    margin-left: 0.25rem !important; }
  .rtl .mx-xxl-1 {
    margin-left: auto;
    margin-right: 0.25rem !important; }
  .rtl .mx-xxl-2 {
    margin-right: auto;
    margin-left: 0.5rem !important; }
  .rtl .mx-xxl-2 {
    margin-left: auto;
    margin-right: 0.5rem !important; }
  .rtl .mx-xxl-3 {
    margin-right: auto;
    margin-left: 1rem !important; }
  .rtl .mx-xxl-3 {
    margin-left: auto;
    margin-right: 1rem !important; }
  .rtl .mx-xxl-4 {
    margin-right: auto;
    margin-left: 1.5rem !important; }
  .rtl .mx-xxl-4 {
    margin-left: auto;
    margin-right: 1.5rem !important; }
  .rtl .mx-xxl-5 {
    margin-right: auto;
    margin-left: 3rem !important; }
  .rtl .mx-xxl-5 {
    margin-left: auto;
    margin-right: 3rem !important; }
  .rtl .px-xxl-0 {
    padding-right: auto;
    padding-left: 0 !important; }
  .rtl .px-xxl-0 {
    padding-left: auto;
    padding-right: 0 !important; }
  .rtl .px-xxl-1 {
    padding-right: auto;
    padding-left: 0.25rem !important; }
  .rtl .px-xxl-1 {
    padding-left: auto;
    padding-right: 0.25rem !important; }
  .rtl .px-xxl-2 {
    padding-right: auto;
    padding-left: 0.5rem !important; }
  .rtl .px-xxl-2 {
    padding-left: auto;
    padding-right: 0.5rem !important; }
  .rtl .px-xxl-3 {
    padding-right: auto;
    padding-left: 1rem !important; }
  .rtl .px-xxl-3 {
    padding-left: auto;
    padding-right: 1rem !important; }
  .rtl .px-xxl-4 {
    padding-right: auto;
    padding-left: 1.5rem !important; }
  .rtl .px-xxl-4 {
    padding-left: auto;
    padding-right: 1.5rem !important; }
  .rtl .px-xxl-5 {
    padding-right: auto;
    padding-left: 3rem !important; }
  .rtl .px-xxl-5 {
    padding-left: auto;
    padding-right: 3rem !important; }
  .rtl .mr-xxl-auto {
    margin-right: 0 !important;
    margin-left: auto !important; }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .rtl .ml-xxl-auto {
    margin-right: auto !important;
    margin-left: 0 !important; }
  .rtl .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; } }
.rtl .float-left {
  float: right !important; }
.rtl .float-right {
  float: left !important; }
.rtl .float-none {
  float: none !important; }
@media (min-width: 576px) {
  .rtl .float-sm-left {
    float: right !important; }
  .rtl .float-sm-right {
    float: left !important; }
  .rtl .float-sm-none {
    float: none !important; } }
@media (min-width: 768px) {
  .rtl .float-md-left {
    float: right !important; }
  .rtl .float-md-right {
    float: left !important; }
  .rtl .float-md-none {
    float: none !important; } }
@media (min-width: 992px) {
  .rtl .float-lg-left {
    float: right !important; }
  .rtl .float-lg-right {
    float: left !important; }
  .rtl .float-lg-none {
    float: none !important; } }
@media (min-width: 1200px) {
  .rtl .float-xl-left {
    float: right !important; }
  .rtl .float-xl-right {
    float: left !important; }
  .rtl .float-xl-none {
    float: none !important; } }
@media (min-width: 1440) {
  .rtl .float-xxl-left {
    float: right !important; }
  .rtl .float-xxl-right {
    float: left !important; }
  .rtl .float-xxl-none {
    float: none !important; } }

[direction="rtl"][data-theme-version="dark"] .border, [direction="rtl"][data-theme-version="dark"] .border-left, [direction="rtl"][data-theme-version="dark"] .border-right {
  border-color: #333a54 !important; }
[direction="rtl"] .text-right {
  text-align: left !important; }
[direction="rtl"] .text-left {
  text-align: right !important; }
[direction="rtl"] .border-right {
  border-left: 1px solid #d7dae3 !important;
  border-right: 0 !important; }
[direction="rtl"] .border-left {
  border-right: 1px solid #d7dae3 !important;
  border-left: 0 !important; }
[direction="rtl"] .dropdown-menu {
  left: auto; }
[direction="rtl"] .dropdown-menu-right {
  left: 0;
  right: auto; }
  @media only screen and (max-width: 575px) {
    [direction="rtl"] .dropdown-menu-right {
      left: 15px; } }
[direction="rtl"] .notification_dropdown .dropdown-menu-right .media > span {
  margin-left: 10px;
  margin-right: 0; }

[direction="rtl"]:not([data-container="boxed"]) .nav-header {
  left: auto;
  right: 0; }
[direction="rtl"][data-container="wide-boxed"] .nav-header {
  left: auto;
  right: 0; }
[direction="rtl"] .nav-header {
  text-align: right;
  right: auto; }
  [direction="rtl"] .nav-header .brand-title {
    margin-left: 0;
    margin-right: 15px; }
  [direction="rtl"] .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 1.75rem; }
    [data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
    [data-sidebar-style="compact"][direction="rtl"] .nav-header .brand-logo[data-layout="horizontal"] {
      padding-right: 30px; }
    [data-sidebar-style="mini"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
    [data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
      padding-right: 0; }
      [data-layout="horizontal"][data-sidebar-style="modern"][direction="rtl"] .nav-header .brand-logo {
        padding-right: 30px; }
    @media (max-width: 767.98px) {
      [direction="rtl"] .nav-header .brand-logo {
        padding-right: 0; } }
[direction="rtl"] .nav-control {
  right: auto;
  left: -4.0625rem; }
  @media (max-width: 767.98px) {
    [direction="rtl"] .nav-control {
      left: -4.0625rem; } }
  @media (max-width: 575.98px) {
    [direction="rtl"] .nav-control {
      left: -4.0625rem; } }
[direction="rtl"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  right: 0; }

[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-right: 30px; }

[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
  width: 16rem; }

[direction="rtl"] .header {
  padding: 0 0.9375rem;
  padding-right: 17.1875rem; }
  @media (max-width: 767.98px) {
    [direction="rtl"] .header {
      padding-right: 3.75rem;
      padding-left: 0; } }
  [direction="rtl"] .header .header-content {
    padding-left: 0;
    padding-right: 5.3125rem; }
    @media only screen and (max-width: 575px) {
      [direction="rtl"] .header .header-content {
        padding-right: 4.5rem; } }
    [data-sidebar-style="compact"][direction="rtl"] .header .header-content {
      padding-right: 1.875rem; }
    [data-sidebar-style="modern"][direction="rtl"] .header .header-content {
      padding-right: 5.3125rem; }
    [data-sidebar-style="overlay"][direction="rtl"] .header .header-content {
      padding-right: 5.3125rem; }
  [direction="rtl"] .header .nav-control {
    right: 0.4375rem;
    left: auto; }
[direction="rtl"] .header-left .search_bar .dropdown-menu,
[direction="rtl"] .header-left .search_bar .dropdown-menu.show {
  right: 40px !important; }
  @media only screen and (max-width: 575px) {
    [direction="rtl"] .header-left .search_bar .dropdown-menu,
    [direction="rtl"] .header-left .search_bar .dropdown-menu.show {
      right: -100px !important; } }
[direction="rtl"] .header-left .search_bar .search_icon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem; }
  @media only screen and (max-width: 575px) {
    [direction="rtl"] .header-left .search_bar .search_icon {
      border-radius: 5rem; } }
[direction="rtl"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem; }
  [direction="rtl"][data-layout="horizontal"] .header .header-content {
    padding-right: 1.875rem;
    padding-left: 1.875rem; }
[direction="rtl"][data-layout="horizontal"][data-sidebar-style="mini"] .header {
  padding-right: 6rem; }
[direction="rtl"][data-sidebar-style="mini"] .header {
  padding: 0 0.9375rem;
  padding-right: 3.75rem; }
[direction="rtl"][data-sidebar-style="compact"] .header {
  padding: 0 0.9375rem;
  padding-right: 9.375rem; }
[direction="rtl"][data-sidebar-style="compact"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem; }
[direction="rtl"][data-sidebar-style="modern"] .header {
  padding: 0 0.9375rem;
  padding-right: 9.375rem; }
[direction="rtl"][data-sidebar-style="modern"][data-layout="horizontal"] .header {
  padding: 0;
  padding-right: 16rem; }

[direction="rtl"] {
  text-align: right; }
  [direction="rtl"] .deznav {
    text-align: right; }
    [direction="rtl"] .deznav .metismenu ul {
      padding: 0 25px 0 0; }
      [direction="rtl"] .deznav .metismenu ul:after {
        left: auto;
        right: 25px; }
      [direction="rtl"] .deznav .metismenu ul a:before {
        left: auto;
        right: -5px; }
    [direction="rtl"] .deznav .metismenu li > a svg {
      margin-left: 5px;
      margin-right: 0; }
      [data-sidebar-style="compact"][direction="rtl"] .deznav .metismenu li > a svg {
        left: auto;
        margin-left: auto;
        margin-right: auto; }
      [data-sidebar-style="icon-hover"][direction="rtl"] .deznav .metismenu li > a svg {
        margin-left: 0; }
    [direction="rtl"] .deznav .metismenu li.active > .has-arrow:after {
      transform: rotate(45deg) translateY(-50%); }
    [direction="rtl"] .deznav .metismenu .has-arrow:after {
      left: 1.5625rem;
      right: auto; }
      [data-layout="horizontal"][direction="rtl"] .deznav .metismenu .has-arrow:after {
        left: 1.125rem; }
      [data-sidebar-style="modern"][direction="rtl"] .deznav .metismenu .has-arrow:after {
        -webkit-transform: rotate(-45deg) translateY(-50%);
        transform: rotate(-45deg) translateY(-50%); }
  [direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul li:hover ul {
    right: 11.8125rem;
    left: 0; }

[direction="rtl"] .list-group {
  padding-left: 0; }
[direction="rtl"] .form-check-input {
  margin-left: -1.25rem;
  margin-right: inherit; }
[direction="rtl"] .form-check-inline .form-check-input {
  margin-right: 0;
  margin-left: 10px; }
[direction="rtl"] .radio input,
[direction="rtl"] .radio-inline,
[direction="rtl"] .checkbox input,
[direction="rtl"] .checkbox-inline input {
  margin-left: 0;
  margin-right: 0; }
[direction="rtl"] .content-body {
  margin-right: 17.1875rem;
  margin-left: auto; }
  [data-sidebar-style="modern"][direction="rtl"] .content-body {
    margin-right: 9.375rem; }
  [direction="rtl"] .content-body .page-titles {
    text-align: right; }
[direction="rtl"][data-layout="horizontal"] .content-body {
  margin-right: 0; }
[direction="rtl"][data-layout="horizontal"] .deznav .metismenu li li .has-arrow:after {
  -webkit-transform: rotate(-4deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%); }
[direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 3.75rem; }
[direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .content-body {
  margin-right: 9.375rem; }
[direction="rtl"][data-sidebar-style="overlay"] .content-body {
  margin-right: 0; }
[direction="rtl"] #external-events .external-event:before {
  margin-right: 0;
  margin-left: .9rem; }
[direction="rtl"] .post-input a i {
  margin-left: 15px;
  margin-right: 0; }

@media only screen and (max-width: 1350px) and (min-width: 1200px) {
  [direction="rtl"] .content-body {
    margin-right: 14.375rem; } }
[direction="rtl"] .sidebar-right {
  left: -15.625rem;
  right: auto;
  box-shadow: 2px 3px 10px 0px rgba(119, 119, 119, 0.1); }
  [direction="rtl"] .sidebar-right.show {
    left: 0;
    right: auto; }
  [direction="rtl"] .sidebar-right .sidebar-right-trigger {
    left: 100%;
    right: auto;
    border-radius: 0 5px 5px 0;
    box-shadow: 5px 3px 5px 0px rgba(119, 119, 119, 0.15); }
[direction="rtl"] .bootstrap-select .dropdown-toggle .filter-option {
  text-align: right; }

html[dir="rtl"] [direction="rtl"] .footer {
  padding-right: 17.1875rem;
  padding-left: 0; }
  @media (max-width: 767.98px) {
    html[dir="rtl"] [direction="rtl"] .footer {
      padding-right: 0; } }
html[dir="rtl"] [direction="rtl"][data-sidebar-style="overlay"] .footer {
  padding-right: 0; }
html[dir="rtl"] [direction="rtl"] .menu-toggle .footer {
  padding-right: 3.75rem; }
html[dir="rtl"] [direction="rtl"][data-container="boxed"] .footer {
  padding-right: 0; }
html[dir="rtl"] [direction="rtl"][data-sidebar-style="mini"]:not([data-layout="horizontal"]) .footer {
  padding-right: 3.75rem; }
html[dir="rtl"] [direction="rtl"][data-sidebar-style="compact"]:not([data-layout="horizontal"]) .footer {
  padding-right: 9.375rem; }

/*$primary  : #7356f1 !default;*/
[data-theme-version="dark"] {
  background: #181f39;
  color: #828690; }
  [data-theme-version="dark"] h1,
  [data-theme-version="dark"] h2,
  [data-theme-version="dark"] h3,
  [data-theme-version="dark"] h4,
  [data-theme-version="dark"] h5,
  [data-theme-version="dark"] h6 {
    color: #fff !important; }
  [data-theme-version="dark"] a.link {
    color: #ddd; }
  [data-theme-version="dark"] a.link:focus,
  [data-theme-version="dark"] a.link:hover {
    color: #2F4CDD; }
  [data-theme-version="dark"] a:hover {
    color: #fff; }
  [data-theme-version="dark"] .border-right {
    border-right: 1px solid #333a54 !important; }
  [data-theme-version="dark"] .border-left {
    border-left: 1px solid #333a54 !important; }
  [data-theme-version="dark"] .border-top {
    border-top: 1px solid #333a54 !important; }
  [data-theme-version="dark"] .border-bottom {
    border-bottom: 1px solid #333a54 !important; }
  [data-theme-version="dark"] .border {
    border: 1px solid #333a54 !important; }

[data-theme-version="dark"] .dropdown-menu {
  background-color: #181f39;
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1); }
  [data-theme-version="dark"] .dropdown-menu .dropdown-item.selected, [data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item.active, [data-theme-version="dark"] .dropdown-menu .dropdown-item:hover, [data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
    background-color: #1e2746;
    color: #7e7e7e; }
[data-theme-version="dark"] .form-control {
  background-color: #181f39;
  border-color: #333a54;
  color: #fff; }
[data-theme-version="dark"] .card {
  background-color: #1e2746;
  box-shadow: none; }
[data-theme-version="dark"] .btn-outline-dark:hover {
  background-color: #181f39;
  border-color: #181f39; }
[data-theme-version="dark"] .tdl-holder input[type=text],
[data-theme-version="dark"] .tdl-holder li {
  background-color: #1e2746;
  border-color: #333a54; }
[data-theme-version="dark"] .tdl-holder input[type=text]::placeholder {
  color: #828690; }
[data-theme-version="dark"] .tdl-holder label:hover {
  background-color: #333a54;
  color: #fff; }
[data-theme-version="dark"] .text-muted {
  color: #828690 !important; }
[data-theme-version="dark"] .modal-content {
  background: #1e2746; }
[data-theme-version="dark"] .modal-footer,
[data-theme-version="dark"] .modal-header {
  border-color: #333a54; }
[data-theme-version="dark"] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none; }
  [data-theme-version="dark"] .close:hover {
    color: #fff; }
[data-theme-version="dark"] .table strong {
  color: #fff; }
[data-theme-version="dark"] .text-dark {
  color: #828690 !important; }
[data-theme-version="dark"] .star-rating .product-review,
[data-theme-version="dark"] .new-arrival-content .item {
  color: #fff; }
[data-theme-version="dark"] .custom-dropdown .dropdown-menu {
  border-color: #333a54; }
[data-theme-version="dark"] .widget-stat .media > span {
  background: #333a54;
  border-color: #333a54;
  color: #fff; }
[data-theme-version="dark"] .plus-minus-input .custom-btn {
  background: #181f39;
  border-color: #333a54; }
[data-theme-version="dark"] .dropdown-divider,
[data-theme-version="dark"] .size-filter ul li {
  border-color: #333a54; }
[data-theme-version="dark"] .custom-select {
  border-color: #333a54;
  color: #828690;
  background: #181f39; }
[data-theme-version="dark"] .nav-tabs {
  border-color: #333a54; }
[data-theme-version="dark"] .mail-list .list-group-item.active i {
  color: #fff; }
[data-theme-version="dark"] hr {
  border-color: #333a54; }
[data-theme-version="dark"] .grid-col {
  background: #181f39; }
[data-theme-version="dark"] .noUi-target {
  border-color: #333a54;
  border-radius: 8px;
  box-shadow: none; }
  [data-theme-version="dark"] .noUi-target .noUi-connects {
    background: #333a54; }
[data-theme-version="dark"] .noUi-marker-large,
[data-theme-version="dark"] .noUi-marker {
  background: #333a54; }
[data-theme-version="dark"] .input-group-text {
  background: #333a54;
  color: #7e7e7e; }
[data-theme-version="dark"] .note-editor.note-frame {
  border-color: #333a54; }
  [data-theme-version="dark"] .note-editor.note-frame .btn {
    color: #fff; }
  [data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
    color: #fff; }
[data-theme-version="dark"] .widget-media .timeline .timeline-panel {
  border-color: #333a54; }
[data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
  border-color: #333a54; }
[data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
  border-color: #333a54; }
[data-theme-version="dark"] .list-group-item-action {
  color: #7e7e7e; }
[data-theme-version="dark"] .list-group-item-action:focus,
[data-theme-version="dark"] .list-group-item-action:hover,
[data-theme-version="dark"] .list-group-item-action:focus {
  background-color: #181f39;
  border-color: #181f39; }
[data-theme-version="dark"] .list-group-item.active {
  color: #fff;
  border-color: #3F9AE0; }
  [data-theme-version="dark"] .list-group-item.active:focus, [data-theme-version="dark"] .list-group-item.active:hover, [data-theme-version="dark"] .list-group-item.active:focus {
    background-color: #3F9AE0;
    border-color: #3F9AE0;
    color: #fff; }
[data-theme-version="dark"] .swal2-popup {
  background: #1e2746; }

[data-theme-version="dark"] .header {
  background-color: #1e2746; }
[data-theme-version="dark"] .header-left input {
  background: #181f39 !important;
  border-color: transparent;
  color: #fff !important; }
  [data-theme-version="dark"] .header-left input:focus {
    box-shadow: none;
    border-color: #3F9AE0; }
  [data-theme-version="dark"] .header-left input::placeholder {
    color: #fff; }
[data-theme-version="dark"] .header-left .search_icon {
  background: #181f39 !important; }
  [data-theme-version="dark"] .header-left .search_icon i {
    color: #fff; }
[data-theme-version="dark"] .header-right .right-sidebar a {
  color: #3F9AE0;
  border-color: #333a54; }
[data-theme-version="dark"] .header-right .header-profile > a.nav-link .header-info strong {
  color: #fff; }
[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  background: #181f39; }
[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff; }
  [data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
    color: #fff; }
[data-theme-version="dark"] .header-right .notification_dropdown .dropdown-item a {
  color: #fff; }

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
  background-color: #181f39; }
[data-theme-version="dark"] .nav-control {
  color: #fff; }
[data-theme-version="dark"] .brand-logo {
  color: #fff; }
  [data-theme-version="dark"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="dark"] .nav-header,
[data-theme-version="dark"] .nav-control {
  background-color: #181f39; }
[data-theme-version="dark"] .nav-control {
  color: #fff; }
[data-theme-version="dark"] .brand-logo {
  color: #fff; }
  [data-theme-version="dark"] .brand-logo:hover {
    color: #fff; }

[data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li > ul {
  background-color: #1e2746; }
[data-theme-version="dark"] .fixed-content-box {
  background-color: #1e2746; }
  [data-theme-version="dark"] .fixed-content-box .head-name {
    background: #1e2746;
    color: #fff;
    border-color: #333a54; }
  [data-theme-version="dark"] .fixed-content-box + .header + .deznav {
    background-color: #181f39; }
[data-theme-version="dark"] .deznav {
  background-color: #1e2746; }
  [data-theme-version="dark"] .deznav .metismenu > li > a {
    color: #b3b3b3; }
  [data-theme-version="dark"] .deznav .metismenu > li:hover > a, [data-theme-version="dark"] .deznav .metismenu > li:focus > a, [data-theme-version="dark"] .deznav .metismenu > li.mm-active > a {
    color: #fff; }
    [data-theme-version="dark"] .deznav .metismenu > li:hover > a::after, [data-theme-version="dark"] .deznav .metismenu > li:focus > a::after, [data-theme-version="dark"] .deznav .metismenu > li.mm-active > a::after {
      border-color: #fff; }
  [data-theme-version="dark"] .deznav .metismenu > li.mm-active ul ul {
    background-color: transparent; }
  [data-theme-version="dark"] .deznav .metismenu ul:after {
    background-color: #333a54; }
  [data-theme-version="dark"] .deznav .metismenu ul a:hover, [data-theme-version="dark"] .deznav .metismenu ul a:focus, [data-theme-version="dark"] .deznav .metismenu ul a.mm-active {
    color: #fff; }
  [data-theme-version="dark"] .deznav .metismenu ul a:before {
    background-color: #fff; }
  [data-theme-version="dark"] .deznav .metismenu a {
    color: #b3b3b3; }
  [data-theme-version="dark"] .deznav .metismenu .has-arrow:after {
    border-color: #fff; }

[data-theme-version="dark"] .sidebar-right {
  background-color: #1d2544; }
  [data-theme-version="dark"] .sidebar-right .nav-tabs {
    background-color: #1d2544;
    border-bottom: 1px solid #181f39; }
    [data-theme-version="dark"] .sidebar-right .nav-tabs .nav-link.active {
      background-color: #181f39; }

[data-theme-version="dark"] .footer {
  background-color: transparent; }
  [data-theme-version="dark"] .footer .copyright {
    background-color: transparent; }
    [data-theme-version="dark"] .footer .copyright p {
      color: #fff; }


.content-body .container-fluid{
    background: #f9f9f9;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a, [data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a, [data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li > ul, [data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li.mm-active > a {
  background-color: #ffffff;
}

ul.metismenu,span.nav-text{
  color: #000000;
  font-size: 13px;
  /* color: #333333; */
}

[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu > li:hover > a {
  background-color: #74b4e7;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li > a i {
	color: #5c5c5c;
	/* color: #BDBDBD; */
	/* font-weight: normal; */
}


.react-datepicker__input-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.react-datepicker__input-container input{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


/* Custome */
h3.text-white.mb-1.welcome-text {
  font-size: 27px;
}
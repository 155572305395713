.cmNavigations span i:hover {
    cursor: pointer;
}
.cmNavigations span i{
    color: #6392b7
}
.container-careermapping li {
    list-style: unset !important;
}
.cmNavigations.true span i {
    cursor: default;
    color: #6393b700
}
/* .cmNavigations:hover {
    cursor: pointer;
} */
.view-organo-i:hover{
    cursor: pointer;
}
.sectionA {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 25% auto;
}
.sectionA [class^="box-"]{
    /* display: grid;
    place-items: center; */
}

.core-comp-div{
    min-height: 4rem;
}
.core-comp-div .core-comp-div-ul{
    display: grid;
    grid-auto-flow: row;
    column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
}
.core-comp-div .core-comp-div-ul, .technical-comp-div .technical-comp-div-ul, .leadership-comp-div .leadership-comp-div-ul, .compliance-comp-div .compliance-comp-div-ul {
    display: grid;
    grid-auto-flow: row;
    column-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
}
.section-one-row{
    border-radius: 5px;
    /* border: 1px solid gray; */
    background-color: #def2fb;
}
.section-one-row .grade{
    /* padding-left: 0px; */
    border-bottom-left-radius: 5px;
    background-color: #bdd9f4;
}
.section-one-row .jd{
    /* padding-right: 0px; */
    border-bottom-right-radius: 5px;
    background-color: #a0ccf5;
}
.section-one-row .jd:hover{
   cursor: pointer;
   background-color: #add4e6cc;
}
.section-one-ttl {
    padding: .1rem 0rem;
}
.competency-section-ttl.text-center {
    padding: .5rem 0rem;
    background-color: #cce8fe;
    margin-bottom: 1rem;
    border-radius: 0.2rem;
}
.technical-comp-div{
    background-color: #e4f2ff;
    border-radius: 5px;
}

.twin-comp{
    margin-top: 15px;
}

.compliance-big-ttl{
    background-color: #def2fb;
   /* padding-top: 1.5rem;
   padding-bottom: 1.5rem; */
}
.compliance-big-ttl .compliance-courses-ttl{
    /* margin-top: 1.5rem;
    margin-bottom: 1.5rem; */
}
.compliance-big-ttl h4{
    margin: 0px;
}
.compliance-data{
    background-color: #e4f2ff;
    border-radius: 5px;
    margin-bottom: 2rem;
}
.job-kpi{
    background-color: #add4e6cc;
}
.job-kpi:hover{
    cursor: pointer;
    background-color: #afd8ec94;
}

  @media only screen and (max-width: 576px) {
    /* For mobile phones: */
    .technical-comp-div .technical-comp-div-ul, .leadership-comp-div .leadership-comp-div-ul, .compliance-comp-div .compliance-comp-div-ul {
        display: grid;
        grid-auto-flow: row;
        column-gap: 20px;
        grid-template-columns: repeat(1, 1fr);
    }
    .core-comp-div .core-comp-div-ul{
        display: grid;
        grid-auto-flow: row;
        column-gap: 20px;
        grid-template-columns: repeat(1, 1fr);
    }
    /* .container-organo img{

    } */
  }

  @media only screen and (max-width: 768px) {
    /* For tablets: */
   
    .core-comp-div .core-comp-div-ul{
        display: grid;
        grid-auto-flow: row;
        column-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
  }
  @media only screen and (min-width: 992px) {
    /* For desktop: */
  
    .core-comp-div .core-comp-div-ul{
        display: grid;
        grid-auto-flow: row;
        column-gap: 20px;
        grid-template-columns: repeat(3, 1fr);
    }
  }